import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { ChangeEvent, MouseEvent } from "react";
import { getStorageData } from "../../../../packages/framework/src/Utilities";
import { toast } from "react-toastify";
import { Team } from "../../../../packages/components/src/CustomKeyboards/AssignTeamModal";

export interface DuplicateCustomKeyboardResponse {
  error?: string;
  data: {
    id: string;
    type: string;
    attributes: {
      id: number;
      name: string;
      position_assigned: string;
      default_keyboard_for_position: string;
      team_compatibility: [];
      competition_compatibility: [];
      industry_compatibility: string;
      assign_players: string;
      keyboard_group_id: number;
      keyboard_action: [];
      created_by: string;
      missed_op_percentage: string;
      made_change_percentage: string;
      missed_change_percentage: string;
      is_active: boolean;
    }
  }
}

export interface CreateCustomKeyboardResponse {
  error?: string;
  data: {
    id: string;
    type: string;
    attributes: {
      id: number;
      name: string;
      position_assigned: null | string;
      default_keyboard_for_position: null | string;
      team_compatibility: [];
      competition_compatibility: [];
      industry_compatibility: null | string;
      assign_players: null | string;
      keyboard_group_id: number;
      keyboard_action: [];
      created_by: null | string;
      missed_op_percentage: string;
      made_change_percentage: string;
      missed_change_percentage: string;
      is_active: null | string;
    }
  }
}

export interface RenameKeyboardGroupResponse {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

export interface DefaultKeyboard {
  id: string;
  type: string;
  attributes: DefaultKeyboardAttribute;
}

export interface DefaultKeyboardAttribute {
  id: number;
  name: string;
  position_assigned: string;
  default_keyboard_for_position: string;
  team_compatibility: any[];
  competition_compatibility: any[];
  industry_compatibility: null;
  assign_players: null;
  keyboard_action: any[];
  created_by: null | string;
  missed_op_percentage: string;
  made_change_percentage: string;
  missed_change_percentage: string;
  is_active: null;
}

export interface GetDefaultKeyboardsResponse {
  data: Array<DefaultKeyboard>
}

export interface GetTeamResponse {
  data: Array<Team>;
}

export interface Keyboard {
  id: number;
  name: string;
  position_assigned: string;
  default_keyboard_for_position: string;
  team_compatibility: Array<string>;
  competition_compatibility: Array<string>;
  industry_compatibility: string;
  assign_players: string;
  createdBy: string;
  createdIn: string;
  created_by: string;
  missed_op_percentage: string;
  made_change_percentage: string;
  missed_change_percentage: string;
  timeUsed: number;
  is_active: boolean;
}

export interface KeyboardGroup {
  id: number;
  type: string;
  expanded: boolean;
  attributes: {
    id: number;
    name: string;
    keyboards: Array<Keyboard>;
    searchResult?: Array<Keyboard>;
    searchText?: string;
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  selectedKeyboard: Keyboard | undefined;
  defaultKBOpen: boolean;
  customKBOpen: boolean;
  createKbGrOpen: boolean;
  createKbGrName: string;
  createKbGrNameError: string;
  createCustomKbOpen: boolean;
  createCustomKbName: string;
  createCustomKbNameError: string;
  customKbTitle: string;
  renameKbGrOpen: boolean;
  renameKbGrName: string;
  renameKbGrNameError: string;
  duplicateKbOpen: boolean;
  kbInfoOpen: boolean;
  deleteKbOpen: boolean;

  defaultTableData: Array<DefaultKeyboard>;
  defaultSearchData: Array<DefaultKeyboard>;
  searchKey: string;

  customKeyboardGroup: Array<KeyboardGroup>;
  selectedCustomKeyboardGroup: KeyboardGroup | undefined;
  activateKbOpen: boolean;
  token: string;
  userRole: string;
  teams: Array<Team & { selected: boolean }>;
  isCoachOrTeamAdmin: boolean;

  defaultKbLoading: boolean;
  kbGroupLoading: boolean;
  subscriptionKeyboard: number | undefined;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfcustomkeyboardsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  maxKbGr: number = 50;
  maxCustomKb: number = 5;
  getKeyboardGroupsCallId: any;
  createKeyboardGroupsCallId: any;
  getDefaultKeyboardsCallId: string = '';
  renameKeyboardGroupCallId: string = '';
  createCustomKeyboardCallId: string = '';
  activateCustomKeyboardCallId: string = "";
  getTeamCallId: string = "";
  deleteCustomKeyboardCallId: string = "";
  duplicateCustomKeyboardCallId: string = '';
  getSubCallId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      selectedKeyboard: undefined,
      defaultKBOpen: true,
      customKBOpen: false,
      createKbGrOpen: false,
      createKbGrName: '',
      createKbGrNameError: '',
      createCustomKbOpen: false,
      createCustomKbName: '',
      createCustomKbNameError: '',
      customKbTitle: 'Custom Keyboards',
      renameKbGrOpen: false,
      renameKbGrName: 'Custom Keyboards',
      renameKbGrNameError: '',
      duplicateKbOpen: false,
      kbInfoOpen: false,
      deleteKbOpen: false,
      selectedCustomKeyboardGroup: undefined,
      activateKbOpen: false,
      token: '',
      customKeyboardGroup: [],

      // default keyboard
      defaultTableData: [],
      defaultSearchData: [],
      searchKey: '',

      userRole: '',
      teams: [],
      isCoachOrTeamAdmin: false,

      defaultKbLoading: true,
      kbGroupLoading: true,
      subscriptionKeyboard: undefined
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.getKeyboardGroups();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    this.handleGetKeyboardGroupsResponse(message);
    this.handleCreateKeyboardGroupResponse(message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      )

      const apiRequestCallIds = {
        [this.getDefaultKeyboardsCallId]: this.handleGetDefaultKeyboardsResponse,
        [this.renameKeyboardGroupCallId]: this.handleRenameKeyboardGroupResponse,
        [this.createCustomKeyboardCallId]: this.handleCreateCustomKeyboardRespone,
        [this.activateCustomKeyboardCallId]: this.handleActivateCustomKeyboardResponse,
        [this.getTeamCallId]: this.handleGetTeamResponse,
        [this.deleteCustomKeyboardCallId]: this.handleDeleteCustomKeyboardResponse,
        [this.duplicateCustomKeyboardCallId]: this.handleDuplicateCustomKeyboardResponse,
        [this.getSubCallId]: this.handleDueDateResponse
      }

      if (apiRequestCallId && apiRequestCallIds[apiRequestCallId]) {
        apiRequestCallIds[apiRequestCallId](responseJson, errorResponse);
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  handleGetKeyboardGroupsResponse = (message: Message) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getKeyboardGroupsCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.setState({ kbGroupLoading: false })

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const keyboardGroupsRes = responseJson.data;
      if (keyboardGroupsRes) {
        this.setState({ customKeyboardGroup: keyboardGroupsRes });
      }
    }
  }
  
    handleDueDateResponse = (responseJson: any, errorResponse?: string) => {
      if (!errorResponse && responseJson) {
        this.setState({
          subscriptionKeyboard:responseJson.subscription_keyboard
        })
      }
    }
  
  
  handleCreateKeyboardGroupResponse = (message: Message) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.createKeyboardGroupsCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson: { id: number } = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson?.id) {
        toast.success(configJSON.createKbGrSuccess, { position: 'top-center' });
        this.getKeyboardGroups();
      } else {
        toast.error(configJSON.createKbGrFail, { position: "top-center" });
      }
    }
  }

  handleDuplicateCustomKeyboardResponse = (responseJson: DuplicateCustomKeyboardResponse, errorResponse?: string) => {
    if (!errorResponse && responseJson?.data?.id && !responseJson?.error) {
      toast.success(configJSON.successMessage, { position: 'top-center' })

      this.setState(prev => ({
        customKeyboardGroup: prev.customKeyboardGroup.map(item => {
          if (item.id === prev.selectedCustomKeyboardGroup!.id) {
            const newGroup = item;

            newGroup.attributes.keyboards.push({ ...prev.selectedKeyboard!, ...responseJson.data.attributes })

            return newGroup;
          }

          return item;
        })
      }))

      this.handleCloseDuplicateKb();
    } else {
      toast.error(configJSON.failMessage, { position: "top-center" })
    }
  }

  handleDeleteCustomKeyboardResponse = (responseJson: { message: string }, errorResponse?: string) => {
    if (!errorResponse && responseJson.message && responseJson.message === "Keyboard deleted successfully") {
      toast.success(configJSON.successMessage, { position: 'top-center' });

      this.setState(prev => ({
        customKeyboardGroup: prev.customKeyboardGroup.map(item => {
          if (item.id === prev.selectedCustomKeyboardGroup!.id) {
            item.attributes.keyboards = item.attributes.keyboards.filter(keyboard => {
              if (keyboard.id === prev.selectedKeyboard!.id) {
                return false;
              }

              return true;
            })
          }

          return item;
        })
      }))

      this.handleCloseDeleteModal();
    } else {
      toast.error(configJSON.failMessage, { position: "top-center" });
    }
  }

  handleActivateCustomKeyboardResponse = (responseJson: { message: string }, errorResponse?: string) => {
    if (!errorResponse && responseJson.message && responseJson.message === "Toggle successful") {
      toast.success(configJSON.successMessage, { position: 'top-center' });

      this.setState(prev => ({
        customKeyboardGroup: prev.customKeyboardGroup.map(item => {
          if (item.id === prev.selectedCustomKeyboardGroup!.id) {
            item.attributes.keyboards = item.attributes.keyboards.map(keyboard => {
              if (keyboard.id === prev.selectedKeyboard!.id) {
                keyboard.is_active = !keyboard.is_active
              }

              return keyboard;
            })
          }

          return item;
        })
      }))

      this.handleCloseActivateModal();
    } else {
      toast.error(configJSON.failMessage, { position: "top-center" });
    }
  }

  getTeamApi = (token: string) => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.getTeamCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTeamURL
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  getKeyboardGroups = () => {
    if (!this.state.kbGroupLoading) {
      this.setState({ kbGroupLoading: true })
    }

    const token = window.localStorage.getItem("authToken");

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getKeyboardGroupsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllKBGroupsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  createKeyboardGroups = (name: string) => {
    const token = window.localStorage.getItem("authToken");

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createKeyboardGroupsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createKBGroupEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const httpBody = {
      keyboard_group: { name }
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  componentDidMount = async () => {
    const token = await getStorageData("authToken")
    const userData = sessionStorage.getItem('profile')

    if (userData) {
      const parsedUserData = JSON.parse(userData);
      const { role } = parsedUserData;

      this.setState({ userRole: role, isCoachOrTeamAdmin: role === "team admin" || role === "coach" })
    }

    this.setState({ token: token || "" })
    this.getSubscriptionDueData(token)
    this.getDefaultKeyboards(token)
    this.getTeamApi(token);
  }

  formatNumber = (text: string) => {
    if (!text) {
      return "";
    }

    let color = "#8DE301"
    const chars = text.split("")

    if (chars.includes("-")) {
      color = "#FF1919"
    }

    return color;
  }

  getSubscriptionDueData = (token: string) => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.getSubCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDueDateEndpoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  getDefaultKeyboards = (token: string) => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.getDefaultKeyboardsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDefaultKeyboardsURL
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  handleGetDefaultKeyboardsResponse = (responseJson: GetDefaultKeyboardsResponse, errorResponse?: string) => {
    this.setState({ defaultKbLoading: false })
    if (!errorResponse && responseJson) {
      this.setState({ defaultTableData: responseJson.data || [] })
    }
  }

  handleGetTeamResponse = (responseJson: GetTeamResponse, errorResponse?: string) => {
    if (!errorResponse && responseJson.data) {
      this.setState({ teams: responseJson.data.map((item, index: number) => ({ ...item, selected: index === 0 ? true : false })) })
    }
  }

  // search on keyboard group
  handleChangeSearchKey = (event: any, groupId: number) => {
    const searchText = event.target.value;
    const currentGroup = this.state.customKeyboardGroup.find(item => item.id === groupId);
    if (!currentGroup) return;
    const searchResult = currentGroup.attributes.keyboards.filter(item => item.name.toLowerCase().search(searchText.toLowerCase()) >= 0);
    currentGroup.attributes.searchText = searchText;
    currentGroup.attributes.searchResult = searchResult;
    this.setState({ customKeyboardGroup: [...this.state.customKeyboardGroup] });
  }

  handleDefaultKBCollapse = () => {
    this.setState(prev => ({ defaultKBOpen: !prev.defaultKBOpen }))
  }

  handleCustomKBCollapse = (groupId: number) => {
    this.setState(prev => ({
      customKeyboardGroup: prev.customKeyboardGroup.map(item => {
        if (item.id === groupId) {
          item.expanded = !item.expanded;
        }

        return item;
      })
    }))
  }

  handleActiveItem = (groupId: number, keyboardId: number) => {
    this.setState(prev => {
      const selectedCustomKeyboardGroup = prev.customKeyboardGroup.find(item => item.id === groupId);

      return {
        selectedCustomKeyboardGroup,
        selectedKeyboard: selectedCustomKeyboardGroup!.attributes.keyboards.find(item => item.id === keyboardId),
        activateKbOpen: true,
      }
    })
  }

  handleCloseCreateKbGrModal = () => {
    this.setState({
      createKbGrOpen: false, createKbGrName: '', createKbGrNameError: ''
    })
  }

  handleChangeCreateKbGrName = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ createKbGrName: event.target.value })

    if (!event.target.value) {
      this.setState({ createKbGrNameError: configJSON.emptyKbGrName })
    } else {
      this.setState({ createKbGrNameError: '' })
    }
  }

  handleOpenCreateKbGr = () => {
    this.setState({ createKbGrOpen: true })
  }

  handleCloseCreateCustomKbModal = () => {
    this.setState({
      createCustomKbOpen: false, createCustomKbName: '', createCustomKbNameError: ''
    });
  }

  handleSubmitCreateCustomKeyboadModal = () => {
    if (!this.state.createCustomKbName) {
      this.setState({ createCustomKbNameError: configJSON.emptyCustomKeyboardName })
    } else if (!this.state.createCustomKbNameError) {
      this.setState({ createCustomKbNameError: '' })

      const header = {
        "Content-Type": configJSON.exampleApiContentType,
        token: this.state.token
      }

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      )

      this.createCustomKeyboardCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createCustomKeyboardURL
      )
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      )
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postMethod
      )
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({
          "keyboard": {
            "name": this.state.createCustomKbName,
            "keyboard_group_id": String(this.state.selectedCustomKeyboardGroup!.id)
          }
        })
      )

      runEngine.sendMessage(requestMessage.id, requestMessage)
    }
  }

  handleCreateCustomKeyboardRespone = (responseJson: CreateCustomKeyboardResponse, errorResponse?: string) => {
    if (!errorResponse && responseJson && !responseJson.error) {
      toast.success(configJSON.createCustomKeyboardSuccess, { position: 'top-center' });
      this.props.navigation.navigate("CreateKeyboards", { id: responseJson.data.id });
    } else {
      toast.error(configJSON.createCustomKeyboardFail, { position: 'top-center' });
    }
  }

  handleChangeCreateCustomKbName = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value.trim()) {
      this.setState({ createCustomKbNameError: configJSON.emptyCustomKeyboardName })
    } else if (event.target.value && event.target.value.trimEnd().length > 20) {
      this.setState({ createCustomKbNameError: configJSON.maxKeyboardNameLengthExceeded })
    } else {
      this.setState({ createCustomKbNameError: '' })
    }

    this.setState({ createCustomKbName: event.target.value })
  }

  handleOpenCreateCustomKb = (groupId: number) => {
    if (this.state.customKeyboardGroup.find(item => item.id === groupId)!.attributes.keyboards.length < this.maxCustomKb && (this.state.userRole === 'team admin' || this.state.userRole === 'coach')) {
      this.setState(prev => ({ createCustomKbOpen: true, selectedCustomKeyboardGroup: prev.customKeyboardGroup.find(item => item.id === groupId) }))
    }
  }

  handleCloseRenameKbModal = () => {
    this.setState({ renameKbGrOpen: false, renameKbGrName: '', renameKbGrNameError: '' })
  }

  handleChangeRenameKbGrName = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      this.setState({ renameKbGrNameError: configJSON.emptyKbGrName })
    } else {
      this.setState({ renameKbGrNameError: '' })
    }

    this.setState({ renameKbGrName: event.target.value })
  }

  // rename custom keyboard group
  handleEditCustomKb = (event: MouseEvent, groupId: number) => {
    event.stopPropagation();
    this.setState(prev => {
      const selectedCustomKeyboardGroup = prev.customKeyboardGroup.find(item => item.id === groupId);

      return { renameKbGrOpen: true, selectedCustomKeyboardGroup, renameKbGrName: selectedCustomKeyboardGroup!.attributes.name }
    })
  }

  handleSubmitRenameCustomKb = () => {
    if (this.state.renameKbGrName) {
      const header = {
        "Content-Type": configJSON.exampleApiContentType,
        token: this.state.token
      }

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      )

      this.renameKeyboardGroupCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.renameKeyboardGroupURL.replace("keyboardGroupId", this.state.selectedCustomKeyboardGroup!.id)
      )
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      )
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.patchMethod
      )
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({
          keyboard_group: {
            name: this.state.renameKbGrName
          }
        })
      )

      runEngine.sendMessage(requestMessage.id, requestMessage)
    } else {
      this.setState({ renameKbGrNameError: configJSON.emptyKbGrName })
    }
  }

  handleRenameKeyboardGroupResponse = (responseJson: RenameKeyboardGroupResponse, errorResponse?: string) => {
    if (!errorResponse && responseJson && responseJson.id !== undefined) {
      this.setState(prev => ({
        renameKbGrOpen: false,
        selectedCustomKeyboardGroup: undefined,
        renameKbGrName: '',
        renameKbGrNameError: '',
        customKeyboardGroup: prev.customKeyboardGroup.map(item => {
          if (item.id === prev.selectedCustomKeyboardGroup!.id) {
            item.attributes.name = prev.renameKbGrName
          }

          return item;
        })
      }))

      toast.success("Successfully Renamed Keyboard Group!", { position: 'top-center' })
    } else {
      toast.error("Rename Keyboard Group Failed!", { position: 'top-center' })
    }
  }

  handleCloseDuplicateKb = () => {
    this.setState({ duplicateKbOpen: false })
  }

  handleSaveDuplicateKb = () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.duplicateCustomKeyboardCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.duplicateCustomKeyboardURL.replace("keyboardId", this.state.selectedKeyboard!.id)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  handleDuplicateCustomKb = (groupId: number, keyboardId: number) => {
    const selectedKeyboardGroup = this.state.customKeyboardGroup.find(item => item.id === groupId);

    if (selectedKeyboardGroup!.attributes.keyboards.length > this.maxCustomKb - 1) {
      toast.error(configJSON.maxKeyboardNumberReached, { position: 'top-center' })
    } else {
      this.setState(prev => ({
        duplicateKbOpen: true,
        selectedCustomKeyboardGroup: selectedKeyboardGroup,
        selectedKeyboard: prev.customKeyboardGroup.find(item => item.id === groupId)!.attributes.keyboards.find(item => item.id === keyboardId)
      }))
    }
  }

  handleCloseInfoModal = () => {
    this.setState({ kbInfoOpen: false, selectedKeyboard: undefined })
  }

  handleShowKbInfo = (groupId: number, keyboardId: number) => {
    this.setState(prev => ({
      selectedKeyboard: prev.customKeyboardGroup.find(item => item.id === groupId)!.attributes.keyboards.find(item => item.id === keyboardId),
      kbInfoOpen: true,
    }))
  }

  handleCloseDeleteModal = () => {
    this.setState({ selectedKeyboard: undefined, deleteKbOpen: false })
  }

  handleShowDeleteKb = (groupId: number, keyboardId: number) => {
    this.setState(prev => {
      const selectedGr = prev.customKeyboardGroup.find(item => item.id === groupId);

      return {
        selectedKeyboard: selectedGr!.attributes.keyboards.find(item => item.id === keyboardId),
        selectedCustomKeyboardGroup: selectedGr,
        deleteKbOpen: true
      }
    })
  }

  handleDeleteCustomKb = () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.deleteCustomKeyboardCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteCustomKeyboardUrl.replace("keyboardId", this.state.selectedKeyboard!.id)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  handleChangeSearchKeyDefaultKb = (event: ChangeEvent<HTMLInputElement>) => {
    const { defaultTableData } = this.state;
    const searchText = event.target.value;
    const searchResult = defaultTableData.filter(item => item.attributes.name.toLowerCase().search(searchText.toLowerCase()) >= 0);
    this.setState({
      searchKey: event.target.value,
      defaultSearchData: searchResult,
    });
  }

  handleSubmitCreateKbGrModal = () => {
    const { createKbGrName } = this.state;

    if (!createKbGrName) {
      this.setState({ createKbGrNameError: configJSON.emptyKbGrName })

      return;
    }

    this.createKeyboardGroups(createKbGrName);

    this.setState({
      createKbGrOpen: false,
      createKbGrName: '',
      createKbGrNameError: ''
    })
  }

  handleCloseActivateModal = () => {
    this.setState({ activateKbOpen: false, selectedKeyboard: undefined, selectedCustomKeyboardGroup: undefined })
  }

  handleSubmitActivate = () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.activateCustomKeyboardCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.activateCustomKeyboardURL.replace("keyboardId", this.state.selectedKeyboard!.id)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchMethod
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  handleSelectTeam = (item: Team & { selected: boolean }) => {
    this.setState(prev => ({
      teams: prev.teams.map(team => {
        if (item.id === team.id) {
          team.selected = true;
        } else {
          team.selected = false;
        }

        return team;
      })
    }))
  }

  handleEditItem = (customKeyboardId: number) => {
    this.props.navigation.navigate("CreateKeyboards", { id: customKeyboardId })
  }
  navigateAddContactUs() {
    const navigateTo = new Message(getName(MessageEnum.NavigationMessage));
    navigateTo.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'AddContactus'
    );
    navigateTo.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateTo);
  }
  getTotalKeygoardLength = () =>{
    return this.state.customKeyboardGroup.reduce((a: number, b: KeyboardGroup) => {
      return a + b.attributes.keyboards.length
    }, 0)
}
  // Customizable Area End
}
