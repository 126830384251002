import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  styled,
  Modal,
} from "@material-ui/core";
import { Item } from "../../../blocks/cfcustomkeyboards/src/CreateKeyboardsController";
import CheckBoxMirrorActions from "./CheckBoxMirrorActions";

interface Props {
  show: boolean;
  handleClose: Function;
  onCustomizePress?: Function;
  labels?: any;
  dataSource?: Item[];
  selecteditems?: Item[];
  onSaveSelectedItem?: Function;
  pickedItemsBank?: Item[];
  gameTitle?: string;
}

const Wrapper = styled(Modal)(({ theme }) => ({
  textTransform: "none",
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",

  "& .modal-body": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "80%",
    backgroundColor: "#252527",
    borderRadius: 10,
    overflow: "hidden",
    padding: 30,
  },

  "& .header-wrapper": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },

  "& .header-title-wrapper": {
    width: "70%"
  },
  "& .header-label-1": {
    color: "#CFD1D4",
    fontSize: 26,
    fontWeight: 400,
  },
  "& .header-label-2": {
    color: "#676767",
    fontSize: 20,
    fontWeight: 400,
  },

  "& .customize-btn": {
    textTransform: "none",
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 10,
    borderColor: "#FF881A",
    paddingLeft: 20,
    paddingRight: 20,
  },

  "& .customize-btn-title": {
    fontSize: 16,
    fontWeight: 400,
    color: "#FF881A",
  },

  "& .action-area": {
    borderRadius: 10,
    overflow: "hidden",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#FF881A",
    marginTop: 30,
    width: "100%",
  },

  "& .selected-actions": {
    height: 150,
    backgroundColor: "#1D1D1F",
    borderRadius: 10,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    margin: 20,
    paddingLeft: 30,
    paddingRight: 30,
    position: "relative",
  },

  "& .btn-wrapper": {
    marginTop: 15,
  },

  "& .back-btn": {
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#FF881A",
    color: "#FF881A",
    width: 150,
    textTransform: "none",
    marginRight: 20,
  },

  "& .save-btn": {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#FF881A",
    width: 150,
    textTransform: "none",
  },

  "& .action-bank-area": {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    margin: 20,
  },

  "& .action-bank-wrapper": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flex: 1,
  },

  "& .action-bank-title": {
    color: "#CFD1D4",
    fontSize: 16,
    fontWeight: 400,
    marginBottom: 10,
  },

  "& .action-bank-list": {
    width: 300,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
  },

  "& .action-item": {
    width: 120,
    height: 40,
    backgroundColor: "#1D1D1F",
    borderRadius: 10,
    fontSize: 10,
    marginRight: 5,
    marginTop: 3,
    color: "#CFD1D4",
    textTransform: "none"
  },

  "& .selected-action-item": {
    width: 120,
    height: 40,
    backgroundColor: "#252527",
    borderRadius: 10,
    fontSize: 10,
    marginRight: 5,
    marginTop: 3,
    color: "#CFD1D4",
    textTransform: "none"
  },

  "& .normal-text": {
    color: "#676767",
    fontSize: 16,
    fontWeight: 400,
    textAlign: "center",
  },

  "& .separate": {
    flex: 1,
  },
}));


const AddEditActionModal = (props: Props) => {
  const { show,
    handleClose,
    onCustomizePress = () => { },
    labels,
    dataSource = [],
    selecteditems = [],
    onSaveSelectedItem = () => { },
    pickedItemsBank,
    gameTitle = "",
  } = props;
  const [selectedActions, setSelectedActions] = useState<Item[]>([]);
  React.useEffect(() => {
    setSelectedActions(selecteditems.map(item => item));
  }, [show]);

  return (
    <Wrapper open={show} onClose={() => handleClose()}>
      <Box className="modal-body">
        <Box className="header-wrapper">
          <Box className="header-title-wrapper">
            <Typography className="header-label-1">
              Add / Edit {labels?.header}
            </Typography>
            
            <Typography className="header-label-2">
              {labels?.headerDetail}
            </Typography>
          </Box>
          
          <Button className="customize-btn" onClick={() => onCustomizePress()}>
            <Typography className="customize-btn-title">
              Customize {labels?.header}
            </Typography>
          </Button>
        </Box>

        <Box className="action-area">
          <Box className="selected-actions">
            {selectedActions.map((action: Item) => (
              <Button key={action.id} className="selected-action-item" onClick={() => {
                setSelectedActions(selectedActions.filter(element => element.id !== action.id));
              }}>
                {action?.name}
              </Button>
            ))}
            
            <Typography style={{
              position: "absolute",
              bottom: 0,
              right: 10,
              color: "#676767",
              fontSize: 18
            }}>
              Actions Remaining: 
              <span style={{
                color: dataSource.length === selectedActions.length ? "red" : "white"
              }}>{10-selectedActions.length}</span>
            </Typography>
          </Box>
          
          <Box className="action-bank-area">
            <Box className="action-bank-wrapper">
              <Typography className="action-bank-title">
                FantasticWE {gameTitle} Actions:
              </Typography>
              
              <Box className="action-bank-list">
                {dataSource?.map((action: Item, index: number) => {
                  if (!!selectedActions.find(element => element.id === action.id)) {
                    return undefined
                  }

                  return (
                    <Button
                      data-test-id="action-list-item"
                      key={action.id} className="action-item" onClick={() => {
                        if(selectedActions.length < 10){
                          setSelectedActions([...selectedActions, action])
                        }
                      }}>
                      {action?.name}
                    </Button>
                  )
                })}
              </Box>
            </Box>
            
            <Box className="separate" />
            
            <Box className="action-bank-wrapper">
              <Typography className="action-bank-title">
                Custom Added Actions:
              </Typography>
              
              {
                pickedItemsBank?.length ? 
                  pickedItemsBank?.map((action: Item) => (
                    <Button
                      key={action.id}
                      className="action-item"
                      onClick={() => {
                        if (!selectedActions.find(element => element.id === action.id)) {
                          setSelectedActions([...selectedActions, action]);
                        }
                      }}
                    >
                      {action?.name}</Button>
                  )) : 
                    <Typography className="normal-text">
                      No Custom Actions Added.Custom Actions that do not match formal
                      statistics will not be included in formal calculations.
                    </Typography>
              }
            </Box>
          </Box>
        </Box>
        
        <Box className="btn-wrapper">
            <Button className="back-btn" onClick={() => handleClose()}>Back</Button>
            
            <Button data-test-id="save-action-btn1" className="save-btn" onClick={() => {
              onSaveSelectedItem([ ...selectedActions ]);
              handleClose();
            }}>Save</Button>
        </Box>
      </Box>
    </Wrapper>
  )
}

export default AddEditActionModal;