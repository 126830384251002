import React from "react";
// Customizable Area Start
// Customizable Area End

import LandingPageController, {
  Props,
  configJSON,
} from "./LandingPageController";
import { Box, Button, Typography, styled } from "@material-ui/core";
import { bg_landing, group_logo, logo } from "./assets";

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderSpinnerContainer = (
    <Box
      style={{
        height: "100%",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img src={group_logo} width={280} height={140} alt="bg-spinner" />
    </Box>
  );
  renderContentContainer = (
    <Box className="container">
      <Box className="content">
        <Box className="titleContainer">
          <Typography className="titleText" component="h6">
            {configJSON.labelTitleText}
          </Typography>
        </Box>
        <Box className="groupSelection">
          <Button
            data-testid="btnSelectSport"
            className="select-gametype-btn"
            onClick={() => this.handleSelectSport("Sports")}
          >
            Sports
          </Button>
          <img className="logo" src={logo} />
          <Button
            data-testid="btnSelectESport"
            className="select-gametype-btn"
            onClick={() => this.handleSelectSport("eSports")}
          >
            Esports
          </Button>
        </Box>
      </Box>
    </Box>
  );
  // Customizable Area End

  render() {
    return (
      <LandingPageContainer>
        {this.renderContentContainer}
      </LandingPageContainer>
    );
  }
}

// Customizable Area Start

const LandingPageContainer = styled(Box)(({ theme }) => ({
  height: "100vh",
  "& .container": {
    display: "flex",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    backgroundImage: `url(${bg_landing})`,
    backgroundSize: "100% 100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("md")]: {
      backgroundSize: "cover",
    },
    "& .content": {
      marginTop: "100px",
      "& .titleText": {
        fontFamily: "Poppins",
        fontSize: "24px",
        fontWeight: 700,
        textAlign: "center",
        color: "#CFD1D4",
      },
      "& .groupSelection": {
        marginTop: "100px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "80px",
        [theme.breakpoints.down(560)]: {
          gap: "20px",
        },
        [theme.breakpoints.down(360)]: {
          gap: "5px",
        },
        "& .select-gametype-btn": {
          backgroundColor: "#252527",
          width: 320,
          height: 120,
          color: "#FF881A",
          fontSize: 36,
          borderRadius: 8,
          borderColor: "#FF881A",
          borderWidth: 3,
          borderStyle: "solid",
          textTransform: "none",
          "&:hover": {
            backgroundColor: "#FF881A",
            color: "#1D1D1F",
          },
        },

        "& .logo": {
          width: 440,
          height: 440,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 220,
        },

        "& .selection-item": {
          color: "white",
          padding: "0 36px 30px",
          background: "#1D1D1F",
          borderRadius: "10px",
          boxShadow: "-5px 4px 12px 0px #00000040 inset",
          width: "210px",
          height: "260px",
          display: "flex",
          flexDirection: "column",
          [theme.breakpoints.down(750)]: {
            width: "150px",
            height: "230px",
          },
          [theme.breakpoints.down(480)]: {
            padding: "0 8px 20px",
          },
          [theme.breakpoints.down(360)]: {
            padding: "0 4px 15px",
          },
          "& .selection-labelContainer": {
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderBottom: "1px solid #FF881A",
            marginBottom: "20px",
            "& .selection-label": {
              fontFamily: "Poppins",
              fontSize: "36px",
              fontWeight: 400,
              color: "#CFD1D4",
            },
          },
          "& .selection-btnContainer": {
            display: "flex",
            justifyContent: "center",
            "& .selection-btn": {
              fontFamily: "Poppins",
              fontSize: "14px",
              fontWeight: 400,
              textTransform: "capitalize",
              color: "#1D1D1F",
              background: "#FF881A",
              borderRadius: "8px",
              width: "100%",
              maxWidth: "120px",
            },
          },
        },
      },
    },
  },
}));

// Customizable Area End
