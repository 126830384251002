import React from "react";

import {
  Box,
  Button,
  TextField,
  Typography,
  Select,
  MenuItem,
  Modal,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";

import ContactusController, { Props, configJSON } from "./ContactusController";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import InputField from "../../../components/src/InputField.web";
import { Formik } from "formik";
import * as Yup from "yup";
import { arrowDown, arrowUp, closeIcon } from "./assets";
interface WrapperProps {
  show: boolean;
  onClose: () => void;
}
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  overrides: {
    MuiButton: {
      contained: {
      },
      root: {
        textTransform: "none"
      }
    },
    MuiPaper: {
      root: {
        backgroundColor: ""
      }
    },
    MuiStepper: {

      root: {
        padding: "2px",
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "#252527"
      }
    },
    MuiTypography: {
      root: {
        "&.infoTitle": {
          fontFamily: "Poppins",
          fontSize: "24px",
          fontWeight: 400,
          lineHeight: "24.69px",
          letterSpacing: "-0.005em",
          textAlign: "center",
          color: "#CFD1D4"

        },
        "&.inputLabel": {
          color: '#CFD1D4',
          gap: 8,
          width:'5rem'
        }
      }
    },

    MuiDialog: {
      paperWidthSm: {
        maxWidth: 800
      }
    },
    MuiDialogContent: {
      root: {
        padding: 0,
        "&:first-child": {
          paddingTop: 0
        }
      }
    },

  }
});
const validationSchema = Yup.object().shape({
  fullName: Yup.string().required('Full Name is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  subject: Yup.string().required('Subject is required'),
  message: Yup.string().required('Message is required'),
  inquiryCategory: Yup.object({
    value: Yup.string().required('Inquiry Category is required')
  }),
  noOfUsers: Yup.number()
    .when('inquiryCategory.value', {
      is: 'Custom Subscription(Enterprise)',
      then: Yup.number()
        .typeError('Number of users should be a number')
        .min(0, 'Number of Users should be greater than 0')
        .required('Number of users required'),
      otherwise: Yup.number().notRequired(),
    })
});

export default class AddContactus extends ContactusController {
  constructor(props: Props) {
    super(props);
  }

  renderSuccessModal(props: WrapperProps) {
    return (<Wrapper open={props.show} onClose={props.onClose}>
      <Box className='modal-body'>
        <img src={closeIcon} className="close-icon" onClick={props.onClose} />

        <Typography component="span" className="title-text">Success!</Typography>

        <Typography component="span" className="sub-text">Your feedback has been successfully submitted.</Typography>

        <Box className="divider" />

        <Box className="btn-row">
          <Box className="save-btn" onClick={props.onClose} data-test-id="empty-role-position-save-btn">Done</Box>
        </Box>
      </Box>
    </Wrapper>)
  }

  render() {
    const options = [
    {value: 'Custom Subscription(Enterprise)', label: 'Custom Subscription(Enterprise)'},  { value: 'Technical support', label: "Technical support" }, { value: 'Billing', label: "Billing" }, { value: 'Feature Request', label: 'Feature Request' }, { value: 'Feedback', label: 'Feedback' }
    ]
    return (
      // Customizable Area Start
      <div id="main_block">
        <ThemeProvider theme={theme}>
          <StyledUserProfileContainer>
            <NavigationMenu navigation={this.props.navigation} id="NavigationMenu" currentTabProp="video" />
            {this.renderSuccessModal({ show: this.state.isOpen, onClose: this.hideModal })}
            <Box className="content-wrapper">
              <Box className="left-content-wrapper">
                <Box className="content-container">

                  <Typography component="span" className="content-title" data-test-id="contactus">
                    Contact Us
                  </Typography>
                  <Box>
                    <Typography className="bottom-text" style={{ marginBottom: '8px' }}>Reach out to the support team or administrators of assistance, inquiries, or feedback.</Typography>
                  </Box>
                  <Formik
                    initialValues={{ fullName: '', email: '', subject: '', message: '',noOfUsers:'', inquiryCategory: { value: '' } }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      setSubmitting(false);
                      this.addContactFormSubmit(values)
                    }}
                    data-test-id="addcontactUs"
                  >

                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                      <form onSubmit={handleSubmit} >
                        <Box style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
                          <Box >
                            <InputItem>
                              <Typography variant="body1" className="inputLabel">Full Name</Typography>
                              <InputField
                                testID="fullNameInput"
                                name="fullName"
                                type="text"
                                placeholder="Enter Full Name"
                                value={values.fullName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="MuiFilledInput-underline inputField"
                                isHideMsgError={false}
                                validationMsg={touched.fullName ? errors.fullName: ''}
                              />

                            </InputItem>

                            <InputItem>
                              <Typography variant="body1" className="inputLabel">Email</Typography>
                              <InputField
                                testID="emailInput"
                                name="email"
                                placeholder="Enter Email"

                                type="text"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}

                                className="MuiFilledInput-underline inputField"
                                isHideMsgError={false}
                                validationMsg={touched.email ? errors.email: ''}
                              />

                            </InputItem>
                            <InputItem>
                              <Typography variant="body1" className="inputLabel">Subject</Typography>
                              <InputField
                                testID="subjectInput"
                                name="subject"
                                placeholder="Enter Subject"

                                type="text"
                                value={values.subject}
                                onChange={handleChange}
                                onBlur={handleBlur}

                                className="MuiFilledInput-underline inputField"
                                isHideMsgError={false}
                                validationMsg={touched.subject ? errors.subject: ''}
                              />

                            </InputItem>  
                            <Box className="pd5" style={{ display: 'flex', justifyContent: 'space-between', gap: '7rem',paddingBottom:'8px' }}>
                              <Typography variant="body1" className="inputLabel">Inquiry Category</Typography>
                              <div style={{ width: '410px' }}>
                                <div
                                  style={{
                                    padding: "0 6px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    borderRadius: 10,
                                    borderBottom: "1px solid #FF881A",
                                    flexDirection: "row",
                                    backgroundColor: "#1D1D1F",
                                    alignItems: "center",
                                  }}
                                >
                                  <Select
                                    data-test-id="accountSelectTest"
                                    displayEmpty
                                    renderValue={(value: any) => {
                                      if (!value) {
                                        return <span style={{
                                          color: "#676767",
                                          fontFamily: "Poppins",
                                          left: "400px !important"
                                        }}>Select Inquiry Category</span>
                                      }
                                      return value.value
                                    }}
                                    disableUnderline
                                    onChange={(e: any) => {setFieldValue("inquiryCategory", e.target.value);
                                      this.handleInquiryCategoryChange(e.target.value,setFieldValue)
                                    }}
                                    onBlur={handleBlur}
                                    onOpen={() => this.handleSelectDropDown(true)}
                                    onClose={() => this.handleSelectDropDown(false)}
                                    inputProps={{
                                      style: {},
                                      "aria-label": "Without label",
                                    }}
                                    open={this.state.enableField}
                                    style={{
                                      width: "100%",
                                      fontFamily: "Poppins",
                                      color: "#676767",
                                    }}
                                    IconComponent={() =>
                                      <img
                                        src={this.state.enableField ? arrowUp : arrowDown}
                                        alt="Arrow"
                                        style={{ width: 20, height: 20 }}
                                        onClick={(event) => {
                                          this.handleSelectDropDown(!this.state.enableField); 
                                        }}
                                      />
                                    }

                                    MenuProps={{

                                      PaperProps: {
                                        style: {
                                          border: "1px solid orange",
                                          fontFamily: "Poppins",
                                          color: "white",
                                          backgroundColor: "black",
                                          minWidth: '200px !important',
                                          top: '800px !important',
                                        },
                                      },
                                    }}
                                  >
                                    {options.map((inquiry: any, index: number) => {
                                      const selectedI = inquiry.value == values.inquiryCategory?.value
                                      return (
                                        <MenuItem
                                          style={{
                                            fontFamily: "Poppins",
                                            color: selectedI ? "#CFD1D4" : "white",

                                            backgroundColor: selectedI ? "#FF881A" : "intial",
                                          }}
                                          value={inquiry}
                                          key={index}
                                        >
                                          {inquiry.label}
                                        </MenuItem>
                                      )
                                    })}
                                  </Select>

                                </div>
                                <Box style={{ display: "block" }}>
                                  {<span className="validationMsg" style={{ visibility: "visible" }}>{touched.inquiryCategory && errors.inquiryCategory?.value}</span>}
                                </Box>
                              </div>
                            </Box>
                            {values.inquiryCategory?.value ==='Custom Subscription(Enterprise)' && ( <InputItem>
                              <Typography variant="body1" className="inputLabel">Number Of Users</Typography>
                              <InputField
                                testID="noOfUserInput"
                                name="noOfUsers"
                                placeholder="Enter Number Of Users"
                                type="text"
                                value={values.noOfUsers}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="MuiFilledInput-underline inputField"
                                isHideMsgError={false}
                                validationMsg={touched.noOfUsers ? errors.noOfUsers: ''}
                              />
                            </InputItem>)}
                          </Box>


                          <Box style={{ display: 'flex', justifyContent: 'center', width: "50%",marginLeft:'24px' }}>
                            <Box style={{ display: 'flex', alignItems: 'center', flex: '1', paddingLeft:'8px' }}>
                              <Typography variant="body1" className="inputLabel">Message</Typography>
                            </Box>
                            <Box style={{ flex: '2' }}>
                              <TextFieldStyle>
                                <TextField multiline
                                  className="inputField"
                                  variant="filled"
                                  minRows={16}
                                  placeholder="Enter Message here..."
                                  autoComplete='off'
                                  disabled={false}
                                  name="message"
                                  autoFocus={false}
                                  onChange={handleChange}
                                  value={values.message}
                                  onBlur={handleBlur}
                                />
                              </TextFieldStyle>
                              <Box style={{ display: "block" }}>
                                {<span className="validationMsg" style={{ visibility: "visible" }}>{touched.message && errors.message}</span>}
                              </Box>


                            </Box>

                          </Box>


                        </Box>



                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
                          <Button
                          data-test-id='back-btn'
                            variant="outlined"
                            style={{
                              color: "#FF881A",
                              borderColor: "#FF881A",
                              borderRadius: 8,
                              fontSize: 18,
                              padding: "10px 16px 10px 16px",
                              gap: 8,
                              fontFamily: "Montserrat",
                              fontWeight: 600,
                              lineHeight: "normal",
                              width: "200px",
                              height: '55px'
                            }}
                            onClick={this.btnBackProps.onPress}
                          >
                            Back
                          </Button>
                          <Button
                            type="submit"
                            variant="contained"
                            data-test-id="btnSubmit"
                            style={{
                              backgroundColor: "#FF881A",
                              borderRadius: 8,
                              fontSize: 18,
                              padding: "10px 16px 10px 16px",
                              gap: 8,
                              fontFamily: "Montserrat",
                              fontWeight: 600,
                              lineHeight: "normal",
                              width: "200px",
                              height: '55px',
                              marginLeft: '40px'
                            }}
                          >
                            Send
                          </Button>
                        </Box>
                      </form>
                    )}
                  </Formik>
                </Box>

              </Box>
            </Box>

          </StyledUserProfileContainer>

        </ThemeProvider>

      </div>
    );
  }
}
const Wrapper = styled(Modal)(({ theme }) => ({
  textTransform: "none",
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",

  "& *": {
    boxSizing: "border-box"
  },

  "& .modal-body": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "626px",
    backgroundColor: "#252527",
    borderRadius: 10,
    overflow: "auto",
    padding: "40px",
    gap: '32px',
    position: 'relative',
    border: '0px solid',
    outline: 'none'
  },

  "& .title-text": {
    fontFamily: 'Poppins',
    fontWeight: '700',
    fontSize: '20px',
    color: "#CFD1D4"
  },

  "& .sub-text": {
    fontFamily: "Poppins",
    fontWeight: "400",
    fontSize: '18px',
    color: "#676767",
    textAlign: 'center'
  },

  "& .divider": {
    width: "386px",
    height: '2px',
    backgroundColor: "#FF881A",
  },

  "& .btn-row": {
    display: 'flex',
    gap: "24px",
  },

  "& .back-btn": {
    width: "166px",
    height: '55px',
    borderRadius: '8px',
    border: 'solid 1px #FF881A',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    fontSize: '18px',
    color: "#FF881A",
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  "& .save-btn": {
    width: '166px',
    height: '55px',
    borderRadius: '8px',
    backgroundColor: '#FF881A',
    fontFamily: "Montserrat",
    fontWeight: '600',
    fontSize: '18px',
    color: "#1D1D1F",
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  "& .close-icon": {
    position: 'absolute',
    width: '24px',
    top: '20px',
    right: '20px',
    cursor: 'pointer'
  }
}))

const TextFieldStyle = styled(Box)({
  "& .MuiFilledInput-underline": {
    borderBottom: "1px solid #FF881A",
    boxShadow: "4px 4px 4px 0px #00000040 inset",
    borderRadius: "10px",
    backgroundColor: "#1D1D1F"
  },
  "& .MuiFilledInput-underline::before": {
    borderBottom: "none",
  },
  "& .MuiFilledInput-underline::after": {
    borderBottom: "none",
  },
  "& .MuiInputBase-input": {
    color: '#CFD1D4 !important',

  },
  "& .MuiFilledInput-input": {
    height: "25px",
    padding: '14px 12px !important',
    color: '#CFD1D4',
    fontSize: '16px',
    fontFamily: `"Poppins", sans-serif`,
    borderRadius: "10px",
    backgroundColor: "#1D1D1F"
  },
  "& .MuiFormControl-root": {
    width: '100%'
  },
  '& .validationMsg': {
    fontFamily: 'Poppins',
    color: '#FF1919',
    float: 'left',
    fontWeight: 400,
    fonstSize: '1rem',
    margin: '6px 0'
  },
  '& .lableStyle': {
    color: "#676767",
    fontWeight: 400,
    fontSize: '20px',
    paddingBottom: '4px'
  },
  '& input:-webkit-autofill': {
    '-webkit-box-shadow': '0 0 0 30px #1D1D1F inset !important',
    '-webkit-text-fill-color': '#CFD1D4 !important',
  },
})
const InputItem = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  paddingBottom: 10,
  gap: '7rem',
  "& .pd": {
    padding: "5px 0"
  },
  "& .flex3": {
    flex: 3
  },
  "& .flex4": {
    flex: 5
  },
  "& .select-container": {
    width: "416px"
  },
  "& .gameScore1": {
    width: 184
  }
  ,
  "& .gameScore2": {
    width: 184
  },
  "& .inputField": {
    width: "416px"
  },
  "& .opponentInputField": {
    width: "285px !important"
  },
  "& .rmdp-top-class": {
    width: '300px'
  },
  "& .rmdp-calendar": {
    border: '1px solid #FF881A',
    backgroundColor: '#1D1D1F',
    boxShadow: 'none',
    width: '100%'
  },
  "& .rmdp-day-picker > div:first-child": {
    width: '100%',
  },
  "& .rmdp-day": {
    color: '#CFD1D4',
    fontSize: '14px',
    fontFamily: `"Poppins", sans-serif`
  },
  "& .rmdp-week-day": {
    color: '#CFD1D4',
    fontSize: '14px',
    fontFamily: `"Poppins", sans-serif`
  },
  "& .rmdp-header-values": {
    color: '#FF881A',
    fontWeight: 700
  },
  "& .rmdp-day.rmdp-today span": {
    backgroundColor: '#FBB26E',
  },
  "& .rmdp-month-picker, .rmdp-year-picker": {
    color: '#FF881A',
    fontWeight: 700,
    backgroundColor: '#1D1D1F'
  },
  "& .rmdp-day.rmdp-selected span:not(.highlight)": {
    backgroundColor: '#FF881A',
    boxShadow: 'none',
    fontWeight: 500
  },
  "& .rmdp-day:not(.rmdp-disabled, .rmdp-day-hidden) span:hover": {
    backgroundColor: '#FF881A'
  },
  "& .rmdp-arrow": {
    border: 'solid #FF881A',
    borderWidth: '0 2px 2px 0'
  },
  "& .rmdp-arrow-container:hover": {
    backgroundColor: "transparent",
    boxShadow: 'none',
    border: 'none',
    borderWidth: 'none'
  },

  "& .rmdp-container ": {
    width: "417px",
    color: '#CFD1D4',
    "& .input-date": {
      background: "#1D1D1F",
      boxShadow: "4px 4px 4px 0px #00000040 inset",
      borderRadius: "10px",
      borderBottom: "1px solid #FF881A"
    },
    "& .input-date input": {
      width: "100%",
      height: "50px",
      background: "transparent",
      border: "unset",
      padding: "0 12px",
      color: "#CFD1D4",
      fontSize: "16px",
      fontFamily: "Poppins",
      fontWeight: 400
    },
    "& .input-date input:focus-visible": {
      outline: "unset"
    }
  },
  "& .icon-tabler-calendar-event ": {
    right: '16px !important'
  },
  "& .rmdp-input": {
    height: '56px',
    width: "100%",
    color: '#CFD1D4',
    background: '#1D1D1F',
    border: 'none',
    borderBottom: "1px solid #FF881A",
    boxShadow: "4px 4px 4px 0px #00000040 inset",
    borderRadius: "10px",
    margin: '1px 0',
    padding: '2px 5px',
    fontSize: '16px',
    fontWeight: 400
  },


}))
const StyledUserProfileContainer = styled(Box)(() => ({
  minHeight: "100vh",
  paddingBottom: "20px",
  boxSizing: "border-box",
  overflow: "hidden",
  position: "relative",
  '& .validationMsg': {
    fontFamily: 'Poppins',
    color: '#FF1919',
    float: 'left',
    fontWeight: 400,
    fonstSize: '1rem',
    margin: '6px 0'
  },
  "& .content-wrapper": {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    gap: "32px",
    boxSizing: "border-box",
    padding: "32px",

  },
  "& .bottom-text": {
    fontFamily: 'Poppins',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '21.69px',
    color: '#555555',
    marginTop: '20px',
    width: '100%'
  },
  "& .left-content-wrapper": {

    boxSizing: "border-box",
    width: "100%"
  },
  "& .MuiPaper-rounded": {
    top: "450px !important",
    minWidth: '200px !important'
  },
  "& .content-title": {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "28px",
    letterSpacing: " -0.02em",
    color: "#CFD1D4"
  },
  "& .MuiSelect-selectMenu": {
    minHeight: "2.188em"
  },
  "& .MuiSelect-select": {
    color: "#CFD1D4"
  },
  "& .content-container": {
    borderRadius: "10px",
    backgroundColor: "#252527",
    padding: "56px 62px",
    boxSizing: "border-box",
    height: "100%"
  },
  "& .permission-dropdown": {
    minWidth: '230px',
    height: '40px',

    "& .select-container": {
      height: '40px !important',
    },

    "& *:not(.css-26l3qy-menu)": {
      maxHeight: '100% !important'
    },

    "& .css-26l3qy-menu": {
      padding: "0 8px",
      borderRadius: '10px',
      border: "1px solid #FF881A",
      backgroundColor: "#1D1D20 !important",
      fontFamily: "Poppins",
      fontWeight: '400',
      fontSize: '18px',
      color: "#CFD1D4",

      "& .css-bqjh3z-option": {
        backgroundColor: "#1D1D20",
      },

      "& .css-1tlt3hm-option": {
        color: "#1D1D1F"
      },

      '& [class*="-option"]': {
        borderRadius: '8px',
        marginTop: '2px',
        cursor: 'pointer',

        "&:hover": {
          color: "#1D1D1F !important",
          backgroundColor: '#FF881A',
        },

        "&:last-child": {
          color: "#FF1919"
        }
      }
    }
  },
}))
// Customizable Area End
