export const add = require("../assets/add.png")
export const delete_button = require("../assets/button_delete.png")
export const check = require("../assets/check.png")
export const share = require("../assets/share.png")
export const edit = require("../assets/edit.png")
export const arrow = require("../assets/arrow.png")
export const button_back = require("../assets/button_back.png")
export const button_next = require("../assets/button_next.png")
export const button_savegrp = require("../assets/button_savegrp.png")
export const vector_ = require("../assets/vector_.png")
export const seperator1=require("../assets/seperator1.png")
export const defaultAvatar = require("../assets/default_user_avatar.png");