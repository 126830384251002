import React from "react";
import {
  Button,
  Typography,
  styled,
} from "@material-ui/core";
import { BorderColor, Add } from "@material-ui/icons";

interface Props {
  label: string;
  onClick?: Function;
  dataTestId?: string;
  disabled?: boolean;
}

const Wrapper = styled(Button)(({ theme }) => ({
  textTransform: "none",
  height: 40,
  paddingLEft: 10,
  paddingRight: 10,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",

  "& .label": {
    color: "#FF881A",
    fontSize: 14,
    marginRight: 5,
    fontWeight: "bold",
    marginLeft: 10,
  },

  "& .icon": {
    color: "#FF881A",
    fontSize: 20
  },
}));


const CustomButton = (props: Props) => {
  const { label, onClick = () => { }, disabled = false } = props;

  return (
    <Wrapper disabled={disabled} onClick={() => { if (!disabled) onClick() }} data-test-id={props.dataTestId}>
      <Typography className="label">{label}</Typography>
      {label.toUpperCase() === "ADD" && <Add className="icon" />}
      {label.toUpperCase() === "EDIT" && <BorderColor className="icon" />}
    </Wrapper>
  );
};

export default CustomButton;