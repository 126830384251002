// Customizable Area Start
import React from "react";
import { Box, styled, Button, Typography, Input, Snackbar } from "@material-ui/core";
import { KeyboardArrowLeft, Search } from "@material-ui/icons";

import CreateKeyboardsController, {
  Item,
  Position,
  Props,
  Staff,
} from "./CreateKeyboardsController";
import NavigationMenu from "../../../../packages/blocks/navigationmenu/src/NavigationMenu.web";
import ProcedureTable, { Step } from "../../../components/src/CustomKeyboards/ProcedureTable.web";
import CheckBoxRow from "../../../components/src/CustomKeyboards/CheckBoxRow.web";
import AddEditSubActionModal from "../../../components/src/CustomKeyboards/AddEditSubActionModal";
import InformModal from "../../../components/src/CustomKeyboards/InformModal";
import ItemsBankModal from "../../../components/src/CustomKeyboards/SubActionsBankModal";
import AddEditActionModal from "../../../components/src/CustomKeyboards/AddEditActionModal";
import CustomButton from "../../../components/src/CustomKeyboards/CustomButton";
import AssignRolePositionModal from "../../../../packages/components/src/CustomKeyboards/AssignRoleModal";
import AssignTeamModal from "../../../components/src/CustomKeyboards/AssignTeamModal";
import EmptyRolePositionModal from "../../../../packages/components/src/CustomKeyboards/EmptyRolePositionModal";
import SummanyTable from "../../../../packages/components/src/CustomKeyboards/SummanyTable";
import { defaultTeamImg } from "./assets";
import PreviewKeyboard from "../../../../packages/components/src/CustomKeyboards/PreviewKeyboard";
import AssignPlayerModal, { Player, Staff as PlayerStaff } from "../../../../packages/components/src/CustomKeyboards/AssignPlayerModal";
import UpdateKeyboardModal from "../../../../packages/components/src/CustomKeyboards/UpdateKeyboardModal";
// Customizable Area End

export default class CreateKeyboards extends CreateKeyboardsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderStepPartOne = (): Step[] => {
    let steps = [
      {
        label: "Team Compatibility",
        renderStepContent: () => this.renderTeamCompatibility(),
        required: true,
      },
      {
        label: "Competition Compatibility",
        renderStepContent: () => this.renderCompetition(),
        required: true,
      },
      {
        label: "Assign Roles / Positions",
        renderStepContent: () => this.renderAssignRole(),
        required: false,
      },
      {
        label: "Edit  Individual Players",
        renderStepContent: () => this.renderAssignPlayers(),
        required: false,
      },
    ];

    if (this.state.userRole !== 'team admin') {
      steps = steps.filter(item => item.label !== "Team Compatibility")
    }

    return steps;
  }

  stepsPart1 = [
    {
      label: "Team Compatibility",
      renderStepContent: () => this.renderTeamCompatibility(),
      required: true,
    },
    {
      label: "Competition Compatibility",
      renderStepContent: () => this.renderCompetition(),
      required: true,
    },
    {
      label: "Assign Roles / Positions",
      renderStepContent: () => this.renderAssignRole(),
      required: false,
    },
    {
      label: "Edit  Individual Players",
      renderStepContent: () => this.renderAssignPlayers(),
      required: false,
    },
  ];

  stepsPart2 = [
    {
      label: "Actions",
      renderStepContent: () => this.renderActions(),
      required: true,
    },
    {
      label: "Sub-Actions",
      renderStepContent: () => this.renderSubActions(),
      required: true,
    },
    {
      label: "Values",
      renderStepContent: () => this.renderValues(),
      required: true,
    },
    {
      label: "Reasons",
      renderStepContent: () => this.renderReason(),
      required: true,
    },
    {
      label: "Sub-Reasons",
      renderStepContent: () => this.renderSubReason(),
      required: false,
    },
  ];

  renderTeamCompatibility = () => {
    const selectedTeam = this.state.teams.filter(item => item.selected)

    return (
      <Box className="compatibility-wrapper">
        {
          selectedTeam.length > 0 ?
            <>
              {selectedTeam.map(item => (
                <Box key={String(item.id)} className="team-item"><img src={defaultTeamImg} width="100%" /></Box>
              ))}
            </> :
            <Typography className="unassigned-text">(Unassigned)</Typography>
        }

        {
          this.state.currentpart !== 1 || this.state.part1StepIndex !== 0 ?
            null :
            <CustomButton dataTestId="edit-team-btn" label="Edit" onClick={this.showAssignedTeam} />
        }
      </Box>
    )
  }

  renderCompetition = () => {
    const { selectedCompetitionCompatibilities, competitionCompatibilities, userRole, currentpart, part1StepIndex } = this.state;
    let disabled = currentpart !== 1 || part1StepIndex !== 1;

    if (userRole !== "team admin") {
      disabled = currentpart !== 1 || part1StepIndex !== 0;
    }

    return (
      <Box className="competition-wrapper">
        {competitionCompatibilities.map((element, index) => (
          <CheckBoxRow
            dataTestId="competition-checkbox"
            disabled={disabled}
            key={index + ""}
            checked={selectedCompetitionCompatibilities.map(item => item.id).includes(element.id)}
            label={element.name}
            onClick={() => this.selectCompetitionCompatibility(element)}
          />
        ))}
      </Box>
    )
  }

  renderAssignRole = () => {
    const checkSelected = (staffList: Array<Staff>, positionList: Array<Position>) => {
      for (let staff of staffList) {
        for (let position of positionList) {
          if (staff.selected || position.selected) {
            return true;
          }
        }
      }

      return false;
    }
    const isAssigned = checkSelected(this.state.staffList, this.state.positionList);
    let disabled = this.state.currentpart !== 1 || this.state.part1StepIndex !== 2;

    if (this.state.userRole !== "team admin") {
      disabled = this.state.currentpart !== 1 || this.state.part1StepIndex !== 1;
    }

    return (
      <Box className="competition-wrapper">
        {
          isAssigned ?
            <Typography className="assigned-text">(Assigned)</Typography> :
            <Typography className="unassigned-text">(Unassigned)</Typography>
        }
        {
          disabled ?
            null :
            <CustomButton dataTestId="edit-role-position-btn" label="Edit" onClick={this.showAssignRolePosition} />
        }
      </Box>
    )
  }

  renderAssignPlayers = () => {
    const checkSelected = (playerList: Array<Player>, playerStaffList: Array<PlayerStaff>) => {
      for (let player of playerList) {
        for (let staff of playerStaffList) {
          if (player.selected || staff.selected) {
            return true;
          }
        }
      }

      return false;
    }
    const isAssigned = checkSelected(this.state.playerList, this.state.playerStaffList);
    let disabled = this.state.currentpart !== 1 || this.state.part1StepIndex !== 3;

    if (this.state.userRole !== "team admin") {
      disabled = this.state.currentpart !== 1 || this.state.part1StepIndex !== 2;
    }

    return (
      <Box className="competition-wrapper">
        {
          isAssigned ?
            <Typography className="assigned-text">(Assigned)</Typography> :
            <Typography className="unassigned-text">(Unassigned)</Typography>
        }
        {
          disabled ?
            null :
            <CustomButton dataTestId="edit-player-btn" label="Edit" onClick={this.showAssignPlayer} />
        }
      </Box>
    )
  }

  renderActions = () => {
    const isShowEditBtn = this.state.currentpart === 2 && this.state.part2StepIndex === 0;
    const { selectedActions } = this.state;
    return (
      <Box className="action-list-wrapper">
        {this.state.selectedActions.map((element: Item, index: number) => (
          <Button key={element.id} className="action-btn">{element.name}</Button>
        ))}
        {isShowEditBtn && <CustomButton data-test-id="show-action-modal-btn" label={!selectedActions.length ? "Add" : "Edit"} onClick={this.showEditAction} />}
      </Box>
    )
  }

  renderSubActions = () => {
    const isShowEditBtn = this.state.currentpart === 2 && this.state.part2StepIndex === 1;
    const { selectedSubActions, selectedActions } = this.state;
    return (
      <Box className="action-list-wrapper">
        {!selectedSubActions.length && !!selectedActions.length && <Typography className="unassigned-label">This Action does not have any Sub-Actions</Typography>}
        {!selectedSubActions.length && !selectedActions.length && <Typography className="unassigned-label">Please add an action in order to add its’ Sub-Actions.</Typography>}
        {this.filterUniqueItems(this.state.selectedSubActions).map((element, index) => (
          <Button key={element.id} className="action-btn">{element.name}</Button>
        ))}
        {isShowEditBtn && <CustomButton label={!selectedSubActions.length ? "Add" : "Edit"} onClick={this.showEditSubAction} />}
      </Box>
    )
  }

  renderValues = () => {
    const isShowEditBtn = this.state.currentpart === 2 && this.state.part2StepIndex === 2;
    const { selectedValues, selectedActions } = this.state;
    return (
      <Box className="action-list-wrapper">
        {!selectedValues.length && !!selectedActions.length && <Typography className="unassigned-label">This Action does not have any Values</Typography>}
        {!selectedValues.length && !selectedActions.length && <Typography className="unassigned-label">Please add an action to add/edit the values it contains. Missed-OP value will be assigned by default for every action.</Typography>}
        {this.filterUniqueItems(this.state.selectedValues).map((element, index) => (
          <Button key={element.id} className="action-btn">{element.name}</Button>
        ))}
        {isShowEditBtn && <CustomButton label={!selectedValues.length ? "Add" : "Edit"} onClick={this.showEditValues} />}
      </Box>
    )
  }

  renderReason = () => {
    const isShowEditBtn = this.state.currentpart === 2 && this.state.part2StepIndex === 3;
    const { selectedReasons, selectedActions } = this.state;
    return (
      <Box className="action-list-wrapper">
        {!selectedReasons.length && !!selectedActions.length && <Typography className="unassigned-label">This Action does not have any Reasons</Typography>}
        {!selectedReasons.length && !selectedActions.length && <Typography className="unassigned-label">Please add an action in order to add its Reasons.</Typography>}
        {this.filterUniqueItems(this.state.selectedReasons).map((element, index) => (
          <Button key={element.id} className="action-btn">{element.name}</Button>
        ))}
        {isShowEditBtn && <CustomButton label={!selectedReasons.length ? "Add" : "Edit"} onClick={this.showEditReasons} />}
      </Box>
    )
  }

  renderSubReason = () => {
    const isShowEditBtn = this.state.currentpart === 2 && this.state.part2StepIndex === 4;
    const { selectedSubReasons, selectedActions } = this.state;
    return (
      <Box className="action-list-wrapper">
        {!selectedSubReasons.length && !!selectedActions.length && <Typography className="unassigned-label">This Action does not have any Sub-Reasons</Typography>}
        {!selectedSubReasons.length && !selectedActions.length && <Typography className="unassigned-label">Please add an action in order to add its Sub-Reasons.</Typography>}
        {this.filterUniqueItems(this.state.selectedSubReasons).map((element, index) => (
          <Button key={element.id} className="action-btn">{element.name}</Button>
        ))}
        {isShowEditBtn && <CustomButton label={!selectedSubReasons.length ? "Add" : "Edit"} onClick={this.showEditSubReasons} />}
      </Box>
    )
  }

  renderPart1 = () => {
    const { part1StepIndex } = this.state;
    return (
      <Box className="create-keyboard-box">
        <Box className="procedure-header">
          <Button className="procedure-back-btn" data-test-id="part-one-back-btn" onClick={this.handlePartOneBackBtn}>
            <KeyboardArrowLeft className="arrow-back-icon" />
          </Button>
          <Box className="procedure-label-wrapper">
            <Typography className="label1">Custom Keyboards - <span style={{ color: "#FF881A" }}>Part 1 (Create Keyboard)</span></Typography>
            <Typography className="label2">In this page you will create a custom keyboard and assign default keyboards to the team that you work with.</Typography>
          </Box>
          <Box className="seperate-blank" />
          <Box className="search-keyboard">
            <Input className="search-input" placeholder="Search Keyboard........" />
            <Search className="search-icon" />
          </Box>
        </Box>
        <ProcedureTable
          currentStep={part1StepIndex}
          steps={this.renderStepPartOne()}
        />
        <Box className={`next-back-button-wrapper${part1StepIndex === 0 ? " justify-end" : ""}`}>
          {
            part1StepIndex === 0 ?
              null :
              <Button
                className="back-btn"
                onClick={() => {
                  if (part1StepIndex >= 1) this.setState({ part1StepIndex: part1StepIndex - 1 });
                }}
              >
                Back
              </Button>
          }
          <Button
            data-test-id="part-one-next-btn"
            className="next-btn"
            disabled={this.disabledPartOneNextBtn()}
            onClick={this.handlePartOneNextBtn}>Next</Button>
        </Box>
      </Box>
    )
  }

  renderPart2 = () => {
    const {
      part2StepIndex,
    } = this.state;
    return (
      <Box className="create-keyboard-box">
        <Box className="procedure-header">
          <Button className="procedure-back-btn" onClick={() => this.setState({ currentpart: 1 })}>
            <KeyboardArrowLeft className="arrow-back-icon" />
          </Button>
          <Box className="procedure-label-wrapper">
            <Typography className="label1">Custom Keyboards - <span style={{ color: "#FF881A" }}>Part 2 (Create Keyboard)</span></Typography>
            <Typography className="label2">This table holds your team's keyboards. You can add keyboards based on your subscription limit. Deleting a keyboard creates space for a new one, but it also removes any associated markings - Reports are saved.</Typography>
          </Box>
          <Box className="seperate-blank" />
          <Box className="search-keyboard">
            <Input className="search-input" placeholder="Search Keyboard........" />
            <Search className="search-icon" />
          </Box>
        </Box>
        <SummanyTable
          id={this.state.keyboardInfo?.data.attributes.id}
          name={this.state.keyboardInfo?.data.attributes.name}
          created_by={`${this.state.keyboardInfo?.data.attributes?.createt_by?.data?.attributes?.first_name || ""} ${this.state.keyboardInfo?.data.attributes?.createt_by?.data?.attributes?.last_name || ""}`}
          created_at={this.state.keyboardInfo?.data?.attributes.createt_at}
        />
        <ProcedureTable
          currentStep={part2StepIndex}
          steps={this.stepsPart2}
        />
        <Typography style={{
          color: "#676767",
          fontSize: 20,
          alignSelf: "center",
          textAlign: "center",
          marginTop: 20
        }}>A Keyboard will not be created until there are at least (1 Reason, 1 Value) - Per Action<span style={{ color: "#FF1919" }}>*</span></Typography>
        <Box className="next-back-button-wrapper">
          <Button
            className="back-btn"
            onClick={() => {
              if (part2StepIndex >= 1) this.setState({ part2StepIndex: part2StepIndex - 1 });
            }}>
            Back
          </Button>

          <Button data-test-id="part-two-next-btn" className={this.part2SubmitBtnClass()} onClick={this.part2ClickNext}>
            {this.getCreateKeyboardLabel()}
          </Button>
        </Box>
      </Box>
    )
  }
  // Customizable Area End

  render() {
    const {
      currentpart,
      selectedActions,
    } = this.state;

    return (
      // Customizable Area Start
      <StyledCustomKeyboard>
        <NavigationMenu navigation={this.props.navigation} id="NavigationMenu" currentTabProp="settings" />

        <Snackbar
          open={this.state.openSnackBar}
          onClose={this.closeSnackBar}
          message={this.state.snackBarMessage}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          action={<React.Fragment><Button color="secondary" size="small" onClick={this.closeSnackBar}>UNDO</Button></React.Fragment>
          }
        />

        {/* Update Keyboard Confirm Modal */}
        <UpdateKeyboardModal show={this.state.showUpdateKeyboardModal} onClose={this.hideUpdateKeyboardModal} onSave={this.saveUpdateKeyboardModal} keyboardName={this.state.keyboardInfo?.data.attributes.name!} />

        {/* Assign Staffs / Players */}
        <AssignPlayerModal show={this.state.showAssignPlayerModal} onClose={this.hideAssignPlayer} staffList={this.state.playerStaffList} playerList={this.state.playerList} onSave={this.savePlayers} />

        {/* Assign Roles / Positions */}
        <AssignRolePositionModal show={this.state.showAssignRolePositionModal} onClose={this.hideAssignRolePosition} onSave={this.saveRolePosition} staffList={this.state.staffList} positionList={this.state.positionList} />

        {/* Empty Role and Position confirm modal */}
        <EmptyRolePositionModal show={this.state.showEmptyRolePositionModal || this.state.showEmptyPlayerModal} onClose={this.hideEmptyRolePosition} onSave={this.saveEmptyRolePosition} selecting={this.state.showEmptyRolePositionModal ? "members or roles" : "staffs or players"} />

        {/* Assign Team */}
        <AssignTeamModal
          show={this.state.showAssignTeamModal}
          onClose={this.hideAssignTeam}
          teams={this.state.teams}
          onSave={this.saveTeams}
        />

        {/* Actions */}
        <AddEditActionModal
          show={this.state.showEditActionModal}
          handleClose={this.hideEditAction}
          onCustomizePress={this.showActionBank}
          dataSource={this.state.defaultActions}
          selecteditems={selectedActions}
          onSaveSelectedItem={(items: Item[]) => this.saveAction([...items])}
          labels={{
            header: "Actions",
            headerDetail: "Here you will be assigning / creating all of the actions you would wish this keyboard to have, Or select existing actions for your new / existing keyboard."
          }}
          gameTitle={this.state.gameTitle}
        />
        <ItemsBankModal
          show={this.state.showActionBankModal}
          handleClose={this.hideActionBank}
          groupItems={this.state.actionsBankGroup}
          bankItems={this.state.actionsBank}
          setPickedItemsBank={(items: any[]) => this.saveActionBank(items)}
          labels={{
            header: "Action Bank",
            headerDetail: "Here you will be assigning / Selecting all of the actions you would wish this keyboard to have, Or select existing actions for your new / existing keyboard.",
            tableColumn1Label: "Group Name",
            tableColumn2Label: "Actions Name",
          }}
        />

        {/* Sub-Actions  */}
        <AddEditSubActionModal
          show={this.state.showEditSubActionModal}
          handleClose={this.hideEditSubAction}
          onCustomizePress={this.showSubActionBank}
          headerData={this.state.selectedActions}
          bottomDotedlineData={this.state.subActions}
          onSavePress={(items: Item[]) => this.saveSubAction([...items])}
          initialSelectedItems={this.state.selectedSubActions}
          labels={{
            header: "Sub-Actions",
            headerDetail: "Here you will be assigning / creating and assigning all of the Sub-actions you would wish your actions to have."
          }}
        />
        <ItemsBankModal
          show={this.state.showSubActionBankModal}
          handleClose={this.hideSubActionBank}
          groupItems={this.state.subActionsBankGroup}
          bankItems={this.state.subActionsBank}
          setPickedItemsBank={this.setPickedSubActionsBank}
          labels={{
            header: "Sub-Action Bank",
            headerDetail: "Here you will be assigning / Selecting all of the sub-actions you would wish this keyboard to have, Or select existing actions for your new / existing keyboard.",
            tableColumn1Label: "Group Name",
            tableColumn2Label: "Sub-Actions Name",
          }}
        />

        {/* Values  */}
        <AddEditSubActionModal
          show={this.state.showEditValuesModal}
          handleClose={this.hideEditValues}
          onCustomizePress={this.showValuesBank}
          headerData={this.state.selectedActions}
          bottomDotedlineData={this.state.values}
          onSavePress={(items: Item[]) => this.saveValue([...items])}
          initialSelectedItems={this.state.selectedValues}
          labels={{
            header: "Values",
            headerDetail: "Here you will be assigning all of the values to your desired actions, please note that Missed-OP is set as a default value for every action."
          }}
        />
        <ItemsBankModal
          show={this.state.showValuesBankModal}
          handleClose={this.hideValuesBank}
          groupItems={this.state.valuesBankGroup}
          bankItems={this.state.valuesBank}
          setPickedItemsBank={this.setPickedValuesBank}
          labels={{
            header: "Value Bank",
            headerDetail: "Here you will be assigning / Selecting all of the values you would wish this keyboard to have, Or select existing actions for your new / existing keyboard.",
            tableColumn1Label: "Group Name",
            tableColumn2Label: "Values Name",
          }}
        />

        {/* Reasons */}
        <AddEditSubActionModal
          show={this.state.showEditReasonsModal}
          handleClose={this.hideEditReasons}
          onCustomizePress={this.showReasonsBank}
          headerData={this.state.selectedActions}
          bottomDotedlineData={this.state.reasons}
          onSavePress={(items: Item[]) => this.saveReason([...items])}
          initialSelectedItems={this.state.selectedReasons}
          labels={{
            header: "Reasons",
            headerDetail: "Here you will be assigning / creating all of the Reasons you would wish this keyboard to have."
          }}
        />
        <ItemsBankModal
          show={this.state.showReasonsBankModal}
          handleClose={this.hideReasonsBank}
          groupItems={this.state.reasonsBankGroup}
          bankItems={this.state.reasonsBank}
          setPickedItemsBank={this.setPickedReasonsBank}
          labels={{
            header: "Reason Bank",
            headerDetail: "Here you will be assigning / Selecting all of the reasons you would wish this keyboard to have, Or select existing actions for your new / existing keyboard.",
            tableColumn1Label: "Group Name",
            tableColumn2Label: "Reasons Name",
          }}
        />

        {/* Sub-Reasons */}
        <AddEditSubActionModal
          show={this.state.showEditSubReasonsModal}
          handleClose={this.hideEditSubReasons}
          onCustomizePress={this.showSubReasonsBank}
          headerData={this.state.selectedActions}
          bottomDotedlineData={this.state.subReasons}
          onSavePress={(items: Item[]) => this.saveSubReason([...items])}
          initialSelectedItems={this.state.selectedSubReasons}
          labels={{
            header: "Sub-Reasons",
            headerDetail: "Here you will be assigning / creating all of the Sub-Reasons you would wish this keyboard to have."
          }}
        />
        <ItemsBankModal
          show={this.state.showSubReasonsBankModal}
          handleClose={this.hideSubReasonsBank}
          groupItems={this.state.subReasonsBankGroup}
          bankItems={this.state.subReasonsBank}
          setPickedItemsBank={this.setPickedSubReasonsBank}
          labels={{
            header: "Sub-Reason Bank",
            headerDetail: "Here you will be assigning / Selecting all of the sub-reasons you would wish this keyboard to have, Or select existing actions for your new / existing keyboard.",
            tableColumn1Label: "Group Name",
            tableColumn2Label: "Sub-Reasons Name",
          }}
        />

        {currentpart === 1 && this.renderPart1()}
        {currentpart === 2 && this.renderPart2()}

        {currentpart === 2 && <PreviewKeyboard
          actions={this.state.selectedActions}
          subActions={this.state.selectedSubActions}
          values={this.state.selectedValues}
          reasons={this.state.selectedReasons}
          subReasons={this.state.selectedSubReasons}
          comment={this.state.comment}
          setComment={(commentText: string) => this.setState({ comment: commentText })}
          isShowPreviewBadge={true}
        />}
        <InformModal
          show={this.state.informModal.show}
          header={this.state.informModal.header}
          title={this.state.informModal.title}
          onClose={this.onCloseInformModalPress}
        />
      </StyledCustomKeyboard>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyledCustomKeyboard = styled(Box)(({ theme }) => ({
  "& .create-keyboard-box": {
    backgroundColor: "#252527",
    marginLeft: 40,
    marginRight: 40,
    borderRadius: 10,
    padding: 20,
    marginTop: 20,
  },

  "& .next-back-button-wrapper": {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },

  "& .next-btn": {
    width: 150,
    height: 45,
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 6,
    borderColor: "#FF881A",
    color: "#FF881A",
    textAlign: "center",
    marginTop: 20,
    paddingTop: 10,
    textTransform: 'initial',
    fontSize: '18px'
  },

  "& .finish-btn": {
    width: 150,
    height: 45,
    borderRadius: 6,
    color: "#1D1D1F",
    backgroundColor: "#8DE301",
    textAlign: "center",
    marginTop: 20,
    paddingTop: 10,
    fontWeight: 400,
    textTransform: 'initial',
    fontSize: '16px',
    lineHeight: '18px',
  },

  "& .update-btn": {
    width: 150,
    borderRadius: 6,
    height: 45,
    textTransform: 'initial',
    color: "#1D1D1F",
    paddingTop: 10,
    fontWeight: 400,
    backgroundColor: "#FF881A",
    textAlign: "center",
    marginTop: 20,
    fontSize: 16,
  },

  "& .back-btn": {
    width: 150,
    height: 45,
    color: "#1D1D1F",
    textAlign: "center",
    marginTop: 20,
    backgroundColor: "#FF881A",
    paddingTop: 10,
    fontSize: '18px',
    textTransform: 'initial'
  },

  "& .procedure-header": {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    marginLeft: -20,
    marginTop: 10,
    marginBottom: 20
  },

  "& .procedure-back-btn": {
    width: 52,
    height: 48,
    borderStyle: "solid",
    borderWidth: 2,
    borderColor: "#FF881A"
  },

  "& .arrow-back-icon": {
    color: "#FF881A"
  },

  "& .procedure-label-wrapper": {
    flex: 10,
    marginLeft: 30,
  },

  "& .label1": {
    fontSize: 30,
    fontWeight: 400,
    color: "#CFD1D4",
    marginTop: 7
  },

  "& .label2": {
    fontSize: 20,
    color: "#676767",
    fontWeight: 400,
    marginTop: 10
  },

  "& .seperate-blank": {
    flex: 1
  },

  "& .search-keyboard": {
    flex: 5,
    backgroundColor: "#1D1D1F",
    height: 60,
    alignSelf: "flex-end",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 10,
    overflow: "hidden",
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 5,

    "& .search-input": {
      flex: 1,
      height: 60,
      color: "#CFD1D4",
    },

    "& .search-icon": {
      width: 30,
      height: 30,
      color: "#CFD1D4"
    },
  },

  "& .action-list-wrapper": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "center",
    width: "100%"
  },

  "& .action-btn": {
    width: "40%",
    height: 40,
    backgroundColor: "#1D1D1F",
    margin: 4,
    borderRadius: 8,
    fontSize: 14,
    textTransform: "none",
    color: "#CFD1D4",
    whiteSpace: 'nowrap',
  },

  "& .competition-wrapper": {
    width: "60%",
    marginTop: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },

  "& .compatibility-wrapper": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    marginTop: 10,
    flexWrap: "wrap",
    "& .team-item": {
      width: 88,
      height: 88,
      borderRadius: 44,
      overflow: "hidden",
      backgroundColor: "#1D1D1F",
      margin: 5
    }
  },

  "& .unassigned-text": {
    color: "#FF1919",
    fontWeight: 400,
    fontSize: 16
  },

  "& .unassigned-label": {
    color: "#676767",
    fontWeight: 400,
    fontSize: 16,
    textAlign: "center",
    marginLeft: 20,
    marginRight: 20,
    marginTop: 10
  },

  "& .assigned-text": {
    color: "#8DE301",
    fontWeight: 400,
    fontSize: 16
  },

  "& .justify-end": {
    justifyContent: 'end'
  }
}));
// Customizable Area End
