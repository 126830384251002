// Customizable Area Start
import React from "react";
import { Typography } from "@material-ui/core";

const width = 400;
const height = (width * 2) / 3;

const ExternalVideoView = ({ link, externalPlayer }) => {
  return !link ? null : (
    <div style={webStyles.container}>
      <Typography data-testid="titleText" variant="h2" style={webStyles.title}>
        External Video
      </Typography>

      <video
        style={webStyles.video}
        autoPlay
        ref={externalPlayer}
        src={link}
        data-testid="video"
      />
    </div>
  );
};

const webStyles = {
  container: {
    borderRadius: 8,
    padding: 8,
    margin: 8,
    backgroundColor: "#3E84F6",
    display: "flex",
  },
  title: {
    color: "#3E84F6",
  },
  video: {
    borderRadius: 8,
    height,
    width,
    backgroundColor: "black",
  },
};
// Customizable Area End

export default ExternalVideoView;
