import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import * as Yup from "yup";
import { imgPasswordVisible, imgPasswordInVisible } from "./assets";

interface ResetPassResponse {
  id: string;
  type: string;
  attributes: {
    activated: boolean;
    country_code: string | null;
    email: string;
    first_name: string;
    full_phone_number: string | null;
    last_name: string;
    phone_number: string | null;
    type: string;
    created_at: string;
    updated_at: string;
    device_id: string | null;
    unique_auth_id: string;
  };
}

interface ResetPassApiResponse {
  data?: {
    data: ResetPassResponse;
  };
  message?: string;
  errors?: string
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  accountType: string;
  emailSchema: any;
  phoneSchema: any;
  otpSchema: any;
  passwordSchema: any;
  accountStatus: any;
  passwordRules: any;
  emailValue: any;
  phoneValue: any;
  countryCodeSelected: any;
  token: any;
  enablePasswordField: boolean;
  btnConfirmPasswordShowHide: boolean;
  email: string;
  showMsg: string;
  password: string;
  confirmPassword: string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ForgotPasswordWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  validationAPICallId: any;
  requestEmailOtpCallId: any;
  requestPhoneOtpCallId: any;
  requestChangePasswordCallId: any;
  requestGoToConfirmationCallId: any;
  otpToken: any;
  isChangePassword: boolean = false;
  sendMailApiCallId: string = '';
  //Properties from config
  labelTextIsAccountRecovery: string = configJSON.labelTextIsAccountRecovery;
  secondLabelText: string = configJSON.secondLabelText;
  thirdLabelText: string = configJSON.thirdLabelText;
  sixthLabelText: string = configJSON.sixthLabelText;
  fifthLabelText: string = configJSON.fifthLabelText;
  firstInputAutoCompleteType: any = configJSON.firstInputAutoCompleteType;
  forthLabelText: string = configJSON.forthLabelText;
  firstInputPlaceholder: string = configJSON.firstInputPlaceholder;
  buttonTextIsNext: string = configJSON.buttonTextIsNext;
  firstInputKeyboardStyle: any = configJSON.firstInputKeyboardStyle;
  secondInputPlaceholder: string = configJSON.secondInputPlaceholder;
  firstInputErrorColor: any = configJSON.firstInputErrorColor;
  secondInputKeyboardType: any = configJSON.secondInputKeyboardType;
  secondInputErrorColor: any = configJSON.secondInputErrorColor;
  secondInputAutoCompleteType: any = configJSON.secondInputAutoCompleteType;
  buttonTitleIsSMSPhoneAccount: string =
    configJSON.buttonTitleIsSMSPhoneAccount;
  thirdInputErrorColor: any = configJSON.thirdInputErrorColor;
  thirdInputPlaceholder: string = configJSON.thirdInputPlaceholder;
  buttonTitleIsEmailAccount: string = configJSON.buttonTitleIsEmailAccount;
  labelTextIsPleaseEnterYourNewPassword: string =
    configJSON.labelTextIsPleaseEnterYourNewPassword;
  labelTextIsYourPasswordHasBeenSuccessfullyChanged: string =
    configJSON.labelTextIsYourPasswordHasBeenSuccessfullyChanged;
  placeholderIsPassword: string = configJSON.placeholderIsPassword;
  buttonColorForNextButton: any = configJSON.buttonColorForNextButton;
  placeholderIsReTypePassword: string = configJSON.placeholderIsReTypePassword;
  buttonTitleIsOk: string = configJSON.buttonTitleIsOk;
  imgPasswordInVisible: any = imgPasswordInVisible;
  imgPasswordVisible: any = imgPasswordVisible;
  buttonColorForOkButton: any = configJSON.buttonColorForOkButton;
  countryCodeSelectorPlaceholder: string =
    configJSON.countryCodeSelectorPlaceholder;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    //email schema
    let emailSchema = {
      email: Yup.string()
        .email(configJSON.pleaseEnterAValidEmail)
        .required(configJSON.emailIsRequired),
    };

    //phone schema
    let phoneSchema = {
      // countryCode: Yup.number()
      // .required("Country code is required"),

      phone: Yup.string()
        .matches(configJSON.phoneRegExp, configJSON.phoneNumberIsNotValid)
        .required(configJSON.phoneNumberIsRequired),
    };

    //passwordSchema
    let passwordSchema = {
      password: Yup.string()
        .required(configJSON.pleaseEnterAPassword)
        .min(2, configJSON.passwordMustBeAtLeast2Characters),
      confirmPassword: Yup.string()
        .required(configJSON.pleaseConfirmYourPassword)
        .when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            configJSON.passwordsMustMatch
          ),
        }),
    };

    //otpSchema
    let otpSchema = {
      otpCode: Yup.number().min(4).required(configJSON.otpCodeIsRequired),
    };

    this.state = {
      accountType: "sms",
      emailValue: "",
      accountStatus: "ChooseAccountType",
      phoneValue: "",
      countryCodeSelected: "",
      passwordRules: "",
      phoneSchema: phoneSchema,
      otpSchema: otpSchema,
      token: "",
      emailSchema: emailSchema,
      passwordSchema: passwordSchema,
      btnConfirmPasswordShowHide: true,
      enablePasswordField: true,
      email: '',
      password: '',
      confirmPassword: '',
      showMsg: '',
    };
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.validationRulesRequest();
  }
  goToLoginPage = () => {
    this.props.navigation.navigate("LoginBlock");
  };

  forgotPasswordHandler = () => {
    const mailPattern = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/

    if (mailPattern.test(this.state.email)) {
      this.setState({
        showMsg: ''
      })
      const header = {
        "Content-Type": configJSON.forgotPasswordAPiContentType,
        token: this.state.token
      };
      const httpBody = {
        "data": {
          "attributes": {
            "email": this.state.email,
          }
        }
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.sentLinkApiEndpoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      this.sendMailApiCallId = requestMessage.messageId;

      runEngine.sendMessage(requestMessage.id, requestMessage);
    } else {
      this.setState({
        showMsg: configJSON.IncorrectEmail
      })
    }
  }
  validationRulesRequest = () => {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessages = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationAPICallId = requestMessages.messageId;
    requestMessages.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileValidationSettingsAPiEndPoint
    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessages.id, requestMessages);
  };

  async receive(from: string, message: Message) {
    if(getName(MessageEnum.RestAPIResponceMessage) === message.id) {

    
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
   
    if (this.sendMailApiCallId === message.properties.RestAPIResponceDataMessage) {
      this.handelResetPasswordResponse(responseJson)
    }
  }
  }


  // Customizable Area Start
  changeHandler = (event: any) => {
    this.setState({
      email: event?.target?.value
    })
  }
  handelResetPasswordResponse = (responseJson: ResetPassApiResponse) => {
    if (responseJson?.data) {
      this.setState({
        showMsg: 'Emaill sent.'
      })
    } else 
      this.setState({
        showMsg: 'Incorrect Email'
      })
  }

  // Customizable Area End
}
