import React from "react";
// Customizable Area Start
interface Props {
  isOpen: boolean;
  onCloseDialog: () => void;
}
interface S {

}
interface SS {

}
// Customizable Area End

import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Typography, styled } from "@material-ui/core";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Close } from "@material-ui/icons";

export default class ModalSuccessNewPassword extends BlockComponent<
  Props,
  S,
  SS
>  {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      <Dialog 
        open={this.props.isOpen}
        PaperProps={{
        style: {
          margin: 'auto',
          border: "unset",
          background: "transparent"
        },
      }}
        BackdropProps={{
          style: {
            backdropFilter: "blur(5px)", // Adjust the pixel value to increase or decrease the blur effect
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: Adjust the background color and opacity
          }
        }}
      >
        <ModalSuccessNewPasswordContainer>
          <Box style={{ textAlign: "right" }}>
            <IconButton
              data-testId="closeDialog"
              onClick={() => this.props.onCloseDialog()}
              aria-label="close">
              <Close style={{color: "#676767"}}/>
            </IconButton>
          </Box>
          <DialogTitle style={{ paddingTop: 0, textAlign: "center", marginTop: "-20px" }}>
            <Typography style={{color: "#CFD1D4", fontFamily: "Poppins", fontSize: "20px", fontWeight: 700}} variant="h6">Success!</Typography>
          </DialogTitle>
          <DialogContent style={{textAlign: "center", marginTop: 20}}>
            <DialogContentText style={{fontFamily: "Poppins", fontWeight: 400, fontSize: "18px", color: "#676767"}}>
              Your password has been successfully changed.
            </DialogContentText>
          </DialogContent>
          <Box style={{border: "1px solid #FF881A", width: "50%", margin: "0 auto"}}></Box>
          <DialogActions style={{display: "flex", justifyContent: "center", marginTop: 24}}>
            <Button
              data-testId="closeDialog"
              style={{maxWidth: "150px", width: "100%", height: "50px", background: "#FF881A", fontFamily: "Poppins", color: "#1C1C1C", fontSize: "14px", fontWeight: 400, textTransform: "unset"}}
              onClick={() => this.props.onCloseDialog()}
              color="primary" variant="contained">
              Log-In
            </Button>
          </DialogActions>
        </ModalSuccessNewPasswordContainer>

      </Dialog>
    );
  }
}

// Customizable Area Start

const ModalSuccessNewPasswordContainer = styled(Box)(({ theme }) => ({
  width: "570px",
  height: "280px",
  background: "#1D1D1F",
  borderRadius: "10px",
  boxShadow: "-5px 4px 12px 0px #00000040 inset"

}))

// Customizable Area End
