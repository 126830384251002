// Customizable Area Start
import React from "react";
import { Typography } from "@material-ui/core";

import Participant from "./Participant.web";

const configJSON = require("./config");

const ParticipantsView = ({ participants }) => {
  const splitParticipantsIntoRows = numberPerRow => {
    const participantList = [...participants.keys()];

    const newArr = [];
    while (participantList.length) {
      newArr.push(participantList.splice(0, numberPerRow + 1));
    }
    return newArr;
  };

  return (
    <div key={"k1"} style={webStyles.container}>
      <Typography
        id="titleText"
        variant="h2"
        style={webStyles.participantLabel}
      >
        {configJSON.participantsLabel}
      </Typography>
      {splitParticipantsIntoRows(2).map(row => (
        <div style={webStyles.participantContainer}>
          {row.map(participant => (
            <Participant key={participant} participantId={participant} />
          ))}
        </div>
      ))}
    </div>
  );
};

const webStyles = {
  container: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    padding: 8
  },
  participantLabel: {
    color: "#3E84F6"
  },
  participantContainer: {
    display: "flex"
  }
};

export default ParticipantsView;
// Customizable Area End
