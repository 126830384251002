export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const backgroundImg = require("../assets/image_bg.png");
export const logo = require("../assets/image_.png")
export const steam = require("../assets/img_1.png")
export const epic = require("../assets/img_2.png")
export const riot = require("../assets/img_3.png")
export const eyeOn = require("../assets/eye-on.svg")
export const eyeOff = require("../assets/eye-off.svg")
export const bgImage = require("../assets/bg_image_common.png")
export const bgImageEsports = require("../assets/bg_image_esports.png")
export const bgImageSports = require("../assets/bg_image_sports.png")