import React from "react";

// Customizable Area Start
import {
    Box, Button, Typography
    , styled, Checkbox
} from "@material-ui/core";
import VideosController, { Props, Video } from "./VideosController";
import { clip, favoriteIcon, mainScreen, markIcon, report, share,edit, verticalLine, playIcon, folderIc, notFull, unCheck } from "./assets";
import MenuButton from "../../../components//src//MenuButton";
import ImageComponent from "../../../components/src/ImageComponent.web";
// Customizable Area End


export default class ListVideo extends VideosController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    
    itemPerVideo = ["5", "10", "15", "20"]
    renderButtons(video: Video){
        return  <Box className="col_7 col_item">
        <Box style={{ width: "100%", display: "flex", flexWrap: "wrap",  alignItems: "center", gap: "8px", padding: "0 16px" }}>
            <Button
                style={{backgroundColor:'#FFB03A', color: "black", borderColor: "#FFB03A", display: "flex" }}
                className='btnAction'
                variant="contained"
                data-test-id="open-video"
                endIcon={<img src={edit} alt="icon" style={{ width: 24, height: 24 }} />}
                onClick={() =>{this.handleEditBtn(video)}}
            >
                Edit
            </Button>
            <Button
                style={{backgroundColor:'#FF881A',color:'black', display: `flex`}}
                className='btnAction'
                variant="contained"
                endIcon={<img src={markIcon} alt="icon" style={{ width: 24, height: 24 }} />}
                onClick={() => this.goToVideoEventScreen(video.id)}
            >
                Mark
            </Button>
            <Button
                style={{backgroundColor:'#BCBCBC',color:'black', borderColor: "#BCBCBC", display: `flex` }}
                className='btnAction'
                variant="contained"
                endIcon={<img src={clip} alt="icon" style={{ width: 24, height: 24 }} />}
            >
                Clips
            </Button>
            <Button
                style={{ backgroundColor:'#A5B5D4', color: "black", borderColor: "#A5B5D4", display: `flex` }}
                className='btnAction'
                variant="contained"
                endIcon={<img src={share} alt="icon" style={{ width: 24, height: 24 }} />}
                onClick={()=>this.handleShare(video.id,video.game_type)}
                data-test-id="share-btn"
            >
                Share
            </Button>
            <Button
                style={{ backgroundColor:"#32A228",color: "black" ,borderColor: "#32A228", display: `flex` }}
                className='btnAction'
                variant="contained"
                endIcon={<img src={report} alt="icon" style={{ width: 24, height: 24 }} />}
            >
                Report
            </Button>
            {video.type === 'game_folders' && (<Button
                style={{backgroundColor:"#B4E730",color: "black", borderColor: "#B4E730", display: `flex` }}
               className='btnAction'
                variant="contained"
                endIcon={<img src={favoriteIcon} alt="icon" style={{ width: 24,position:'relative',right:'4px', height: 24 }} />}
            >
               <span style={{ marginLeft: '6px' }}>Favourites</span>
            </Button>)}
            
        </Box>
        <img alt="vertical-line" src={verticalLine} height={165} />
    </Box>
    }

    renderGameFolderIc = (video: any) => {
        const {people_with_uploads_count,total_people_count} = video;
        const full = people_with_uploads_count === total_people_count;
        return (
            <div style={{ position: "relative", width: '55px', height: "43px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div style={{ position: "absolute", color: full ? '#8DE301' : "#FF881A", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                    {people_with_uploads_count||"0"}/{total_people_count||"0"}
                </div>
                <img src={full ? folderIc : notFull} style={{ width: '55px', height: '43px' }} />
            </div>
        )
    }

    renderColumn(video: Video){
        const custom_name = video.custom_name || video.folder_name
        const videoCustomName = custom_name ? `( ${custom_name} )` : "";
        return <>
        
            <Box className="col_1 col_item" style={{ display: "flex", flexDirection: "row", background: "black", boxShadow: `8px 0px 4px -3px ${video.game_status === "Win" ? "#8DE301" : "#FF1919"}`,height:120 }}>
                <div style={{ flex: 2, height: "100%", backgroundColor: "#252527", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {video.type === 'attachment' ?
                        <img alt="icon" style={{ width: "60px", height: "60px" }} src={playIcon} /> : this.renderGameFolderIc(video)
                    }
                </div>
                <div style={{ flex: 5}}>
                    <ImageComponent style={{ width: "100%", objectFit: "fill" }} src={video.thumbnail || ""} alt={"Thumbnail"} fallbackSrc={mainScreen}/>
                </div>
            </Box>
            <Box className="col_2 col_item">
                {video.type === "attachment"?  <Typography className="col_item_text" style={{ fontWeight: 500 }}>
                    {`${video.team_name} VS ${video.oppo_team_name} ${videoCustomName}`}
                </Typography>:
                <Typography className="col_item_text" style={{ fontWeight: 500 }}>
                    {`${video.my_team_name} VS ${video.opponent_team_name} ${videoCustomName}`}
                </Typography>}
                <img alt="vertical-line" src={verticalLine} height={165} />
            </Box>
            <Box className="col_3 col_item">
                <Typography className="col_item_text">
                {video.type === "attachment"?`${video.game_date}` : `${video.game_due_date}`}
                </Typography>
            </Box>
            <Box className="col_4 col_item">
                <Typography className="col_item_text">
                    {video.game_type}
                </Typography>
            </Box>
            <Box className="col_5 col_item">
                <Typography className="col_item_text">
                    {video.created_by}
                </Typography>
            </Box>
        </>
    }
    renderMarkingStatus = (video:any)=>{
        return( <Box className="col_6 col_item">
        <img alt="vertical-line" src={verticalLine} height={165} />
        <Typography className="col_item_text">{video?.Marked_users || "0"}/{video?.total_users||"0"}</Typography>
        <img alt="vertical-line" src={verticalLine} height={165} />
    </Box>)
    }
    renderOptions=(video:any)=>{
        const getOptions = (video:any) => {
            return [
                ...(video.type !== 'game_folders'
                ? [{ label: "Information", onClick: () => { this.props.showInformation(video) } }] 
                : []),
                { label: "Move Folder", onClick: () => {} },
                { label: "Edit Game Data", onClick: () => {} },
                { label: "Manage Access", onClick: () => {this.handleShare(video.id,video.game_type)} },
                { label: "Delete", onClick: () => this.props.onDelete(video.id,video.type)},
            ]
        }
        return    <Box className="col_8 col_item">
        { this.props.isItemSelected(video.id) ? <Box className="" style={{width:"100%"}}>
                <Checkbox
                    size="medium"
                    checked={true} style={{
                        color: "#FF8826",
                        transform: 'scale(2)',
                        zIndex:3,
                    }}
                />
            </Box>
            : <Box style={{ width: "100%" }}>
                    {(this.props.selectMode && !this.props.isItemSelected(video.id)) ? <Box className="">
                <Checkbox
                    size="medium"
                    checked={true}
                    style={{
                        color: "rgba(207, 209, 212, 1)",
                        transform: 'scale(2)'
                    }}
                    checkedIcon={<img src={unCheck} height={52} width={52}/>}
                />
            </Box>: <MenuButton options={getOptions(video)} />}
            
                    </Box>}
                </Box>
    }
    /* istanbul ignore next */
    renderList(){
       
    const videoItemMember:any = [];
        for (let index = 0; index < this.state.count; index++) {
            const video = this.props.videos[index]
            
            if(video){
                let  videoItem:any = (
                    <Box key={index} data-test-id="list-video-item" onClick={() => { if (this.props.selectMode) { this.props.handleSelect(video.id, video.type,video.videos_size_gb); } }} className="column_item item_video" style={{ marginTop: "8px", cursor: "pointer",position:"relative",overflow:"hidden", boxShadow: this.props.isItemSelected(video.id) ? "0 2px 0 rgba(255, 136, 26, 1),0 -2px 0 rgba(255, 136, 26, 1)" : 'none' }}>
                       
                        
                            {this.renderColumn(video)}
                            {this.renderMarkingStatus(video)}
                            {this.renderButtons(video)}
                            {this.renderOptions(video)}
                            {this.props.isItemSelected(video.id) && <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, backgroundColor: 'rgba(0, 0, 0, 0.7)', zIndex: 1 }} />}
                    </Box>

                );

                videoItemMember.push(videoItem);
            }
        }
        return videoItemMember
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ListVideoStyled>
                {/* Header Table 8 col*/}
                <Box className="column_item">
                    <Box className="col_1">
                    </Box>
                    <Box className="col_2">
                        <Typography className="header_text">
                            Game Name
                        </Typography>
                    </Box>
                    <Box className="col_3">
                        <Typography className="header_text">
                            Date
                        </Typography>
                    </Box>
                    <Box className="col_4">
                        <Typography className="header_text">
                            Game Type
                        </Typography>
                    </Box>
                    <Box className="col_5">
                        <Typography className="header_text">
                            Created By
                        </Typography>
                    </Box>
                    <Box className="col_6">
                        <Typography className="header_text">
                            Upload & Marking Status
                        </Typography>
                    </Box>
                    <Box className="col_7">
                        <Typography className="header_text">
                            Actions
                        </Typography>
                    </Box>
                    <Box className="col_8">
                    </Box>
                </Box>
                {/* Component item 8 col*/}
                <Box style={{ display: "flex", flexDirection: "column", rowGap: "8px" }}>
                    <Box style={{height:"auto",overflowY:"scroll",backgroundColor:"reds",scrollbarWidth: "thin"}}>
                    {this.renderList()}
                    </Box>
                    <Box style={{display: "flex", gap: 18}}>
                    <Typography  style={{fontFamily: "Poppins", fontSize: "20px", fontWeight: 400, color: "#CFD1D4"}}>
                      Show </Typography>
                        {this.itemPerVideo.map((item,index) => (
                            <Box key={index} style={{cursor: "pointer"}}>
                                <Typography data-test-id={`count${index}`} onClick={()=> this.handleCount(item)} 
                                style={{fontFamily: "Poppins", fontSize: "20px", fontWeight: 400, color: `${Number(item)===this.state.count ? "#FF881A" : "#CFD1D4"}`}}>
                                    {item}
                                </Typography>
                            </Box>
                        ))}
                        <Typography  style={{fontFamily: "Poppins", fontSize: "20px", fontWeight: 400, color: "#CFD1D4"}}>
                      Files </Typography>
                    </Box>
                </Box>
            </ListVideoStyled>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const ListVideoStyled = styled(Box)(({ theme }) => ({
    flex: 1,
    "& .item_video": {
        backgroundColor:"#252527",
        borderRadius: "10px",
        overflow: "hidden",
        boxShadow: "-5px 4px 12px 0px #00000040 inset",
        "&:hover": {
            borderBottom: "2px solid #FF881A",
            backgroundColor: "#3C3C3C", 
        }
    },
    "& .column_item": {
        display: "flex",
        "& .header_text": {
            fontFamily: "Poppins",
            fontWeight: 600,
            fontSize: "16px",
            color: "#676767",
            height: "50px",
            textAlign: "center",
            display: "flex",
            alignItems: "end",
            justifyContent: "center"
        },
        "& .col_item": {
            textAlign: "center",
            display: "flex",
            minHeight: "165px",
            alignItems: "center",
            "& .col_item_text": {
                width: "100%",
                fontFamily: "Poppins",
                fontWeight: 400,
                fontSize: "16px",
                color: "#CFD1D4"
            }
        },
        "& .col_1": {
            maxWidth: "calc(100%/12*1.5)",
            flex: "0 0 calc(100%/12*1.5)"
        },
        "& .col_2": {
            maxWidth: "calc(100%/12*2.5)",
            flex: "0 0 calc(100%/12*2.5)"
        },
        "& .col_3": {
            maxWidth: "calc(100%/12*1)",
            flex: "0 0 calc(100%/12*1)"
        },
        "& .col_4": {
            maxWidth: "calc(100%/12*1)",
            flex: "0 0 calc(100%/12*1)"
        },
        "& .col_5": {
            maxWidth: "calc(100%/12*1)",
            flex: "0 0 calc(100%/12*1)"
        },
        "& .col_6": {
            maxWidth: "calc(100%/12*1)",
            flex: "0 0 calc(100%/12*1)"
        },
        "& .col_7": {
            maxWidth: "calc(100%/12*3.5)",
            flex: "0 0 calc(100%/12*3.5)"
        },
        "& .col_8": {
            maxWidth: "calc(100%/12*0.5)",
            flex: "0 0 calc(100%/12*0.5)"
        },
        "& .cardTypoDots": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        },
        "& .btnAction": {
            background: "#1D1D1F",
            border: "1px solid #FF881A",
            fontFamily: "Montserrat",
            fontWeight: 400,
            fontSize: "16px",
            color: "#FF881A",
            width: "max-content",
            textTransform: "capitalize",
            flex: "0 0 calc(100% / 3 - 6px)",
            borderRadius: "8px",
        },

        "& .checkedIconWrapper": {
            height: "100%",
            position: "absolute",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 2,
            right: 30
        }

    }
}));

// Customizable Area End
