import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  TextField,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

import {Check, Close} from "@material-ui/icons"

import { backgroundImg, logo } from "./assets";
import NewPasswordWebController, { Props } from "./NewPasswordWebController";
import ModalSuccessNewPassword from "./ModalSuccessNewPassword";
import { eyeOff, eyeOn } from "../../email-account-login/src/assets";

// Customizable Area End

const NewPasswordStyled = styled(Box)({
  '& .lableStyles': {
    color: "#676767",
    fontSize: "20px",
    fontWeight: "400",
    marginBottom: "8px",
    fontFamily: "Poppins"
  },
  '& .MuiFilledInput-underline': {
    borderBottom: '1px solid #FF881A',
    boxShadow: '4px 4px 4px 0px #00000040 inset',
    borderRadius: '10px'
  },

  '& .MuiInput-underline::before': {
    outline: 'none',
    borderBottom: 'none',
  },
  '& focus: .inputField': {
    outline: 'none',
    border: 'none'
  },
  "& .inputField": {
    padding: "0 12px 0 8px",
    color: '#CFD1D4',
    boxShadow: '4px 4px 4px 0px #00000040 inset',
    borderRadius: '10px',
    borderBottom: '1px solid #FF881A',
    backgroundColor: '#1D1D1F',
  },
  '& .MuiInput-underline::after': {
    borderBottom: 'none'
  },
  '& .MuiInputBase-input': {
    color: '#CFD1D4',
    borderRadius: '10px',
    padding: "15px 0px ",
  },
  "& .logoImages": {
    display: 'flex',
    justifyContent: 'center',
    margin: 'auto',
  },
  '& .buttonContainer': {
    justifyContent: 'center',
    display: 'flex',
    top: '25vh',
  },
  '& .boxContainner': {
    flex: "0 0 calc(100%/12*3.5)",
    display: 'flex',
    overflow: 'hidden',
    backgroundRepeat: 'no-repeat'
  },

  '& .login-container': {
    width: '47.917vw'
  },
  '& .btn-below-line': {
    border: '1px solid #676767',
    margin: 'auto',
    marginTop: '4%',
    marginBottom: '4%',

  },
  '& .bottom-container': {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '2%',
    alignItems: 'baseline',
    fontFamily: 'Poppins',
  },
  '& .games-company-container': {
    display: 'flex',
    justifyContent: 'center',
  },
  '& .loginBtn': {
    backgroundColor: '#FF881A',
    color: '#1D1D1F',
    textTransform: 'none',
    maxWidth: "120px",
    width: '100%',
    fontWeight: 400,
    fontFamily: "Poppins",
    padding: "10px",
    fontSize: "18px",
    lineHeight: "20px",
  },
  '& .bottom-link-text': {
    color: '#FF881A',
    fontSize: '0.833vw',
    fontWeight: 400,
    fontFamily: 'Poppins',
    cursor: "pointer"
  },
  '& .bottom-text': {
    fontSize: '0.833vw',
    paddingRight: '1%',
    color: '#CFD1D4',
  },

  '& .imgs-container-box': {
    boxShadow: '-11px 0px 13px 0px #00000040 inset',
    width: '100%',
    background: '#252527',
    height: '100vh',
    '@media(max-height:810px)': {
      height: '122vh'
    }
  },
  '& .forgot-password-text': {
    color: '#FF881A',
    justifyContent: 'end',
    width: '86%',
    display: 'flex',
    cursor: 'pointer',
    top: '2vw',
    fontSize: '0.833vw',
    fontFamily: 'Poppins'
  },
  '& .input-containers': {
    width: '100%',

  },
  '& .bgImg': {
    height: '100vh',
    width: '100vw',
    '@media(max-width:1280px)': {
      height: '105.5%'
    },
    '@media(max-width:1848px)': {
      height: '100vh'
    }
  },
  '& .img-style': {
    margin: '1%'
  },
  '& .card-text': {
    fontWeight: 400,
    fontSize: '14px',
    fontFamily: 'Montserrat',
    color: '#CFD1D4'
  },
  '& .card-containers': {
    width: '90%',
    margin: 'auto',
    backgroundColor: '#1D1D1F',
    borderRadius: '10px',
    marginBottom: '4%',
    "& .cards-text": {
      fontFamily: "Montserrat",
      color: "#CFD1D4",
      fontWeight: 400,
      fontSize: "14px"
    }
  },
  '& .input-lable-box': {
    marginTop: '2%',
  },
  '& .image-wrapper': {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    overflow: "auto"
  },
  '& .image-body': {
    display: 'flex',
    backgroundRepeat: 'no-repeat',
    overflow: 'hidden',
    flex: "0 0 calc(100%/12*8.5)",
    backgroundSize: "100% 100%",
    backgroundImage: `url(${backgroundImg})`,
  },
})

export default class LoginBlock extends NewPasswordWebController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <NewPasswordStyled>
        <Box className="image-wrapper">

          <Box className="boxContainner">

            <Box className="imgs-container-box" style={{ display: "flex", flexDirection: "column" }}>
              <Box style={{ display: "flex", justifyContent: "center", width: "100%", marginTop: 60 }}>
                <img className="logoImages" width={220} src={logo} />
              </Box>
              <Box style={{ marginTop: 30 }}>
                <Typography style={{ color: "#CFD1D4", fontFamily: "Poppins", fontSize: "30px", fontWeight: "400", textAlign: "center", margin: "20px 0px" }}>Create New Password</Typography>
                <Box className="input-containers" style={{ display: "flex", justifyContent: "center", width: "90%", margin: "0px auto", flexDirection: "column" }}>
                  <Typography className="lableStyles">Enter new password</Typography>
                  <TextField className="inputField"
                    name="password"
                    data-test-id="enterNewPass"
                    type={this.state.enableNewPasswordField ? "password" : "text"}
                    onChange={this.handleChangeInput}
                    InputProps={{
                      disableUnderline: true,
                      endAdornment:
                        (
                          <InputAdornment position="end" style={{ marginLeft: "10px" }}>
                            <IconButton
                              aria-label="toggle password visibility"
                              edge="end"
                              style={{ color: "#676767", marginRight: "0px" }}
                              onClick={() => this.setState({
                                enableNewPasswordField: !this.state.enableNewPasswordField
                              })}
                            >
                              {this.state.enableNewPasswordField ? (
                                <img src={eyeOff} width={32} />
                              ) : (
                                <img src={eyeOn} width={32} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                    }}
                  />
               
                </Box>
                <Box className="input-containers" style={{ display: "flex", justifyContent: "center", width: "90%", margin: "0px auto", marginTop: 24, flexDirection: "column" }}>
                  <Typography className="lableStyles">Confirm new password</Typography>
                  <TextField className="inputField"
                    data-testId="inputFieldCheckPassword"
                    name="reTypePassword"
                    style={{ paddingRight: 0 }}
                    onChange={this.handleChangeInput}
                    type={this.state.enablePasswordField ? "password" : "text"}
                    InputProps={{
                      disableUnderline: true,
                      endAdornment:
                        (
                          <InputAdornment position="end" style={{ marginLeft: "10px" }}>
                            <IconButton
                              aria-label="toggle password visibility"
                              edge="end"
                              style={{ color: "#676767", marginRight: "0px" }}
                              onClick={() => this.setState({
                                enablePasswordField: !this.state.enablePasswordField
                              })}
                            >
                              {this.state.enablePasswordField ? (
                                <img src={eyeOff} width={32} />
                              ) : (
                                <img src={eyeOn} width={32} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                    }}
                  />
                     <Typography style={{
                    marginTop: 20,
                    color: "white"
                  }}>{this.state.passwordValidationStatus.length ? 'Your password must:' :''}</Typography>
                  {this.state.passwordValidationStatus.map(validationElement => (
                    <Box style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}>
                      {validationElement.isNotValid ? <Close htmlColor="red"/> : <Check htmlColor="green"/>}
                      <Typography style={{
                        color: validationElement.isNotValid ? "red" : "green",
                        marginLeft: 15
                      }}>{validationElement.title}</Typography>
                    </Box>
                  ))}
                </Box>
                <Box className="buttonContainer" style={{ display: "flex", justifyContent: "center", width: "100%", marginTop: 40 }} >
                  <Button variant="contained"
                    data-test-id="resetBtn"
                    className="loginBtn"
                    onClick={() => this.resetPassword()}
                  >Reset</Button>
                </Box>
              </Box>
              <Box className="card-containers" >
                <Box style={{ display: 'flex', padding: '16px', }}>
                  <Box>
                    <Typography className="cards-text">
                    Every access to this website is logged. Only authorized users may enter. ©2020-2024 All Copy Rights Reserved to
                      Fantastic Athletes Corporation, USA This system is patent protected - US Patent #11113332 & US20210349942A1</Typography>
                  </Box>
                  <Box style={{ border: '1px solid #FF881A', margin: "0 4px" }} />
                  <Box style={{ margin: 'auto' }}>
                    <img style={{ width: '8vw' }} src={logo} />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="image-body" >
          </Box>
        </Box>
        <ModalSuccessNewPassword data-testId="modalSuccess" isOpen={this.state.isResetPassword} onCloseDialog={() => this.handleCloseDialog()} />
      </NewPasswordStyled>
      // Customizable Area End
    );
  }
}


