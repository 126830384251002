Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";
exports.multipartFormHeader="multipart/form-data"

exports.accountsAPiEndPoint =
  "account/accounts";

exports.account_blockApiEndPoint = 
'account_block/accounts';

exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.apiMethodTypeAddDetail = "POST";
exports.apiMethodPatch="PATCH"

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";
exports.apiContentType = "application/json";

exports.validationApiMethodType = "GET";

exports.emailRegExp =/^[0-9a-z]+(?:\.[0-9a-z]+)*@[a-z0-9]{2,}(?:\.[a-z]{2,})+$/
exports.emailErrorMsg ="Please enter a valid email"
exports.blankField = "This Field can't be blank"
exports.inCorrectFirstname ="First name is not valid"
exports.inCorrectLastname ="Last name is not valid"
exports.passwordRegEx = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/
exports.incorrectPass = "Password contains min. 8 characters - 1 Upper, 1 lower, 1 number"
exports.invalidConfirmPassword = "Password And Confirm Password Must be same"
exports.noError = ""
exports.getCountriesApiEndPoint = "/account_block/accounts/countries"
exports.getRolesApiEndPoint = "/account_block/get_roles"
exports.getStatesApiEndPoint = "/account_block/accounts/states"
exports.getEmailValidationEndPoint = "/account_block/accounts/email_already_exists?email="

exports.getEsportTitlesApiEndPoint = "/account_block/esport_title_list"
exports.getEsportRolesApiEndPoint = "/account_block/in_game_roles"
exports.getSportTitlesApiEndPoint = "/account_block/sport_title_list"
exports.getSportPositionsApiEndPoint = "/account_block/get_positions"

exports.updateAccountApiEndPoint="account_block/accounts/"

exports.countriesApiEndPoint = "account_block/accounts/countries"
exports.statesApiEndPoint = "account_block/accounts/states?country="
exports.urlSteamSSO="bx_block_login/logins"
// Customizable Area End
