import React from "react";

// Customizable Area Start
import { Box, Button, Dialog, IconButton, Tab, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Tabs, ThemeProvider, Typography, createTheme, styled } from "@material-ui/core";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { Close, KeyboardArrowLeft } from "@material-ui/icons";
import { info, tick } from "../../customisableusersubscriptions/src/assets";
import DropDownSp from "../../../components/src/DropDownSp.web";
import InputField from "../../../components/src/InputField.web";

const theme = createTheme({
    overrides:{
        MuiTabs: {
            root: {
              display:"inline-block",
              borderRadius:10,
              backgroundColor:"#1D1D1F",
            },
            indicator: {
              height: 1,
              display:"none",
              backgroundColor: "#FF881A",
            },
          },
          MuiTab: {
            root: {
              fontSize: "16px",
              lineHeight: "24px",
              fontWeight: 500,
              fontFamily: "Poppins",
              letterSpacing:" -0.015em",
              textAlign: "center",
              color:"white",
              '&.Mui-selected': {
                color: "#1D1D1F",
                backgroundColor:"#FF881A",
                borderRadius:10,
              },
              "@media (min-width:600px)":{
                minWidth:99,
              },
            },
            textColorInherit:{
              opaciity:1,
              backgroundColor:'#252527',
              color:"white",
              '&.Mui-selected': {
                color: "#1D1D1F",
              },
            }
          },
          MuiTableContainer:{
            root:{
                backgroundColor:"#252527",
                maxWidth:"100%",
                padding:32,
                width:"auto"
            }
          },
          MuiTableCell:{
            root:{
              borderBottom: 0,
              border:"none",
              boxSizing:"border-box",
            },
            alignLeft:{
              padding:0,
            },
            alignCenter:{
              padding:1,
            },
            head:{
              padding:2
            },
            body:{
                fontFamily: "Poppins",
                fontSize: "24px",
                fontWeight: 400,
                lineHeight: "36px",
                letterSpacing: "-0.015em",
                textAlign: "left",
                color:"#CFD1D4",
                padding:0,
                borderBottom:"0.5px solid rgba(103, 103, 103, 0.7)"
            },
          }
    }
})
// Customizable Area End

import CustomisableusersubscriptionsController, {
  Props,
} from "./CustomisableusersubscriptionsController";
import { Skeleton } from "@material-ui/lab";

export default class Customisableusersubscriptions extends CustomisableusersubscriptionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

 colors = ["#474747", "#646464", "#857D6E", "#A45050", "#C27125"];

  renderTableHeaders=(label: string,price: number|string, total: number|string,bgColor: string, logo:string)=>{
    const currentSubscriptionHeader = this.findSubscriptionHeader(); // Get the current subscription header
    const isCurrentSubscription = currentSubscriptionHeader && currentSubscriptionHeader.name === label;
    const highlightedBgColor = isCurrentSubscription ? '#43ff641a' : bgColor;
    return <div style={{backgroundColor:`${highlightedBgColor}`,display:"flex",flexDirection:"column",alignItems:"center",gap:!price&&!total?70:45, padding:"24px 14px",borderRadius:"20px 20px 0px 0px",width:"15rem",boxSizing:"border-box",minHeight:208}}>
        <div style={{display:"flex",gap:8,alignItems:"center"}}>
            <img src={logo} height={40} width={40} />
            <Typography className="logoLabel">{label}</Typography>
        </div>
      { price==="0.0"&&total==="0.0"?<div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
      <span style={{fontSize: 24,fontWeight: 500,color: "#CFD1D4",fontFamily: "Poppins",lineHeight: "28px",}}>Contact Us</span>
      </div>
      :
      <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
            <div>
            <span style={{fontSize: "2.5rem",fontWeight: 400,color: "#CFD1D4",fontFamily: "Poppins",lineHeight: "28px",}}>${price}</span>
            <span style={{fontSize: 25,
                        fontWeight: 400,
                        color: "#CFD1D4",
                        fontFamily: "Poppins",
                        lineHeight: "28px",}}> Per User
                        </span>
            </div>
            <div><span style={{fontSize: 20,fontWeight: 400,color: "#CFD1D4",fontFamily: "Poppins",lineHeight: "28px",}}>(${total} total)</span></div>
        </div>}
    </div>
  }
  renderChangeUsers=()=>{
    return <div style={{width:"26rem"}}>
    <div style={{display:"flex",flexDirection:"column",gap:29,alignItems:"center",width:232}}>
       <Typography className="logoLabel">Number of Users<img src={info} height={24} width={24}/></Typography>   
       <DropDownSp
        value={this.state.selectedUsers}
        option={[{label:"1",value:"1"},{label:"10",value:"10"},{label:"15",value:"15"},{label:"20",value:"20"},{label:"25",value:"25"},{label:"30",value:"30"},{label:"35",value:"35"},{label:"40",value:"40"},{label:"45",value:"45"},{label:"50",value:"50"}]}
        name="users"
        handleChangeFunction={this.handleChangeUsers}
        placeholder="Select"
        height="40px"
        popoverWidth="180px"
        data-test-id="changeUsers"
        />
    </div>
        </div>
  }
  renderTableCell=(value:string|boolean|null)=>{
    if(typeof value==="string"){
      return value;
    } else{
      return value?<img src={tick} height={17.5} width={24}/>:"-"
    }
    
  }
  // Customizable Area End

  render() {
    return (
        // Customizable Area Start
        <ThemeProvider theme={theme}>
        <StyledSubscriptions>
        <NavigationMenu
          navigation={this.props.navigation}
          id="NavigationMenu"
          currentTabProp="subscriptions"
          
          />
           <Dialog
          open={this.state.contactUsModal}
          data-test-id="contactUs-modal"
          PaperProps={{
            style: {
              border: "unset",
              margin: 'auto',
              background: "transparent",
              maxWidth: "unset"
            },
          }}
          BackdropProps={{
            style: {
              backdropFilter: "blur(5px)", // Adjust the pixel value to increase or decrease the blur effect
              backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: Adjust the background color and opacity
            }
          }}
        >
          <StyledModal style={{width: '780px'}}>
            <IconButton
              data-test-id="on-close-modal"
              style={{ cursor: "pointer" }}
              onClick={this.closeContactUs}
              aria-label="close">
              <Close style={{ color: "#676767" }} />
            </IconButton>

            <Typography variant="h6">Contact Us</Typography>
            <div style={{display:"flex",flexDirection:"column",alignItems:"center",gap:24}}>
            <Typography variant="body1" >
            Whoa, a big organization has entered the chat.<br/>We'd love to talk with you!
            </Typography>

            <div style={{width:288,borderRadius:10,backgroundColor:"#252527",display:"flex",flexDirection:"column",alignItems:"center"}}>
            <div style={{display:"flex",flexDirection:"column",alignItems:"center",width:230,}}>
              <p className="modalLabel">Number of Users<img src={info} height={24} width={24}/></p>   
              <InputField
                data-test-id="team-name-input"
                className="MuiFilledInput-underline inputField"
                name="noOfUsers"
                type="text"
                placeholder="Type here.."
                // value={this.state.formValue.teamName}
                // onChange={this.changeHandler}
              />
            </div>
            </div>
            
            
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Button
                data-test-id="close-contactUs-modal"
                variant="outlined"
                style={{
                  borderRadius: 8,
                  color: "#FF881A",
                  borderColor: "#FF881A",
                  fontFamily: "Montserrat",
                  fontSize: 18,
                  width: "200px",
                  padding: "10px 16px 10px 16px",
                  gap: 8,
                  fontWeight: 600,
                  textTransform: 'none',
                  lineHeight: "22px",
                  height: '55px',
                  cursor: "pointer",
                }}
                onClick={this.closeContactUs}
                >
                Cancel
              </Button>
              <Button
                data-test-id="contactUsbtn"
                variant="contained"
                style={{
                  backgroundColor: "#FF881A",
                  color: "#1D1D1F",
                  borderRadius: 8,
                  fontSize: 18,
                  padding: "10px",
                  gap: 8,
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  lineHeight: "22px",
                  width: "200px",
                  height: '55px',
                  marginLeft: '24px',
                  cursor: "pointer",
                  textTransform: 'none'
                }}
                onClick={() => this.navigateAddContactUs()}

                >
                Contact Us
              </Button>
            </div>
          </div>
          </StyledModal>
          </Dialog>
            <Box className="top-header">
            <Button className="procedure-back-btn" onClick={this.goToUserProfile} data-test-id="back-btn">
                <KeyboardArrowLeft className="arrow-back-icon" />
            </Button>
            <Box style={{display:"flex",gap:10,alignItems:"center"}}>
            <Typography className="label1">Select Billing Type:</Typography>
            <Tabs value={this.state.billType} onChange={this.onBillingChange} data-test-id="tabs">
                        <Tab label="Monthly" value="month" />
                        <Tab label="Annually" value="year" />
            </Tabs>
            </Box>
            <Box></Box>
            </Box>
            
            <Box className="table-container">
                {this.state.dataLoading? <Skeleton className="skeleton" variant="rect" height={600} />
              :<TableContainer>
                    <Table aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell style={{width:"40%"}} align="left">{this.renderChangeUsers()}</TableCell>
                            {this.state.subscriptionsData.headers.map((header:any, index:number) => (
                            <TableCell align="center" key={header.id}>
                              {this.renderTableHeaders(header["name"], header.price, header.total, this.colors[index%this.colors.length], header.image)}
                            </TableCell>
                            ))}
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {this.state.subscriptionsData.rows.map((row: any, rowIndex: number) => (
                            <TableRow key={rowIndex}>
                              <TableCell component="th" scope="row">{row.description}</TableCell>
                              {this.state.subscriptionsData.headers.map((header: any, cellIndex: number) => {
                                const isCurrentSubscription = this.findSubscriptionHeader()?.name === header.name;
                                const cellBgColor = isCurrentSubscription ? "#43ff641a" : ""; 
                                return (
                                  <TableCell key={cellIndex} align="center">
                                    <div style={{ width: "15rem", paddingTop: 18, paddingBottom: 18, background: cellBgColor }}>
                                      {this.renderTableCell(row[header.name])}
                                    </div>
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          ))}
                        </TableBody>
                        <TableFooter> 
                          <TableRow>

                          <TableCell align="left"><Typography className="footerLabel">Subscribe to improve using<br/> Fantastic<span style={{color:"#FF881A"}}>We</span>:</Typography></TableCell> 
                          {this.state.subscriptionsData.headers.map((header:any,position:number)=>(  header.name !== "Enterprise" && (
                            <TableCell style={{width:"15rem"}} align="center" key={position}>
                              <div style={{width:"15rem"}}>
                                    <Button
                                    data-test-id={`buy-btn${position}`}
                                    onClick={()=>this.onBuyHere(header.id)}
                                      variant="outlined"
                                      style={{
                                        color: "#CFD1D4",
                                        backgroundColor: this.findSubscriptionHeader()?.name === header.name ? "#43ff641a" : `${this.colors[position % this.colors.length]}`, // Conditional background color
                                        fontSize: 32,
                                        padding: "6px 21px 6px 21px",
                                        fontWeight: 500,
                                        gap: 10,
                                        lineHeight: "28px",
                                        borderRadius: "20px",
                                        fontFamily: "Montserrat",
                                        width: "240px",
                                        height: '78px',
                                        textTransform: "none"
                                      }}
                                      >
                                      Buy Here
                                    </Button>
                                        </div>
                                  </TableCell>
                               )) )}
                                 {this.state.subscriptionsData.headers.some((header:any) => header.name === "Enterprise")&&<TableCell style={{width:"15rem"}} align="center" key={"contactus"}>
                              <div style={{width:"15rem"}}>
                                    <Button
                                    data-test-id="open-contactUs-modal"
                                      variant="outlined"
                                      style={{
                                        color: "#CFD1D4",
                                        backgroundColor: "#C27125",
                                        fontSize: 32,
                                        padding: "6px 21px 6px 21px",
                                        gap: 10,
                                        fontFamily: "Montserrat",
                                        fontWeight: 500,
                                        lineHeight: "28px",
                                        width: "15rem",
                                        height: '78px',
                                        borderRadius: "20px",
                                        textTransform: "none"
                                      }}
                                      onClick={this.handleContactUs}
                                      >
                                      Contact Us
                                    </Button>
                                        </div>
                                  </TableCell>}
                               </TableRow>
                        </TableFooter>
                    </Table>
              </TableContainer>}
            </Box>
        </StyledSubscriptions>
        </ThemeProvider>
        // Customizable Area End
        );
  }
}

// Customizable Area Start
const webStyle = {
  // custom style definitions
};

const StyledModal = styled(Box)(({ theme }) => ({
  background: "#1D1D1F",
  boxShadow: "-5px 4px 12px 0px #00000040 inset",
  padding: "40px",
  borderRadius: "10px",
  position: "relative",

  
  "& .MuiIconButton-root": {
    height: "24px",
    width: "24px",
    padding: "4px",
    top: "16px",
    right: "16px",
    position: "absolute",
  },
  "& .MuiPaper-root": {
    maxWidth: "800px",
  },
  
  "& .MuiTypography-h6": {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "700",
    marginBottom: 32,
    lineHeight: "28px",
    textAlign: "center",
    color: "#CFD1D4",
  },

  "& .MuiTypography-body1": {
    fontFamily: "Poppins",
    fontWeight: "400",
    textAlign: "center",
    fontSize: "18px",
    lineHeight: "26px",
    color: "#676767",
  },
  "& .modalLabel": {
    display:"inline-flex",
    fontSize: 22,
    fontWeight: 400,
    color: "#CFD1D4",
    fontFamily: "Poppins",
    lineHeight: "28px",
    gap:6,
    alignItems:"center"
  },

}))

const StyledSubscriptions = styled(Box)(() => ({
    minHeight: "100vh",
    paddingBottom: "20px",
    boxSizing: "border-box",
    overflowX: "hidden",
    position:"relative",

    "& .procedure-back-btn": {
        width: 52,
        height: 48,
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: "#FF881A",
        borderRadius:"0px 8px 8px 0px"
      },
      "& .arrow-back-icon": {
        color: "#FF881A",
        height:24,
        width:24
      },
      "& .top-header": {
        padding:"32px 40px",
        display:"flex",
        justifyContent:"space-between"
      },
      "& .label1": {
        fontSize: 20,
        fontWeight: 400,
        color: "#CFD1D4",
        fontFamily: "Poppins",
        lineHeight: "28px",
      },
      "& .logoLabel": {
        display:"inline-flex",
        fontSize: 24,
        fontWeight: 400,
        color: "#CFD1D4",
        fontFamily: "Poppins",
        lineHeight: "28px",
        gap:6,
        alignItems:"center"
      },

      "& .footerLabel": {
        fontSize: 22,
        fontWeight: 400,
        color: "#CFD1D4",
        fontFamily: "Poppins",
        lineHeight: "33px",
        letterSpacing: "-0.015em",
        paddingTop:60,
        paddingBottom:40,
      },

      "& .table-container":{
        padding:"44px",
        boxSizing:"border-box"
      },
      "& .skeleton":{
        padding:44,
        borderRadius: '10px',
        backgroundColor: '#ffffff21'
      }


}));
// Customizable Area End
