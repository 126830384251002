import React from "react";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { styled } from "@material-ui/core/styles";
import PositionsPlayers from "./PositionsPlayers.web";
import DropDownGroup from "../../../components/src/DropDownGroup";
import { add, delete_button, vector_,share,edit,seperator1} from "./assets"
import { Container, Box, Typography,Checkbox, Avatar } from "@material-ui/core";
// Customizable Area End

import ShareController, { Props, configJSON } from "./ShareController.web";

export default class Share extends ShareController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.handleChange = this.handleChange.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
 
  renderBp =()=>{
    return (
      <Checkbox
        disableRipple
        checkedIcon={<this.BpCheckedIcon />}
        icon={<this.BpIcon />}
        inputProps={{ "aria-label": "Checkbox demo" }}
      />
   );
  }
   BpIcon = styled("span")(({ theme }) => ({
    borderRadius: 5,
    width: 16,
    height: 16,
    position:'relative',
    top:'-6px',
    border: "3px solid #676767",
  }));
   BpCheckedIcon = styled(this.BpIcon)({
    backgroundColor: "transparent",
     backgroundImage: `url(${vector_})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    padding: 1,
    "&::before": {
      display: "block",
      width: 16,
      height: 16,
      color: "#252527",
      content: '""',
    },
  });
  
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { rows } = this.state;
    return (
      <div>
        <div style={{ overflowX: "hidden", position: 'relative' }}>
          <ShareStyle>
            <ThemeProvider theme={shareTheme}>
              <Container className="container">
                <Box className="header">
                  <Typography className="text_font" variant="h6">{configJSON.labelTitleText}</Typography>
                  <Typography className="subtext_font">{configJSON.labelText}</Typography>
                </Box>
                <Box className="header2">
                  <img src={share} alt="share" className="img-style" />
                  <Typography className="shareText">{configJSON.labelTextContent}</Typography>
                </Box>
                <Box className="header3">
                  {this.renderBp()}
                  <Typography className="checkText">{configJSON.labelTextContentCheck}</Typography>
                </Box>
              </Container>
            </ThemeProvider>
          </ShareStyle>
          <img src={seperator1} width={"100%"} />
          <ShareStyle>

            <Typography className="headingText" style={{width:"620px",textAlign:"left",margin:"auto",flexWrap:"wrap", display:"flex",paddingBottom:10}}>{configJSON.playerText}<Typography>{configJSON.playerText2}</Typography></Typography>
            <div style={{ overflowY: 'scroll', margin: '20px 30px',backgroundColor:"#1D1D1F" }}>
              <div className="tableContainer">
                <div aria-label="simple table" style={{backgroundColor:"#1D1D1F"}}>
                  <div>
                    <div style={{paddingTop:15, display: "flex", flex: 1, justifyContent: "space-around", alignItems: "center" }}>
                      <div className="headingTable" style={{ flex: 7 }}>Group</div>
                      <div className="headingTable" style={{ flex: 6 }}>Competition</div>
                      <div className="headingTable" style={{ flex: 4 }}>Action</div>
                    </div>
                  </div>

                  {this.props.groups?.map((row: any, index: number) => (
                    <>
                    <div key={row.id} className="tablerow" style={{padding:"3px 20px", margin: "10px 20px", flex: 1, backgroundColor: "#252527", borderRadius: 10, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                      <div style={{ flex: 4, display: "flex", flexDirection: "row",paddingRight:"20px" }}>
                        <div className="divHeader" style={{ display: "flex" }}>
                          <Avatar className="avatar">G{index+1}</Avatar>
                          <div className="divSubHeader" style={{width:"268px",marginLeft:"-25px"}}>
                            <div className="typography" style={{ display: "flex",alignItems:"center",height:"100%", justifyContent: "space-between" }}>
                              <div style={{display:"flex",paddingLeft:30, height:"100%",alignItems:"center"}}>
                                <span>{row.attributes.name} {index + 1}{" "}</span>
                                <span style={{ color: "white" }}>(</span>
                                <span
                                  style={{
                                    color: row.attributes.assigned
                                      ? "green"
                                      : "red",
                                  }}
                                >
                                  {row.attributes.assigned
                                    ? "Assigned"
                                    : "Unassigned"}
                                </span>
                                <span style={{ color: "white" }}>)</span>
                              </div>
                              <div style={{display:"flex",height:"100%",alignItems:"center"}}>
                                <img
                                  data-testid="edit-btn"
                                  src={edit}
                                  onClick={() =>{
                                    this.handleClick(row.id);
                                    this.props.handleModal(true)}
                                  }
                                  alt="edit"
                                  className="editicon"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div style={{ flex: 5 }}>                       
                            <DropDownGroup
                              options={this.props.options}
                              value={row.attributes.competition || ""}
                              handleChangeFunction={(e: any) => {
                                this.handleChange(Number(row.id), e.value as string
                                )
                              }}
                              placeholder="Select Game Type" 
                              testId="selectType"/>
                          
                      </div>
                      <div style={{ flex: 2,display:"flex",alignItems:"center", justifyContent:"flex-end", marginLeft: '24px' }}>
                        <img
                          style={{ height: "40px", width: '120px' }}
                          onClick={() => this.deleteGroup(Number(row.id))}
                          src={delete_button}
                          data-testid="deleteGroup-Btn"
                          alt="Delete"
                          className="img-style"
                        />
                      </div>
                    </div>
                    
                    </>
                  ))}
                  <div style={{ display: "flex",marginLeft:"30px",paddingBottom:20,alignItems:"center" }}>
                    <span
                      style={{
                        color: "#FF881A",
                        backgroundColor: "transparent",
                        fontSize:"18px",fontFamily:"Poppins"
                      }}
                    >
                      Add Group
                    </span>
                    <img
                      data-test-id="add-group-icon"
                      src={add}
                      alt="Add Row"
                      className="img-style"
                      onClick={() => this.addRowData("Group")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </ShareStyle>
        </div>
        <div data-test-id="divLogin"
          style={{
            position: 'absolute',
            top: '80px',
            left: '100%',
            backgroundColor: "#252527",
            transition: "right 0.3s ease",
            zIndex: 999
          }}
        >
          {this.state.showNewPaper && this.props.showAssignModal &&
            <PositionsPlayers data-test-id="positionPlayer" folderId={this.props.folderId} closeModal={this.props.handleModal} idt={this.state.idGroup} onNewsPaperGroupUpdate={this.onNewsPaperGroupUpdate} groups={this.props.groups} />}
        </div>
      </div>
            
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const ShareStyle = styled(Box)({
  fontFamily: 'Poppins',

  "& .thirdTableCell": {
    textAlign: "center",
    border: "none",
    width: "1px"
  },
  "& .header": {
    display: "block ruby"
  },
  "& .checkbox": {
    padding: 0,
    color: 'grey',
  },
  "& .secondDiv": {
    display: 'flex',
    alignItems: 'center'
  },
  "& .header2": {
    display: 'flex',
    paddingTop: '10px'
  },
  "& .header3": {
    display: 'flex',
    paddingTop: '10px',
    position: 'relative',
    top: '-68px',
    left: '26%'
  },
  "& .shareText": {
    fontFamily: 'poppins',
    paddingTop: "18px",
    paddingLeft: "15px",
    color: "#676767",

  },
  "& .arrowIcon": {
    width: '20px',
    height: '20px',
    opacity: '0.8',
    position: 'relative',
    left: '261px'
  },
  "& .tablerow": {

  },
  "& .secondSubDiv": {
    marginLeft: 'auto',
    width: '270px'
  },
  "& .secondTableCell": {
    display: 'flex',
    left: '10px',
    width: "286px",
    position: 'relative',
    alignItems: 'center',
    height: '15px',
    backgroundColor: 'black',
    borderRadius: '11px',
    top: '16px',
    borderColor: 'rgb(255, 136, 26)',
    padding: '8px',
    justifyContent: 'center'
  },
  "& .openButton": {
    position: "absolute",
    backgroundColor: 'white',
    color: 'rgb(255, 136, 26)',
    top: "10px",
    right: "48px"
  },
  "& .checkText": {
    top: "-27%",
    fontFamily: 'poppins',
    position: 'relative',
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "white"
  },
  '& .selectFiles': {
    color: "#CFD1D4",
    fontSize: 20,
    fontWeight: 400,
    fontFamily: "sans-serif",
  },
  "& .editIcon": {
    cursor: 'pointer',
    position: "relative",
    marginRight:"10px"
  },
  "& .MuiPaper-root": {
    backgroundColor: 'white'
  },
  "& .tableContainer": {
    minHeight:"400px",
    overflowX: 'hidden',
    backgroundColor: '#1D1D1F',
    position: 'relative',
  },
  "& .table": {
    borderRadius: '4px',
    margin: "13px"
  },
  "& .headingTable": {
    color: '#676767',
    fontSize: '16px',
    textAlign: 'center',
    border: 'none',
    fontFamily: 'Poppins'
  },
  "& .divHeader": {

  },
  "& .MuiTableCell-root": {
    padding: "0px"
  },
  "& .MuiInput-underline::after": {
    borderBottom: "none"
  },
  "& .MuiInput-underline::before": {
    borderBottom: "none"
  },
  "& .MuiSelect-icon": {
    display: "none"
  },
  "& .table.MuiRoot-table": {
    height: '10px'
  },
  "& .headingText": {
    fontSize: 13,
    paddingTop: 15,
    position: 'relative',
    left: '4%',
    fontFamily: 'poppins',
    color: 'white'
  },
  "& .text_font": {
    color: 'white',
    fontFamily: 'poppins',
  },
  "& .container": {
    paddingTop: '20px',
    width: "696px",
    backgroundColor: "#252527",
    height: "200px"
  },
  "& .subtext_font": {
    fontFamily: 'poppins',
    color: "grey",
    paddingLeft: "10px",
  },
  "& .divSubHeader": {
    backgroundColor: '#1d1d1f',
    borderRadius: '5px',
  },
  "& .avatar": {
    height: '40px',
    width: '40px',
    fontSize: "18px",
    fontFamily: "Poppins",
    backgroundColor: 'rgb(255, 136, 26)',
    color: '#151414fa',
    fontWeight: "600"
  },
  "& .next_back": {
    cursor: 'pointer',
    display: 'flex',
    alignItems: "center",
    justifyContent: "center"
  },
  "& .img-style_button": {
    margin: '5px',
    height: '32px'
  },
  "& .typography": {
    fontSize:"16px",
    fontFamily:"Poppins",
    color:"#fff",
  },
  "& .tablecell": {
    position: 'relative',
    borderBottom: 'none'
  },
  "& .img-style": {
    cursor: 'pointer',
  },
})
const shareTheme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End
