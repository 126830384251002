import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Typography,
    styled,
    Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from "@material-ui/core";
import { Item, ItemGroup } from "../../../blocks/cfcustomkeyboards/src/CreateKeyboardsController";
import CheckBoxMirrorActions from "./CheckBoxMirrorActions";

interface Props {
    show: boolean;
    handleClose: Function;
    onCustomizePress?: Function;
    onSavePress?: Function;
    showCustomizeBtn?: boolean;
    labels?: any;
    headerData?: Item[];
    bottomDotedlineData?: Item[];
    initialSelectedItems?: Item[]
}

const Wrapper = styled(Modal)(({ theme }) => ({
    textTransform: "none",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",

    "& .modal-body": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "80%",
        backgroundColor: "#252527",
        borderRadius: 10,
        overflow: "hidden",
        padding: 30,
    },

    "& .header-wrapper": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
    },

    "& .header-title-wrapper": {},
    "& .header-label-1": {
        color: "#CFD1D4",
        fontSize: 26,
        fontWeight: 400,
    },
    "& .header-label-2": {
        color: "#676767",
        fontSize: 20,
        fontWeight: 400,
    },

    "& .customize-btn": {
        textTransform: "none",
        borderStyle: "solid",
        borderWidth: 1,
        borderRadius: 10,
        borderColor: "#FF881A",
        paddingLeft: 10,
        paddingRight: 10,
    },

    "& .customize-btn-title": {
        fontSize: 16,
        fontWeight: 400,
        color: "#FF881A",
    },

    "& .table-wrapper": {
        width: "99%",
        borderRadius: 10,
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#FF881A",
        marginTop: 30,
        minHeight: 100,
        overflowY: "scroll",
        overflowX: "scroll",
        position: "relative",
    },

    "& .action-table": {
        backgroundColor: "#1D1D1F",
        borderRadius: 10,
        overflow: "scroll",
        marginTop: 30,
        minHeight: 100,
        overflowY: "scroll",
        overflowX: "scroll",
        marginLeft: 10,
        marginRight: 10,
    },

    "& .btn-wrapper": {
        marginTop: 15,
    },

    "& .back-btn": {
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#FF881A",
        color: "#FF881A",
        width: 150,
        textTransform: "none",
        marginRight: 20,
    },

    "& .save-btn": {
        borderStyle: "solid",
        borderWidth: 1,
        backgroundColor: "#FF881A",
        width: 150,
        textTransform: "none",
    },
}));

const DashedTableCell = styled(TableCell)({
    borderBottom: "2px dashed #a7a7a8",
    borderRight: "2px dashed #a7a7a8",
    "&:last-child": {
        borderRight: "none",
    },
    color: "#CFD1D4",
    fontSize: 16,
    width: "10%",

    "& .cell-wrapper": {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },

    "& .header-cell": {
        width: "100%",
        maxWidth: 150,
        alignSelf: "center",
        height: 40,
        backgroundColor: "#FF881A",
        borderRadius: 8,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "#1D1D1F",
        textTransform: "none",
    },

    "& .body-cell": {
        width: "100%",
        maxWidth: 150,
        alignSelf: "center",
        height: 40,
        backgroundColor: "#252527",
        borderRadius: 8,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "#CFD1D4",
        marginTop: 10,
        textTransform: "none",
    },
});

const AddEditSubActionModal = (props: Props) => {
    const {
        show,
        handleClose,
        onCustomizePress = () => { },
        showCustomizeBtn = false,
        labels,
        headerData = [],
        bottomDotedlineData = [],
        onSavePress = () => { },
        initialSelectedItems = []
    } = props;
    const [selectedItems, setSelectedItems] = useState<Item[]>([]);
    const [mirrorChecked, setMirrorChecked] = useState<boolean>(false);

    const handleChangeMirror = () => {
        setMirrorChecked(prev => !prev);
    }

    useEffect(() => {
        setSelectedItems(initialSelectedItems.map(item => item))
    }, [show]);

    return (
        <Wrapper open={show} onClose={() => handleClose()}>
            <Box className="modal-body">
                <Box className="header-wrapper">
                    <Box className="header-title-wrapper">
                        <Typography className="header-label-1">
                            Add / Edit {labels?.header}
                        </Typography>
                        <Typography className="header-label-2">
                            {labels?.headerDetail}
                        </Typography>
                    </Box>
                    {showCustomizeBtn && <Button className="customize-btn" onClick={() => onCustomizePress()}>
                        <Typography className="customize-btn-title">
                            Customize {labels?.header}
                        </Typography>
                    </Button>}

                </Box>

                <Box className="table-wrapper">
                    <div style={{
                        position: "absolute",
                        right: 15,
                        top: 380,
                        color: "#676767"
                    }}>{labels?.header} per action: 6</div>
                    <CheckBoxMirrorActions checked={mirrorChecked} onChange={handleChangeMirror} checkboxLable="Mirror Sub-Actions for All Actions" />
                    <TableContainer className="action-table" component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {headerData.map((columnElm) => (
                                        <DashedTableCell>
                                            <div className="cell-wrapper">
                                                <Button className="header-cell" onClick={() => {
                                                    console.log(bottomDotedlineData)
                                                }}>
                                                    <Typography>{columnElm.name}</Typography>
                                                </Button>
                                            </div>
                                        </DashedTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow style={{ height: 300 }}>
                                    {headerData.map((columnElm) => (
                                        <DashedTableCell>
                                            <div className="cell-wrapper">
                                                {selectedItems?.filter(element => element.parent_id === columnElm.id).map((rowElm) => (
                                                    <Button
                                                        onClick={() => {
                                                            setSelectedItems([...selectedItems.filter(filterElm => filterElm.id !== rowElm.id || filterElm.parent_id !== columnElm.id)]);
                                                        }}
                                                        className="body-cell">{rowElm.name}</Button>
                                                ))}
                                            </div>
                                        </DashedTableCell>
                                    ))}
                                </TableRow>
                                <TableRow style={{ height: 200 }}>
                                    {headerData.map((columnElm) => (
                                        <DashedTableCell>
                                            <div className="cell-wrapper">
                                                {bottomDotedlineData.filter(element => element.parent_id === columnElm.id).map((rowElm) => {
                                                    if (!!selectedItems.find(filterElm => filterElm.id === rowElm.id && filterElm.parent_id === columnElm.id)) return <></>;
                                                    return (
                                                        <Button
                                                            onClick={() => {
                                                                if(selectedItems.filter(items => items.parent_id === columnElm.id).length < 6){
                                                                    setSelectedItems([...selectedItems, rowElm])
                                                                }
                                                            }}
                                                            className="body-cell">{rowElm.name}</Button>
                                                    )
                                                })}
                                            </div>
                                        </DashedTableCell>
                                    ))}
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>


                <Box className="btn-wrapper">
                    <Button className="back-btn" onClick={() => handleClose()}>Back</Button>
                    <Button className="save-btn" onClick={() => {
                        onSavePress(selectedItems);
                        handleClose();
                    }}>Save</Button>
                </Box>
            </Box>
        </Wrapper>
    );
};

export default AddEditSubActionModal;
