

import React, { useState, useEffect } from 'react';
import { styled,Modal,Typography,Box ,Button} from '@material-ui/core';
import {folder} from "../../blocks/videos/src/assets"
import { IFile } from "./CustomFeatureVideoProcess/OptimizationStatus.web";
declare global {
  interface Window {
    api: {
      receive: (channel: string, callback: (data: {message: string}) => void) => void;
      removeAllListeners: (channel: string) => void;
      selectFileDialog: ()=> Promise<{filePath: string, message: string}[]>;
      selectFile: (file: (string|undefined)[])=> Promise<IFile[]>;
      convertFile: (file: { filePath: string; message: string; } | IFile) => Promise<void>;
      getListFileProcess: () => Promise<IFile[]>,
      resumeOptimization: (listFile: IFile) => Promise<void>;
      getPathOptimizedVideo: (fileName: string) => Promise<string>;
      parseBlobFile: (filePath: string) => Promise<string>;
      clearStorageOptimized: () => void;
      disableOptimization: () => void;
      getLatestFolderGame: () => void;
      handleUploadSuccessful: (id: number, fileName: string, uriKeyUpdate: string) => Promise<void>;
      getListFileKeyEncryption: () => Promise<string>;
      removeFileKeyUri: (fileName: string) => Promise<void>;
      getListOptimizationFile: () => Promise<IFile[]>;
    }
  }
}
function MediaConverter1() {
    const [listDataProcessing, setListDataProcessing] = useState<{filePath: string, message: string}[]>([])
    const [countFileOptimized, setCountFileOptimized] = useState(0)
    const myWindow: Window = window;
  if(!myWindow?.api){
    return <Button> <img src={folder} className="icons"/> </Button>
  }
    const [message, setMessage] = useState('');
    const [open ,setOpen]=useState(false)
    useEffect(() => {
    // Listen for video processing progress updates and display them
    myWindow.api.receive('video-processing-progress', (data:any) => {
      setMessage(data.message);
    });
    // Listen for a message indicating the video conversion is complete
    myWindow.api.receive('video-processing-status', (data: any) => {
      setMessage(data);
      setCountFileOptimized((prevCount) => prevCount + 1)
    });
    // Listen for any errors that occur during video processing
    myWindow.api.receive('video-processing-error', (data:any) => setMessage(data));

    // Cleanup function to remove all listeners when the component unmounts
    // or before the useEffect hook runs again
    return () => {
        myWindow.api.removeAllListeners('video-processing-progress');
        myWindow.api.removeAllListeners('video-processing-status');
        myWindow.api.removeAllListeners('video-processing-error');
      };
    }, []);

    useEffect(() => {
      if(countFileOptimized === listDataProcessing.length)
        setMessage("Video be optimized successfully")
    }, [countFileOptimized])

    const handleFileSelectAndConvert = async () => {
      setOpen(true);
      setMessage("Please select a video file to start processing.");
      const filePaths = await myWindow.api.selectFileDialog(); // Open file selection dialog
      if (!filePaths) { // Check if a file was selected
        setMessage('No file selected.');
        return;
      }
      if(typeof filePaths === "string"){
        return setMessage(filePaths)
      }
      setMessage('Starting video processing...');
      setListDataProcessing(filePaths)
      for ( const file of filePaths ){
        myWindow.api.convertFile(file) // Request file conversion
      }
    };
    const handleClose=()=>{
      setOpen(false);
    }

    return (
        <StyledGridItem>
        <Button> <img src={folder} className="icons" onClick={handleFileSelectAndConvert}/>
        </Button> 
           <Modal
           open={open}
           onClose={handleClose}
           aria-labelledby="modal-modal-title"
           aria-describedby="modal-modal-description"
           style={{ 
          position:"absolute",
          top:"50%",
          left:"50%"
        }}
          >
              <Box 
              style={{ transform: "translate(-50%, -50%)",width: 400,
                 backgroundColor: "white",
                 padding: 20,
                wordBreak: "break-all",
                 }}>
              <Typography id="modal-modal-description" style={{paddingTop:10}}>
              {message}
              </Typography>
       </Box>
     </Modal>
     
    
  </StyledGridItem>
  )
}



const StyledGridItem = styled('div')(({ theme }) => ({
    '& .icons': {
      [theme.breakpoints.down('lg')]: {
        width: 40,
      },
      [theme.breakpoints.down('md')]: {
        width: 30,
      },
      [theme.breakpoints.down('sm')]: {
        width: 20,
      },
      [theme.breakpoints.down('xs')]: {
        width: 10,
      },
    },
    '& .modal':{
      transform: "translate(-50%, -50%)",
      width: 400,
      bgcolor: "white",
      padding: 4,
    },
    '& .modalParent':{
      display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%"
    }
    }));
    export default MediaConverter1