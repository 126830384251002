import { Box, Modal, Typography, styled } from "@material-ui/core";
import { closeIcon } from "../../../../packages/blocks/cfcustomkeyboards/src/assets";
import React from "react";

export interface Props {
    show: boolean;
    onClose: () => void;
    onSave: () => void;
    selecting: string;
}

const Wrapper = styled(Modal)(({ theme }) => ({
    textTransform: "none",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",

    "& *": {
        boxSizing: "border-box"
    },

    "& .modal-body": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "626px",
        backgroundColor: "#252527",
        borderRadius: 10,
        overflow: "auto",
        padding: "40px",
        gap: '32px',
        position: 'relative'
    },

    "& .title-text": {
        fontFamily: 'Poppins',
        fontWeight: '700',
        fontSize: '20px',
        color: "#CFD1D4"
    },

    "& .sub-text": {
        fontFamily: "Poppins",
        fontWeight: "400",
        fontSize: '18px',
        color: "#676767",
        textAlign: 'center'
    },
   
    "& .divider": {
        width: "386px",
        height: '2px',
        backgroundColor: "#FF881A",
    },

    "& .btn-row": {
        display: 'flex',
        gap: "24px",
    },

    "& .back-btn": {
        width: "166px",
        height: '55px',
        borderRadius: '8px',
        border: 'solid 1px #FF881A',
        fontFamily: 'Montserrat',
        fontWeight: '600',
        fontSize: '18px',
        color: "#FF881A",
        cursor: 'pointer',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center'
    },

    "& .save-btn": {
        width: '166px',
        height: '55px',
        borderRadius: '8px',
        backgroundColor: '#FF1919',
        fontFamily: "Montserrat",
        fontWeight: '600',
        fontSize: '18px',
        color: "#1D1D1F",
        cursor: 'pointer',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center'
    },

    "& .close-icon": {
        position: 'absolute',
        width: '24px',
        top: '20px',
        right: '20px',
        cursor: 'pointer'
    }
}))

const EmptyRolePositionModal = (props: Props) => {
    return (
        <Wrapper open={props.show} onClose={() => { }}>
            <Box className='modal-body'>
                <img src={closeIcon} className="close-icon" onClick={props.onClose} />

                <Typography component="span" className="title-text">Warning!</Typography>

                <Typography component="span" className="sub-text">If no {props.selecting} are selected then all the players can be given access to this keyboard.</Typography>
            
                <Box className="divider" />

                <Box className="btn-row">
                    <Box className="back-btn" onClick={props.onClose} data-test-id="empty-role-position-back-btn">Back</Box>

                    <Box className="save-btn" onClick={props.onSave} data-test-id="empty-role-position-save-btn">Proceed</Box>
                </Box>
            </Box>
        </Wrapper>
    )
}

export default EmptyRolePositionModal