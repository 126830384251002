import React from "react";
import { Button, Checkbox, Typography, styled } from "@material-ui/core";

interface Props {
    label: string;
    checked: boolean;
    onClick?: Function;
    disabled?: boolean;
    dataTestId?: string;
}

const Wrapper = styled(Button)(({ theme }) => ({
    width: "100%",
    height: 50,
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#1D1D1F",
    borderRadius: 8,
    overflow: "hidden",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: 10,
    paddingRight: 5,
    marginTop: 5,
    textTransform: "none",

    "& svg": {
        color: "#FF881A !important"
    },

    "& .label": {
        color: "#CFD1D4",
        fontSize: 16,
        fontWeight: 400,
    },
}));

const CheckBoxRow = (props: Props) => {
    const { label, checked, onClick = () => { }, disabled = false, dataTestId } = props;

    return (
        <Wrapper data-test-id={dataTestId} disabled={disabled} onClick={() => { if (!disabled) onClick() }}>
            <Typography className="label">{label}</Typography>
            <Checkbox checked={checked} color="default" />
        </Wrapper>
    );
};

export default CheckBoxRow;
