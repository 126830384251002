import React from 'react';
import { styled } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import { Box, Button, Typography } from '@material-ui/core';
import ShareController, { PlayerFilter, Props } from './ShareController.web';
export default class PositionsPlayers extends ShareController {
  constructor(props: Props) {
    super(props);
  }

  renderFilter = () => {
    const { staffFilters, inGameRole,positions } = this.state;
    
    const renderSection = (title:string, filters:PlayerFilter[]) => (
      <>
        <Typography component="span" className="title">
          {title}
        </Typography>
        <Box style={{ marginTop: 10, flexWrap: "wrap", display: "flex" }}>
          {this.renderPosition(filters)}
        </Box>
      </>
    );
  
    return (
      <Box className="">
        {staffFilters?.length > 0 && renderSection("Staff", staffFilters)}
        {inGameRole?.length > 0 && renderSection("In-Game Role", inGameRole)}
        {positions?.length > 0 && renderSection("Position", positions)}
      </Box>
    );
  }

  render() {
    return (
      <Positions>
        <PaperStyle className='scroll-container'>
          <Close
            data-testid='close-modal'
            onClick={() => {
              this.handleClose();
              this.props.closeModal(false);
            }}
            style={{
              position: 'absolute',
              zIndex: 600,
              top: 10,
              right: 20,
              color: '#676767',
            }}
          />
          <Box
            style={{
              height: '600px',
              overflowY: 'scroll',
              scrollbarWidth: 'auto',
            }}
          >
           {this.renderFilter()}

            <Box className=''>
              <Typography component='span' className='title'>
                Players
              </Typography>
              <Box style={{ marginTop: 10, flexWrap: 'wrap', display: 'flex' }}>
                {this.renderPlayerInfo(this.state.allPlayers)}
              </Box>
            </Box>
          </Box>
          <div className='next_back'>
            <Button
              data-test-id='btn-click'
              className={
                this.state.isSaveClicked
                  ? 'img-style_button_click'
                  : 'img-style_button'
              }
              onClick={this.handleSaveButtonClick}
              onMouseLeave={this.handleSaveButtonBlur}
            >
              Save Group{' '}
            </Button>
          </div>

        </PaperStyle>
      </Positions>
    );
  }
}
const Positions = styled(Box)({
  '& .next_back': {
    paddingTop: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '& .scroll-container': {
    overflowY: 'hidden',
    overflowX: 'hidden',
    width: '660px',
    height: '720px',
  },
  '& .avatar': {
    left: '23px',
    height: '50px',
    width: '50px',
    backgroundColor: 'rgb(255, 136, 26)',
    color: '#151414fa',
    fontWeight: 'bold',
    zIndex: 1,
  },
  '& .title': {
    fontSize: '16px',
    fontFamily: 'poppins',
    color: 'white',
    marginBottom: '1rem',
    position: 'relative',
    left: '27px',
  },
  '& .item-container': {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '0.5rem',
  },
  '& .info-container': {
    backgroundColor: '#252527',
    padding: '0.5rem',
    borderRadius: '5px',
    marginLeft: '0.5rem',
    width: '220px',
    position: 'relative',
  },
  '& .prev-group-assigned': {
    position: 'absolute',
    fontFamily: 'Poppins',
    fontWeight: '400',
    fontSize: '10px',
    color: '#8DE301',
    bottom: '4px',
    right: '8px',
  },
  '& .info': {
    color: 'white',
    width: '250px',
    position: 'relative',
    left: '16px',
  },
  '& .img-style_button_click': {
    fontFamily: 'poppins',
    margin: '5px',
    borderRadius: '4px',
    backgroundColor: 'rgb(255, 136, 26)',
    fontWeight: 'bold',
    borderColor: '4px solid rgb(255, 136, 26)',
    color: 'black',
    width: '180px',
    height: '58px',
  },
  '& .img-style_button': {
    fontFamily: 'poppins',
    margin: '5px',
    borderRadius: '4px',
    backgroundColor: 'rgb(29, 29, 31)',
    fontWeight: 'bold',
    border: '1px solid rgb(255, 136, 26)',
    color: 'rgb(255, 136, 26)',
    width: '180px',
    height: '58px',
  },
});

const PaperStyle = styled(Box)({
  backgroundColor: 'rgb(29, 29, 31)',
  color: '#151414fa',
  width: '614px',
  position: 'relative',
  padding: '20px 0px',
});