import React, { Component } from "react";
import { Box, Popover, TextField, Typography, styled } from "@material-ui/core";
interface Option {
  value: string;
  label: string;
}

interface MyProps {
  label?: string;
  option?: Option[];
  placeholder?: string;
  name: string;
  handleChangeFunction: any;
  value: string;
  validationMsg?: string;
  isDisabled?:boolean;
  hideIndicator?:any;
  height?:string;
  popoverWidth?: string;
}

interface DropdownState {
  isOpen: HTMLDivElement | null;
  searchTerm: string;
  selectedOption: string;
}
export default class DropDownSp extends Component<MyProps, DropdownState> {
  constructor(props: MyProps) {
    super(props);
    this.state = {
      isOpen: null,
      searchTerm: "",
      selectedOption: "",
    };
  }

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchTerm: e.target.value });
  };

  closeDropdown = () => {
    this.setState((prevState) => ({ isOpen: null }));
  };

  openDropdown = (event: React.MouseEvent<HTMLDivElement>) => {
    if(!this.props.isDisabled)
    this.setState({ isOpen: event.currentTarget });
  };


  handleOptionClick = (option: string) => {
    this.setState({ selectedOption: option, searchTerm: "", isOpen: null });
  };

  render(): React.ReactNode {
    const { isOpen, searchTerm } = this.state;
    const { option } = this.props;
        
    return (
      <TextFieldStyle>
        <Typography
          className="label-style-dr">
          {this.props.label}
        </Typography>
        <Box style={{ width: "100%",borderBottom:"2px solid #FF881A",borderRadius: 5 }}>
          <Box
            onClick={this.openDropdown}
            component={"button"}
            style={{
              width: "100%",
              height: `${this.props.height?this.props.height:"53px"}`,
              backgroundColor: "#1D1D1F",
              borderRadius: 10,
              borderBottom: "1px solid #FF881A",
              border: "1px solid #1d1d1f",
              display:"flex",flexDirection:"row", alignItems:"center",
              cursor:this.props.isDisabled?"not-allowed":"pointer"
            }}
          >
         {  this.props.value||this.state.selectedOption? <span style={{paddingLeft:12, color: "#CFD1D4", fontWeight: 400, fontSize: "16px",fontFamily:"Poppins" }}>
              {this.props.value||this.state.selectedOption}
            </span>:
            <span style={{paddingLeft:12, color: "#676767", fontWeight: 400, fontSize: "16px",fontFamily:"Poppins" }}>
            {this.props.placeholder}
          </span>
            }
            <Box style={{position:"absolute",right:5}} ><img alt="selected" style={{rotate:isOpen ? "180deg":"0deg"}} src={require("./drop_down.svg")} width={32} height={32} /></Box>
          </Box>
          <Popover open={Boolean(isOpen)}
            onClose={this.closeDropdown}
            anchorEl={isOpen}
            id={"popover"}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            PaperProps={{style:{borderRadius:10, border: "1px solid #FF881A",backgroundColor:"#1D1D1F",
            width:`${this.props.popoverWidth?this.props.popoverWidth:""}`}}}
            >
            <Box
            >
              <Box>
                <input
                  type="text"
                  className="dropdown-input"
                  placeholder="Search"
                  color="#676767"
                  value={searchTerm}
                  onChange={this.handleInputChange}
                  style={{ width: "100%",color:"#fff", paddingLeft:25,marginRight:30,outline:"none",border:0, height: "50px",backgroundColor:"#1D1D1F" }}
                />
                <Box
                  style={{
                    position: "absolute",
                    top: 12,
                    right: 20,
                  }}
                >
                  <img alt="vertical-line" src={require("./search.svg")} style={{backgroundColor:"#1D1D1F"}} height={24} width={24} />
                </Box>
              </Box>

              <Box
                className="dropdown-content"
                style={{
                  width: "360px",
                  display: "flex",
                  paddingBottom: "10px",
                  flexDirection: "column",
                  overflowX:"hidden",
                  minHeight: "100px",
                  maxHeight: "300px",
                  overflowY: "auto",
                  backgroundColor: "#1D1D1F",
                }}
              >
                {option &&
                  option
                    .filter((options) =>
                      options.label
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    )
                    .map((option, index) => (
                      <Box key={index}
                      onClick={() => {
                        this.props.handleChangeFunction(option);
                        this.handleOptionClick(option.label);
                      }}
                      >
                      <Options
                        className={this.props.value === option.value ? "active":""}
                      >
                        <Box style={{ width: 24, height: 24 ,marginLeft:"-10px", paddingRight:"10px",}} >
                          {this.props.value === option.value ? <img alt="selected" src={require("./icon_selected.svg")} height={24} />: ""}
                        </Box>
                        

                        {option.label}
                    
                      </Options>
                      </Box>
                    ))}
              </Box>
            </Box>
            </Popover>
        </Box>
        <Box style={{
          visibility: this.props.validationMsg ? "visible" : "hidden",
          display: this.props?.validationMsg?.length ? "block" : "none"
        }}>
          {<span className="validationMsg">{this.props.validationMsg ? this.props.validationMsg : "error"}</span>}
        </Box>
      </TextFieldStyle>
    );
  }
}
const TextFieldStyle = styled(Box)({
  position: "relative",
  display: "inline-block",
  width: "100%",
  height: "100px",
  "& .MuiFilledInput-underline": {
    borderBottom: "1px solid #FF881A",
    boxShadow: "4px 4px 4px 0px #00000040 inset",
    borderRadius: "10px",
  },
  "& .dropdown-input":{
    borderTopLeftRadius:10,
    borderTopRightRadius:10,
  },
  "& .dropdown-input::placeholder":{
    color: "#676767",
    fontSize:14,
    opacity: 1,
  },
  "& .MuiFilledInput-underline::before": {
    borderBottom: "none",
  },
  "& .MuiFilledInput-underline::after": {
    borderBottom: "none",
  },
  "& .MuiFilledInput-input": {
    height: "24px !important",
    padding: "14px 12px !important",
    color: "#CFD1D4",
    fontSize: "16px",
    fontFamily: `"Poppins", sans-serif`,
  },
  "& .MuiFormControl-root": {
    width: "100%",
  },
  "& .validationMsg": {
    fontFamily: "Poppins",
    color: "#FF1919",
    float: "left",
    fontWeight: 400,
    fonstSize: "1rem",
    margin: "6px 0",
  },
  "& .lableStyle": {
    color: "#676767",
    fontWeight: 400,
    fontSize: "20px",
    paddingBottom: "4px",
  },
  "& .label-style-dr": {
    fontFamily: "DM Sans",
    fontSize: "24px",
    fontWeight: "500",
    lineHeight: "20px",
    letterSpacing:" -0.02em",
    textAlign: "left",
    color:"#555555",
    marginBottom:6,
    marginLeft:8
  },
  "& .dropdown-content": {
    scrollbarWidth: "thin", /* For Firefox */
    scrollbarColor: "#676767 #1D1D1F !important", /* For Firefox */
  },
  "& .dropdown-content::-webkit-scrollbar": {
    width: "8px !important",
  },
  "& .dropdown-content::-webkit-scrollbar-thumb": {
    backgroundColor: "#676767 !important",
    borderRadius: "10px !important",
  },
  "& .dropdown-content::-webkit-scrollbar-track": {
    backgroundColor: "#1D1D1F !important",
  }
})


const Options = styled(Box)(({ theme }) => ({
  padding: "10px 20px",
  backgroundColor: "#1D1D1F",
  color: "#fff",
  fontSize: 16,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  margin: "0 10px",
  fontFamily: "Poppins",
  borderRadius: 10,
  cursor: "pointer",
  
  "&:hover": {
    backgroundColor: "#EF8E3B",
  },
  "&.active":{
    backgroundColor: "#EF8E3B",
  }
}));