import React from "react";
import { Box } from "@material-ui/core";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IFile } from "./OptimizationStatus.web";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { getStorageData } from "../../../framework/src/Utilities";
import { IBlock } from "../../../framework/src/IBlock";
import { toast } from "react-toastify";
import CustomUiProgress, { EStatusUiProgress } from './CustomUiProgress.web';

interface Props {
}

interface S {
    token: string;
    listFile: IFile[];
    listFileProcessing: IFile[];
}

interface SS {
}

export default class UploadFile extends BlockComponent<
    Props,
    S,
    SS
> {
    sendFilesToCloudId: string[] = [];
    updateFilesToCloudId: string[] = [];
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            token: "",
            listFile: [],
            listFileProcessing: [],
        }

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId != null && this.sendFilesToCloudId.includes(apiRequestCallId)) {
                if (responseJson?.data) {
                    toast.success("Upload Successfully")
                    const { id, files } = responseJson?.data;
                    // Find m3u8 master file
                    const getFileM3u8 = files.find((file: { filename: string }) => file.filename.includes('_master.m3u8')).filename;
                    const getUrlKeyEncryptionFile = files.find((file: { filename: string }) => file.filename.includes('.key')).url;
                    const regex = /^optimized_.*_master\.m3u8$/;
                    const match = getFileM3u8.match(regex);
                    if (match && match[0]) {
                        // Remove file optimization out temp_file.json array
                        const fileNameBeRemoved = this.transformFormatFileName(match[0])
                        await window?.api?.handleUploadSuccessful(id, fileNameBeRemoved, getUrlKeyEncryptionFile)
                        const removeFileInListFileProgressing = this.state.listFileProcessing.filter(file => file.fileName !== fileNameBeRemoved);
                        this.setState({ listFileProcessing: removeFileInListFileProgressing })
                    }
                    window?.api?.getLatestFolderGame();
                }
                else {
                    const errMessage = responseJson?.errors[0]?.token || responseJson?.errors?.toString()
                    toast.error(errMessage || "Upload Error")
                }
            }

            if (apiRequestCallId != null && this.updateFilesToCloudId.includes(apiRequestCallId)) {
                if (responseJson?.data) {
                    const { id, files } = responseJson?.data;
                    const getFileM3u8 = files.find((file: { filename: string }) => file.filename.includes('.m3u8')).filename;
                    const nameFileKeyChange = `${id}_${getFileM3u8}`
                    await window?.api?.removeFileKeyUri(nameFileKeyChange)
                }
            }
        }
        runEngine.debugLog("Message Recived", message);

        // Customizable Area End
    }

    async componentDidMount(): Promise<void> {
        window?.api?.receive('list-video-file', (data: any) => {
            this.setState({ listFile: data })
        });
        window.addEventListener('online', this.updateOnlineStatus);
        window.addEventListener('offline', this.updateOnlineStatus);
        const listOptimizationFile = await window?.api?.getListOptimizationFile();
        if (listOptimizationFile && listOptimizationFile.length > 0) {
            this.handlePreUploadFile(listOptimizationFile)
        }
        const listFileUpdateKeyEncryption = await window?.api?.getListFileKeyEncryption();
        if(listFileUpdateKeyEncryption) await this.handleAfterUploadFile(listFileUpdateKeyEncryption)
    }
    async componentWillUnmount() {
        if (window?.api) {
            window?.api?.removeAllListeners('list-video-file');
        }
        window.removeEventListener('online', this.updateOnlineStatus);
        window.removeEventListener('offline', this.updateOnlineStatus);
    }
    updateOnlineStatus = async () => {
        const { onLine } = navigator;
        if (!onLine) {
            this.setState({ listFileProcessing: [] })
        }
        else {
            const listOptimizationFile = await window?.api?.getListOptimizationFile();
            // Check upload new url key
            const listFileUpdateKeyEncryption = await window?.api?.getListFileKeyEncryption();
            if(listFileUpdateKeyEncryption) await this.handleAfterUploadFile(listFileUpdateKeyEncryption)
            // Check what files is not uploading
            if (listOptimizationFile && listOptimizationFile.length > 0) {
                this.setState({ listFileProcessing: [] })
                this.handlePreUploadFile(listOptimizationFile)
            }
        }
    };
    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): Promise<void> {
        if (prevState.listFile !== this.state.listFile) {
            const listOptimizationFile = await window?.api?.getListOptimizationFile();
            await this.handlePreUploadFile(listOptimizationFile)
        }
        if (prevState.listFileProcessing !== this.state.listFileProcessing) {
            // Check upload new url key
            const listFileUpdateKeyEncryption = await window?.api?.getListFileKeyEncryption();
            if(listFileUpdateKeyEncryption) await this.handleAfterUploadFile(listFileUpdateKeyEncryption)
            // Check what files is not uploading
            const listOptimizationFile = await window?.api?.getListOptimizationFile();
            if (!listOptimizationFile || listOptimizationFile.length < 1 && this.state.listFileProcessing.length > 0) {
                this.setState({ listFileProcessing: [] });
            }
        }
    }

    async sendFilesToCloud(formData: FormData) {
        const token = await getStorageData('authToken');
        const header = {
            token,
            'ngrok-skip-browser-warning': 'true' 
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.sendFilesToCloudId.push(requestMessage.messageId);

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_bulk_uploading/attachments`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "Post"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    async handlePreUploadFile(listFile: IFile[]) {
        for (const file of listFile) {
            const listOptimizedFilePath = await window?.api?.getPathOptimizedVideo(file.fileName);
            const parseListOptimizedFile: { name: string, filePath: string, fileName: string }[] = JSON.parse(listOptimizedFilePath)
            const formData = new FormData()
            const { duration } = file
            formData.append("video_length", duration.toString())
            const storageFormData = JSON.parse(await getStorageData('formData'));
            for (const key in storageFormData) {
                if (storageFormData[key]?.fileContent) {
                    const { fileContent, fileName } = storageFormData[key]
                    const [meta, content] = fileContent.split(',');
                    const mime = meta.match(/:(.*?);/)[1];
                    const binary = atob(content);
                    const array = [];
                    for (let i = 0; i < binary.length; i++) {
                        array.push(binary.charCodeAt(i));
                    }
                    const file = new File([new Uint8Array(array)], fileName, { type: mime });
                    formData.append(key, file);
                } else {
                    formData.append(key, storageFormData[key]);
                }
            }
            for (const optimizedFile of parseListOptimizedFile) {
                const fileBuffer = await window?.api?.parseBlobFile(optimizedFile.filePath)
                const fileBlob = new Blob([fileBuffer])
                formData.append(optimizedFile.name, fileBlob, optimizedFile.fileName)
            }
            const isFileProcessing = this.state.listFileProcessing.some(fileProcessing => fileProcessing.fileName === file.fileName);
            if (!isFileProcessing && file.isOptimization) {
                this.setState({ listFileProcessing: [...this.state.listFileProcessing, file] })
                this.sendFilesToCloud(formData)
            }
        }
    }

    
    async updateFilesAttachment(formData: FormData) {
        const token = await getStorageData('authToken');
        const header = {
            token,
            'ngrok-skip-browser-warning': 'true' 
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.updateFilesToCloudId.push(requestMessage.messageId);

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_bulk_uploading/attachments/upload_files`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "Post"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    async handleAfterUploadFile(jsonListFileKeyEncryption: string) {
        const parseListFileKeyEncryption: { name: string, filePath: string, fileName: string, id: number }[] = JSON.parse(jsonListFileKeyEncryption)
        for (const file of parseListFileKeyEncryption) {
            const formData = new FormData()
            const fileBuffer = await window?.api?.parseBlobFile(file.filePath)
            const fileBlob = new Blob([fileBuffer])
            formData.append(file.name, fileBlob, file.fileName)
            formData.append("id", file.id.toString())
            this.updateFilesAttachment(formData)
        }
    }


    transformFormatFileName(fileName: string) {
        // Remove the 'optimized_' prefix and '_master.m3u8' suffix
        let baseName = fileName.replace(/^optimized_/, '').replace(/_master\.m3u8$/, '');

        // Split the base name and the extension
        const extIndex = baseName.lastIndexOf('_');
        const baseNameWithoutExt = baseName.slice(0, extIndex);
        const extension = baseName.slice(extIndex + 1);

        // Combine the base name and the extension with a dot
        const transformedFileName = `${baseNameWithoutExt}.${extension}`;

        return transformedFileName;
    }

    render() {
        const isCheckHasOptimizedFile = this.state.listFile.some(file => file.isOptimization)

        return (
            <Box>
                {
                    <CustomUiProgress isOpenSnackbar= {Boolean(this.state.listFileProcessing.length)} listFile={this.state.listFileProcessing} statusUiProgress={EStatusUiProgress.UPLOAD} />
                }
            </Box>
        );
    }
}
