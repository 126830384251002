import React from "react";
import { BlockComponent } from "../../framework/src/BlockComponent";
import { runEngine } from "../../framework/src/RunEngine";
import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../framework/src/IBlock";
import { setStorageData } from "../../../packages/framework/src/Utilities";


interface Props {
  navigation: {
    navigate: (to: string, params: Record<string, string | number>) => void;
    getParam: (param: string, alternative: string) => void;
    goBack: () => void;
    history: any;
  }
}
interface S {
   
}

interface SS {
}

 class OAuthCallbackSteam extends BlockComponent<
    Props,
    S,
    SS
> {
   
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount(): Promise<void> {
        const searchParams = new URLSearchParams(this.props.navigation.history.location.search)        
        const authToken = searchParams.get('token');
      
    if (!authToken) {
        console.error('Authorization code not found');
        return;
      }
      await setStorageData("authToken",authToken)

      const navigationMessage = new Message(getName(MessageEnum.NavigationDashboardMessage))      
      navigationMessage.addData(getName(MessageEnum.NavigationPropsMessage),this.props)
      this.send(navigationMessage);
    }
   
    

    render() {
        return (
           <div style={{color:"white"}}>Processing With Steam Games Please Wait.....</div>
        );
    }
}

export default OAuthCallbackSteam