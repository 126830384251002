// Customizable Area Start
import React from "react";
import { MeetingProvider } from "@videosdk.live/react-sdk";

import "react-confirm-alert/src/react-confirm-alert.css";
import Meeting from "./Meeting";

const MeetingContainerView = ({
  meetingId,
  defaultMicOn,
  defaultWebcamOn,
  participantName,
  token,
  onMeetingLeave,
}) => {
  return <MeetingProvider
    config={{
      meetingId,
      micEnabled: defaultMicOn,
      webcamEnabled: defaultWebcamOn,
      name: participantName,
    }}
    token={token}
    reinitialiseMeetingOnConfigChange={true}
    joinWithoutUserInteraction={true}
  >
    <Meeting
      id="meetingView"
      onMeetingLeave={onMeetingLeave}
    />
  </MeetingProvider>
};

export default MeetingContainerView;
// Customizable Area End
