export const logoApp = require('../assets/logoApp.png')
export const swapIcon = require('../assets/Swap.svg')
export const calendarIcon = require('../assets/calendarIcon.svg')
export const customIcon = require('../assets/customIcon.svg')
export const filterIcon = require('../assets/filterIcon.svg')
export const teamIcon = require('../assets/teamIcon.svg')
export const userOnline = require('../assets/userOnline.svg')
export const dropDownIcon = require('../assets/dropDown.svg')
export const videoGameIcon = require('../assets/videoGameIcon.svg')
export const tagIcon = require('../assets/tagIcon.svg')
export const warningIcon = require('../assets/warningIcon.svg')
export const performanceTag = require('../assets/performanceTag.svg')
export const akIcon = require('../assets/akIcon.svg')
export const headshotIcon = require('../assets/headShotIcon.svg')
export const dustMap = require('../assets/dustMap.svg')
export const plusIcon = require('../assets/plusIcon.svg')
export const bgBlur = require('../assets/bgBlur.svg')
export const fantasticweDefaultTeamImg = require('../assets/fantasticwe_default_team_img.png')
export const imageSVG = require('../assets/image_.svg')
export const profileAvatar = require('../assets/profile_avatar.png')
export const bgGunImg = require('../assets/image_guninwallbg.png')
export const fileIcon = require('../assets/file_icon.svg')



