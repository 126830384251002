import React, { useState } from "react";
import {
  Button,
  Typography,
  styled,
  Box,
  TextField,
} from "@material-ui/core";
import { BorderColor, Add } from "@material-ui/icons";
import { Item } from "../../../blocks/cfcustomkeyboards/src/CreateKeyboardsController";
import { previewText } from "../../../../packages/blocks/cfcustomkeyboards/src/assets";

interface Props {
  actions?: Item[],
  subActions?: Item[],
  values?: Item[],
  reasons?: Item[],
  subReasons?: Item[],
  comment: string;
  setComment: Function;
  isShowPreviewBadge?: boolean;
}

const Wrapper = styled(Box)(({ theme }) => ({
  textTransform: "none",
  minHeight: 100,
  display: "flex",
  flexDirection: "column",
  width: "100%",
  backgroundColor: "#252527",
  marginTop: 30,
  paddingTop: 20,
  paddingBottom: 20,
  position: 'relative',

  "& .row-header": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
  },

  "& .row-body": {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-around",
    marginTop: 5,
  },

  "& .column-wrapper": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
    flex: 1,
  },

  
  "& .header-label": {
    color: "#676767",
    fontSize: 18,
    textAlign: "center",
  },
  

  "& .item-wrapper": {
    backgroundColor: "#1D1D1F",
    width: "40%",
    margin: 2,
    color: "#CFD1D4",
    textTransform: "none",
    borderRadius: 8,
    boxShadow: '-6px 6px 12px rgba(0, 0, 0, 0.1)' ,
  },

  "& .comment-wrapper": {
    borderRadius: 10,
    overflow: "hidden",
    borderStyle: "solid",
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    borderBottomWidth: 2,
    borderColor: "#FF881A",
    marginTop: 30,
  },

  "& .preview-badge": {
    position: 'absolute',
    top: '-10px',
    right: '0',
    zIndex: 1
  }
}));

const filterUniqueItems = (items: Item[]) => {
  const seenIds = new Set<number>();
  return items.filter(item => {
    if (seenIds.has(item.id)) {
      return false;
    } else {
      seenIds.add(item.id);
      return true;
    }
  });
}

const PreviewKeyboard = (props: Props) => {
  const { actions = [], subActions = [], values = [], reasons = [], subReasons = [], comment, setComment } = props;
  const [selectedActionId, setSelectedActionId] = useState(0);

  const renderActions = () => {
    return (
      <>
        {actions.map(element => (
          <Button
            onClick={() => {
              if(selectedActionId === element.id) setSelectedActionId(0);
              else setSelectedActionId(element.id);
            }}
            className="item-wrapper"
            style={{
              backgroundColor: element.id === selectedActionId ? "#FF881A" : undefined
            }}
          >{element.name}</Button>
        ))}
      </>
    )
  }

  const renderSubActions = () => {
    const shownData = !!selectedActionId ? subActions.filter(filterElm => filterElm.parent_id === selectedActionId) : filterUniqueItems(subActions);
    return (
      <>
        {shownData.map(element => (
          <Button className="item-wrapper">{element.name}</Button>
        ))}
      </>
    )
  }

  const renderValues = () => {
    const shownData = !!selectedActionId ? values.filter(filterElm => filterElm.parent_id === selectedActionId) : filterUniqueItems(values);
    return (
      <>
        {shownData.map(element => (
          <Button className="item-wrapper">{element.name}</Button>
        ))}
      </>
    )
  }

  const renderReasons = () => {
    const shownData = !!selectedActionId ? reasons.filter(filterElm => filterElm.parent_id === selectedActionId) : filterUniqueItems(reasons);
    return (
      <>
        {shownData.map(element => (
          <Button className="item-wrapper">{element.name}</Button>
        ))}
      </>
    )
  }

  const renderSubReasons = () => {
    const shownData = !!selectedActionId ? subReasons.filter(filterElm => filterElm.parent_id === selectedActionId) : filterUniqueItems(subReasons);
    return (
      <>
        {shownData.map(element => (
          <Button className="item-wrapper">{element.name}</Button>
        ))}
      </>
    )
  }

  const renderComment = () => {
    return (
      <Box className="comment-wrapper">
        <TextField
          value={comment}
          onChange={event => setComment(event.target.value)}
          id="filled-multiline-static"
          multiline
          rows={6}
          defaultValue=""
          placeholder="Enter the Comments here....."
          variant="filled"
          InputProps={{
            style: {
              color: "#FFFFFF",
              backgroundColor: "#1D1D1F",
            },
          }}
          InputLabelProps={{
            style: {
              color: "#676767",
            },
          }}
        />
      </Box>
    )
  }

  const columns = [
    { lanel: "Step 1: Action", render: renderActions },
    { lanel: "Step 2: Sub-Action", render: renderSubActions },
    { lanel: "Step 3: Value", render: renderValues },
    { lanel: "Step 4: Reason", render: renderReasons },
    { lanel: "Step 5: Sub-Reason(Optional)", render: renderSubReasons },
    { lanel: "Comment (Optional)", render: renderComment },
  ]

  return (
    <Wrapper>
      {props.isShowPreviewBadge ? <img src={previewText} width={172} className="preview-badge" /> : null}
      <Box className="row-header">
        {columns.map(columnElm => (
          <Box className="column-wrapper">
            <Typography className="header-label">{columnElm.lanel}</Typography>
          </Box>
        ))}
      </Box>
      <Box className="row-body">
        {columns.map(columnElm => (
          <Box className="column-wrapper">
            {columnElm.render()}
          </Box>
        ))}
      </Box>
    </Wrapper>
  );
};

export default PreviewKeyboard;