import { Box, Typography, styled } from "@material-ui/core";
import React, { ChangeEvent, Component } from "react";
import InputField from "./InputField.web";

interface Props {
    email: string;
    onClickResend: () => void;
    onSubmit: (otp: string) => void;
    errorMsg: string;
    className?: string;
}

interface State {
    countdown: number;
    otp: string;
}

export default class OTPVerificationForm extends Component<Props, State> {
    timer: any;

    constructor(props: any) {
        super(props);
        this.state = {
            countdown: 59,
            otp: '',
        };
    }

    componentDidMount() {
        this.timer = setTimeout(this.decreaseCountdown, 1000);
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    decreaseCountdown = () => {
        this.setState(prevState => ({
            countdown: prevState.countdown > 0 ? prevState.countdown - 1 : prevState.countdown
        }), () => {
            this.timer = setTimeout(this.decreaseCountdown, 1000);
        });
    }

    handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({ otp: event.target.value })
    }

    handleClickSubmit = () => {
        if (this.state.otp) {
            this.props.onSubmit(this.state.otp);
        }
    }

    handleClickResend = () => {
        if (this.state.countdown === 0) {
            this.setState({ countdown: 59 })
            this.props.onClickResend();
        }
    }

    render() {
        return (
            <StyledContainer data-test-id="otp-container" className={this.props.className}>
                <Typography component="span" className="title">OTP Verification</Typography>

                <Typography component="span" className="sub-text">
                    We've sent a verification code to your email
                    <br />
                    {this.props.email}
                </Typography>

                <Box className="input-container">
                    <InputField autoFocus={true} className="MuiFilledInput-underline inputField" name="otp" label='' type="text" value={this.state.otp} onChange={this.handleChange} validationMsg={this.props.errorMsg} placeholder="Enter your OTP" data-test-id="otp-input" />
                </Box>

                <Box className={`submit-btn${this.state.otp.trim() === '' ? " disabled" : ""}`} onClick={this.handleClickSubmit} data-test-id="otp-submit-btn">Submit</Box>

                <Typography component="span" className="resend-text">
                    Didn't receive OTP?
                    <br />
                    <Typography data-test-id="resend-btn" component="span" className={this.state.countdown === 0 ? "clickable" : "non-clickable"} onClick={this.handleClickResend}>Click to resend</Typography> in 00:{this.state.countdown > 9 ? this.state.countdown : "0" + this.state.countdown}
                </Typography>
            </StyledContainer>
        )
    }
}

const StyledContainer = styled(Box)(({ theme }) => ({
    borderRadius: '10px',
    width: '100%',
    boxSizing: 'border-box',
    padding: '25px',
    fontFamily: 'Poppins',
    color: "white",
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    backgroundColor: '#252527',

    "& .title": {
        width: '100%',
        fontSize: '26px',
        textAlign: 'center'
    },

    "& .sub-text": {
        fontSize: '18px',
        width: '100%',
        textAlign: 'center'
    },

    "& .input-container": {
        width: '100%',
    },

    "& .submit-btn": {
        width: '100%',
        padding: '10px 0',
        backgroundColor: '#FF881A',
        color: 'white',
        fontSize: '20px',
        textAign: 'center',
        cursor: 'pointer',
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },

    "& .disabled": {
        backgroundColor: 'grey',
        color: 'black',
        cursor: 'initial'
    },

    "& .resend-text": {
        width: '100%',
        textAlign: 'center',
        fontSize: '18px',
    },

    "& .clickable": {
        cursor: 'pointer',
        color: '#FF881A',
        textDecoration: 'underline',
        fontSize: '19px'
    },

    "& .non-clickable": {
        cursor: 'initial',
        color: 'grey',
        textDecoration: 'initial',
        fontSize: '19px'
    },
}))