import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { styled, Box } from "@material-ui/core";
const moreOption = require("./moreOption.svg");

interface Props {
    options: {
        label: string;
        onClick?: any;
    }[]
}

interface State {
    anchorEl: null | HTMLElement;
  }

class MenuButton extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            anchorEl: null,
        };
    }

    handleClick = (event: any) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    render() {
        const { anchorEl } = this.state;
        const { options } = this.props;

        return (
            <div>
                <Box
                    className="cardTypoDots"
                    id="profile-button"
                    onClick={this.handleClick}
                >
                    <img src={moreOption} />
                </Box>
                <StyledMenu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={this.handleClose}
                >
                    {options.map((option: any, index: any) => (
                        <MenuItem key={index} onClick={() =>{ 
                            option.onClick();
                            this.handleClose();
                        }}>
                            {option.label}
                        </MenuItem>
                    ))}
                </StyledMenu>
            </div>
        );
    }
}

export default MenuButton;

export const StyledMenu = styled((props: any) => (
    <Menu
        data-test-id="menu"
        elevation={0}
        anchorOrigin={{
            horizontal: "right",
            vertical: "bottom",
        }}
        transformOrigin={{
            horizontal: "right",
            vertical: "top",
        }}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiPaper-root": {
        border: "1px solid #FF881A",
        width: 250,
        marginTop: theme.spacing(8),
        backgroundColor: "#1D1D1F",
        borderRadius: 10,
        color: "#CFD1D4",
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        [theme.breakpoints.down('lg')]: {
            width: 200,
        },
        [theme.breakpoints.down('md')]: {
            width: 149,
        },
        [theme.breakpoints.down('sm')]: {
            width: 101,
        },
        "& .MuiMenu-list": {
            margin: "10px",
            padding: "4px 0",
        },
        "& .MuiMenuItem-root": {
            [theme.breakpoints.down('lg')]: {
                fontSize: 15,
                marginRight: theme.spacing(1.2),
            },
            [theme.breakpoints.down('md')]: {
                fontSize: 13,
                marginRight: theme.spacing(1),
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: 11,
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: 9,
            },
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                marginRight: theme.spacing(1.5),
            },
            "&:hover": {
                backgroundColor: "#FF881A",
                color: "#1D1D1F",
                border: "1px solid #FF881A",
                borderRadius: 5,
            },
        },
    },
}));