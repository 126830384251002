import React, { ChangeEvent } from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  styled,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Collapse,
  Switch,
  withStyles,
  Theme,
  createStyles,
  SwitchProps,
  SwitchClassKey,
  Modal,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import NavigationMenu from "../../../../packages/blocks/navigationmenu/src/NavigationMenu.web";
import { arrowDown, arrowUp, closeIcon, createCustomKbIcon, createKbGrIcon, customKeyboardEditIcon, defaultTeamIcon, defaultTeamImg, deleteIcon, duplicateIcon, headerInfoIcon, rowEditIcon, rowInfoIcon, searchIcon, statIcon } from "./assets";
import InputField from "../../../../packages/components/src/InputField.web";
import Loader from "../../../../packages/components/src/Loader.web";

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface CustomSwitchProps extends SwitchProps {
  classes: Styles;
}
// Customizable Area End

import CfcustomkeyboardsController, {
  KeyboardGroup,
  Props,
  configJSON,
} from "./CfcustomkeyboardsController";

export default class Cfcustomkeyboards extends CfcustomkeyboardsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderCustomKeyboardTable = (customKeyboardGroup: KeyboardGroup) => {
    const createBtnDisabled = this.getTotalKeygoardLength() > (this.state.subscriptionKeyboard || this.maxCustomKb) - 1 || !this.state.isCoachOrTeamAdmin;

    const CustomSwitch = withStyles((theme: Theme) =>
      createStyles({
        root: {
          width: 48,
          height: 28,
          padding: 0,
        },
        switchBase: {
          padding: '2px 0 0 5px',
          '&$checked': {
            transform: 'translateX(16px)',
            color: "#676767",
            '& + $track': {
              backgroundColor: '#FF881A',
              opacity: 1,
              border: 'none',
            },
          },
          '&$focusVisible $thumb': {
            color: '#676767',
            border: '6px solid #fff',
          },
        },
        thumb: {
          width: 24,
          height: 24,
          boxShadow: 'none',
          color: "#676767"
        },
        track: {
          borderRadius: 26 / 2,
          border: "none",
          backgroundColor: '#181818',
          opacity: 1,
        },
        checked: {},
        focusVisible: {},
      }),
    )(({ classes, ...props }: CustomSwitchProps) => {
      return (
        <Switch
          focusVisibleClassName={classes.focusVisible}
          disableRipple
          classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked,
          }}
          {...props}
        />
      );
    })

    const shownData = !!customKeyboardGroup.attributes.searchText ? customKeyboardGroup.attributes.searchResult : customKeyboardGroup.attributes.keyboards;

    return (
      <Box className="default-kb-main-content">
        {customKeyboardGroup.attributes.keyboards.length > 0 ?
          <TableContainer className="keyboards-table-container">
            <Table className="keyboards-table" aria-label="keyboards table">
              <TableHead>
                <TableRow>
                  <TableHeaderCell align="center">#</TableHeaderCell>

                  <TableHeaderCell align="center"></TableHeaderCell>

                  <TableHeaderCell align="center">Keyboard Name</TableHeaderCell>

                  <TableHeaderCell align="center">Position Assigned</TableHeaderCell>

                  <TableHeaderCell align="center">Default Keyboard for (Position)</TableHeaderCell>

                  <TableHeaderCell align="center">M-OP change %</TableHeaderCell>

                  <TableHeaderCell align="center">Made Change %</TableHeaderCell>

                  <TableHeaderCell align="center">Missed Change %</TableHeaderCell>

                  <TableHeaderCell align="center">Active?</TableHeaderCell>

                  <TableHeaderCell align='center'>Actions</TableHeaderCell>
                </TableRow>
              </TableHead>


              <TableBody>
                {
                  shownData?.map((item, index) => {
                    const cellBGColor = index % 2 === 0 ? "" : "#252527";
                    return (
                      <CustomTableRow data-test-id="keyboard-row" key={String(item.id)}>
                        <TableBodyCell style={{ backgroundColor: cellBGColor }} align="center">{index + 1}</TableBodyCell>

                        <TableBodyCell style={{ backgroundColor: cellBGColor }} align="center">
                          <img src={defaultTeamIcon} width={70} />
                        </TableBodyCell>

                        <TableBodyCell style={{ backgroundColor: cellBGColor }} align="center">{item.name}</TableBodyCell>

                        <TableBodyCell style={{ backgroundColor: cellBGColor }} align="center">{item.position_assigned}</TableBodyCell>

                        <TableBodyCell style={{ backgroundColor: cellBGColor }} align="center"></TableBodyCell>

                        <TableBodyCell style={{ backgroundColor: cellBGColor, color: this.formatNumber(item.missed_op_percentage) }} align="center">{item.missed_op_percentage}</TableBodyCell>

                        <TableBodyCell style={{ backgroundColor: cellBGColor, color: this.formatNumber(item.made_change_percentage) }} align="center">{item.made_change_percentage}</TableBodyCell>

                        <TableBodyCell style={{ backgroundColor: cellBGColor, color: this.formatNumber(item.missed_change_percentage) }} align="center">{item.missed_change_percentage}</TableBodyCell>

                        <TableBodyCell style={{ backgroundColor: cellBGColor }} align="center">
                          <CustomSwitch data-test-id="active-switch" checked={item.is_active} onChange={() => this.handleActiveItem(customKeyboardGroup.id, item.id)} />
                        </TableBodyCell>

                        <TableBodyCell style={{ backgroundColor: cellBGColor }} align="center">
                          <Box className="row-actions">
                            <img src={statIcon} width={24} />

                            {this.state.isCoachOrTeamAdmin ?
                              <>
                                <img data-test-id="edit-keyboard-btn" src={rowEditIcon} width={24} onClick={() => this.handleEditItem(item.id)} />

                                <img data-test-id="duplicate-custom-kb-btn" src={duplicateIcon} width={24} onClick={() => this.handleDuplicateCustomKb(customKeyboardGroup.id, item.id)} />
                              </> :
                              null
                            }

                            <img data-test-id="show-custom-kb-info-btn" src={rowInfoIcon} width={24} onClick={() => this.handleShowKbInfo(customKeyboardGroup.id, item.id)} />

                            {
                              this.state.isCoachOrTeamAdmin ?
                                <img data-test-id="delete-custom-kb-btn" src={deleteIcon} width={24} onClick={() => this.handleShowDeleteKb(customKeyboardGroup.id, item.id)} /> :
                                null
                            }
                          </Box>
                        </TableBodyCell>
                      </CustomTableRow>
                    )
                  })
                }
              </TableBody>
            </Table>
          </TableContainer> : null
        }

        <Box className="bottom-content">
          <Box className="remain-keyboard">
            <Typography className="remain-keyboard-title" component="span">
              Remaining Custom Keyboards:{" "}

              <Typography className="red-text" component="span">{(this.state.subscriptionKeyboard || this.maxCustomKb) - this.getTotalKeygoardLength()}</Typography>
            </Typography>

            <Typography className="more-keyboard-text" component="span">
              Need more keyboards?{" "}

              <Typography className="contact-us-text" data-test-id="contact-us-id" component="span" onClick={() => this.navigateAddContactUs()} >Contact Us</Typography>
            </Typography>
          </Box>

          <Box data-test-id="create-custom-kb-btn" className={`create-keyboard-btn${createBtnDisabled ? " disabled" : ""}`} onClick={() => this.handleOpenCreateCustomKb(customKeyboardGroup.id)}>
            Create Keyboard
          </Box>
        </Box>
      </Box>
    )
  }

  renderCustomKeyboards = () => {
    return this.state.customKeyboardGroup.map(group => {
      return (
        <Box className="default-kb-wrapper" key={String(group.id)}>
          <Box data-test-id="custom-kb-gr-collapsible" className="default-kb-title" onClick={() => this.handleCustomKBCollapse(group.id)}>
            <Typography className="default-kb-title-text" component='span'>{group.attributes.name}</Typography>

            <Box className="custom-kb-title-btn">
              <img data-test-id="rename-kb-gr-btn" src={customKeyboardEditIcon} width={44} onClick={event => this.handleEditCustomKb(event, group.id)} />

              <img src={group.expanded ? arrowUp : arrowDown} width={44} height={44} />
            </Box>
          </Box>

          <Collapse in={group.expanded} className="default-kb-content">
            <Box className="default-kb-content-header">
              <Typography className="default-kb-content-header-text" component="span">
                This table holds your team's keyboards. You can add keyboards based on your subscription limit. Deleting a keyboard creates space for a new one, but it also removes any associated markings - Reports are saved.
              </Typography>

              <Box className="search-input-wrapper">
                <TextField className="search-input" onChange={event => this.handleChangeSearchKey(event, group.id)} placeholder="Search keyboard..." variant="standard" data-test-id="custom-kb-gr-search-input" />

                <img src={searchIcon} width={32} alt="search" className="search-icon" />
              </Box>
            </Box>

            {this.renderCustomKeyboardTable(group)}
          </Collapse>
        </Box>
      )
    })
  }

  renderDefaultKeyboards = () => {
    return (
      <Box className="default-kb-wrapper">
        <Box data-test-id="default-kb-collapsible" className="default-kb-title" onClick={this.handleDefaultKBCollapse}>
          <Typography className="default-kb-title-text" component='span'>FantasticWE Default Keyboards</Typography>
          <img src={this.state.defaultKBOpen ? arrowUp : arrowDown} width={44} height={44} />
        </Box>

        <Collapse in={this.state.defaultKBOpen} className="default-kb-content">
          <Box className="default-kb-content-header">
            <Typography className="default-kb-content-header-text" component="span">
              This table holds your team's keyboards. You can add keyboards based on your subscription limit. Deleting a keyboard creates space for a new one, but it also removes any associated markings - Reports are saved.
            </Typography>

            <Box className="search-input-wrapper">
              <TextField className="search-input" value={this.state.searchKey} onChange={this.handleChangeSearchKeyDefaultKb} placeholder="Search keyboard..." variant="standard" data-test-id="search-input" />

              <img src={searchIcon} width={32} alt="search" className="search-icon" />
            </Box>
          </Box>

          <Box className="default-kb-main-content">
            <TableContainer className="keyboards-table-container">
              <Table className="keyboards-table" aria-label="keyboards table">
                <TableHead>
                  <TableRow>
                    <TableHeaderCell align="center">#</TableHeaderCell>

                    <TableHeaderCell align="center"></TableHeaderCell>

                    <TableHeaderCell align="left">
                      <Box className="header-title">Keyboard Name <img src={headerInfoIcon} width={24} /></Box>
                    </TableHeaderCell>

                    <TableHeaderCell align="center">
                      <Box className="header-title">Position Assigned <img src={headerInfoIcon} width={24} /></Box>
                    </TableHeaderCell>

                    <TableHeaderCell align='center'>Created By</TableHeaderCell>

                    <TableHeaderCell align='center'>Times Used</TableHeaderCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {
                    (!!this.state.searchKey ? this.state.defaultSearchData : this.state.defaultTableData)?.map((item, index) => {
                      const cellBGColor = index % 2 === 0 ? "" : "#252527";
                      return (
                        <CustomTableRow data-test-id="keyboard-row" key={String(item.id)}>
                          <TableBodyCell style={{ backgroundColor: cellBGColor }} align="center">{item.id}</TableBodyCell>

                          <TableBodyCell style={{ backgroundColor: cellBGColor }} align="center">
                            <img src={defaultTeamIcon} width={70} />
                          </TableBodyCell>

                          <TableBodyCell style={{ backgroundColor: cellBGColor }} align="left">{item.attributes.name}</TableBodyCell>

                          <TableBodyCell style={{ backgroundColor: cellBGColor }} align="center">{item.attributes.position_assigned}</TableBodyCell>

                          <TableBodyCell style={{ backgroundColor: cellBGColor }} align="center">{item.attributes.created_by}</TableBodyCell>

                          <TableBodyCell style={{ backgroundColor: cellBGColor }} align="center">0</TableBodyCell>
                        </CustomTableRow>
                      )
                    })
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Collapse>
      </Box>
    )
  }

  renderCreateModal = (data: {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    icon: string;
    label: string;
    value: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    validationMsg: string;
    onSubmit: () => void;
    inputPlaceHolder?: string;
    submitBtnTitle?: string;
  }) => {
    const { isOpen, title, icon, label, onChange, value, validationMsg, onClose, onSubmit, inputPlaceHolder = "Enter Group Name", submitBtnTitle = "Create" } = data;

    return (
      <StyledCreateKbGrModal data-test-id="create-kb-gr-container" open={isOpen} onClose={() => { }}>
        <Box className="form-container">
          <Typography className="title" component="span">{title}</Typography>

          <img src={icon} width={112} />

          <Typography className="label" component="span">{label}</Typography>

          <Box className="input-container">
            <InputField autoFocus={true} className="MuiFilledInput-underline inputField" name="kbGrName" label='' type="text" value={value} onChange={onChange} validationMsg={validationMsg} placeholder={inputPlaceHolder} data-test-id="group-name-input" />
          </Box>

          <Box className="btn-container">
            <Box data-test-id="create-modal-cancel-btn" className="cancel-btn" onClick={onClose}>Cancel</Box>

            <Box data-test-id="create-modal-submit-btn" className="create-btn" onClick={onSubmit}>{submitBtnTitle}</Box>
          </Box>
        </Box>
      </StyledCreateKbGrModal>
    )
  }

  renderBackdrop = () => {
    return this.state.createKbGrOpen || this.state.createCustomKbOpen || this.state.renameKbGrOpen || this.state.duplicateKbOpen || this.state.kbInfoOpen || this.state.deleteKbOpen || this.state.activateKbOpen ?
      <Box className="backdrop-filter" />
      : null
  }

  renderTeamList = () => {
    if (this.state.userRole === 'team admin') {
      return (
        <Box className="teams-container">
          {
            this.state.teams.map((item, index) => {
              return (
                <>
                  <Box key={String(item.id)} data-test-id="team" className={`team${item.selected ? " selected" : ""}`} style={{ marginLeft: index !== 0 ? "16px" : "0" }} onClick={() => this.handleSelectTeam(item)}>
                    <img src={item.attributes.image || defaultTeamImg} width={88} />

                    <Box className="team-info">
                      <Typography className="team-name-text" component="span">{item.attributes.name}</Typography>

                      <Typography className="team-user-text" component="span">{item.attributes.count} users</Typography>
                    </Box>
                  </Box>

                  {index === 0 && <Box className="divider" />}
                </>
              )
            })
          }
        </Box>
      )
    } else {
      return null;
    }
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <StyledCustomKeyboard>
        <NavigationMenu navigation={this.props.navigation} id="NavigationMenu" currentTabProp="settings" />

        <Loader loading={this.state.defaultKbLoading || this.state.kbGroupLoading} />

        {/* Team List */}
        {this.renderTeamList()}

        {/* Default Keyboard Table */}
        {this.renderDefaultKeyboards()}

        {/* Custom Keyboard Table */}
        {this.renderCustomKeyboards()}

        <Box data-test-id="create-kb-gr-btn" className={`create-kb-gr-btn${this.state.customKeyboardGroup.length > this.maxKbGr - 1 || !this.state.isCoachOrTeamAdmin ? " disabled" : ""}`} onClick={this.handleOpenCreateKbGr}>
          Create Keyboard Group
        </Box>

        {/* Create Keyboard Group */}
        {this.renderCreateModal({
          isOpen: this.state.createKbGrOpen,
          onClose: this.handleCloseCreateKbGrModal,
          title: "Create Keyboard Group!",
          icon: createKbGrIcon,
          label: "Custom Keyboard Group Name",
          value: this.state.createKbGrName,
          onChange: this.handleChangeCreateKbGrName,
          validationMsg: this.state.createKbGrNameError,
          onSubmit: this.handleSubmitCreateKbGrModal
        })}

        {/* Create Custom Keyboard */}
        {this.renderCreateModal({
          isOpen: this.state.createCustomKbOpen,
          onClose: this.handleCloseCreateCustomKbModal,
          title: "Create Custom Keyboard!",
          icon: createCustomKbIcon,
          label: "Custom Keyboard Name",
          value: this.state.createCustomKbName,
          onChange: this.handleChangeCreateCustomKbName,
          validationMsg: this.state.createCustomKbNameError,
          onSubmit: this.handleSubmitCreateCustomKeyboadModal,
          inputPlaceHolder: 'Enter Keyboard Name'
        })}

        {/* Rename keyboard Group */}
        {this.renderCreateModal({
          isOpen: this.state.renameKbGrOpen,
          onClose: this.handleCloseRenameKbModal,
          title: "Rename Keyboard Group!",
          icon: createCustomKbIcon,
          label: "Custom Keyboard Group Name",
          value: this.state.renameKbGrName,
          onChange: this.handleChangeRenameKbGrName,
          validationMsg: this.state.renameKbGrNameError,
          onSubmit: this.handleSubmitRenameCustomKb,
          submitBtnTitle: 'Rename'
        })}

        {/* Duplicate Custom Keyboard Modal */}
        <StyledDuplicateKbModal open={this.state.duplicateKbOpen} onClose={() => { }}>
          <Box className="duplicate-container">
            <img src={closeIcon} width={24} className="close-icon" onClick={this.handleCloseDuplicateKb} />

            <Typography className="title" component="span">Duplicate Keyboard!</Typography>

            <Typography className="detail" component="span">
              Duplicating{" "}

              <Typography className="keyboard-name" component="span">{"["}{this.state.selectedKeyboard?.name}{"]"}</Typography>{" "}

              will create a new exact copy of this keyboard for fast creation of similar keyboards.
            </Typography>

            <Typography className="detail" component="span">Duplicates will not inherit change % information & keyboard report data.</Typography>

            <Box className="divider" />

            <Box className="btn-container">
              <Box data-test-id="duplicate-cancel-btn" className="cancel-btn" onClick={this.handleCloseDuplicateKb}>Cancel</Box>

              <Box data-test-id="duplicate-submit-btn" className="duplicate-btn" onClick={this.handleSaveDuplicateKb}>Duplicate</Box>
            </Box>
          </Box>
        </StyledDuplicateKbModal>

        {/* Info Modal */}
        <StyledInfoModal open={this.state.kbInfoOpen} onClose={() => { }}>
          <Box className="info-container">
            <img src={closeIcon} width={24} className="close-icon" onClick={this.handleCloseInfoModal} />

            <Typography className="title" component="span">Keyboard Information!</Typography>

            <Typography className="keyboard-name" component='span'>{this.state.selectedKeyboard?.name}</Typography>

            <Typography className="created-on" component="span">
              Created On: {this.state.selectedKeyboard?.createdIn}
            </Typography>

            <Typography className="created-on" component="span">
              Created By: {this.state.selectedKeyboard?.created_by}
            </Typography>

            <Box className="divider" />

            <Box data-test-id="show-info-back-btn" className="back-btn" onClick={this.handleCloseInfoModal}>Back</Box>
          </Box>
        </StyledInfoModal>

        {/* Delete Modal */}
        <StyledDeleteModal open={this.state.deleteKbOpen} onClose={() => { }}>
          <Box className="delete-container">
            <img src={closeIcon} width={24} className="close-icon" onClick={this.handleCloseDeleteModal} />

            <Typography className="title" component="span">Delete Keyboard!</Typography>

            <Typography className="detail" component="span">
              Are you sure, you want to deleting{" "}

              <Typography className="keyboard-name" component='span'>{"["}{this.state.selectedKeyboard?.name}{"]"}</Typography>

              ?

              <br />

              This action can not be undone.
            </Typography>

            <Typography className="detail" component="span">The following action will not delete any previous data marked with this keyboard - but will delete the keyboard report.</Typography>

            <Box className="divider" />

            <Box className="btn-container">
              <Box className="cancel-btn" onClick={this.handleCloseDeleteModal}>Cancel</Box>

              <Box data-test-id="submit-delete-custom-kb-btn" className="delete-btn" onClick={this.handleDeleteCustomKb}>Delete</Box>
            </Box>
          </Box>
        </StyledDeleteModal>

        {/* Activate/Deactivate Modal */}
        <StyledActivateModal open={this.state.activateKbOpen} onClose={() => { }}>
          <Box className="activate-container">
            <img src={closeIcon} width={24} className="close-icon" onClick={this.handleCloseActivateModal} />

            <Typography className="title" component="span">{this.state.selectedKeyboard?.is_active ? "Deactivate" : "Activate"} Keyboard!</Typography>

            <Typography className="detail" component="span">
              {this.state.selectedKeyboard?.is_active ? "Deactivating" : "Activating"}{" "}

              <Typography className="keyboard-name" component="span">{"["}{this.state.selectedKeyboard?.name}{"]"}</Typography> will result in this keyboard being {this.state.selectedKeyboard?.is_active ? "disabled" : "enabled"} for selection or usage by anyone within the team.
            </Typography>

            <Typography className="detail mt-24" component='span'>Previous associated data will remain unchanged.</Typography>

            <Box className="divider" />

            <Box className="btn-container">
              <Box className="cancel-btn" onClick={this.handleCloseActivateModal}>Cancel</Box>

              {
                this.state.selectedKeyboard?.is_active ?
                  <Box data-test-id="deactivate-btn" className="submit-btn" onClick={this.handleSubmitActivate}>Deactivate</Box>
                  :
                  <Box data-test-id="activate-btn" className="submit-btn activating" onClick={this.handleSubmitActivate}>Activate</Box>
              }
            </Box>
          </Box>
        </StyledActivateModal>

        {this.renderBackdrop()}
      </StyledCustomKeyboard>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyledActivateModal = styled(Modal)(({ theme }) => ({
  width: '780px',
  height: 'fit-content',
  borderRadius: '10px',
  padding: '40px',
  backgroundColor: "#252527",
  margin: 'auto',
  marginTop: '427px',

  "& *": {
    boxSizing: 'border-box'
  },

  "& div:first-child": {
    backgroundColor: "transparent !important"
  },

  "& .close-icon": {
    position: 'absolute',
    top: '-20px',
    right: '-20px',
    cursor: 'pointer'
  },

  "& .detail": {
    fontWeight: '400',
    marginTop: '32px',
    fontFamily: 'Poppins',
    color: "#676767",
    width: '100%',
    fontSize: '18px',
    textAlign: 'center',

    "& .keyboard-name": {
      color: "#FF881A"
    }
  },

  "& .activate-container": {
    with: '100%',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },

  "& .btn-container": {
    display: 'flex',
    marginTop: '24px',
    height: '64px',
    flexDirection: 'row',
    gap: '24px',
  },

  "& .divider": {
    width: "calc(100% - 160px)",
    marginTop: '24px',
    backgroundColor: "#FF881A",
    height: '2px',
  },

  "& .submit-btn": {
    width: '190px',
    display: 'inline-flex',
    alignItems: 'center',
    fontWeight: '600',
    backgroundColor: "#FF1919",
    fontSize: '18px',
    justifyContent: 'center',
    cursor: 'pointer',
    fontFamily: "Montserrat",
    color: "#1D1D1F",
    borderRadius: '8px',

    "&.activating": {
      backgroundColor: "#FF881A"
    }
  },

  "& .cancel-btn": {
    width: '190px',
    border: 'solid 1px #FF881A',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    fontSize: '18px',
    color: "#FF881A",
    cursor: 'pointer',
    borderRadius: '8px',
  },

  "& .title": {
    fontSize: '20px',
    fontWeight: '700',
    fontFamily: 'Poppins',
    color: "#CFD1D4"
  },

  [theme.breakpoints.down(769)]: {
    width: "550px",

    "& .divider": {
      width: "100%",
    }
  }
}))

const StyledDeleteModal = styled(Modal)(({ theme }) => ({
  width: '780px',
  height: 'fit-content',
  borderRadius: '10px',
  padding: '40px',
  backgroundColor: "#252527",
  margin: 'auto',
  marginTop: '406px',

  "& *": {
    boxSizing: 'border-box'
  },

  "& .delete-container": {
    with: '100%',
    flexDirection: 'column',
    display: 'flex',
    position: 'relative',
    alignItems: 'center'
  },

  "& div:first-child": {
    backgroundColor: "transparent !important"
  },

  "& .title": {
    fontSize: '20px',
    fontFamily: 'Poppins',
    fontWeight: '700',
    color: "#CFD1D4"
  },

  "& .detail": {
    fontSize: '18px',
    marginTop: '32px',
    fontWeight: '400',
    color: '#676767',
    fontFamily: 'Poppins',
    textAlign: 'center'
  },

  "& .keyboard-name": {
    color: '#FF881A'
  },

  "& .sub-detail": {
    marginTop: '0',
  },

  "& .btn-container": {
    height: '64px',
    display: 'flex',
    gap: '24px',
    flexDirection: 'row',
    marginTop: '24px'
  },

  "& .divider": {
    height: '2px',
    backgroundColor: "#FF881A",
    width: "calc(100% - 160px)",
    marginTop: '24px',
  },

  "& .delete-btn": {
    width: '190px',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    display: 'inline-flex',
    fontFamily: "Montserrat",
    fontWeight: '600',
    fontSize: '18px',
    backgroundColor: "#FF1919",
    color: "#1D1D1F",
    borderRadius: '8px'
  },

  "& .cancel-btn": {
    width: '190px',
    display: 'inline-flex',
    justifyContent: 'center',
    fontWeight: '600',
    color: "#FF881A",
    alignItems: 'center',
    fontFamily: 'Montserrat',
    border: 'solid 1px #FF881A',
    fontSize: '18px',
    cursor: 'pointer',
    borderRadius: '8px',
  },

  "& .close-icon": {
    cursor: 'pointer',
    position: 'absolute',
    top: '-20px',
    right: '-20px',
  },

  [theme.breakpoints.down(769)]: {
    width: '550px',

    "& .divider": {
      width: '100%'
    }
  }
}))

const StyledInfoModal = styled(Modal)(({ theme }) => ({
  width: "626px",
  height: 'fit-content',
  borderRadius: '10px',
  padding: '40px',
  backgroundColor: "#252527",
  margin: "auto",
  marginTop: '430px',

  "& div:first-child": {
    backgroundColor: 'transparent !important'
  },

  "& *": {
    boxSizing: 'border-box'
  },

  "& .close-icon": {
    position: 'absolute',
    top: '-20px',
    right: '-20px',
    cursor: 'pointer'
  },

  "& .info-container": {
    display: 'flex',
    position: 'relative',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },

  "& .keyboard-name": {
    fontWeight: '400',
    marginTop: '32px',
    fontSize: '18px',
    fontFamily: "Poppins",
    color: "#FF881A"
  },

  "& .title": {
    fontSize: '20px',
    fontFamily: "Poppins",
    fontWeight: '700',
    color: "#CFD1D4"
  },

  "& .divider": {
    height: '2px',
    marginTop: '24px',
    width: "calc(100% - 160px)",
    backgroundColor: "#FF881A",
  },

  "& .created-on": {
    marginTop: '24px',
    fontWeight: '400',
    fontSize: '18px',
    fontFamily: "Poppins",
    color: "#676767"
  },

  "& .back-btn": {
    marginTop: '24px',
    width: "166px",
    alignItems: 'center',
    fontWeight: '600',
    height: '55px',
    display: 'inline-flex',
    borderRadius: '8px',
    backgroundColor: "#FF881A",
    justifyContent: 'center',
    cursor: 'pointer',
    fontFamily: "Montserrat",
    fontSize: '18px',
    color: "#1D1D1F"
  }
}))

const StyledDuplicateKbModal = styled(Modal)(({ theme }) => ({
  width: '780px',
  height: 'fit-content',
  borderRadius: '10px',
  padding: '40px',
  backgroundColor: '#252527',
  margin: 'auto',
  marginTop: '427px',
  textAlign: 'center',

  "& *": {
    boxSizing: 'border-box'
  },

  "& .duplicate-container": {
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  "& div:first-child": {
    backgroundColor: 'transparent !important'
  },

  "& .detail": {
    marginTop: '32px',
    fontFamily: 'Poppins',
    fontWeight: '400',
    fontSize: '18px',
    color: "#676767"
  },

  "& .title": {
    fontFamily: "Poppins",
    fontWeight: '700',
    fontSize: '20px',
    color: "#CFD1D4"
  },

  "& .keyboard-name": {
    color: "#FF881A"
  },

  "& .btn-container": {
    display: 'flex',
    gap: '24px',
    height: '64px',
    marginTop: '24px',
  },

  "& .divider": {
    marginTop: '24px',
    height: '2px',
    backgroundColor: "#FF881A",
    width: "calc(100% - 204px)"
  },

  "& .cancel-btn": {
    padding: '10px 16px',
    minWidth: "190px",
    alignItems: 'center',
    height: '100%',
    display: 'inline-flex',
    borderRadius: '8px',
    border: 'solid 1px #FF881A',
    justifyContent: 'center',
    fontFamily: "Montserrat",
    fontWeight: '600',
    width: 'fit-content',
    fontSize: '18px',
    color: "#FF881A",
    cursor: 'pointer'
  },

  "& .close-icon": {
    top: '-20px',
    position: 'absolute',
    right: '-20px',
    cursor: 'pointer'
  },

  "& .duplicate-btn": {
    padding: '10px 16px',
    width: 'fit-content',
    borderRadius: '8px',
    fontFamily: "Montserrat",
    backgroundColor: "#FF881A",
    display: 'inline-flex',
    minWidth: "190px",
    color: "#1D1D1F",
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    fontWeight: "600",
    fontSize: '18px',
    cursor: 'pointer'
  },

  [theme.breakpoints.down(769)]: {
    width: "550px",

    "& .divider": {
      width: '100%'
    }
  }
}))

const StyledCreateKbGrModal = styled(Modal)(({ theme }) => ({
  width: '630px',
  height: 'fit-content',
  minHeight: '472px',
  borderRadius: '10px',
  boxSizing: 'border-box',
  padding: '40px',
  margin: "auto",
  marginTop: '333px',
  backgroundColor: "#252527",

  "& .form-container": {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },

  "& div:first-child": {
    backgroundColor: 'transparent !important'
  },

  "& .title": {
    fontFamily: "Poppins",
    fontWeight: '700',
    fontSize: '20px',
    color: "#CFD1D4"
  },

  "& img": {
    marginTop: '32px',
  },

  "& .input-container": {
    width: '100%',
    marginTop: '16px'
  },

  "& .label": {
    fontSize: '16px',
    fontFamily: "Poppins",
    fontWeight: '400',
    marginTop: '46px',
    color: "#CFD1D4",
    width: '100%',
  },

  "& .btn-container": {
    width: 'calc(100% - 110px)',
    minWidth: 'fit-content',
    marginTop: '5px',
    display: 'flex',
    justifyContent: 'space-between',
    height: '55px'
  },

  "& .create-btn": {
    fontSize: '18px',
    width: 'fit-content',
    boxSizing: 'border-box',
    minWidth: '200px',
    height: '100%',
    borderRadius: '8px',
    padding: '10px 16px',
    fontFamily: "Montserrat",
    alignItems: 'center',
    backgroundColor: "#FF881A",
    cursor: 'pointer',
    display: 'inline-flex',
    fontWeight: '600',
    color: "#1D1D1F",
    justifyContent: 'center'
  },

  "& .cancel-btn": {
    fontFamily: "Montserrat",
    width: 'fit-content',
    minWidth: '200px',
    alignItems: 'center',
    padding: '10px 16px',
    height: '100%',
    borderRadius: '8px',
    border: 'solid 1px #FF881A',
    boxSizing: 'border-box',
    display: 'inline-flex',
    fontWeight: '600',
    fontSize: '18px',
    color: "#FF881A",
    cursor: 'pointer',
    justifyContent: 'center',
  },
}))

const CustomTableRow = styled(TableRow)(({ theme }) => ({
  height: '59px',
  backgroundColor: 'transparent',
  border: 'none',
  cursor: 'pointer',

  "& td": {
    "&:first-child": {
      color: 'white',
      borderTopLeftRadius: '10px',
      borderBottomLeftRadius: '10px'
    },

    "&:last-child": {
      borderTopRightRadius: '10px',
      borderBottomRightRadius: '10px'
    }
  },

  "&:hover": {
    "& td": {
      borderBottom: '1px solid #FF881A',
      backgroundColor: '#3D3D3D !important',

      "& img": {
        visibility: 'visible'
      },

      "&:first-child": {
        color: '#FF881A'
      },

      "&:last-child": {
      }
    },
  }
}))

const TableBodyCell = styled(TableCell)(({ theme }) => ({
  padding: '0',
  fontSize: '20px',
  fontWeight: 400,
  fontFamily: "Poppins",
  border: 'none',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  color: "#CFD1D4",

  "& img": {
    visibility: "hidden",
  },
  // backgroundColor: "transparent",
}))

const TableHeaderCell = styled(TableCell)(({ theme }) => ({
  padding: '0',
  fontSize: '20px',
  fontFamily: "Poppins",
  fontWeight: 400,
  border: 'none',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  color: '#676767',

  "& .header-title": {
    display: 'inline-flex',
    alignItems: 'center',
    gap: '4px',
  }
}))

const StyledCustomKeyboard = styled(Box)(({ theme }) => ({
  paddingTop: '20px',
  paddingBottom: '40px',
  boxSizing: 'border-box',
  minHeight: '100vh',
  backgroundColor: "#1D1D20",

  "& *": {
    boxSizing: 'border-box'
  },

  "& .default-kb-wrapper": {
    width: 'calc(100% - 80px)',
    margin: 'auto',
    marginTop: '10px',
    borderRadius: '10px',
    backgroundColor: '#252527',
    padding: '15px'
  },

  "& .default-kb-title": {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },

  "& .default-kb-title-text": {
    fontFamily: 'Poppins',
    fontWeight: '400',
    fontSize: '30px',
    color: "#FF881A"
  },

  "& .default-kb-content": {

  },

  "& .default-kb-content-header": {
    display: 'flex',
    flexDirection: 'row',
    gap: '160px',
  },

  "& .default-kb-content-header-text": {
    fontFamily: 'Poppins',
    fontWeight: '400',
    fontSize: '24px',
    color: "#676767"
  },

  "& .default-kb-main-content": {
    marginTop: '30px',
    width: '100%',
    maxWidth: '100%',
    overflowX: 'auto',
  },

  "& .create-kb-gr-btn": {
    marginTop: '40px',
    minWidth: '258px',
    width: 'fit-content',
    height: '64px',
    borderRadius: '8px',
    padding: '10px 16px',
    backgroundColor: "FF881A",
    fontFamily: "Montserrat",
    fontWeight: '600',
    fontSize: '18px',
    color: "#1C1C1C",
    cursor: 'pointer',
    marginLeft: '40px',
    display: 'inline-flex',
    alignItems: 'center',

    "&.disabled": {
      backgroundColor: 'rgba(255, 136, 26, 0.5)',
      cursor: 'not-allowed',
      color: 'rgba(28, 28, 28, 0.5)'
    }
  },

  "& .custom-kb-title-btn": {
    height: '100%',
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    gap: '36px',
    alignItems: 'center',

    "& img": {
      cursor: 'pointer'
    }
  },

  "& .row-actions": {
    height: '100%',
    display: 'flex',
    gap: '18px',
    alignItems: "center",

    "& img": {
      cursor: 'pointer',
      visibility: 'visible'
    }
  },

  "& .bottom-content": {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '32px'
  },

  "& .remain-keyboard": {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%'
  },

  "& .remain-keyboard-title": {
    fontFamily: "Poppins",
    fontSize: '24px',
    fontWeight: '500',
    color: "#CFD1D4"
  },

  "& .red-text": {
    fontWeight: '400',
    color: '#FF1919',
    fontSize: '24px'
  },

  "& .more-keyboard-text": {
    fontFamily: "Poppins",
    fontSize: '16px',
    fontWeight: '400',
    color: "#676767"
  },

  "& .contact-us-text": {
    color: "#FF881A",
    textDecoration: "underline",
    cursor: 'pointer'
  },

  "& .create-keyboard-btn": {
    width: '190px',
    height: '64px',
    borderRadius: '8px',
    padding: '10px 16px',
    backgroundColor: '#FF881A',
    fontFamily: "Montserrat",
    fontWeight: '600',
    fontSize: '18px',
    color: "#1C1C1C",
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',

    "&.disabled": {
      backgroundColor: 'rgba(255, 136, 26, 0.5)',
      cursor: 'not-allowed',
      color: 'rgba(28, 28, 28, 0.5)'
    }
  },

  "& .backdrop-filter": {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1,
    height: '100vh',
    width: '100vw',
    backgroundColor: "rgba(0,0,0,0.4)",
    backdropFilter: 'blur(3px)'
  },

  "& .content-header": {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },

  "& .search-input-wrapper": {
    height: '64px',
    position: 'relative',
    minWidth: '576px',
    marginTop: '40px'
  },

  "& .search-input": {
    height: '64px',
    borderRadius: '10px',
    backgroundColor: '#1D1D20',
    padding: '10px 24px',
    marginLeft: '2px',
    width: '100%',

    "& .MuiInputBase-root": {
      height: '100%',
      width: 'calc(100% - 24px)',

      "&::before": {
        border: 'none'
      },

      "&::after": {
        border: 'none'
      }
    },

    "& .MuiInputBase-input.MuiInput-input": {
      color: '#818181',
      padding: '0',

      "&::placeholcer": {
        color: "#818181",
        opacity: '1'
      }
    }
  },

  "& .search-icon": {
    position: 'absolute',
    top: '16px',
    right: '16px'
  },

  "& .keyboards-table-container": {
    boxShadow: 'none',
    padding: '16px',
    backgroundColor: '#1D1D20',
    borderRadius: '10px'
  },

  "& .keyboards-table": {
    borderCollapse: 'separate',
    border: "transparent",
    backgroundColor: '#1D1D20',
    borderRadius: '10px'
  },

  "& .teams-container": {
    overflow: 'auto',
    width: "calc(100% - 80px)",
    margin: "10px auto auto",
    display: 'flex',
    paddingBottom: '11px',
    scrollbarWidth: 'auto',
  },

  "& .team-info": {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },

  "& .team-name-text": {
    fontFamily: "Poppins",
    fontWeight: '400',
    fontSize: '12px',
    color: "#CFD1D4"
  },

  "& .team-user-text": {
    fontFamily: 'Poppins',
    fontWeight: '400',
    fontSize: '18px',
    color: "#CFD1D4"
  },

  "& .team": {
    backgroundColor: "#252527",
    minWidth: '272px',
    height: '120px',
    minHeight: 'fit-content',
    borderRadius: '10px',
    border: 'none',
    boxSizing: 'border-box',
    padding: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '32px',
    cursor: 'pointer',

    "& img": {
      borderRadius: '50%',
      border: 'solid 2px #5A5A5A'
    },

    "&.selected": {
      border: 'solid 1px #FF881A',

      "& img": {
        border: 'solid 2px #FF881A'
      },

      "& .team-name-text": {
        color: "#FF881A"
      },

      "& .team-user-text": {
        color: "#FF881A"
      }
    }
  },

  "& .divider": {
    marginLeft: '16px',
    height: '120px',
    backgroundColor: '#CFD1D4',
    borderRadius: '11px',
    border: 'solid 1px #CFD1D4'
  },

  [theme.breakpoints.down(1201)]: {
    "& .default-kb-content-header": {
      flexDirection: 'column',
      gap: '0'
    }
  },

  [theme.breakpoints.down(481)]: {
    "& .search-input-wrapper": {
      minWidth: '100%'
    }
  }
}))
// Customizable Area End
