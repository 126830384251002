import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
type Sport = {
  label: string;
  value: string;
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean
  listSport: Sport[]
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      isLoading: true,
      listSport: [{label:"Esports",value:"eSports"}, {label:"Sports",value:"Sports"}]
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(apiRequestCallId != null && apiRequestCallId === this.getAccountTypeId){
        if(responseJson?.account_types){
          this.setState({listSport: responseJson.account_types})
        }
      }
    }
    runEngine.debugLog("Message Recived", message);

    // Customizable Area End
  }

  // Customizable Area Start
  timeoutId: any
  getAccountTypeId: any;
  async componentDidMount(): Promise<void> {
    window.localStorage.removeItem(configJSON.keyLocalStorageSport)
    this.getAccountType()
    this.timeoutId = setTimeout(() => {
      this.setState({isLoading: false})
    }, 2000);
  }
  async componentWillUnmount() {
    clearTimeout(this.timeoutId);
  }
  
  getAccountType() {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAccountTypeId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAccountTypeEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSelectSport(sport: string){
    window.localStorage.setItem(configJSON.keyLocalStorageSport, sport);
    this.props.navigation.navigate('Signup')
  }
  // goToHome() {
  //   const msg: Message = new Message(
  //     getName(MessageEnum.NavigationHomeScreenMessage)
  //   );
  //   msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  //   this.send(msg);
  // }
  // Customizable Area End
}
