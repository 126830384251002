Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Share Video: ";
exports.labelText="(Only the selected players will be shown in the next steps)"
exports.labelTextContent="You can also select the share settings from the previous game settings"
exports.labelTextContentCheck="Use previous share settings"
exports.playerText="Select the players you's wish to share the video with, assign the competition type per group",
exports.playerText2=" (All Groups must be assigned to continue)*"
exports.labelBodyText = "Share Body";
exports.btnShareTitle = "Share Builder.ai";
exports.url="https://www.builder.ai/"
exports.quote="We make software so easy, everyone can do it"
exports.hashtag="#builderai"
exports.deleteApiMethod= "DELETE"
exports.postApiMethod= "POST"
exports.putApiMethod="PUT"
exports.getApiMethod="bx_block_account_groups/groups"
exports.deleteGroup="bx_block_account_groups/groups/";
exports.addNewGroup = "bx_block_account_groups/groups";
exports.editGroup="bx_block_account_groups/groups/"
exports.getFilterPlayerList = "account_block/by_account_type"
exports.getAllPlayerURL = "account_block/accounts"
exports.addPlayerToGroupURL = '/bx_block_account_groups/groups/group_id/add_accounts'
// Customizable Area End