import React from "react";
import { Box, Button, Typography, styled } from "@material-ui/core";
import { ErrorOutline } from "@material-ui/icons";

interface Props {
    steps: Step[];
    currentStep: number;
}

export interface Step {
    label: string;
    renderStepContent: any;
    required: boolean;
}

const Wrapper = styled(Box)(({ theme }) => ({
    width: "100%",
    borderWidth: 1,
    borderStyle: "solid",
    display: "flex",
    flexDirection: "column",
    borderColor: "#FF881A",
    borderRadius: 10,
    overflow: "hidden",

    "& .proc-table-wrapper": {
        display: "flex",
        flexDirection: "row",
        width: "100%",
    },

    "& .proc-table-header": {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        paddingTop: 15,
        borderStyle: "solid",
        borderRightColor: "#676767",
        borderWidth: 0,
        borderRightWidth: 1,
        paddingBottom: 10
    },

    "& .proc-table-body": {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        marginTop: 40,
        paddingBottom: 20
    },
}));

const ProcedureTable = (props: Props) => {
    const { currentStep, steps } = props;

    return (
        <Wrapper data-test-id="procedure">
            {/* render header  */}
            <Box className="proc-table-wrapper">
                {steps.map((stepElement: Step, index: number) => {
                    return (
                        <Box key={index + stepElement.label} className="proc-table-header"
                            style={{
                                borderBottomWidth: index === currentStep ? 4 : 0,
                                borderBottomColor: "#FF881A",
                            }}
                        >
                            <Typography
                                style={{
                                    fontSize: 18,
                                    color: index === currentStep ? "#FF881A" : "#676767",
                                    textAlign: "center",
                                }}
                            >
                                {stepElement.label}
                            </Typography>
                            <Typography
                                style={{
                                    color: currentStep > index ? "#8DE301" : "#CFD1D4",
                                    fontSize: 18,
                                }}
                            >{`Step ${index + 1} ${stepElement.required ? "" : "(Optional)"
                                }`}</Typography>
                        </Box>
                    );
                })}
            </Box>

            {/* render body  */}
            <Box className="proc-table-wrapper">
                {steps.map((stepElement: Step, index: number) => {
                    return (
                        <Box className="proc-table-body" key={index + stepElement.label}>
                            <Box sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center"
                            }}>
                                <Typography
                                    style={{
                                        color: "#CFD1D4",
                                        fontSize: 18,
                                        fontWeight: 400,
                                        textAlign: "center",
                                        marginRight: 5,
                                        marginTop: 5,
                                    }}
                                >{`Step ${index + 1}: ${stepElement.label}`}</Typography>
                                <ErrorOutline style={{ color: "#CFD1D4", }} />
                            </Box>
                            {stepElement.renderStepContent()}
                        </Box>
                    );
                })}
            </Box>
        </Wrapper>
    );
};

export default ProcedureTable;
