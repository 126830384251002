import { Box, Modal, Typography, styled, Checkbox } from '@material-ui/core';
import { defaultTeamImg, teamSelected } from '../../../../packages/blocks/cfcustomkeyboards/src/assets';
import React, { useEffect, useState } from 'react';
import { BpCheckbox } from '../../../../packages/blocks/bulkuploading/src/BulkUploading.web';

export interface Props {
    label?: string;
    checkboxLable?: string;
    checked?: boolean;
    onChange?: () => void;
}

const Wrapper = styled(Box)(({ theme }) => ({
    textTransform: "none",
    display: "none",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: 25,
    paddingRight: 10,
    marginTop: 15,

    "& *": {
        boxSizing: 'border-box'
    },

    "& .label": {
        color: "#CFD1D4",
        fontSize: 16,
    },
}))

const CheckBoxMirrorActions = (props: Props) => {
    const { checkboxLable, checked = false, onChange = () => { } } = props;
    return (
        <Wrapper>
            <Typography className='label'>Keyboard: New Keyboard</Typography>
            <Typography className='label' style={{
                flex: 1,
                textAlign: "right"
            }}>{checkboxLable}</Typography>
            <BpCheckbox onChange={onChange} checked={checked} style={{ marginBottom: 3 }} />
        </Wrapper>
    )
}

export default CheckBoxMirrorActions;