export const folderIcon2 = require("../assets/folder2.png");
export const deleteIcon = require("../assets/delete.png");
export const editIcon = require("../assets/editAccess.png");
export const folderLarge = require("../assets/folderLarge.png")
export const selectFolder = require("../assets/selectFolder.png")
export const arrowIcon = require("../assets/arrowIcon.png")
export const folder = require("../assets/folder.png");
export const add = require("../assets/add.png");
export const untick = require("../assets/untick.png");
export const tickmark = require("../assets/tickmark.png");
export const filter = require("../assets/filter.png");
export const more = require("../assets/more.png");
export const plusIcon = require("../assets/plusIcon.svg");
export const layout = require("../assets/layout.png");
export const search = require("../assets/search.png");
export const folderIcon = require("../assets/folderIcon.png");
export const check = require("../assets/check.png");
export const createFolder = require("../assets/createFolder.png");
export const createGameFolder = require("../assets/createGameFolder.png");
export const uploadGame = require("../assets/uploadGame.png");
export const inGameRecorder = require("../assets/inGameRecorder.png");
export const uploadIcon = require("../assets/uploadIcon.png");
export const folderCreationDisabled = require("../assets/folderCreationDisabled.png");
export const layoutLongView = require("../assets/layoutLongView.png")
export const createFolderIcon = require("../assets/create_folder_icon.png")
export const sampleAvatar = require('../assets/sample_avatar.png')
export const fantasticweDefaultTeamImg = require('../assets/fantasticwe_default_team_img.png')
export const allStaff = require('../assets/all_staff.svg')
export const allPosition = require("../assets/all_position.svg")
export const filterOnHover = require('../assets/filter_on_hover.png')
export const arrowDownOnHover = require('../assets/arrow_down_on_hover.png')
export const createGameFolderIcon = require('../assets/create_game_folder_icon.png')
export const selectedFolderIcon = require('../assets/selected_folder_icon.png')
export const defaultAvatar = require('../assets/default_user_avatar.png')