export const userProfile = require("../assets/user-profile.png");
export const logo = require("../assets/Logo.png");
export const sun = require("../assets/sun.png");
export const myteam = require("../assets/myteam.png");
export const profile = require("../assets/profile.png");
export const ring = require("../assets/ring.png");
export const schedule = require("../assets/schedule.png");
export const settings = require("../assets/settings.png");
export const stats = require("../assets/stats.png");
export const video = require("../assets/video.png");
export const dashboard2 = require("../assets/dashboard2.png");
export const stats2 = require("../assets/stats2.png");
export const video2 = require("../assets/video2.png");
export const dashboard = require("../assets/dashboard.png");
export const svg1 = require("../assets/svg1.svg");
export const svg2 = require("../assets/svg2.svg");
export const svg3 = require("../assets/svg3.svg");
export const svg4 = require("../assets/svg4.svg");
export const svg5 = require("../assets/svg5.svg");
export const svg6 = require("../assets/svg6.svg");
export const svg7 = require("../assets/svg7.svg");
export const svg8 = require("../assets/svg8.svg");
export const svgarrow = require("../assets/arrow.svg");
export const settingIcon = require("../assets/settingIcon.svg");
export const defaultAvatar = require("../assets/button_avatar.png");