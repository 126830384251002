Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings2";
exports.labelBodyText = "settings2 Body";

exports.getStatesApiEndPoint = "/account_block/accounts/states"
exports.getDueDateEndpoint = "/bx_block_custom_user_subs/get_due_date"
exports.btnExampleTitle = "CLICK ME";
exports.getTotalStorageUsedURL = "bx_block_custom_user_subs/space_used_total";
exports.getStorageUsedByMonthURL = "bx_block_custom_user_subs/space_used_month"
// Customizable Area End