import React, { useEffect, useState } from 'react'
import { Box, Button, Dialog, Divider, IconButton, Typography, styled } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { gridExample, layout, rowExample, rowView } from '../assets';

const CustomButton=styled(Button)({
    '&[aria-selected="true"]':{
        border:"2px solid rgba(255, 136, 26, 1)"
    }
})

type LayoutModalProps={
  onClose:()=>void,
  isActive:boolean,
  onSave:(layoutType:string,time:string)=>void
}

export default function LayoutModal(props:LayoutModalProps) {
    const [layoutType,setLayoutType]=useState("Row");
    const [time,setTime]=useState("OFF");
    useEffect(() =>{
      const layout = localStorage.getItem("videoLayoutView")
      if(layout){
        const layoutView = JSON.parse(layout)
        setLayoutType(layoutView.layout)
        setTime(layoutView.timeGroup)
      }
    },[])
  return (
    <Dialog
          data-testid="layout-modal"
          maxWidth="lg"
          open={props.isActive}
          PaperProps={{
            style: {
              border: "unset",
              margin: 'auto',
              background: "transparent",
              maxWidth: "auto"
    
            },
          }}
          BackdropProps={{
            style: {
              backdropFilter: "blur(5px)", // Adjust the pixel value to increase or decrease the blur effect
              backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: Adjust the background color and opacity
            }
          }}
        >
          <StyledFolderModal>
            <IconButton
            aria-labelledby='on-close-modal'
              data-testid="on-close-modal"
              style={{ cursor: "pointer" }}
              onClick={props.onClose}
              aria-label="close">
              <Close style={{ color: "#676767" }} />
            </IconButton>
            <Typography variant="h6">Layout!</Typography>
            <Box className='informationDetails'>
            <Typography variant="body1">
            Select the type of view you prefer the most
            </Typography>
            <Box className='selectLayout'>
                <Box className='gridLayout'>
                    <Box style={{display:"flex",alignItems:"center",gap:16}}>
                    <span style={{  
                        fontFamily: "Poppins",
                        fontSize: "20px",
                        fontWeight: 400,
                        lineHeight: "28px",
                        textAlign: "left",
                        color:"#CFD1D4"
                    }}>Grid</span>
                 <img src={layout} className="img icons" />
                    </Box>
                    <CustomButton onClick={()=> setLayoutType("Grid")} aria-label='selectGrid' aria-selected={layoutType==="Grid"}><img src={gridExample} height={174} width={294}/></CustomButton>
                </Box>
                <Box className='rowLayout'>
                <Box style={{display:"flex",alignItems:"center",gap:16}}>
                    <span style={{  
                        fontFamily: "Poppins",
                        fontSize: "20px",
                        fontWeight: 400,
                        lineHeight: "28px",
                        textAlign: "left",
                        color:"#CFD1D4"
                    }}>Row</span>
                 <img src={rowView} className="img icons" />
                    </Box>
                    <CustomButton onClick={()=> setLayoutType("Row")
                  } aria-label='selectRow' aria-selected={layoutType==="Row"}><img src={rowExample} height={174} width={294}/></CustomButton>
                </Box>
            </Box>
                <Typography style={{  
                        fontFamily: "Poppins",
                        fontSize: "18px",
                        fontWeight: 400,
                        lineHeight: "26px",
                        textAlign: "left",
                        color:"#CFD1D4"
                    }}>Time Grouping: <span style={{fontFamily: "Poppins",
                    fontSize: "18px",
                    fontWeight: 400,
                    lineHeight: "26px",
                    textAlign: "center",
                    color: "#676767",}}>(Videos will be displayed in the time groups)</span></Typography>
                    <Box style={{display:"flex",gap:16,alignItems:"center"}}>
                        <CustomButton variant="contained" aria-label='Off' aria-selected={time==="OFF"} onClick={() => setTime("OFF")} className='groupingBtns'>Off</CustomButton>
                        <CustomButton variant="contained" aria-label='1 Day' aria-selected={time==="1Day"} onClick={() => setTime("1Day")} className='groupingBtns'>1 Day</CustomButton>
                        <CustomButton variant="contained" aria-label='3 Day' aria-selected={time==="3Day"} onClick={() => setTime("3Day")} className='groupingBtns'>3 Day</CustomButton>
                        <CustomButton variant="contained" aria-label='1 Week' aria-selected={time==="1Week"} onClick={() => setTime("1Week")} className='groupingBtns'>1 Week</CustomButton>
                        <CustomButton variant="contained" aria-label='3 Weeks' aria-selected={time==="3Weeks"} onClick={() => setTime("3Weeks")} className='groupingBtns'>3 Weeks</CustomButton>
                        <CustomButton variant="contained" aria-label='1 Month' aria-selected={time==="1Month"} onClick={() => setTime("1Month")} className='groupingBtns'>1 Month</CustomButton>
                    </Box>
            <Divider style={{ width: '386px', backgroundColor: '#FF881A', margin: "24px auto",height:2 }} />
          
              <Button
                data-test-id="save-layout"
                variant="contained"
                style={{
                    backgroundColor: "#FF881A",
                    color: "#1D1D1F",
                    borderRadius: 8,
                    fontSize: 18,
                    padding: "10px",
                    gap: 8,
                    fontFamily: "Montserrat",
                    fontWeight: 600,
                  lineHeight: "22px",
                  width: "200px",
                  height: '55px',
                  marginLeft: '24px',
                  cursor: "pointer"
                }}
                onClick={()=>props.onSave(layoutType,time)}
              >
                Save
              </Button>
        
                </Box>
          </StyledFolderModal>
        </Dialog>
  )
}

const StyledFolderModal = styled(Box)(({ theme }) => ({
    background: "#1D1D1F",
    borderRadius: "10px",
    position: "relative",
    boxShadow: "-5px 4px 12px 0px #00000040 inset",
    padding: "40px",
    width:874,
    height:637,
  
    
    "& .MuiIconButton-root": {
      height: "24px",
      width: "24px",
      padding: "4px",
      position: "absolute",
      top: "16px",
      right: "16px",
    },
    "& .MuiPaper-root": {
      maxWidth: "800px",
    },
    
    "& .MuiTypography-h6": {
      fontFamily: "Poppins",
      fontSize: "20px",
      fontWeight: 700,
      lineHeight: "28px",
      textAlign: "center",
      color: "#CFD1D4",
      marginBottom: 32,
      
    },
    
    "& .MuiTypography-body1": {
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: "18px",
      lineHeight: "26px",
      textAlign: "center",
      color: "#676767",
      "& .fileSize":{
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "18px",
        lineHeight: "26px",
        textAlign: "center",
        letterSpacing: "-0.015em",
        color: "#FF881A",
      }
    },
    "& .informationDetails":{
        display:"flex",
        flexDirection:"column",
        gap:24,
        justifyContent: "center", 
        alignItems: "center",
    },
    "& .selectLayout":{
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        gap:24,
        "& .rowLayout":{
            display:"flex",
            flexDirection:"column",
            alignItems:"center",
            gap:24,
        },
        "& .gridLayout":{
            display:"flex",
            gap:24,
            flexDirection:"column",
            alignItems:"center"
        },
        
    },
    "& .groupingBtns":{
        width: 119,
        height: 40,
        borderRadius: "8px 0px 0px 0px",
        boxShadow: "-6px 4px 6px 0px #00000080",
        fontFamily: "Poppins",
        backgroundColor:"#1D1D1F",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        textAlign: "left",
        color:"#CFD1D4",


    }
  
  }))

