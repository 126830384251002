export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const customKeyboards = require('../assets/custom_keyboards.png');
export const paymentInformation = require('../assets/payment_information.png');
export const applePay = require("../assets/apple_pay.png");
export const gpay = require('../assets/gpay.png');
export const mastercard = require('../assets/mastercard.png');
export const paypal = require('../assets/paypal.png');
export const visa = require('../assets/visa.png');
export const plansSubscriptions = require('../assets/plans_subscriptions.png');
export const greenTick = require('../assets/green_tick.png');
export const defaultUserAvatar = require("../assets/default_user_avatar.png")
export const addIcon = require("../assets/add_icon.svg")
export const deleteIcon = require('../assets/delete_icon.svg')
export const calendarIcon = require('../assets/calendar_icon.svg')
export const defaultAvatar = require("../assets/button_avatar.png");
export const settingIcon = require("../assets/setting_icon.svg");
export const imageAvatar = require("../assets/image_avatar.png");
export const eyeOn = require('../assets/eye-on.svg')
export const eyeOff = require('../assets/eye-off.svg')