import React from "react";
import { Box, TextField, Typography, styled } from "@material-ui/core";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { IBlock } from "../../../framework/src/IBlock";
import { IListValueKeyboard } from "../../../blocks/videos/src/VideoEventController"

interface Props {
    isPreview?: boolean;
    listValueKeyboard?: IListValueKeyboard;
    isSubmit?: boolean;
    handleSelectAction?: (actionId: number) => void;
    handleSelectSubAction?: (subActionId: number) => void;
    handleSelectValue?: (valueId: number) => void;
    handleSelectReason?: (reasonId: number) => void;
    handleSelectSubReason?: (subReasonId: number) => void;
    handleChangeComment?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

interface S {
}

interface SS {
}

export type TValueEvent = "made" | "missed" | "missed-op";

export const LIST_COLOR_VALUE_EVENT = {
    "made": "#8DE301",
    "missed": "#FF1919",
    "missed-op": "#FF881A"
}

export function getColorForValue(valueEvent: TValueEvent) {
    const colorValueEvent = LIST_COLOR_VALUE_EVENT[valueEvent?.toLowerCase() as TValueEvent]
    return colorValueEvent ?? "#CFD1D4"
  }

export default class Events extends BlockComponent<
    Props,
    S,
    SS
> {
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
        ];

        this.state = {
            isPreview: false
        }

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        // Customizable Area End
    }

    render() {
        return (
            <EventStyled id="events_component">
                <Box className="list_step_event">
                    <Box className="event_column">
                        <Box className="event_column_header">
                            <Typography>
                                Step 1: Action
                            </Typography>
                        </Box>
                        <Box className="event_column_values">
                            {this.props.listValueKeyboard?.listAction?.map(((action, index) => (
                                <Box key={action.id + new Date().getTime().toString() + index} data-test-id="actionBox" className={`item ${action.isActive ? "item-active" : ""}`} onClick={() => this.props.handleSelectAction && this.props.handleSelectAction(action.id)}>
                                    {action?.name}
                                </Box>
                            )))}
                        </Box>
                    </Box>
                    <Box className="event_column">
                        <Box className="event_column_header">
                            <Typography>
                                Step 2: Sub-Action
                            </Typography>
                        </Box>
                        <Box className="event_column_values">
                            {
                                this.props.listValueKeyboard?.listSubAction?.map((sub_action, index) => (
                                <Box key={sub_action.id + new Date().getTime().toString() + index} data-test-id="subActionBox" className={`item ${sub_action.isActive ? "item-active" : ""}`} onClick={() => this.props.handleSelectSubAction && this.props.handleSelectSubAction(sub_action.id)}>
                                    {sub_action?.name}
                                    </Box>
                                ))
                            }
                        </Box>
                    </Box>
                    <Box className="event_column">
                        <Box className="event_column_header">
                            <Typography>
                                Step 3: Value
                            </Typography>
                        </Box>
                        <Box className="event_column_values">
                            {
                                this.props?.listValueKeyboard?.listValue?.map((value, index) => (
                                    <Box
                                        key={value.id + new Date().getTime().toString() + index}
                                        data-test-id="valueBox"
                                        className={`item ${value.isActive ? "item-active" : ""} ${getColorForValue(value?.name as TValueEvent) ? `item-${getColorForValue(value?.name as TValueEvent).substring(1)}` : ""}` }
                                        onClick={() => this.props.handleSelectValue && this.props.handleSelectValue(value.id)}>
                                        {value?.name}
                                    </Box>
                                ))
                            }
                        </Box>
                    </Box>
                    <Box className="event_column">
                        <Box className="event_column_header">
                            <Typography>
                                Step 4: Reason
                            </Typography>
                        </Box>
                        <Box className="event_column_values">
                            {
                                this.props?.listValueKeyboard?.listReason?.map((reason, index) => (
                                    <Box data-test-id="reasonBox" key={reason.id + new Date().getTime().toString() + index} className={`item ${reason.isActive ? "item-active" : ""}`} onClick={() => this.props.handleSelectReason && this.props.handleSelectReason(reason.id)}>
                                        {reason?.name}
                                    </Box>
                                ))
                            }
                        </Box>
                    </Box>
                    <Box className="event_column">
                        <Box className="event_column_header">
                            <Typography>
                                Step 5: Sub-Reason(Optional)
                            </Typography>
                        </Box>
                        <Box className="event_column_values">
                            {
                                this.props?.listValueKeyboard?.listSubReason?.map((subReason, index) => (
                                    <Box data-test-id="subReasonBox" key={subReason.id + new Date().getTime().toString() + index} className={`item ${subReason.isActive ? "item-active" : ""}`} onClick={() => this.props.handleSelectSubReason && this.props.handleSelectSubReason(subReason.id)}>
                                        {subReason?.name}
                                    </Box>
                                ))
                            }
                        </Box>
                    </Box>
                    <Box className="event_column col_text_area">
                        <Box className="event_column_header">
                            <Typography>
                                Comment (Optional)
                            </Typography>
                        </Box>
                        <Box className="event_column_values">
                            {this.props.isSubmit
                                && <TextField
                                    className="text_field"
                                    variant="outlined"
                                    data-test-id="commentField"
                                    value={this.props.listValueKeyboard?.commentInput}
                                    onChange={(event) => this.props.handleChangeComment && this.props.handleChangeComment(event)}
                                    margin="normal"
                                    placeholder="Enter the Comments here....."
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    InputProps={{
                                        style: {
                                            height: "130px",
                                            alignItems: "flex-start",
                                            background: "#1D1D1F",
                                            borderBottom: "1px solid #FF881A",
                                            borderRadius: "10px",
                                            boxShadow: "4px 4px 4px 0px #00000040 inset"
                                        },
                                    }}
                                />
                            }
                        </Box>
                    </Box>
                </Box>
            </EventStyled>
        );
    }
}

// Customizable Area Start
const EventStyled = styled(Box)(({ theme }) => ({
    flex: "0 0 calc(100% / 12 * 10.5)",
    background: "#252527",
    padding: "40px 32px 24px 32px",
    borderTop: "1px solid #0A0A0A",
    borderRadius: "10px 10px 0 0 ",
    "& .list_step_event": {
        display: "flex",
        columnGap: "6px",
        alignItems: "flex-start",
    },
    "& .event_column": {
        flex: "0 0 calc(100% / 12 * 1.8 - 6px)",
        maxWidth: "calc(100% / 12 * 1.8 - 6px)",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        rowGap: "16px",
        "& .event_column_header": {
            position: "relative",
            textAlign: "center",
            "& p": {
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
                fontFamily: "Poppins",
                fontSize: "15px",
                color: "#676767",
                whiteSpace: "nowrap"
            }
        },
        "& .event_column_values": {
            marginTop: "20px",
            display: "flex",
            flexWrap: "wrap",
            gap: "8px",
            justifyContent: "space-between"
        },

        "& .item": {
            flex: "1 1 calc(50% - 8px)",
            maxWidth: "50%",
            wordBreak: "break-word",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px",
            borderRadius: "8px",
            backgroundColor: "#1D1D1F",
            boxShadow: "-6px 4px 6px 0px #00000080",
            textAlign: "center",
            boxSizing: "border-box",
            fontFamily: "Montserrat",
            fontSize: "14px",
            color: "#CFD1D4",
            cursor: "pointer"
        },
        "& .item:hover": {
            cursor: "pointer"
        },

        "& .item:hover, .item-active": {
            backgroundColor: "#FF881A",
            fontWeight: 600,
            color: "#1D1D1F"
        },
        "& .item.item-8DE301": {
            color: "#8DE301"
        },
        "& .item-8DE301:hover, .item-active.item-8DE301": {
            backgroundColor: "#8DE301",
            fontWeight: 600,
            color: "#1D1D1F"
        },
        "& .item.item-FF1919": {
            color: "#FF1919"
        },
        "& .item-FF1919:hover, .item-active.item-FF1919": {
            backgroundColor: "#FF1919",
            fontWeight: 600,
            color: "#1D1D1F"
        },
        "& .item.item-FF881A": {
            color: "#FF881A"
        },
        "& .item-FF881A:hover, .item-active.item-FF881A": {
            backgroundColor: "#FF881A",
            fontWeight: 600,
            color: "#1D1D1F"
        },
    },
    "& .event_column.col_text_area": {
        flex: "0 0 calc(100% / 12 * 3 - 6px)",
        maxWidth: "calc(100% / 12 * 3 - 6px)",
    },
    "& .col_text_area": {
        "& .event_column_values": {
            "& .text_field": {
                width: "100%",
                marginTop: 0,
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: 0
                }
            },
            "& .MuiOutlinedInput-input": {
                paddingTop: "10px"
            },
            "& .MuiInputBase-input": {
                fontFamily: "Poppins",
                fontSize: "14px",
                color: "#CFD1D4"
            },
            "& .MuiInputBase-input::placeholder": {
                color: '#676767',
                fontFamily: "Poppins",
                fontSize: "14px"
            }
        }
    }
}));