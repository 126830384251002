import React from "react";
// Customizable Area Start
interface Props {
  isOpen: boolean;
  onCloseDialog: () => void;
}
interface S {

}
interface SS {

}
// Customizable Area End

import { Box, Dialog, DialogContent, DialogContentText, IconButton, Typography, styled } from "@material-ui/core";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Close } from "@material-ui/icons";
import { logo } from "./assets";

export default class ModalInfoSignup extends BlockComponent<
  Props,
  S,
  SS
>  {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      <Dialog 
        open={this.props.isOpen}
        PaperProps={{
        style: {
            border: "unset",
            margin: 'auto',
            background: "transparent"
        },
      }}
        BackdropProps={{
          style: {
            backdropFilter: "blur(5px)", // Adjust the pixel value to increase or decrease the blur effect
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: Adjust the background color and opacity
          }
        }}
      >
        <ModalInfoStyled>
          <Box style={{ textAlign: "right" }}>
            <IconButton
              data-testId="closeDialog"
              onClick={() => this.props.onCloseDialog()}
              aria-label="close">
              <Close style={{color: "#676767"}}/>
            </IconButton>
          </Box>
          <DialogContent style={{marginTop: -50, padding: "16px"}}>
            <DialogContentText style={{margin: 0}}>
                <Box
                  style={{
                    display: "flex",
                    width: "100%%",
                    borderRadius: "10px"
                  }}
                >
                  <Box style={{padding: "24px 0"}}>
                    <Typography style={{fontFamily: "Montserrat", fontWeight: 400, fontSize: "16px", color: "#CFD1D4"}}>
                    Every access to this website is logged. Only authorized users may enter. ©2020-2024 All Copy Rights Reserved to 
                      Fantastic Athletes Corporation, USA This system is patent protected - US Patent #11113332 & US20210349942A1
                    </Typography>
                  </Box>
                  <Box style={{ border: "1px solid #FF881A", margin: "0 12px" }} />
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <Box style={{width: "100%", textAlign: "center"}}>
                        <img style={{ width: "130px" }} src={logo} />
                    </Box>
                  </Box>
                </Box>
            </DialogContentText>
          </DialogContent>
        </ModalInfoStyled>

      </Dialog>
    );
  }
}

// Customizable Area Start

const ModalInfoStyled = styled(Box)(({ theme }) => ({
  width: "570px",
  height: "20 0px",
  background: "#1D1D1F",
  borderRadius: "10px",
  boxShadow: "-5px 4px 12px 0px #00000040 inset"

}))

// Customizable Area End
