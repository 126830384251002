// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'



import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import ToastNotification from "../../components/src/ToastNotification.web"

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import AccountTeam from "../../blocks/accountgroups/src/AccountTeam.web";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import Cfcustomkeyboards from "../../blocks/cfcustomkeyboards/src/Cfcustomkeyboards.web";
import CreateKeyboards from "../../blocks/cfcustomkeyboards/src/CreateKeyboards.web";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Share from "../../blocks/share/src/Share";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import Cfmultiplestreamencoding from "../../blocks/cfmultiplestreamencoding/src/Cfmultiplestreamencoding";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions.web";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Sorting from "../../blocks/sorting/src/Sorting";
import Videos from "../../blocks/videos/src/Videos";
import VideoEvent from "../../blocks/videos/src/VideoEvent.web";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Cfvideoondemand from "../../blocks/cfvideoondemand/src/Cfvideoondemand";
import Cfvideostreamingfileprocessing from "../../blocks/cfvideostreamingfileprocessing/src/Cfvideostreamingfileprocessing";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import Cfcharts from "../../blocks/cfcharts/src/Cfcharts";
import Onboardingguide from "../../blocks/onboardingguide/src/Onboardingguide";
import Chatbot5 from "../../blocks/chatbot5/src/Chatbot5";
import OfflineBrowsing from "../../blocks/offlinebrowsing/src/OfflineBrowsing";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Cfversioncontrol1 from "../../blocks/cfversioncontrol1/src/Cfversioncontrol1";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import Cflocalcaching from "../../blocks/cflocalcaching/src/Cflocalcaching";
import Settings2 from "../../blocks/settings2/src/Settings2";
import CreateComment from "../../blocks/comments/src/CreateComment";
import HelpCentre from "../../blocks/helpcentre/src/HelpCentre.web";
import HelpCentreQA from "../../blocks/helpcentre/src/HelpCentreQA";
import HelpCentreSub from "../../blocks/helpcentre/src/HelpCentreSub";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Cfalgorithm1 from "../../blocks/cfalgorithm1/src/Cfalgorithm1";
import Notifications from "../../blocks/notifications/src/Notifications";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Analytics from "../../blocks/analytics/src/Analytics";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import LandingPage from "../../blocks/landingpage/src/LandingPage.web";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import Cfaddvideoevents from "../../blocks/cfaddvideoevents/src/Cfaddvideoevents";
import {GroupVideoCall as GroupVideoCalls} from "../../blocks/groupvideocall/src/GroupVideoCall"
import Cfapiintegrationwithsteamepicriot from "../../blocks/cfapiintegrationwithsteamepicriot/src/Cfapiintegrationwithsteamepicriot";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Cfvideostreaming1 from "../../blocks/cfvideostreaming1/src/Cfvideostreaming1";
import Servicespecificsettingsadmin2 from "../../blocks/servicespecificsettingsadmin2/src/Servicespecificsettingsadmin2";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Cfvideobookmarking from "../../blocks/cfvideobookmarking/src/Cfvideobookmarking";
import GroupVideoCall from "../../blocks/groupvideocall/src/GroupVideoCallView.web";
import Signup from "../../blocks/email-account-registration/src/Signup.web"
import ContentManagement from '../../blocks/contentmanagement/src/ContentManagement.web';
import ForgotPasswordWeb from "../../blocks/forgot-password/src/ForgotPassword.web";
import LoginBlock from "../../blocks/email-account-login/src/LoginBlock.web"
import Dashboard from "../../blocks/dashboard/src/Dashboard.web"
import UserProfile from '../../blocks/settings2/src/UserProfile.web'
import OptimizationStatus from "../../components/src/CustomFeatureVideoProcess/OptimizationStatus.web";
import UploadFile from "../../components/src/CustomFeatureVideoProcess/UploadFile.web";
import OAuthCallbackEpic from "../../components/src/OAuthCallbackEpic";
import OAuthCallbackSteam from "../../components/src/OAuthCallbackSteam";


const routeMap = {

AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},

AccountTeamGroup:{
  component:AccountTeam,
 path:"/AccountGroup"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
Cfcustomkeyboards:{
 component:Cfcustomkeyboards,
path:"/Cfcustomkeyboards"},
CreateKeyboards:{
  component:CreateKeyboards,
 path:"/CreateKeyboards/:id"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Share:{
 component:Share,
path:"/Share"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
Cfmultiplestreamencoding:{
 component:Cfmultiplestreamencoding,
path:"/Cfmultiplestreamencoding"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
Customisableusersubscriptions:{
 component:Customisableusersubscriptions,
path:"/Customisableusersubscriptions"},
SubscriptionDetails:{
 component:SubscriptionDetails,
path:"/SubscriptionDetails"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
Videos:{
 component:Videos,
path:"/VideoCentre"},
VideoEvent:{
  component:VideoEvent,
 path:"/VideoEvent/:videoId"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Cfvideoondemand:{
 component:Cfvideoondemand,
path:"/Cfvideoondemand"},
Cfvideostreamingfileprocessing:{
 component:Cfvideostreamingfileprocessing,
path:"/Cfvideostreamingfileprocessing"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
Cfcharts:{
 component:Cfcharts,
path:"/Cfcharts"},
Onboardingguide:{
 component:Onboardingguide,
path:"/Onboardingguide"},
Chatbot5:{
 component:Chatbot5,
path:"/Chatbot5"},
ContentManagement:{
  component:ContentManagement,
  path:"/ContentManagement"
},
OfflineBrowsing:{
 component:OfflineBrowsing,
path:"/OfflineBrowsing"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Cfversioncontrol1:{
 component:Cfversioncontrol1,
path:"/Cfversioncontrol1"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
Cflocalcaching:{
 component:Cflocalcaching,
path:"/Cflocalcaching"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Comment:{
 component:Comment,
path:"/Comment"},
CreateComment:{
 component:CreateComment,
path:"/CreateComment"},
HelpCentre:{
 component:HelpCentre,
path:"/HelpCentre"},
HelpCentreQA:{
 component:HelpCentreQA,
path:"/HelpCentreQA"},
HelpCentreSub:{
 component:HelpCentreSub,
path:"/HelpCentreSub"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Cfalgorithm1:{
 component:Cfalgorithm1,
path:"/Cfalgorithm1"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
LoginBlock:{
  component:LoginBlock,
 path:"/", exact: true},
Dashboard:{
  component:Dashboard,
 path:"/dashboard",},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
Cfaddvideoevents:{
 component:Cfaddvideoevents,
path:"/Cfaddvideoevents"},
GroupVideoCall:{
 component:GroupVideoCall,
path:"/GroupVideoCall"},
Cfapiintegrationwithsteamepicriot:{
 component:Cfapiintegrationwithsteamepicriot,
path:"/Cfapiintegrationwithsteamepicriot"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
Cfvideostreaming1:{
 component:Cfvideostreaming1,
path:"/Cfvideostreaming1"},
Servicespecificsettingsadmin2:{
 component:Servicespecificsettingsadmin2,
path:"/Servicespecificsettingsadmin2"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Cfvideobookmarking:{
 component:Cfvideobookmarking,
path:"/Cfvideobookmarking"},
Signup:{
  component: Signup,
  path:'/Signup'
},
ForgotPasswordWeb:{
  component :ForgotPasswordWeb,
  path:'/forgotpasswordblock'
},
UserProfile: {
  component: UserProfile,
  path: '/UserProfile'
},

EpicCallback:{
  component: OAuthCallbackEpic,
  path: "/logins_epic",
},
SteamCallback:{
  component:OAuthCallbackSteam,
  path:"/logins_steam"
},


  Home: {
    component: LandingPage,
    path: '/accounttype',
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: 'auto', minHeight: "100vh", width: '100%',backgroundColor:"#1D1D1F" }}>
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
        <ToastNotification/>
        <OptimizationStatus />
        <UploadFile />
      </View>
    );
  }
}

export default App;