import { Box, Modal, Typography, styled } from "@material-ui/core";
import { closeIcon } from "../../../../packages/blocks/cfcustomkeyboards/src/assets";
import React from "react";

export interface Props {
    show: boolean;
    onClose: () => void;
    onSave: () => void;
    keyboardName: string;
}

const Wrapper = styled(Modal)(({ theme }) => ({
    textTransform: "none",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    "& *": {
        boxSizing: "border-box"
    },

    "& .modal-body": {
        display: "flex",
        alignItems: "center",
        padding: "40px",
        width: "780px",
        backgroundColor: "#252527",
        borderRadius: 10,
        flexDirection: "column",
        overflow: "auto",
        position: 'relative'
    },

    "& .sub-text": {
        color: "#676767",
        fontFamily: "Poppins",
        fontWeight: "400",
        fontSize: '18px',
        textAlign: 'center'
    },

    "& .title-text": {
        fontFamily: 'Poppins',
        fontSize: '20px',
        fontWeight: '700',
        color: "#CFD1D4"
    },

    "& .btn-row": {
        gap: "24px",
        display: 'flex',
    },

    "& .divider": {
        backgroundColor: "#FF881A",
        width: "386px",
        height: '2px',
    },

    "& .save-btn": {
        width: '166px',
        height: '55px',
        borderRadius: '8px',
        cursor: 'pointer',
        backgroundColor: '#FF881A',
        fontFamily: "Montserrat",
        fontWeight: '600',
        fontSize: '18px',
        color: "#1D1D1F",
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center'
    },

    "& .back-btn": {
        width: "166px",
        height: '55px',
        borderRadius: '8px',
        color: "#FF881A",
        border: 'solid 1px #FF881A',
        fontFamily: 'Montserrat',
        fontWeight: '600',
        fontSize: '18px',
        cursor: 'pointer',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center'
    },

    "& .close-icon": {
        top: '20px',
        position: 'absolute',
        cursor: 'pointer',
        width: '24px',
        right: '20px',
    },

    "& .mt-8": {
        marginTop: '8px'
    },

    "& .mt-32": {
        marginTop: '32px',
    },

    "& .mt-24": {
        marginTop: '24px',
    },

    "& .keyboard-name": {
        color: "#FF881A"
    }
}))

const UpdateKeyboardModal = (props: Props) => {
    return (
        <Wrapper open={props.show} onClose={() => { }}>
            <Box className='modal-body'>
                <img src={closeIcon} className="close-icon" onClick={props.onClose} />

                <Typography component="span" className="title-text">Update Keyboard!</Typography>

                <Typography component="span" className="sub-text mt-32">
                    Are you sure, you want to update{" "}

                    <Typography component="span" className="keyboard-name">[{props.keyboardName}]</Typography>
                    ?
                </Typography>

                <Typography component="span" className="sub-text mt-8">This action can not be undone.</Typography>

                <Typography component="span" className="sub-text mt-24">The following action will not delete any previous data marked with this keyboard - but will update all future markings</Typography>

                <Box className="divider mt-24" />

                <Box className="btn-row mt-24">
                    <Box className="back-btn" onClick={props.onClose} data-test-id="update-keyboard-cancel-btn">Cancel</Box>

                    <Box className="save-btn" onClick={props.onSave} data-test-id="update-keyboard-save-btn">Update</Box>
                </Box>
            </Box>
        </Wrapper>
    )
}

export default UpdateKeyboardModal