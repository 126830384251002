import AccountGroupsController, { Props } from "./AccountGroupsController";
import React from 'react'
import { styled, ThemeProvider } from "@material-ui/core/styles";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import Loader from "../../../components/src/Loader.web";
import CustomTable from "../../../components/src/UserTable";
import InputField from "../../../components/src/InputField.web";
import { Close, Add } from "@material-ui/icons";
import {
    Box,
    Button,
    IconButton,
    Typography,
    Dialog,
    Divider,
} from "@material-ui/core";
import { StyledFolderModal, StyledGridItem, StyledModal, TestFieldStyle, theme } from "./AccountGroups.web";
export default class AccountTeam extends AccountGroupsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    renderTeamUserForm = () => {
        return <div style={{ height: 512, borderRadius: 10 }}>
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <p className="header">Edit Team!</p>
                <p className="subHeader" style={{ marginTop: '-20px' }}>Edit Team Information</p>
            </div>
            <Box className="form_container" style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
            <Typography className="lableStyle"><span style={{color:'rgb(207, 209, 212)'}}>Team Name</span></Typography>
                <InputField
                    className="MuiFilledInput-underline inputField "
                    testID="txtUserInputFirstName"
                    name="name"
                    type="text"
                    value={this.state.editTeam.name}
                    onChange={this.onTeamChangeHandler}
                />
                <TextFormFieldStyle>

                    <Box >
                        <Typography className="lableStyle"><span className="formLableStyle">Team Image / Logo</span>(optional)</Typography>
                        <Typography className="lableStyle">AvailableFormats (JPG | JPEG) Limited Upload Size: <span style={{color:'#FF881A'}}>10MB</span></Typography>
                        {this.state.editTeam?.image ? (<label htmlFor="upload-video-input">
                            <Button
                                variant="outlined"
                                style={{
                                    color: "#FF881A",
                                    borderColor: "#FF881A",
                                    padding: "10px 16px",
                                    height: 44,
                                    borderRadius: 8,
                                    width: 174,
                                    fontFamily: "Montserrat"
                                }}
                                component="span"
                                endIcon={< Close onClick={() => this.setState({ editTeam: { ...this.state.editTeam, image: '' } })} />}
                            >
                                {this.state.editTeam?.image?.name}
                            </Button>
                        </label>
                        ) :
                            (<>
                                <input
                                    type="file"
                                    style={{ display: 'none' }}
                                    data-testid="filePicker"
                                    accept=".jpg,.jpeg"
                                    id="upload-video-input"
                                    onChange={this.onChangeFile}
                                />
                                <label htmlFor="upload-video-input">
                                    <Button
                                        variant="outlined"
                                        style={{
                                            color: "#FF881A",
                                            borderColor: "#FF881A",
                                            padding: "10px 16px",
                                            height: 44,
                                            borderRadius: 8,
                                            width: 174,
                                            fontFamily: "Montserrat"
                                        }}
                                        component="span"
                                        endIcon={< Add />}
                                    >
                                        Choose File
                                    </Button>
                                </label>
                            </>
                            )
                        }


                    </Box>
                </TextFormFieldStyle>

            </Box>
            <Box style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center', marginTop: '8px' }}>
                <Button
                    data-test-id="cancel-btn"
                    variant="outlined"
                    style={{
                        color: "#FF881A",
                        borderColor: "#FF881A",
                        borderRadius: 8,
                        fontSize: 18,
                        padding: "10px",
                        gap: 8,
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                        lineHeight: "22px",
                        width: "200px",
                        height: '55px',
                        marginLeft: '24px',
                        cursor: "pointer",
                        textTransform: 'none',
                        marginTop: "10px",
                    }}
                    onClick={() => this.setState({ isEditTeam: false })}

                >
                    Cancel
                </Button>
                <Button
                    data-test-id="create-btn"
                    variant="contained"
                    style={{
                        backgroundColor: "#FF881A",
                        color: "#1D1D1F",
                        borderRadius: 8,
                        fontSize: 18,
                        padding: "10px",
                        gap: 8,
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                        lineHeight: "22px",
                        width: "200px",
                        height: '55px',
                        marginLeft: '24px',
                        cursor: "pointer",
                        textTransform: 'none',
                        marginTop: "10px",
                    }}
                    onClick={this.onTeamEdit}

                >
                    Edit
                </Button>
            </Box>
        </div>
    }
    renderDialogForm = () => {
        return <Dialog
            open={this.state.isEditTeam}
            data-test-id="create-modal"
            PaperProps={{
                style: {
                    border: "unset",
                    margin: 'auto',
                    background: "transparent",
                    maxWidth: "unset"
                },
            }}
            BackdropProps={{
                style: {
                    backdropFilter: "blur(5px)", // Adjust the pixel value to increase or decrease the blur effect
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: Adjust the background color and opacity
                }
            }}
        >
            <StyledModal>
                <Button
                    data-test-id="on-close-modal"
                    style={{ cursor: "pointer", float: "right" }}
                    onClick={this.closeCreateModal}
                    aria-label="close">
                </Button>

                <TestFieldStyle>
                    {this.renderTeamUserForm()}
                </TestFieldStyle>

            </StyledModal>
        </Dialog>
    }
    renderDeleteModal = () => {
        return (<Dialog
            open={this.state.deleteTeamModal}
            PaperProps={{
                style: {
                    border: "unset",
                    margin: 'auto',
                    background: "transparent",
                    maxWidth: "unset"
                },
            }}
            BackdropProps={{
                style: {
                    backdropFilter: "blur(5px)", // Adjust the pixel value to increase or decrease the blur effect
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: Adjust the background color and opacity
                }
            }}
        >
            <StyledFolderModal style={{ width: '780px' }}>
                <IconButton
                    data-test-id="on-close-delete-modal"
                    style={{ cursor: "pointer" }}
                    onClick={this.closeDeleteAccount}
                    aria-label="close">
                    <Close style={{ color: "#676767" }} />
                </IconButton>

                <Typography variant="h6">Delete Team!</Typography>

                <Typography variant="body1" style={{ marginTop: '24px' }}>
                    Deleting {this.state.deleteTeamData?.attributes?.name} will delete all of the following:
                </Typography>
                <Typography variant="body1" style={{ marginTop: '24px', color: 'rgb(207, 209, 212)' }}>
                    All access to Videos & Data, {this.state.deleteTeamData?.attributes?.count} Users from {this.state.deleteTeamData?.attributes?.name}
                </Typography>

                <Typography variant="body1" style={{ marginTop: '24px' }}>
                    The following action will remove {this.bytesToGB(this.state.deleteTeamData?.attributes?.video_size)} GB from your cloud storage.
                </Typography>

                <Divider style={{ width: '496px', margin: "24px auto", backgroundColor: '#FF881A', height: 2 }} />

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Button
                        data-test-id="team-close-delete-modal"
                        variant="outlined"
                        className="delete-modal-close"
                        onClick={this.closeDeleteAccount}
                    >
                        Cancel
                    </Button>
                    <Button
                        data-testid="delete-account"
                        variant="contained"
                        style={{
                            backgroundColor: "#FF1919",
                            color: "#1D1D1F",
                            borderRadius: 8,
                            fontSize: 18,
                            padding: "10px",
                            gap: 8,
                            fontFamily: "Montserrat",
                            fontWeight: 600,
                            lineHeight: "22px",
                            width: "200px",
                            height: '55px',
                            marginLeft: '24px',
                            cursor: "pointer",
                            textTransform: 'none'
                        }}
                        onClick={this.deleteTeamAPI}
                    >
                        Delete
                    </Button>
                </div>
            </StyledFolderModal>
        </Dialog>)
    }
    render() {
        const { initialLoading } = this.state;

        return (
            <ThemeProvider theme={theme}>
                <Loader loading={initialLoading} />
                <NavigationMenu navigation={this.props.navigation} id="NavigationMenu" currentTabProp="team" />
                <StyledGridItem>
                    {this.renderDialogForm()}
                    {this.renderDeleteModal()}
                    <Box style={{ padding: "8px 65px 0px 65px" }}>
                        <CustomTable data-test-id='custom-table-id' columns={this.getTeamColumns()} data={this.getTeamList()} onEdit={this.handleEditTeam} onDelete={this.handleDeleteTeam} navigateToPage={this.navigateToAccountGroup} />
                    </Box>
                </StyledGridItem>
            </ThemeProvider>
        )

    }
}

const TextFormFieldStyle = styled(Box)({
    "& .MuiFilledInput-underline": {
        borderBottom: "1px solid #FF881A",
        boxShadow: "4px 4px 4px 0px #00000040 inset",
        borderRadius: "10px",
        backgroundColor: "#1D1D1F"
    },
    "& .MuiFilledInput-underline::before": {
        borderBottom: "none",
    },
    "& .MuiFilledInput-underline::after": {
        borderBottom: "none",
    },
    "& .MuiFilledInput-input": {
        height: '24px !important',
        padding: '14px 12px !important',
        color: '#CFD1D4',
        fontSize: '16px',
        fontFamily: `"Poppins", sans-serif`,
        borderRadius: "10px",
        backgroundColor: "#1D1D1F"
    },
    "& .MuiFormControl-root": {
        width: '100%'
    },
    '& .validationMsg': {
        fontFamily: 'Poppins',
        color: '#FF1919',
        float: 'left',
        fontWeight: 400,
        fonstSize: '1rem',
        margin: '6px 0'
    },
    '& .lableStyle': {
        color: "#676767",
        fontWeight: 400,
        fontSize: '20px',
        paddingBottom: '4px'
    },
    '& .formLableStyle': {
        color:'rgb(207, 209, 212); !important'
    },
    '& input:-webkit-autofill': {
        '-webkit-box-shadow': '0 0 0 30px #1D1D1F inset !important',
        '-webkit-text-fill-color': '#CFD1D4 !important',
    },
})