import React from "react"
import { Box, Typography, styled } from "@material-ui/core";
import ImageComponent from "../../../../components/src/ImageComponent.web";
import { logo } from "../assets";

interface IPropCommentContent {
    positionProfile?: string;
    nameProfile: string;
    roleProfile: string;
    contentComment: string;
    photoProfile: string | null;
    commentDate: string
}

export default function CommentContent(props: IPropCommentContent) {
    const convertToLocalTime = (serverDateTime: string): string => {
        const options: Intl.DateTimeFormatOptions = {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        };

        const date = new Date(serverDateTime);
        const formattedDate = date.toLocaleString('en-US', options);
        const [datePart, timePart] = formattedDate.split(', ');
        const [month, day, year] = datePart.split('/');
        const [time, period] = timePart.split(' ');

        return `${day}-${month}-${year} | ${time}${period.toUpperCase()}`;
    }
    return (
        <CommentContentStyled style={{ flexDirection: props?.positionProfile === "right" ? "row-reverse" : "row"}}>
            <ImageComponent widthImage="80px" heightImage="80px" alt="o" style={{ borderRadius: 40 }} fallbackSrc={logo} src={props.photoProfile || logo}/>
            <Box className={`comment_content ${props?.positionProfile === "right" ? "comment_content-right" : ""}`}>
                <Box className="content_header">
                    <Typography className="profile-name_role">{props.nameProfile} | {props.roleProfile}</Typography>
                </Box>
                <Box className="content_box">
                    <Typography className="content_text">
                        {props.contentComment}
                    </Typography>
                    <Typography className="content-date_time">
                        {convertToLocalTime(props.commentDate)}
                    </Typography>
                </Box>
            </Box>
        </CommentContentStyled>
    )
}

const CommentContentStyled = styled(Box)(() => ({
    display: "flex",
    margin: "32px 0px 40px",
    gap: 24,
    "& .comment_content": {
        width: "calc(100% - 208px)",
        "& .content_header": {
            "& .profile-name_role": {
                fontFamily: "Poppins",
                fontSize: 14,
                color: "#A4F224"
            }
        },
        "& .content_box": {
            padding: 16,
            position: "relative",
            background: "#1D1D1F",
            borderRadius: "0 10px 10px 10px",
            "& .content_text": {
                fontFamily: "Poppins",
                fontSize: 14,
                color: "#CFD1D4",
                wordBreak: "break-all"
            },
            "& .content-date_time": {
                position: "absolute",
                right: 6,
                bottom: 2,
                fontFamily: "Poppins",
                fontSize: 10,
                color: "#676767"
            }
        }
    },
    "& .comment_content.comment_content-right": {
        "& p.profile-name_role": {
            fontFamily: "Poppins",
            fontSize: 14,
            color: "#FF4B4B",
            textAlign: "right"
        },
        "& .content_box": {
            borderRadius: "10px 0 10px 10px",
        }
    }
}))