import { Box, Modal, Typography, styled } from '@material-ui/core';
import { defaultTeamImg, teamSelected } from '../../../../packages/blocks/cfcustomkeyboards/src/assets';
import React, { useEffect, useState } from 'react';

export interface Team {
    id: string;
    type: "team";
    attributes: {
        id: number;
        name: string;
        image: null | string;
        count: number
    }
    selected: boolean;
}

export interface Props {
    show: boolean;
    onClose: () => void;
    teams: Array<Team>;
    onSave: (teams: Array<Team>) => void;
}

const Wrapper = styled(Modal)(({ theme }) => ({
    textTransform: "none",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",

    "& *": {
        boxSizing: "border-box"
    },

    "& .modal-body": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "900px",
        height: '679px',
        backgroundColor: "#252527",
        borderRadius: 10,
        overflow: "auto",
        padding: "40px",
        gap: '32px',
    },

    "& .title": {
        fontFamily: "Poppins",
        fontWeight: '700',
        fontSize: '20px',
        color: "#CFD1D4"
    },

    "& .sub-text": {
        fontFamily: "Poppins",
        fontWeight: '400',
        fontSize: '20px',
        color: "#CFD1D4"
    },

    "& .team-wrapper": {
        width: '100%',
        padding: '0 86px',
        overflow: 'auto',
        height: '376px'
    },

    "& .team-container": {
        width: '100%',
        overflow: 'auto',
        display: 'flex',
        flexWrap: 'wrap',
        backgroundColor: '#1D1D1F',
        gap: '16px',
        borderRadius: '10px'
    },

    "& .team": {
        width: '200px',
        height: '180px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '8px',
        cursor: 'pointer'
    },

    "& .img-wrapper": {
        width: '88px',
        height: '88px',
        borderRadius: '50%',
        border: 'solid 2px #5A5A5A',
        position: 'relative',

        "&.selected": {
            border: "solid 2px #FF881A"
        }
    },

    "& .team-selected-icon": {
        position: 'absolute',
        top: '28px',
        left: '28px',
    },

    "& .team-name": {
        fontFamily: 'Poppins',
        fontWeight: '400',
        fontSize: '18px',
        color: "#CFD1D4",
        textAlign: 'center',

        "&.selected": {
            color: "#FF881A"
        }
    },

    "& .btn-container": {
        display: 'flex',
        marginTop: '16px',
        gap: '40px',
    },

    "& .back-btn": {
        width: '200px',
        height: '55px',
        borderRadius: '8px',
        border: 'solid 1px #FF881A',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: "#FF881A",
        cursor: 'pointer',
        fontFamily: "Montserrat",
        fontWeight: '600',
        fontSize: '18px'
    },
    "& .save-btn": {
        width: '200px',
        height: '55px',
        borderRadius: '8px',
        cursor: 'pointer',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: "#FF881A",
        fontFamily: "Montserrat",
        fontWeight: '600',
        fontSize: '18px',
        color: "#1D1D1F"
    }
}))

const AssignTeamModal = (props: Props) => {
    const [tempTeams, setTempTeams] = useState<Array<Team>>([]);

    const handleSelectTeam = (id: string) => {
        setTempTeams(prev => prev.map(item => {
            if (item.id === id) {
                return { ...item, selected: !item.selected }
            }

            return item;
        }))
    }

    useEffect(() => {
        if (props.show) {
            setTempTeams(props.teams.map(item => item));
        }
    }, [props.show, props.teams])

    return (
        <Wrapper open={props.show} onClose={props.onClose}>
            <Box className='modal-body'>
                <Typography component="span" className="title">Assign Team!</Typography>

                <Typography component="span" className='sub-text'>You may only select teams that are sharing the same game title</Typography>

                <Box className='team-wrapper'>
                    <Box className='team-container'>
                        {tempTeams.map(item => (
                            <Box className='team' key={String(item.id)} onClick={() => handleSelectTeam(item.id)} data-test-id="team">
                                <Box className={`img-wrapper${item.selected ? " selected" : ""}`}>
                                    <img src={item.attributes.image || defaultTeamImg} width={86} />

                                    {item.selected && <img src={teamSelected} width={32} className='team-selected-icon' />}
                                </Box>

                                <Typography component="span" className={`team-name${item.selected ? " selected" : ""}`}>{item.attributes.name}</Typography>
                            </Box>
                        ))}
                    </Box>
                </Box>

                <Box className='btn-container'>
                    <Box className='back-btn' onClick={props.onClose} data-test-id="assign-team-back-btn">Back</Box>

                    <Box className='save-btn' onClick={() => props.onSave(tempTeams)} data-test-id="assign-team-save-btn">Save</Box>
                </Box>
            </Box>
        </Wrapper>
    )
}

export default AssignTeamModal;