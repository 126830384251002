import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  List,
  ListItem,
  Snackbar,
  Backdrop,
  Modal,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/core/styles";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web"
import { applePay, customKeyboards, gpay, greenTick, imageAvatar, mastercard, paymentInformation, paypal, plansSubscriptions, settingIcon, visa } from "./assets";
import OTPVerificationForm from "../../../components/src/OTPVerificationForm.web";
// Customizable Area End

import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";
import { Skeleton } from "@material-ui/lab";

export default class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <StyledSettingContainer style={{ minHeight: '100vh' }}>
        <NavigationMenu navigation={this.props.navigation} id="NavigationMenu" currentTabProp="settings" onGetUserProfileResponse={this.onGetUserProfileResponse} />

        <StyledOTPModal data-test-id="otp-container" open={this.state.otpModalOpen} onClose={this.handleCloseOTPModal}>
          <OTPVerificationForm email={this.state.email} onClickResend={this.handleClickResend} onSubmit={this.handleSubmitOtp} errorMsg={this.state.otpErrorMsg} />
        </StyledOTPModal>

        <Backdrop open={this.state.toastOpen} onClick={this.hideToast} data-test-id="toast-backdrop" className="toast-backdrop">
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={this.state.toastOpen}
            onClose={this.hideToast}
            autoHideDuration={3000}
          >
            <StyledSnackbarChildren>
              <Typography component="span">
                Changes successfully done

                <img src={greenTick} width={33} alt="success" />
              </Typography>
            </StyledSnackbarChildren>
          </Snackbar>
        </Backdrop>
        <Typography component="p" className="setting-title">
                  Settings
           </Typography>
                <div style={{width:"100%",padding:"0px 32px 0px 32px",boxSizing:"border-box"}}>
                  <Box className="top-row">
                  <Box className="top-item clickable" onClick={this.handleUserProfileNaviagtion} data-test-id="edit-profile">
                          <Typography component="span" className="setting-item-top-title">
                            My Account
                          </Typography>
                    <Box className="top-item-content"> 

                          <Box style={{borderRadius:"50%"}}>
                            <img src={this.state.userAvatar||imageAvatar} height={128} width={128} style={{borderRadius:"50%"}} alt="Profile Picture" />
                          </Box>
                        <Box style={{display:'flex',flexDirection:"column",gap:24}}>

                          <List className="list-item" component='ul'>
                            <ListItem className="list-item-text">
                              <Box className="list-item-dot" />

                              <Typography component='span' className="list-item-text-content">Update Profile Information</Typography>
                            </ListItem>

                            <ListItem className="list-item-text">
                              <Box className="list-item-dot" />

                              <Typography component='span' className="list-item-text-content">Security Settings</Typography>
                            </ListItem>
                          </List>

                          <Typography component="span" className="bottom-text">
                          Edit your personal details such as name, profile picture,
                          <br/>
                          password and contact information.
                          </Typography>
                          </Box>
                        </Box>
                    </Box>
                    <Box className="top-item">
                          <Typography component="span" className="setting-item-top-title">
                          System Preferences
                          </Typography>
                    <Box className="top-item-content"> 

                          <Box style={{borderRadius:"50%"}}>
                            <img className="card-img" src={settingIcon} height={128} width={128} alt="Profile Picture" />
                          </Box>
                        <Box style={{display:'flex',flexDirection:"column",gap:24}}>

                          <List className="list-item" component='ul'>
                            <ListItem className="list-item-text">
                              <Box className="list-item-dot" />

                              <Typography component='span' className="list-item-text-content">Manage different System Settings</Typography>
                            </ListItem>
                          </List>

                          <Typography component="span" className="bottom-text">
                          Edit the way you interact with different features within the
                          <br/>
                           application, including control settings, display options and
                           <br/>
                           accessibility preferences.
                          </Typography>
                          </Box>
                        </Box>
                    </Box>
                  </Box>
               </div>
        <Box className={`item-wrapper${!this.state.intialLoaded ? " w-full" : ""}`}>
          {!this.state.intialLoaded ?
            <>
              <Skeleton className="skeleton" variant="rect" width="25%" height={600} />
              <Skeleton className="skeleton" variant="rect" width="25%" height={600} />
              <Skeleton className="skeleton" variant="rect" width="25%" height={600} />
              <Skeleton className="skeleton" variant="rect" width="25%" height={600} />
            </> :
            <>
            
              <Box className="setting-item clickable" data-test-id="create-custom-keyboard" onClick={this.handleClickCustomKeyboard}>
                <Typography component="span" className="setting-item-title">
                  Custom Keyboards
                </Typography>

                <Box className="img-wrapper">
                  <img className="card-img" src={customKeyboards} width={164} alt="Custom Keyboards" style={{ marginTop: '22px' }} />
                </Box>

                <List className="list-item" component='ul'>
                  <ListItem className="list-item-text">
                    <Box className="list-item-dot" />

                    <Typography component='span' className="list-item-text-content">Create custom keyboards</Typography>
                  </ListItem>

                  <ListItem className="list-item-text">
                    <Box className="list-item-dot" />

                    <Typography component='span' className="list-item-text-content">Set new default keyboards for players</Typography>
                  </ListItem>
                </List>

                <Typography component="span" className="bottom-text">
                  Please note that creating a custom keyboard creates new sets of data for every user connected to that keyboard.
                  <br />
                  Your Dashboard can be filtered to view data by keyboards as well.
                </Typography>
              </Box>

              <Box className="setting-item" style={{ backgroundColor: this.getPaymentBGColor()}}>
                <Typography component="span" className="setting-item-title">
                  Payment information
                </Typography>

                <Box className="img-wrapper">
                  <img className="card-img" src={paymentInformation} width={144} alt="Payment Information" style={{ marginTop: '22px' }} />
                </Box>

                <List className="list-item" component='ul'>
                  <ListItem className="list-item-text">
                    <Box className="list-item-dot" />

                    <Typography component='span' className="list-item-text-content">View / Change payment information</Typography>
                  </ListItem>

                  <ListItem className="list-item-text">
                    <Box className="list-item-dot" />

                    <Typography component='span' className="list-item-text-content">View Payment History</Typography>
                  </ListItem>
                </List>

                <Typography component="span" className="bottom-text">
                  Please note
                  <br />
                  You can change your payment information at anytime! We accept the following payment methods:
                </Typography>

                <Box className="bottom-img-wrapper">
                  <img src={visa} width={45} alt="visa" />

                  <img src={mastercard} width={45} alt="mastercard" />

                  <img src={paypal} width={45} alt="paypal" />

                  <img src={gpay} width={45} alt="gpay" />

                  <img src={applePay} width={45} alt="apple pay" />
                </Box>
              </Box>

              <Box className="setting-item clickable" onClick={this.handleSubscriptionNaviagtion} data-test-id="subscription-btn"
                style={{ backgroundColor: this.getPaymentBGColor()}}
              >
                <Typography component="span" className="setting-item-title">
                  Plans & Subscriptions
                </Typography>

                <Box className="img-wrapper">
                  <img className="card-img" src={plansSubscriptions} width={120} alt="Plans & Subscriptions" style={{ marginTop: '22px' }} />
                </Box>

                <List className="list-item" component='ul'>
                  <ListItem className="list-item-text">
                    <Box className="list-item-dot" />

                    <Typography component='span' className="list-item-text-content">Expand your current plan</Typography>
                  </ListItem>

                  <ListItem className="list-item-text">
                    <Box className="list-item-dot" />

                    <Typography component='span' className="list-item-text-content">Deactivate Subscription</Typography>
                  </ListItem>
                </List>

                <Typography component="span" className="bottom-text">
                  Please note that deactivating your subscription will result in all of your stored videos to be erased within X days of the account being inactive.
                  <br />
                  All of your stored report data will remain, once an account is Reactivated, report data can be accessed again.
                </Typography>
              </Box>

              <Box className="setting-item last-item">
                <Typography component="span" className="setting-item-title">
                  Quick Settings
                </Typography>

                <Box className="content-wrapper">
                  <Box className="content-row">
                    <Typography component="span" className="content-title">2FA</Typography>

                    <Typography component="span" className="content-text">
                      We highly recommend this feature!
                      <br />
                      Activating 2FA (2-Factor-Authenticator) on your account will ensure the safety of your user as much as possible
                    </Typography>

                    <Box className="submit-btn-wrapper">
                      <Box className="submit-btn" onClick={this.handleSubmit} data-test-id="activate-btn">
                        <Typography component='span' className="btn-text">{this.state.twoFAEnabled ? "Deactivate" : "Activate"} 2FA</Typography>
                      </Box>
                    </Box>
                  </Box>

                  <Box className="content-row">
                    <Typography component="span" className="content-title">Notifications</Typography>

                    <Typography component="span" className="content-text no-padding">
                      Notifications can help a coach with managing and monitoring team & individual user activities.
                    </Typography>

                    <Box className="notification-setting-wrapper">
                      <Box className="setting-checkbox">
                        <Typography component="span" className="setting-checkbox-title">
                          Receive notifications when:
                        </Typography>

                        <Box className="setting-checkbox-item-wrapper">
                          <Typography component='span' className="setting-checkbox-item" onClick={() => this.handleClickCheckbox(0)} data-test-id="first-checkbox">
                            <Box className={`custom-checkbox${this.state.checkboxValue[0] ? ' checkbox-active' : ''}`} /> A player finished marking
                          </Typography>

                          <Typography component='span' className="setting-checkbox-item" onClick={() => this.handleClickCheckbox(1)} data-test-id="second-checkbox">
                            <Box className={`custom-checkbox${this.state.checkboxValue[1] ? ' checkbox-active' : ''}`} /> Team Report is Ready to view
                          </Typography>

                          <Typography component='span' className="setting-checkbox-item" onClick={() => this.handleClickCheckbox(2)} data-test-id="third-checkbox">
                            <Box className={`custom-checkbox${this.state.checkboxValue[2] ? ' checkbox-active' : ''}`} /> A new video has been uploaded
                          </Typography>
                        </Box>
                      </Box>

                      <Box className="submit-btn-wrapper width-fit">
                        <Box className="submit-btn height-fit" onClick={this.handleChangeNotificationSetting} data-test-id="save-btn">
                          <Typography component='span' className="btn-text">Save</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </>}
        </Box>
      </StyledSettingContainer>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyledOTPModal = styled(Modal)(({ theme }) => ({
  width: '500px',
  margin: 'auto',
  marginTop: '150px',
  backgroundColor: 'transparent',
}))

const StyledSettingContainer = styled(Box)(({ theme }) => ({
  paddingBottom: '20px',
  boxSizing: 'border-box',

  "& .skeleton": {
    borderRadius: '10px',
    backgroundColor: '#ffffff21'
  },

  "& .w-full": {
    width: '100% !important',
  },

  "& .toast-backdrop": {
    zIndex: '0',
    background: 'transparent'
  },

  "& .top-row":{
    display:"flex",
    gap:15
  },

  "& .top-item":{
    backgroundColor:"#252527",
    padding:"24px 40px",
    width:"50%",
    borderRadius:10
  },

  "& .top-item-content":{
    display:"flex",
    gap:24,
    alignItems:"center",
    justifyContent:"center",
  },
  "& .clickable": {
    cursor: 'pointer'
  },
  "& .clickable:active": {
  backgroundColor:"#1D1D1F"
  },
  "& .setting-title": {
    color: '#CFD1D4',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '30px',
    lineHeight: '45px',
    letterSpacing:"-0.015em",
    padding:"32px 32px"
  },
  "& .item-wrapper": {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    gap: '10px',
    justifyContent: 'space-between',
    padding: '0 32px',
    marginTop: '13px',
    width: 'fit-content',
    // maxWidth: '100%',
    boxSizing: 'border-box'
  },

  "& .setting-item": {
    overflow: 'hidden',
    boxSizing: 'border-box',
    flexBasis: '100%',
    height: '600px',
    minHeight: 'fit-content',
    padding: '0 17px 32px 10px',
    borderRadius: '10px',
    backgroundColor: '#252527',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },

  "& .last-item": {
    flexBasis: '100%',
    paddingLeft: '13px',
    paddingRight: '13px'
  },

  "& .setting-item-top-title":{
    color: '#CFD1D4',
    fontFamily: 'Poppins',
    fontWeight: '400',
    fontSize: '24px',
    lineHeight: '24.69px',
  },

  "& .setting-item-title": {
    width: '100%',
    textAlign: 'center',
    height: '84px',
    color: '#CFD1D4',
    fontFamily: 'Poppins',
    fontWeight: '400',
    fontSize: '24px',
    lineHeight: '24.69px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  "& .img-wrapper": {
    height: '184px',
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '100%',
  },

  "& .card-img": {
    maxWidth: '100%'
  },

  "& .list-item": {
    width: '100%',
    marginTop: '20px',
  },

  "& .list-item-dot": {
    width: '5px',
    height: '5px',
    borderRadius: '50%',
    backgroundColor: '#CFD1D4',
    marginRight: '13px',
    marginTop: '14px',
  },

  "& .list-item-text": {
    boxSizing: 'border-box',
    alignItems: 'start',
    padding: '0'
  },

  "& .list-item-text-content": {
    fontFamily: 'Poppins',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '32.69px',
    color: '#CFD1D4',
    padding: '0',
  },

  "& .bottom-text": {
    fontFamily: 'Poppins',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '21.69px',
    color: '#555555',
    marginTop: '20px',
    width: '100%'
  },

  "& .bottom-img-wrapper": {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '30px',
    maxWidth: '300px',
  },

  "& .content-wrapper": {
    display: 'flex',
    flexDirection: 'column',
    gap: '13px',
    width: '100%',
    boxSizing: 'border-box'
  },

  "& .content-row": {
    borderRadius: '10px',
    width: '100%',
    padding: '6px 17px 15px 11px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#1D1D1F',
    boxSizing: 'border-box'
  },

  "& .content-title": {
    fontFamily: "Poppins",
    fontWeight: '400',
    fontSize: '24px',
    lineHeight: '24.69px',
    display: 'inline-flex',
    alignItems: 'center',
    color: '#CFD1D4',
    height: '47px'
  },

  "& .content-text": {
    fontFamily: "Poppins",
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '21.69px',
    color: '#555555',
    paddingRight: '60px'
  },

  "& .submit-btn-wrapper": {
    width: '100%',
    marginTop: '5px',
  },

  "& .submit-btn": {
    cursor: 'pointer',
    padding: '5px 10px',
    borderRadius: '10px',
    backgroundColor: '#252527',
    float: 'right',
    boxShadow: "-5px 4px 4px 0px #00000040",
    boxSizing: 'border-box'
  },

  "& .btn-text": {
    width: '177px',
    height: '36px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: "Poppins",
    fontWeight: '400',
    fontSize: '20px',
    lineHeight: '20px',
    color: '#CFD1D4',
  },

  "& .no-padding": {
    padding: "0",
    boxSizing: 'border-box'
  },

  "& .notification-setting-wrapper": {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%'
  },

  "& .setting-checkbox": {
    display: 'flex',
    flexDirection: 'column'
  },

  "& .setting-checkbox-title": {
    height: '47px',
    display: 'inline-flex',
    alignItems: 'center',
    fontFamily: 'Poppins',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24.69px',
    color: '#CFD1D4',
    paddingLeft: '5px',
  },

  "& .setting-checkbox-item-wrapper": {
    display: 'flex',
    flexDirection: 'column',
    justifyContnt: 'space-between',
    paddingLeft: '15px'
  },

  "& .setting-checkbox-item": {
    height: '32px',
    display: 'inline-flex',
    fontFamily: 'Poppins',
    fontWeight: '300',
    fontSize: '16px',
    lineHeight: '16px',
    color: "#FF881A",
    gap: '9px',
    alignItems: 'center',
    cursor: 'pointer'
  },

  "& .custom-checkbox": {
    width: '17px',
    minWidth: '17px',
    height: '17px',
    borderRadius: '10%',
    border: 'solid 1px #D9D9D9',
    padding: '1px',
    boxSizing: 'border-box',
    background: 'none'
  },

  "& .width-fit": {
    width: 'fit-content',
    display: 'flex',
    alignItems: 'end',

    "& .height-fit": {
      height: 'fit-content'
    },

    "& .btn-text": {
      width: '81.76px',
      height: '36px'
    },
  },

  "& .checkbox-active": {
    background: 'radial-gradient(50% 50% at 50% 50%, #FFA723 0%, #FF881A 100%)',
  },

  [theme.breakpoints.down(1600)]: {
    "& .setting-item": {
      height: '650px'
    },

    "& .list-item-text-content": {
      fontSize: '20px'
    }
  },

  [theme.breakpoints.down(1450)]: {
    "& .setting-item-title": {
      fontSize: '22px'
    },

    "& .list-item-text-content": {
      fontSize: '18px'
    },

    "& .bottom-text": {
      fontSize: '15px'
    },

    "& .content-title": {
      fontSize: '22px'
    },

    "& .content-text": {
      fontSize: '15px'
    },

    "& .btn-text": {
      fontSize: '18px'
    },

    "& .setting-checkbox-title": {
      fontSize: '15px'
    },

    "& .setting-checkbox-item": {
      fontSize: '15px'
    }
  },

  [theme.breakpoints.down(1350)]: {
    "& .setting-item": {
      height: '690px'
    },

    "& .setting-item-title": {
      fontSize: '21px'
    },

    "& .list-item-dot": {
      marginTop: '9px'
    },

    "& .list-item-text-content": {
      fontSize: '16px',
      lineHeight: 'normal'
    },

    "& .bottom-text": {
      fontSize: '14.5px'
    },

    "& .content-title": {
      fontSize: '21px'
    },

    "& .content-text": {
      fontSize: '14.5px'
    },

    "& .btn-text": {
      fontSize: '17px'
    },

    "& .setting-checkbox-title": {
      fontSize: '13px'
    },

    "& .setting-checkbox-item": {
      fontSize: '13px'
    }
  }
}))

const StyledSnackbarChildren = styled(Box)(({ theme }) => ({
  width: '325px',
  height: '64px',
  borderRadius: '42px',
  padding: '0 16px 0 28px',
  boxSizing: 'border-box',
  marginTop: '105px',
  backgroundColor: '#1D1D1F',

  "& span": {
    height: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontFamily: 'Poppins',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24.69px',
    color: '#CFD1D4',
    width: '100%'
  }
}))
// Customizable Area End
