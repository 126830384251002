import React, {useState} from "react";
import {
    Box,
    Button,
    Typography,
    styled,
    Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    TableHead,
} from "@material-ui/core";
import { Item, ItemGroup } from "../../../blocks/cfcustomkeyboards/src/CreateKeyboardsController"

interface Props {
    show: boolean;
    handleClose: Function;
    labels?: any;
    setPickedItemsBank?: Function;
    groupItems: Item[];
    bankItems: Item[];
}

const Wrapper = styled(Modal)(({ theme }) => ({
    textTransform: "none",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",

    "& .modal-body": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "80%",
        backgroundColor: "#252527",
        borderRadius: 10,
        overflow: "hidden",
        padding: 30,
    },

    "& .content": {
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#FF881A",
        padding: 20,
        borderRadius: 10,
    },

    "& .header-wrapper": {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: "100%",
    },

    "& .header-title-wrapper": {},
    "& .header-label-1": {
        color: "#CFD1D4",
        fontSize: 26,
        fontWeight: 400,
    },
    "& .header-label-2": {
        color: "#676767",
        fontSize: 20,
        fontWeight: 400,
    },

    "& .customize-btn": {
        textTransform: "none",
        borderStyle: "solid",
        borderWidth: 1,
        borderRadius: 10,
        borderColor: "#FF881A",
        paddingLeft: 10,
        paddingRight: 10,
    },

    "& .customize-btn-title": {
        fontSize: 16,
        fontWeight: 400,
        color: "#FF881A",
    },

    "& .action-table": {
        backgroundColor: "#1D1D1F",
        borderRadius: 10,
        marginTop: 30,
        overflowX: "scroll",
        overflowY: "scroll",
        overflow: "scroll",
        maxWidth: window.innerWidth * 0.8
    },

    "& .table-cell": {},

    "& .table-cell-header": {
        backgroundColor: "#FF881A",
        height: 40,
        width: 150,
        textTransform: "none",
        borderRadius: 8,
        color: "#1D1D1F",
        minWidth: 150
    },

    "& .table-cell-body": {
        color: "#CFD1D4",
        backgroundColor: "#1D1D1F",
        textTransform: "none",
        borderRadius: 8,
        height: 40,
        boxShadow: '-6px 6px 12px rgba(0, 0, 0, 0.1)' ,
        minWidth: 150,
    },

    "& .btn-wrapper": {
        marginTop: 15,
    },

    "& .save-btn": {
        borderStyle: "solid",
        borderWidth: 1,
        backgroundColor: "#FF881A",
        width: 150,
        textTransform: "none",
    },

    "& .back-btn": {
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#FF881A",
        color: "#FF881A",
        width: 150,
        textTransform: "none",
        marginRight: 20,
    },
}));

const SubActionBankModal = (props: Props) => {
    const { show, handleClose, labels, setPickedItemsBank=()=>{}, groupItems=[], bankItems=[] } = props;
    const [selectedGroupId, setSelectedGroupId] = useState(0);

    return (
        <Wrapper open={show} onClose={() => handleClose()}>
            <Box className="modal-body">
                <Box className="content">
                    <Box className="header-wrapper">
                        <Typography className="header-label-1">{labels?.header}</Typography>
                        <Typography className="header-label-2">
                        {labels?.headerDetail}
                        </Typography>
                    </Box>

                    <TableContainer className="action-table" component={Paper}>
                        <Box
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                width: "50%",
                                justifyContent: "space-between",
                                paddingLeft: 20,
                                paddingTop: 20,
                                color: "#CFD1D4",
                                fontSize: 20,
                                marginBottom: 20,
                                fontWeight: "bold"
                            }}
                        >
                            <Typography> {labels?.tableColumn1Label} </Typography>
                            <Typography> {labels?.tableColumn2Label} </Typography>
                        </Box>
                        <Table>
                            <TableBody>
                                {groupItems.map((groupElm) => (
                                    <TableRow
                                        style={{
                                            backgroundColor: groupElm.id === selectedGroupId ? "gray" : undefined,
                                        }}
                                        onClick={() => setSelectedGroupId(groupElm.id)}>
                                        <TableCell style={{ borderBottom: "none" }}>
                                            <Button className="table-cell-header">{groupElm.name}</Button>
                                        </TableCell>
                                        {bankItems.filter(element => element.parent_id === groupElm.id).map(bankElm => (
                                            <TableCell style={{ borderBottom: "none" }}>
                                                <Button
                                                    className="table-cell-body"
                                                    style={{
                                                        minWidth: 150
                                                    }}
                                                >{bankElm.name}</Button>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Box className="btn-wrapper">
                    <Button className="back-btn" onClick={() => handleClose()}>Back</Button>
                    <Button className="save-btn" onClick={() => {
                        setPickedItemsBank(bankItems.filter(element => element.parent_id === selectedGroupId))
                        handleClose();
                    }}>Save</Button>
                </Box>
            </Box>
        </Wrapper>
    );
};

export default SubActionBankModal;
