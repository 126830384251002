// Customizable Area Start
import React from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  TextField,
  Box,
  Button,
  Typography,
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@material-ui/core";

import MeetingContainer from "./MeetingContainer";

const configJSON = require("./config");

const theme = createTheme({
  palette: {
    primary: {
      main: "#3f51b5",
      contrastText: "#fff"
    }
  },
  typography: {
    h6: {
      fontWeight: 500
    },
    subtitle1: {
      margin: "20px 0px"
    }
  }
});
// Customizable Area End

const GroupVideoCallView = ({
  groups,
  token,
  meetingId,
  deleteUserFromGroup,
  addUserGroupId,
  handleAddUserModal,
  users,
  selectedUser,
  setSelectedUser,
  addUserToGroupHandler,
  loggedInUser,
  toggleAddGroupModal,
  addGroupModal,
  groupName,
  setGroupName,
  addGroupHandler,
  deleteGroupHandler,
  getMeetingToken,
  onMeetingLeave
}) => {
  return (
    // Customizable Area Start
    <ThemeProvider theme={theme}>
      {token && meetingId ? (
        <MeetingContainer
          token={token}
          meetingId={meetingId}
          participantName={loggedInUser.name}
          onMeetingLeave={onMeetingLeave}
          defaultMicOn={true}
          defaultWebcamOn={true}
          data-testid="meetingProvider"
        />
      ) : (
        <>
          <Container>
            <Box style={webStyle.btnWrapper}>
              <Button
                color="primary"
                variant="contained"
                size="small"
                style={webStyle.addBtnStyle}
                onClick={toggleAddGroupModal}
                data-testid="addGroup"
              >
                {configJSON.addGroupText}
              </Button>
            </Box>
            <TableContainer component={Paper} style={webStyle.tableContainer}>
              <Table
                stickyHeader
                aria-label="sticky table"
                style={webStyle.groupTable}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>{configJSON.groupNameText}</TableCell>
                    <TableCell>{configJSON.groupMemberText}</TableCell>
                    <TableCell>{configJSON.actionText}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {groups.map(row => {
                    const groupMembers = row.attributes.accounts;

                    const groupAdmin = groupMembers.find(
                      member => member.role_id === 1
                    );

                    const isCurrentUserAdmin =
                      groupAdmin?.id == loggedInUser.id;

                    return (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          {row.attributes.name}
                        </TableCell>
                        <TableCell>
                          <Typography>
                            {groupAdmin?.first_name && `${groupAdmin?.first_name} (Admin)`}
                          </Typography>
                          {groupMembers?.map(user => {
                            return (
                              <Box key={user.id}>
                                <Typography style={webStyle.participantName}>
                                  {user.first_name}
                                </Typography>
                                {isCurrentUserAdmin && (
                                  <DeleteIcon
                                    data-testid={`deleteUserBtn-${user.id}`}
                                    onClick={() =>
                                      deleteUserFromGroup(row.id, user.id)
                                    }
                                    style={webStyle.deleteIcon}
                                  />
                                )}
                              </Box>
                            );
                          })}
                        </TableCell>
                        <TableCell>
                          {isCurrentUserAdmin && (
                            <>
                              <Button
                                color="primary"
                                variant="contained"
                                size="small"
                                style={webStyle.btnStyle}
                                onClick={() => handleAddUserModal(row.id)}
                                data-testid={`addUserInGroupBtn-${row.id}`}
                              >
                                {configJSON.addUserToGroupText}
                              </Button>
                              <Button
                                color="primary"
                                variant="contained"
                                size="small"
                                style={webStyle.btnStyle}
                                onClick={() => deleteGroupHandler(row.id)}
                                data-testid={`deleteGroupBtn-${row.id}`}
                              >
                                {configJSON.deleteGroupText}
                              </Button>
                            </>
                          )}
                          <Button
                            color="primary"
                            variant="contained"
                            size="small"
                            style={webStyle.btnStyle}
                            onClick={() => getMeetingToken(row.id)}
                            data-testid={`joinMeetingBtn-${row.id}`}
                          >
                            {configJSON.joinMeetingText}
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Container>

          <Dialog
            data-testid="addUserModal"
            open={!!addUserGroupId}
            onClose={() => handleAddUserModal(null)}
          >
            <DialogTitle>{configJSON.addUserText}</DialogTitle>
            <DialogContent>
              <Select
                label={configJSON.selectUserLabelText}
                value={selectedUser}
                onChange={e => setSelectedUser(e.target.value)}
                style={webStyle.textField}
                data-testid="selectUser"
              >
                {users.map(user => {
                  return (
                    <MenuItem key={user.id} value={user.id}>
                      {user.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </DialogContent>
            <DialogActions>
              <Button
                data-testid="cancelBtn"
                onClick={() => handleAddUserModal(null)}
                color="primary"
              >
                {configJSON.cancelText}
              </Button>
              <Button
                data-testid="addUserBtn"
                onClick={addUserToGroupHandler}
                color="primary"
              >
                {configJSON.addText}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={addGroupModal} onClose={toggleAddGroupModal}>
            <DialogTitle>{configJSON.addGroupText}</DialogTitle>
            <DialogContent>
              <TextField
                value={groupName}
                onChange={e => setGroupName(e.target.value)}
                label={configJSON.enterGroupNameLabelText}
                style={webStyle.textField}
                data-testid="groupNameTextInput"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={toggleAddGroupModal} color="primary">
                {configJSON.cancelText}
              </Button>
              <Button
                data-testid="createGroupBtn"
                onClick={addGroupHandler}
                color="primary"
              >
                {configJSON.addText}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </ThemeProvider>
    // Customizable Area End
  );
};

// Customizable Area Start
const webStyle = {
  tableContainer: {
    marginTop: 20
  },
  groupTable: {
    width: "100%"
  },
  participantName: {
    display: "inline"
  },
  deleteIcon: {
    color: "red",
    cursor: "pointer",
    marginBottom: -5,
    marginLeft: 10
  },
  btnStyle: {
    marginRight: 5
  },
  addBtnStyle: {
    marginTop: 10
  },
  textField: {
    width: 360
  },
  btnWrapper: {
    textAlign: "right"
  }
};
// Customizable Area End

export default GroupVideoCallView;

