// Customizable Area Start
export const folder = require("../assets/folder.png");
export const add = require("../assets/add.png");
export const filterIcon = require("../assets/filterIcon.svg");
export const groupUserIcon = require("../assets/groupUser.svg");
export const more = require("../assets/more.png");
export const plusIcon = require("../assets/plusIcon.svg");
export const layout = require("../assets/layout.png");
export const search = require("../assets/search.png");
export const folderIcon = require("../assets/folderIcon.png");
export const stickerComment = require("../assets/sticker_comment.svg");
export const listIcon = require("../assets/listIcon.svg");
export const five = require("../assets/five.svg");
export const reportIcon = require("../assets/report_icon.svg");
export const zerofive = require("../assets/zerofive.svg");
export const dots = require("../assets/dots.png");
export const mainScreen = require("../assets/mainScreen.png");
export const videoPlay = require("../assets/videoPlay.svg");
export const star = require("../assets/star.svg");
export const deletes = require("../assets/deleteIcon.svg");
export const warning = require("../assets/infor.svg");
export const logo = require("../assets/Logo.png");
export const edit = require("../assets/edit.svg");
export const share = require("../assets/share.svg");
export const report = require("../assets/report.svg");
export const clip = require("../assets/clips.svg");
export const checkedBox = require("../assets/checkedBox.png");
export const checkedTick = require("../assets/checkedTick.png");
export const longView = require("../assets/long_view.svg");
export const emptyPlayIcon = require("../assets/emptyVidIcon.svg");
export const filePlayIcon = require("../assets/fullIcon.svg");
export const playIconLong = require("../assets/play_icon_long.svg");
export const playIcon = require("../assets/group_.svg");
export const verticalLine = require("../assets/verticalLine.svg");
export const markIcon = require("../assets/markIcon.png");
export const highlightIcon = require("../assets/highlightIcon.svg");
export const favoriteIcon = require("../assets/star.png");
export const moreIcon = require("../assets/moreIcon.svg");
export const watchVideoIcon = require("../assets/watchVideoIcon.svg");
export const editVideoIcon = require("../assets/editVideoIcon.svg");
export const markVideoIcon = require("../assets/markVideoIcon.svg");
export const layoutVideoIcon = require("../assets/layoutVideoIcon.svg");

export const messageIcon = require("../assets/messageIcon.svg");
export const shareIcon = require("../assets/shareIcon.svg");
export const deleteIcon = require("../assets/deleteIcon.svg");
export const starIcon = require("../assets/starIcon.svg");

export const back15s = require("../assets/back15s.svg");
export const back5s = require("../assets/back5s.svg");
export const back = require("../assets/back.svg");
export const next = require("../assets/next.svg");
export const next5s = require("../assets/next5s.svg");
export const next15s = require("../assets/next15s.svg");

export const blankAvatar = require("../assets/blank_avatar.png");
export const moreOption = require("../assets/moreOption.svg");
export const rowView = require("../assets/layoutLongView.png");
export const rowExample= require("../assets/rowExample.png")
export const gridExample= require("../assets/gridExample.png")
export const trashBinIcon=require("../assets/delete.png")
export const moveIcon=require("../assets/moveIcon.svg")
export const commentIcon=require("../assets/commentIcon.svg")
export const deleteButton=require("../assets/deleteButton.svg")
export const editIcon=require("../assets/editIcon.svg")
export const starIconButton=require("../assets/starIconButton.svg")

export const defaultFolderIcon = require("../assets/folder_icon.png")
export const folderIc = require("../assets/folderic.svg")
export const notFull = require("../assets/notfull.svg")
export const check = require("../assets/check.svg");
export const unCheck= require("../assets/uncheck-Icon.svg")
// Customizable Area End