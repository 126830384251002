import { Box, TextField, Typography, styled } from "@material-ui/core";
import React, { Component } from "react";

type MyProps = {
    label?: string,
    type: string,
    style?: any,
    placeholder?: string,
    className?: string,
    value?: string | number,
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void,
    iconChangeState?: boolean,
    name?: string,
    InputProps?: any
    validationMsg?: string,
    testID?: string;
    ref?: any;
    isHideMsgError?: boolean;
    disabled?: boolean;
    autoFocus?: boolean;
};
export default class InputField extends Component<MyProps> {

    constructor(props: any) {
        super(props);
        this.state = {
        };
    }
    render(): React.ReactNode {
        return (
            <TextFieldStyle>
                <Box>
                    <Typography className="lableStyle">{this.props.label}</Typography>
                    <TextField
                        className={this.props.className?this.props.className:`inputField`}
                        value={this.props.value}
                        data-testID={this.props.testID}
                        name={this.props.name}
                        type={this.props.type}
                        placeholder={this.props.placeholder}
                        defaultValue={this.props.value}
                        onChange={this.props.onChange}
                        InputProps={this.props.InputProps}
                        variant="filled"
                        ref={this.props.ref}
                        autoComplete='off'
                        disabled={this.props.disabled}
                        autoFocus={this.props.autoFocus}
                        onBlur={this.props.onBlur}
                    />
                </Box>
                <Box style={{ display: this.props.isHideMsgError ? "none" : "block" }}>
                    {<span className="validationMsg" style={{ visibility: this.props.validationMsg ? "visible" : "hidden" }}>{this.props.validationMsg ? this.props.validationMsg : "error"}</span>}
                </Box>
            </TextFieldStyle>
        )
    }
}


const TextFieldStyle = styled(Box)({
    "& .MuiFilledInput-underline": {
        borderBottom: "1px solid #FF881A",
        boxShadow: "4px 4px 4px 0px #00000040 inset",
        borderRadius: "10px",
        backgroundColor:"#1D1D1F"
      },
    "& .MuiFilledInput-underline::before": {
        borderBottom: "none",
    },
    "& .MuiFilledInput-underline::after": {
        borderBottom: "none",
      },
      "& .MuiFilledInput-input": {
        height:'24px !important',
        padding:'14px 12px !important',
        color:'#CFD1D4',
        fontSize:'16px',
        fontFamily:`"Poppins", sans-serif`,
        borderRadius: "10px",
        backgroundColor:"#1D1D1F"
      },
    "& .MuiFormControl-root": {
        width: '100%'
    },
    '& .validationMsg': {
        fontFamily: 'Poppins',
        color: '#FF1919',
        float: 'left',
        fontWeight: 400,
        fonstSize: '1rem',
        margin: '6px 0'
    },
    '& .lableStyle': {
        color: "#676767",
        fontWeight: 400,
        fontSize: '20px',
        paddingBottom:'4px'
    },
    '& input:-webkit-autofill': {
        '-webkit-box-shadow': '0 0 0 30px #1D1D1F inset !important',
        '-webkit-text-fill-color': '#CFD1D4 !important',
    },
})