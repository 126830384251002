export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const bgImageEsports = require("../assets/image_bg_esports.png")
export const bgImageSports = require("../assets/image_bg_sports.png")
export const logo = require("../assets/image_.png")
export const steam = require("../assets/img_1.png") 
export const epic = require("../assets/img_2.png")
export const riot = require("../assets/img_3.png")
export const alertLogo = require('../assets/alert.png')
export const eyeOn = require('../assets/eye-on.svg')
export const eyeOff = require('../assets/eye-off.svg')
export const calendarIcon = require('../assets/calendarIcon.svg')