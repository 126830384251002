// Customizable Area Start
import React, { useState } from "react";
import { useMeeting } from "@videosdk.live/react-sdk";

import MeetingView from "./MeetingView";

const Meeting = ({ onMeetingLeave }) => {
  const [participantViewVisible, setParticipantViewVisible] = useState(true);

  const onParticipantJoined = (participant) => {
    console.log(" onParticipantJoined", participant);
  }
  const onParticipantLeft = (participant) => {
    console.log(" onParticipantLeft", participant);
  }
  const onSpeakerChanged = (activeSpeakerId) => {
    console.log(" onSpeakerChanged", activeSpeakerId);
  };
  const onPresenterChanged = (presenterId) => {
    console.log(" onPresenterChanged", presenterId);
  }
  const onMainParticipantChanged = (participant) => {
    console.log(" onMainParticipantChanged", participant);
  }
  const onEntryRequested = (participantId, name) => {
    console.log(" onEntryRequested", participantId, name);
  }
  const onEntryResponded = (participantId, name) => {
    console.log(" onEntryResponded", participantId, name);
  }
  const onRecordingStarted = () => {
    console.log(" onRecordingStarted");
  }
  const onRecordingStopped = () => {
    console.log(" onRecordingStopped");
  }
  const onChatMessage = (data) => {
    console.log(" onChatMessage", data);
  }
  const onMeetingJoined = () => {
    console.log("onMeetingJoined");
  }
  const onMeetingLeft = () => {
    console.log("onMeetingLeft");
    onMeetingLeave();
  }
  const onLiveStreamStarted = (data) => {
    console.log("onLiveStreamStarted example", data);
  };
  const onLiveStreamStopped = (data) => {
    console.log("onLiveStreamStopped example", data);
  };

  const onVideoStateChanged = (data) => {
    console.log("onVideoStateChanged", data);
  };
  const onVideoSeeked = (data) => {
    console.log("onVideoSeeked", data);
  };

  const onWebcamRequested = (data) => {
    console.log("onWebcamRequested", data);
  };
  const onMicRequested = (data) => {
    console.log("onMicRequested", data);
  };
  const onPinStateChanged = (data) => {
    console.log("onPinStateChanged", data);
  };
  const onSwitchMeeting = (data) => {
    window.focus();
  };

  const onConnectionOpen = (data) => {
    console.log("onConnectionOpen", data);
  };

  const {
    meetingId,
    meeting,
    localParticipant,
    mainParticipant,
    activeSpeakerId,
    participants,
    presenterId,
    localMicOn,
    localWebcamOn,
    localScreenShareOn,
    messages,
    isRecording,
    isLiveStreaming,
    pinnedParticipants,
    //
    join,
    leave,
    connectTo,
    end,
    //
    startRecording,
    stopRecording,
    //
    respondEntry,
    //
    muteMic,
    unmuteMic,
    toggleMic,
    //
    disableWebcam,
    enableWebcam,
    toggleWebcam,
    //
    disableScreenShare,
    enableScreenShare,
    toggleScreenShare,
    //
    getMics,
    getWebcams,
    changeWebcam,
    changeMic,

    startVideo,
    stopVideo,
    resumeVideo,
    pauseVideo,
    seekVideo,
    startLivestream,
    stopLivestream,
  } = useMeeting({
    onParticipantJoined,
    onParticipantLeft,
    onSpeakerChanged,
    onPresenterChanged,
    onMainParticipantChanged,
    onEntryRequested,
    onEntryResponded,
    onRecordingStarted,
    onRecordingStopped,
    onChatMessage,
    onMeetingJoined,
    onMeetingLeft,
    onLiveStreamStarted,
    onLiveStreamStopped,
    onVideoStateChanged,
    onVideoSeeked,
    onWebcamRequested,
    onMicRequested,
    onPinStateChanged,
    onSwitchMeeting,
    onConnectionOpen,
  });
  const [value, setValue] = React.useState(0);

  const handleStartRecording = () => {
    startRecording();
  };
  const handleStopRecording = () => {
    stopRecording();
  };
  const confirmLeave = () => {
    try {
      console.log("leave start-end func")

      leave();
      console.log("leave end-")

    } catch(e) {
      console.log("error-",e)
    }
  };

  const viewProps = {
    participantViewVisible,
    meetingId,
    toggleMic,
    toggleWebcam,
    toggleScreenShare,
    value,
    setValue,
    handleStartRecording,
    handleStopRecording,
    confirmLeave,
  };

  return <MeetingView testID="MeetingView" {...viewProps} />;
}

export default Meeting;
// Customizable Area End
