export const upload=require("../assets/upload.png");
export const uploadIcon=require("../assets/uploadIcon.png");
export const calendarIcon = require('../assets/calendarIcon.svg')
export const check = require('../assets/check.png')
export const inAndOut = require('../assets/in&outIcon.png')
export const addIcon=require("../assets/add.png");
export const seperator1=require("../assets/seperator1.png")
export const infoIcon=require("../assets/infoIcon.png");
export const thumb=require("../assets/thumb.svg");
export const delete_button = require("../assets/button_delete.png")
export const share = require("../assets/share.png")
export const keyboard = require("../assets/keyboard.svg")
export const edit = require("../assets/edit.png")
export const arrow = require("../assets/arrow.png")
export const vector_ = require("../assets/vector_.png")
export const arrowStep3 = require("../assets/arrowStep3.svg")
export const createGameFolder = require("../assets/create_game_folder.png")
export const smallInfoIcon = require('../assets/info_icon.svg')
export const assignKeyboards = require('../assets/assign_keyboards.svg')