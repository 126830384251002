import React from "react";

import MeetingContainerView from "./MeetingContainerView.web";

// Customizable Area Start
// Customizable Area End

const MeetingContainer = ({
  // Customizable Area Start
  token,
  meetingId,
  participantName,
  defaultMicOn,
  defaultWebcamOn,
  onMeetingLeave
  // Customizable Area End
}) => {
  // Customizable Area Start
  // Customizable Area End

  const viewProps = {
    // Customizable Area Start
    meetingId,
    defaultMicOn,
    defaultWebcamOn,
    participantName,
    token,
    onMeetingLeave
    // Customizable Area End
  };

  // Customizable Area Start
  // Customizable Area End

  return (
    <MeetingContainerView testId={"MeetingContainerView"} {...viewProps} />
  );
};

export default MeetingContainer;
