import { styled } from '@material-ui/core';
import React, { useState, CSSProperties } from 'react';
import { ReactSVG } from 'react-svg'
interface IPropImage {
  src: string,
  alt: string,
  fallbackSrc: string,
  style?: CSSProperties,
  colorSvgLine?: string,
  widthImage?: string,
  heightImage?: string
}

const SvgContainer = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const StyledReactSVG = styled(ReactSVG)({
  width: '100% !important',
  height: '100% !important',
  display: 'block',
});

const ImageComponent: React.FC<IPropImage> = ({ src, alt, fallbackSrc, ...props }) => {
  const isSvg = src.substring(src.lastIndexOf('.')) === '.svg';

  if (isSvg) {
    return (
      <div style={{width: props?.widthImage ?? "100%", height: props?.heightImage ?? "100%"}}>
        <SvgContainer>
          <StyledReactSVG
            src={src || fallbackSrc}
            {...props}
            beforeInjection={(svg) => {
              svg.setAttribute('style', `width: 100%; height: 100%; color: ${props.colorSvgLine || 'inherit'}`);
            }}
          />
        </SvgContainer>
      </div>
    );
  }

  return <img src={src || fallbackSrc} alt={alt} {...props} width={props?.widthImage ?? "100%"} height={props?.heightImage ?? "100%"} />;
};

export default ImageComponent;