import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  tableContainer: {
    backgroundColor: '#222222',
    color: '#ffffff',
  },
  tableHead: {
    backgroundColor: '#1d1d1f',
  },
  tableCellHeader: {
    fontFamily: "Poppins",
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: "-0.015em",
    color: "#676767",
    border: "none"

  },
  tableRow: {
    borderRadius: "10px",
    '&:hover': {
      backgroundColor: '#3C3C3C',
      borderBottom: "1px solid #FF881A",
      borderRadius: "10px",
    },
  },
  roundedTableRow: {
    overflow: 'hidden',
  },
  tableBody: {
    backgroundColor: "#1d1d1f"
  },
  tableCell: {
    color: '#ffffff',
    borderBottom: 'none',
    padding: "0px, 24px, 0px, 0px"
  },
  verifiedIcon: {
    color: 'green',
  },
  actionsIcon: {
    color: 'red',
    cursor: 'pointer',
  },
  evenRow: {
    backgroundColor: '#1f1f1f', // Darker color for even rows
  },
  oddRow: {
    backgroundColor: '#252527', // Slightly lighter color for odd rows
  },
  firstCol: {
    //styleName: text-xl/font-medium;
    fontFamily: "Poppins",
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: "-0.015em",
    display: "flex",
    alignItems: "center",
    gap: 18,
    justifyContent: "space-evenly"
  },
  info: {
    fontFamily: "Poppins",
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: "-0.015em",
  },
  actions: {

    display: "flex",
    alignItems: "center",
    gap: 8,
    justifyContent: "space-evenly"

  }

});
interface Props {
  columns: string[];
  data: {
    [key: string]: string | null
  }[];
  onEdit?: (data: any) => void;
  onDelete?:(data:any) => void;
  navigateToPage?:(data:any) => void;
}
const CustomTable = (props: Props) => {
  const classes = useStyles();
  const photoCell = (data: string) => {
    return (<TableCell align='left' className={classes.tableCell}>
      <div className={classes.firstCol}>
        <img src={data ? data : require("../src/assets/profile_avatar.png")} alt="Avatar" width="40" height="40" style={{ borderRadius: '50%' }} />
      </div>
    </TableCell>)
  }
  const teamPhotoCell = (data: string) =>{
    return (<TableCell align='left' className={classes.tableCell}>
    <div className={classes.firstCol}>
      <img src={data ? data : require("../src/assets/profile_avatar.png")} alt="Avatar" width="124" height="124" style={{  }} />
    </div>
  </TableCell>)
  }
  const actionCell = (data: {
    [key: string]: string | null
  }) => {
    return (<TableCell align="left" className={classes.tableCell}>
      <div className={classes.actions}>
        <span data-test-id="on-edit-test" className={classes.actionsIcon} onClick={() =>props.onEdit && props.onEdit(data)}><img src={require("../src/assets/edit_icon.svg")} height={24} width={24} /></span>
        <span  data-test-id = "on-delete-test" className={classes.actionsIcon} onClick={() => props.onDelete && props.onDelete(data)}><img src={require("../src/assets/delete_icon.svg")} height={24} width={24} /></span>
      </div>
    </TableCell>)
  }
  const teamNameCell = (data:{[key: string]: string | null}, col: string) =>{
    return(<TableCell className={classes.tableCell} style={{textTransform:'capitalize'}} >
    <div className={classes.info} onClick={props?.navigateToPage} style={{cursor:'pointer'}}>
      {data[col]}
    </div>
  </TableCell>)
  }
  const defaultCell = (data: string) => {
    return (<TableCell className={classes.tableCell} style={{textTransform:'capitalize'}}>
      <div className={classes.info}>
        {data}
      </div>
    </TableCell>)
  }
  const renderTableCell = (col: string, row: any) => {
    switch (col) {
      case 'Photo':
        return photoCell(row[col])
      case 'Action':
        return actionCell(row)
      case 'Team Photo':
        return teamPhotoCell(row[col])
      case 'Team Name':
        return teamNameCell(row,col)

      default:
        return defaultCell(row[col])
    }
  }
  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table>
        <TableHead className={classes.tableHead}>
          <TableRow>
            {props.columns.map((column: string, index: number) => (
              <TableCell align={index ===0 && column.length-1 ===index ?'center': 'left'} className={classes.tableCellHeader} >{!new Set(['Photo','Team Photo']).has(column) ? column : ''}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {props.data.map((item, index) => (
            <TableRow key={index} className={`${classes.tableRow} ${classes.roundedTableRow} ${index % 2 === 0 ? classes.evenRow : classes.oddRow
              }`}>
              {props.columns.map((col: string) => (
                <>
                  {renderTableCell(col, item)}
                </>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomTable;