import React, { useEffect, useState, useRef } from "react";
import { useRunEngine } from "../../utilities/src/hooks/useRunEngine";
import { useBlockHelpers } from "../../utilities/src/hooks/useBlockHelpers";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";

import GroupVideoCallView from "./GroupVideoCallView";

export const configJSON = require("./config");

const subscribedMessages = [
  MessageEnum.CountryCodeMessage,
  MessageEnum.RestAPIResponceMessage,
  MessageEnum.ReciveUserCredentials,
  MessageEnum.SessionResponseMessage
];

// Customizable Area Start
// Customizable Area End

const GroupVideoCall = () => {
  // Customizable Area Start
  const getCurrentUserCallId = useRef("");
  const getGroupCallId = useRef("");
  const removeUserFromGroupCallId = useRef("");
  const getUsersCallId = useRef("");
  const addUserToGroupCallId = useRef("");
  const addGroupCallId = useRef("");
  const deleteGroupCallId = useRef("");
  const getMeetingTokenCallId = useRef("");
  const loginToken = useRef("");

  const [groups, setGroups] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [token, setToken] = useState(null);
  const [meetingId, setMeetingId] = useState(null);
  const [addUserGroupId, setAddUserGroupId] = useState(null);
  const [addGroupModal, setAddGroupModal] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [loggedInUser, setLoggedInUser] = useState(null);

  const {
    sendMessage,
    sendBlockMessage,
    sendNetworkRequest,
    setReceiveCallback,
    subscribe,
    unsubscribeFromMessage,
    debugLog
  } = useRunEngine();

  const {
    showAlert,
    extractNetworkResponse,
    isPlatformWeb
  } = useBlockHelpers();

  const receive = async (from, message) => {
    debugLog("Message Received", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      const sessionToken = message.getData(
        getName(MessageEnum.SessionResponseToken)
      );

      if (sessionToken) {
        loginToken.current = sessionToken
        getLoggedInUser();
      }
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const {
        apiRequestCallId,
        responseJson,
        errorReponse
      } = extractNetworkResponse(message);

      if (errorReponse) {
        console.log("Error", errorReponse);
      }

      if (apiRequestCallId === getCurrentUserCallId.current) {
        if (responseJson && responseJson?.data) {
          setLoggedInUser(responseJson.data);
          getGroups();
          getUsers();
        }
        getCurrentUserCallId.current = null;
      }

      if (apiRequestCallId === getGroupCallId.current) {
        if (responseJson && responseJson?.data) {
          setGroups(responseJson.data);
        }
        getGroupCallId.current = null
      }
      if (apiRequestCallId === removeUserFromGroupCallId.current) {
        if (responseJson && responseJson?.data) {
          getGroups();
        }
        removeUserFromGroupCallId.current = null
      }
      if (apiRequestCallId === getUsersCallId.current) {
        if (responseJson && responseJson?.data?.attributes?.accounts) {
          setUsers(responseJson.data.attributes.accounts);
        }
        getUsersCallId.current = null
      }
      if (apiRequestCallId === addUserToGroupCallId.current) {
        if (responseJson && responseJson?.data) {
          getGroups();
          handleAddUserModal(null);
        } else if (responseJson?.message) {
          alert(responseJson?.message);
        }
        addUserToGroupCallId.current = null;
      }
      if (apiRequestCallId === addGroupCallId.current) {
        if (responseJson && responseJson?.data) {
          getGroups();
          setAddGroupModal(false);
          setGroupName("");
        } else if (responseJson?.message) {
          alert(responseJson?.message);
        }
        addGroupCallId.current = null;
      }
      if (apiRequestCallId === deleteGroupCallId.current) {
        if (responseJson) {
          getGroups();
          if (responseJson?.message) {
            alert(responseJson?.message);
          }
        }
        deleteGroupCallId.current = null;
      }
      if (apiRequestCallId === getMeetingTokenCallId.current) {
        if (responseJson && responseJson?.meeting_id) {
          setMeetingId(responseJson.meeting_id);
          // setToken(responseJson.token)
        } else if (responseJson?.message) {
          alert(responseJson?.message);
        } else if(responseJson?.errors) {
          alert(configJSON.unathorizedGroupMsg);
        }
        getMeetingTokenCallId.current = null;
      }
    }
  };

  useEffect(() => {
    setReceiveCallback(receive);

    subscribedMessages.forEach(message => subscribe(message));
  }, []);

  useEffect(() => {
    getLoginToken();
  }, []);

  const getLoginToken = () => {
    const tokenRequest = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );

    sendBlockMessage(tokenRequest);
  };

  const getLoggedInUser = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: loginToken.current
    };

    sendNetworkRequest(
      getCurrentUserCallId,
      configJSON.getLoggedInUserMethod,
      configJSON.getLoggedInUserEndPoint,
      header
    );
  };

  const getGroups = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: loginToken.current
    };
    sendNetworkRequest(
      getGroupCallId,
      configJSON.getGroupsMethod,
      configJSON.getGroupsEndPoint,
      header
    );
  };

  const getUsers = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: loginToken.current
    };
    sendNetworkRequest(
      getUsersCallId,
      configJSON.getUsersMethod,
      configJSON.getUsersEndPoint,
      header
    );
  };

  const deleteUserFromGroup = (groupId, userId) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: loginToken.current
    };
    const httpBody = {
      account_ids: [userId]
    };
    const endpoint = configJSON.removeUserFromGroupEndPoint.replace(
      "<GROUP_ID>",
      groupId
    );
    sendNetworkRequest(
      removeUserFromGroupCallId,
      configJSON.removeUserFromGroupMethod,
      endpoint,
      header,
      httpBody
    );
  };

  const handleAddUserModal = groupId => {
    setSelectedUser("");
    setAddUserGroupId(groupId);
  };

  const toggleAddGroupModal = () => {
    setGroupName("");
    setAddGroupModal(!addGroupModal);
  };

  const addUserToGroupHandler = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: loginToken.current
    };
    const httpBody = {
      account_ids: [selectedUser]
    };
    const endpoint = configJSON.addUserToGroupEndPoint.replace(
      "<GROUP_ID>",
      addUserGroupId
    );
    sendNetworkRequest(
      addUserToGroupCallId,
      configJSON.addUserToGroupMethod,
      endpoint,
      header,
      httpBody
    );
  };

  const addGroupHandler = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: loginToken.current
    };

    const httpBody = {
      group: {
        name: groupName,
        account_ids: [loggedInUser?.id]
      }
    };
    sendNetworkRequest(
      addGroupCallId,
      configJSON.createGroupMethod,
      configJSON.createGroupEndPoint,
      header,
      httpBody
    );
  };

  const deleteGroupHandler = groupId => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: loginToken.current
    };
    sendNetworkRequest(
      deleteGroupCallId,
      configJSON.deleteGroupMethod,
      configJSON.deleteGroupEndPoint + groupId,
      header
    );
  };

  const getMeetingToken = groupId => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: loginToken.current
    };
    sendNetworkRequest(
      getMeetingTokenCallId,
      configJSON.getMeetingIdMethod,
      configJSON.getMeetingIdEndPoint + groupId,
      header
    );
  };

  const onMeetingLeave = () => {
    setMeetingId(null);
  };

  const viewProps = {
    groups,
    // token,
    token: loginToken.current,
    meetingId,
    deleteUserFromGroup,
    addUserGroupId,
    handleAddUserModal,
    users,
    selectedUser,
    setSelectedUser,
    addUserToGroupHandler,
    loggedInUser,
    toggleAddGroupModal,
    addGroupModal,
    groupName,
    setGroupName,
    addGroupHandler,
    deleteGroupHandler,
    getMeetingToken,
    onMeetingLeave
  };
  // Customizable Area End

  return <GroupVideoCallView testID="GroupVideoCallView" {...viewProps} />;
};

export default GroupVideoCall;