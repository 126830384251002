import React from "react";

const ModalContent = ({ open, children,style }: any) => {
    if (!open) return null;
    return (
        <>
            <div style={{
                position: "fixed",
                display: "flex",
                justifyContent: "center",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0,0,0, .8)",
                zIndex: 1200,
                overflowY: "auto"
            }}>
                <div style={{
                    position: "absolute",
                    backgroundColor: "#FFF",
                    zIndex: 1200,
                    top:"100px",
                    width:"932px",
                    ...style,
                    borderRadius: ".5em",
                }}>{children}</div>
            </div>
        </>
    );
};

export default ModalContent;
