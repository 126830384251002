export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const backIcon = require("../assets/backIcon.png");
export const spark = require("../assets/sparkLogo.svg");
export const ember = require("../assets/emberLogo.svg");
export const nexus = require("../assets/nexusLogo.svg");
export const apex = require("../assets/apexLogo.svg");
export const enterprise = require("../assets/enterpriseLogo.png");
export const info = require("../assets/info-icon.svg");
export const tick = require("../assets/tick.svg");
