import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area Start
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
 
}

interface Staff {
  attributes:StaffAttributes
}

interface Team {
  team_name: string;
  member_size: number;
  sport_title: string;
  esport_title: string;
  team_photo: string;
  staffs: {
    data: Staff[];
  };
  players: {
    data:Staff[]
  }
}
interface Role {
  name: string;
}

interface AccountAttributes {
  activated: boolean;
  country_code: string | null; // 'country_code' can be a string or null
  email: string;
  first_name: string;
  last_name: string;
  role: Role;
  username: string;
  gender: string;
  country:string;
  state: string;
  dob:string;
  photo: string;
  missed_op_score: number;
  team_profile:string; 
}
interface StaffAttributes {
  fullname: string;
  photo: string;
  online: boolean;
}
interface AccountData {
  id: string;
  type: string; // Assuming the type is always 'account'; adjust if needed
  attributes: AccountAttributes;
}

interface Account {
  data: AccountData;
}
export interface PotentialSeeker {
  id: number;
  first_name: string;
  last_name: string;
  photo: string;
  count: number;
}
export interface GameFolder {
  attachment: any
 }
export interface DashboardData {
  team: Team,
  account: Account,
  potential_seekers: PotentialSeeker[],
  game_folders:{data: GameFolder[]}
}
export interface UserProfileData {

      id: number;
      country: string;
      address: string;
      city: string;
      postal_code: string;
      account_id: number;
      full_name: string;
      gender: string;
      date_of_birth: string;
      team_name: string;
      email: string;
      role: string;
      photo: string;
      permissions: Array<string>;
      role_id: number;
      state: string;
      user_name:string;
      account_type: string;
      sport_position: string|null;
      sport_title: string|null;
      esport_title: string|null;
      in_game_role_name: string|null;
      invited_user: boolean;
      team_profile: string;
}
 // Customizable Area End
interface S {
  // Customizable Area Start
  dashboardData: DashboardData;
  totalCandidates: string;
  type: string;
  token: string;
  errorMsg: string;
  loading: boolean;
  isHasData:boolean;
  selectedTab: string;
  profileData:UserProfileData;
  planImage: string;
  initialLoading: boolean;
  subscriptionLoading: boolean;
  // Customizable Area End
}
interface SS {}
export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  apiGetSubscriptionCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      type: "",
      dashboardData:{} as DashboardData,
      totalCandidates: "",
      errorMsg: "",
      token: "",
      loading: true,
      isHasData: true,
      selectedTab: 'Team',
      profileData: {} as UserProfileData,
      planImage:'',
      initialLoading: true,
      subscriptionLoading: true,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    setTimeout(() => {
    this.getSessionProfileData()
    }, 1000);
    this.getSubscriptionData();

    // Customizable Area End
  }

  getDashboardData(): boolean {
    const token = window.localStorage.getItem("authToken") || "";

    // Customizable Area Start
    const webHeader = {
      "Content-Type": "application/json",
      token
    }
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.dashboardGetUrl
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let webErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (webApiRequestCallId === this.apiDashboardItemCallId){
        if (!webResponseJson.error && !webErrorReponse) {      
          this.handleDashboardData(webResponseJson)
        } else if(webResponseJson && webResponseJson.error) {
          this.setState({
            errorMsg: "Something went wrong",
            loading: false
          });
        }
      }
      if (webApiRequestCallId === this.apiGetSubscriptionCallId){
        this.setState({subscriptionLoading:false})
        if (webResponseJson.data && !webErrorReponse) {          
          this.setState({
            planImage:webResponseJson.data.attributes.image,
          });
        } 
      }
    }
    // Customizable Area End
  }
  
  // Customizable Area Start
  componentDidUpdate = (prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void => {
    if (this.state.initialLoading && !this.state.loading && !this.state.subscriptionLoading) {
      this.setState({initialLoading: false})
    }
  }

  handleDashboardData(data:DashboardData) {    
    this.setState({
      dashboardData: data,
      loading: false
    });
  }
  getOnlineCount() {
    const {staffs,players} = this.state.dashboardData?.team || {}
    const total = staffs?.data?.length + players?.data?.length 
    const online =  staffs?.data?.filter((item) => item.attributes.online).length  + players?.data?.filter((item) => item.attributes.online).length
    return {total,online}
  }
  getSessionProfileData() {
    const data =  window.sessionStorage.getItem('profile') || JSON.stringify({})
    if(data){
      this.setState({
        profileData:JSON.parse(data)
      })
    } 
  }

  getSubscriptionData=async ()=> {
    const token = await getStorageData("authToken");

    const webHeader = {
      "Content-Type": "application/json",
      token
    }
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetSubscriptionCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.subscriptionGetUrl
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }
  // Customizable Area End
}
