import React from "react";
// Customizable Area Start
import {
  Box,
  Grid,
  Typography,
  styled
} from "@material-ui/core";
import {
  bgBlur,
  fantasticweDefaultTeamImg,
  logoApp,
  profileAvatar
} from "./assets";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { 
  PotentialSeeker
 } from "./DashboardController.web";

 const potentialSeekers_background=["linear-gradient(315deg, #FFAA29 0%, #FF881A 100%)","linear-gradient(130.72deg, #717171 0%, #D7D7D7 100%)","linear-gradient(310.72deg, #B64420 0%, #572D0E 100%)"];
// Customizable Area End
import DashboardController, 
{
  Props,
} from "./DashboardController.web";
import BrowseRecentGame from "../../../components/src/BrowseRecentGame.web";
import Loader from "../../../components/src/Loader.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderStaffs(userType: string) {
    const { team } = this.state.dashboardData || {}
    const { data } = team && (userType === 'staff' ? team.staffs : team.players) || []
    const isAdmin = this.state.profileData?.role === 'team admin'
    return (
      <Box style={{ padding: '0px 24px'}} marginBottom={'24px'} data-test-id={`render${userType}`}>
        <Typography className="staff-text" style={{ marginBottom: '8px' }}>{userType === 'staff' ? 'Staffs' : 'Players'}</Typography>
        <Grid className="staff-container" style={{ maxHeight: !isAdmin ? '110px' : '220px', overflowY: 'auto' }}>
          {data?.map((item, index) => (
            <Grid item xs={3} className="staff-box" key={index}>
              <Box style={{ display: 'flex', alignContent: 'center', alignItems: 'center', gap: '8px' }} >
                <img src={item.attributes.photo || profileAvatar} width={'48.91px'} height={'50px'} style={{ borderRadius: '50%' }} />
                <Typography className="staff-text">{item.attributes.fullname}</Typography>
              </Box>
              <Typography className="status-texxt" style={{ color: item.attributes.online ? '#8DE301' : '#FF1919' }}>{item.attributes.online ? 'Online':'Offline'}</Typography>
            </Grid>
          ))}
        </Grid>

      </Box>
    )
  }
  renderProfileDetail(name: string, value: string) {
    return (<Box style={{ justifyContent: 'space-between', display: 'flex', color: '#CFD1D4' }} paddingTop={'8px'}>
      <Typography>{name}</Typography>
      <Typography>{value}</Typography>
    </Box>)
  }


  renderPotentialSeeker() {
    const { potential_seekers } = this.state.dashboardData || []

    return (
      <Box className="card" sx={{height:'210px'}}>
        <Box className="poteintial-container">
          <Typography><span className="potential-text">Potential seekers</span> &nbsp;
            <span className="potential-text" style={{ fontSize: '16px' }}>(Most Missed-OP markings per player)</span>
          </Typography>
        </Box>
        <Box className="potential-box">
  {!potential_seekers?.length && (
    <Box style={{ display: 'flex', justifyContent: 'center' }}>
      <Typography className="staff-text">No potential seekers</Typography>
    </Box>
  )}

  <Box className="potential-scroll-container">
    {potential_seekers?.slice(0,3).map((item: PotentialSeeker, index: number) => {
      return (
        <Box className="internal-potential" key={index}>
          <Box className="potential-img-block" style={{background:potentialSeekers_background[index%potentialSeekers_background.length]}}>
            <img src={item.photo || profileAvatar} height={'50px'} width={'48.91px'} style={{borderRadius:"50%"}} />
            <Typography
              style={{
                fontSize: '16px',
                fontWeight: '400',
                fontFamily: 'Poppins',
                color: '#1D1D1F',
                textAlign: 'center',
              }}
            >
              {`${item.first_name} ${item.last_name}`.substring(0,12)}
            </Typography>
          </Box>
          <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft:"20%" }}>
            <Typography className="labelText-3" style={{ color: '#CFD1D4' }}>{item.count}</Typography>
          </Box>
        </Box>
      );
      })}
    </Box>
  </Box>
      </Box>
    )
  }
  renderRecentGames() {
    const { data } = this.state.dashboardData.game_folders || {data:[{attachment:{}}]}
    return (<Box className="card" style={{ paddingBottom: '24px', height:'324px' }}>
      <Box className="poteintial-container">
        <Typography className="potential-text">
          Browse Recent Games
        </Typography>
      </Box>
      <BrowseRecentGame navigation={this.props.navigation} data={data?.slice(0,3)}/>
    </Box>)
  }
  renderDashboardData() {
    const { team } = this.state.dashboardData 
    const { total, online } = this.getOnlineCount()
    return (
      <Box className="totalMop" style={{ flexDirection: 'column', flex: "0 0 calc(65% - 8px)" }} >
      {team && ( <Box className="card" style={{height:408}}>
          <Typography className="labelText">Online <span  style={{color:"#8DE301"}}>{`(${online}/${total})`}</span></Typography>
          {this.renderStaffs('staff')}
          {this.renderStaffs('player')}
        </Box>)}

        {this.state.profileData?.role !== 'team admin' && this.renderRecentGames()}
        {this.renderPotentialSeeker()}


      </Box>
    )
  }
  renderDashboardNodata = () =>
    <StyledGridItem>
      <Box className="container">
        <Box className="childContainer">
          <Typography className="welcome">Welcome!</Typography>
          <Box className="paraContainer"><Typography className="para">
            You can get started using the app! Your dashboard data
            will populate automatically.
          </Typography></Box>
          <Box className="imgContainer"><img className="img" src={logoApp} /></Box>
        </Box>
      </Box>
    </StyledGridItem>
  // Customizable Area End

  render() {
    // Customizable Area Start
    const accountData = this.state.profileData || {};
    const { team } = this.state.dashboardData || {}
    return (
      //Merge Engine DefaultContainer
      <Box style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
        <NavigationMenu navigation={this.props.navigation} id="NavigationMenu" currentTabProp="dashboard" />

        {
          this.state.initialLoading ?
            <Loader loading /> :
            <Box style={{ margin: "24px 40px", flex: 1, display: "flex", flexDirection: "column",overflow:"hidden" }}>
              {this.state.isHasData && (
                <DashboardStyled>
                  <>
                    <Box className="totalMop" style={{ flexDirection: 'column' }}>
                      { team &&<Box className="card" style={{ height:408 }}>
                        <Typography className="labelText" data-testId="personal-text">Personal Diagnostics</Typography>
                        <Box style={{ display: 'flex', justifyContent: 'center', padding: '24px' }}>
                          <Box className="item-option mt-8">
                            <img src={accountData.team_profile || fantasticweDefaultTeamImg} alt="fansta" width={110} height={110} />
                          </Box>

                        </Box>
                        <Box className="center-content" style={{ marginTop: '8px', flexDirection: 'column', alignItems:'center' }}>
                          <Typography className="labelText-2">[{team?.team_name} | {team?.member_size || 0} Members]</Typography>
                          <Typography className="labelText-4 labelText-5" style={{ justifyContent: 'center', alignContent: 'center', display: 'flex' }}>{team?.esport_title}</Typography>

                        </Box>
                        <Box className="center-content" style={{ alignContent: 'center', alignItems: 'center', background: '#1D1D1F', margin: '4px 24px 4px 24px', borderRadius: '8px' }}>
                          {this.state.planImage&&<img src={this.state.planImage} alt="No Plan" width={'40px'} height={'40px'} />}
                          <Typography className="labelText-3">{accountData?.full_name}</Typography>

                        </Box>
                        <Box className="center-content" style={{ marginBottom: "24px", alignContent: 'center', alignItems: 'center' }}>

                          <Typography ><span className="labelText-4">FantasticWe</span> <span className="labelText-5">- Where data becomes humanized</span></Typography>

                        </Box>
                      </Box>}
                      <Box className="card" >
                        <Typography className="labelText">Profile Details</Typography>
                        <Box style={{ display: 'flex', justifyContent: 'center', padding: '24px' }}>
                          <Box className="item-option mt-8">
                            <img src={accountData?.photo || profileAvatar} alt="fansta" width={"110px"} height={"110px"} />
                          </Box>

                        </Box>
                        <Box className="scroll-bar-custom" style={{ borderRadius: '8px', background: "#1D1D1F", margin: '24px', maxHeight: '100%',minHeight:"162px", padding: '8px', overflowY: 'auto' }} data-testId="userprofile">
                          {this.renderProfileDetail('Full Name', `${accountData?.full_name}`)}
                          {this.renderProfileDetail('Username', accountData?.user_name)}
                          {this.renderProfileDetail('Role', accountData?.role)}
                          {this.renderProfileDetail('Gender', accountData?.gender)}
                          {this.renderProfileDetail('Email', accountData?.email)}
                          {accountData.esport_title ? this.renderProfileDetail('Esport Title',accountData.esport_title || '') : this.renderProfileDetail('Sport Title',accountData.sport_title ||'') }
                          {this.renderProfileDetail('Country', accountData?.country)}
                          {this.renderProfileDetail('Team', accountData?.team_name)}
                          {this.renderProfileDetail('State', accountData?.state)}
                          {this.renderProfileDetail('DOB', accountData?.date_of_birth)}
                        </Box>

                      </Box>
                    </Box>
                    {this.renderDashboardData()}
                  </>
                </DashboardStyled>
              ) }
            </Box>
        }
      </Box>

      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const StyledGridItem = styled('div')(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  '& .container': {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#252527",
    width: "100%",
    marginTop: 20,
    marginBottom: 40,
    minHeight: "500px",
  },
  '& .childContainer': {
    width: 750,
    height: 315,
    borderRadius: 10,
    backgroundColor: "#1D1D1F",
    padding: "40px 80px",
    [theme.breakpoints.down('xl')]: {
      height: 315,
    },
    [theme.breakpoints.down('lg')]: {
      width: 600,
      height: 300,
      padding: 0,
    },
    [theme.breakpoints.down('md')]: {
      height: 300,
      padding: 0,
      width: 600

    },
    [theme.breakpoints.down('sm')]: {
      width: 500,
      padding: 0,
      height: 315,
    },
    [theme.breakpoints.down('xs')]: {
      width: 700,
    },
  },

  '& .welcome': {
    color: '#CFD1D4',
    fontWeight: 700,
    fontFamily: "Poppins",
    fontSize: 20,
    textAlign: "center",
    lineHeight: 4,
  },
  '& .paraContainer': {
    width: "80%",
    borderBottom: "2px solid #FF881A",
    margin: "0 auto",
  },
  '& .para': {
    color: '#676767',
    fontWeight: 400,
    fontSize: 18,
    textAlign: "center",
    margin: "0 auto",
    width: "90%",
    lineHeight: 2,
    fontFamily: "Poppins",
    paddingBottom: 30,
  },
  '& .imgContainer': {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    [theme.breakpoints.down('lg')]: {
      paddingTop: 20,
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: 10,
    },
  },
  '& .img': {
    width: "31%",
    [theme.breakpoints.down('lg')]: {
      width: "25%",
    },
  }

}))


const DashboardStyled = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  marginBottom: '24px',
  gap: "16px",
  "& .card": {
    background: "#252527F2",
    position: "relative",
    borderRadius: "10px",
    "& .box-content": {
      justifyContent: 'space-between', display: 'flex', color: '#CFD1D4',
      paddingTop: '1px'
    },
    "& .scroll-bar-custom": {
      '&::-webkit-scrollbar': {
        width: '0.4em'
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.1)',
        outline: '1px solid slategrey'
      }
    },
    "& .center-content": {
      display: 'flex',
      justifyContent: 'center'
    },
    "& .labelText": {
      padding:24,
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: "24px",
      color: "#CFD1D4"
    },
    "& .labelText-2": {
      marginLeft: "16px",
      fontFamily: "Poppins",
      fontWeight: 600,
      fontSize: "24px",
      color: "#CFD1D4",
      lineHeight: 'normal',
      fontStyle: 'normal',
      textTransform: 'capitalize'
    },
    "& .labelText-3": {
      marginLeft: "16px",
      fontFamily: "Poppins",
      fontWeight: 600,
      fontSize: "36px",
      color: "#FF881A",
      lineHeight: 'normal',
      fontStyle: 'normal',
      textTransform: 'capitalize',
      backgroundClip: "text",
    },
    "& .labelText-4": {
      marginLeft: "16px",
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: "16px",
      marginBottom: '24px',
      color: "#FF881A",
      lineHeight: '24px',
      fontStyle: 'normal',
      textTransform: 'capitalize',
      backgroundClip: "text",
    },
    "& .labelText-5": {
      color: "#CFD1D4"
    },
    "& .staff-container": {
      display: 'flex', flexWrap: "wrap", gap: '8px',
      maxHeight: ''
    },
    "& .staff-box": {
      background: "#1D1D1F", borderRadius: '8px',
      display: 'flex',
      justifyContent: 'space-between',
      alignContent: 'center',
      alignItems: 'center',
      padding: '0px 8px',
      gap: '14px',
      minWidth: '32%'
    },
    "& .staff-text": {
      color: "#CFD1D4",
      fontFamily: "Poppins",
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: "400"
    },
    "& .status-text": {
      fontSize: '10px',
      lineHeight: '18px',
    },
    "& .potential-text": {
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: "24px",
      color: "#CFD1D4"
    },
    "& .poteintial-container": {
      display: 'flex',
      gap: '24px',
      padding: '24px',
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
    "& .potential-box": {
      padding: '0px 24px',
      marginBottom: '24px',
      overflow: 'hidden',  // Ensures container hides overflow content
    },
    "& .potential-scroll-container": {
      display: 'flex',
      gap: '24px',
      paddingBottom: '16px',
      overflowX: 'auto',  // Enables horizontal scroll
      scrollbarWidth: 'none',  // Hides scrollbar (for Firefox)
      '&::-webkit-scrollbar': {
        display: 'none',  // Hides scrollbar (for WebKit)
      },
    },
    "& .internal-potential": {
      display: 'flex',
      padding: '8px',
      background: "#1D1D1F",
      borderRadius: '10px',
      alignItems: 'center',
      gap: '16px',
      minWidth:"28%",
    },
    "& .fanta-text": {
      color: "#CFD1D4",
      textAlign: 'center',
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontWeight: 500
    },
    "& .potential-img-block": {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      background: '#FFAA29',
      padding: "4px 4px 8px",
      borderRadius: '8px',
      alignItems: 'center',  // Centers the image and text
      width:"100px",
    },
  },
  "& .totalMop": {
    flex: "0 0 calc(35% - 8px)",
    display: "flex",
    gap: "16px",
    height:"100%",
    "& .totalActionContainer": {
      "& .background-blur": {
        position: 'absolute',
        top: 0,
        left: 20,
        width: '90%',
        height: '90%',
        backgroundImage: `url(${bgBlur})`,
        backgroundSize: '100% 100%',
        backgroundPosition: 'center',
        filter: 'blur(8px)',
        zIndex: -1
      },

      background: "#252527F2",
      position: "relative",
      borderRadius: "10px",
      "& .labelText": {
        marginTop: "24px",
        marginLeft: "16px",
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "24px",
        color: "#CFD1D4"
      },
      "& .tagBlock": {
        position: "absolute",
        top: "4px",
        right: "16px"
      }
    },
    "& .divide": {
      flex: "0 0 calc(30% - 8px)",

    },
    "& .divide-2": {
      flex: "0 0 calc(70% - 8px)",

    },
  },
  "& .boxPermonceTrend": {
    display: "flex",
    gap: "20px"
  },
  "& .item-option": {
    height: '50px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',

    "&:hover .item-option-name": {
      border: '1px solid #FF881A'
    },

    "& img": {
      borderRadius: '50%',
      position: 'relative',
      zIndex: 1302
    },

    "&.mt--8": {
      marginTop: '-8px',
    },

    "&.mt-8": {
      marginTop: '8px'
    }
  },
  "& .usefulInsightsBox": {
    flex: 1,
    background: "#252527",
    borderRadius: "10px",
    padding: "8px 0",
    display: "flex",
    flexDirection: "column",
    "& .header__title": {
      color: "#CFD1D4",
      fontSize: "28px",
      fontWeight: 700,
      fontFamily: "Roboto",
      textAlign: "center",
      paddingBottom: "16px"
    },
    "& .listInsight": {
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      "& .insighItem": {
        background: "#161618",
        boxShadow: "0px 5.242975234985352px 5.242975234985352px 0px #00000040 inset",
        borderRadius: "8px",
        height: "80px",
        display: "flex",
        alignItems: "center",
        "& .imgIcon": {
          marginRight: "24px",
          padding: "16px"
        },
        "& .description__text": {
          padding: "16px",
          fontFamily: "Poppins",
          fontWeight: 400,
          fontSize: "18px",
          color: "#8EA2AB",
          [theme.breakpoints.down(1460)]: {
            fontSize: "16px"
          },
          [theme.breakpoints.down(1405)]: {
            fontSize: "14px"
          },
        }
      }
    }
  },
  "& .statistics": {
    display: "flex",
    justifyContent: "space-between",
    "& .statisticsData": {
      position: "relative",
      height: "200px",
      background: "#252527F2",
      flex: "0 0 24.5%",
      borderRadius: "10px",
      "& .background-blur": {
        position: 'absolute',
        top: 0,
        left: 20,
        width: '90%',
        height: '90%',
        backgroundImage: `url(${bgBlur})`,
        backgroundSize: '100% 100%',
        backgroundPosition: 'center',
        filter: 'blur(8px)',
        zIndex: -1
      },
      "& .statisticsLabel": {
        textAlign: "center",
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "34px",
        color: "#CFD1D4"
      },
      "& .statisticsValue": {
        textAlign: "center",
        fontFamily: "Poppins",
        fontWeight: 500,
        fontSize: "68px",
        color: "#CFD1D4"
      },
      "& .statisticsValue.greenText": {
        color: "#7ED321"
      }
    }
  },
  "& .recentGame__container": {
    position: "relative",
    background: "#252527",
    borderRadius: "10px",
    padding: "32px 0 16px",
    marginBottom: "24px",
    "& .recentGame__title": {
      position: "absolute",
      left: 0,
      right: 0,
      textAlign: "center",
      top: 8,
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: "32px",
      color: "#CFD1D4"
    },
    "& .recentGame__list": {
      display: "flex",
      flexDirection: "column",
      "& .recentGame__layout": {
        display: "flex",
        textAlign: "center",
        "& .col-1": {
          flex: "0 0 calc(100%/12*5)"
        },
        "& .col-2": {
          flex: "0 0 calc(100%/12*2.5)"
        },
        "& .col-3": {
          flex: "0 0 calc(100%/12*2)",
          fontFamily: "Poppins",
          fontSize: "24px",
          fontWeight: 400,
          color: "#CFD1D4"
        },
        "& .col-4": {
          flex: "0 0 calc(100%/12*1.5)",
          fontFamily: "Poppins",
          fontSize: "24px",
          fontWeight: 400,
          color: "#CFD1D4"
        },
        "& .col-5": {
          flex: "0 0 calc(100%/12*1)"
        },
      },
      "& .recentGame__item": {
        background: "#161618",
        height: "60px",
        "& .mapName__text": {
          width: "100%",
          fontFamily: "Poppins",
          fontSize: "24px",
          fontWeight: 500,
          color: "#FFFFFF"
        },
        "& .col-1": {
          display: "flex",
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          justifyContent: "end",
          "& .mapName__box": {
            flex: "0 0 calc(100%/12*4)",
            display: "flex",
            alignItems: "center"
          },
          "& .boxStatus": {
            width: "30px"
          },
          "& .greenBox": {
            background: "#7ED321"
          }
        },
        "& .col-2,.col-3,.col-4": {
          "& .mapName__box": {
            display: "flex",
            height: "100%",
            alignItems: "center"
          },
          "& .mapName__text": {
            fontWeight: 400,
            color: "#CFD1D4"
          }
        },
        "& .col-2": {
          "& .mapName__text": {
            fontWeight: 400,
            fontSize: "20px"
          }
        },
        "& .col-5": {
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }
      }
    }
  }
}))

// Customizable Area End
