import React, { Component } from "react";
import Select, { components } from "react-select";
type Option = { value: string; label: string };
type MyProps = {
    // label?: string,
    placeholder?: string,
    name?: string,
    handleChangeFunction: any,
    value: string,
    validationMsg?: string,
    isDisabled?: boolean,
    isStep1?:boolean,
    options:any;
    testId?:string;
    // hideIndicator?: boolean
};


const groupStyles = {
    color: "white",
    background: "#1D1D1F",
    padding: "5px 0px",
    display: "flex",
};

const GroupHeading = (
    props: any
) => (
    <div style={groupStyles}>
        <components.GroupHeading
            style={{ color: "#676767", marginLeft: 30,fontSize:"16px",fontFamily:"Poppins",fontWeight:"600" }}
            {...props}
        />
    </div>
);

const Option = (props: any) => {
    const {
        children,
        className,
        isDisabled,
        isFocused,
        isSelected,
        innerRef,
        innerProps,
    } = props;
    return (
        <div
            ref={innerRef}
            style={{
                backgroundColor: isSelected || isFocused ? "#F08E3B" : "#1D1D1F",
                color: "#fff",
                textAlign: "left",
                padding: "12px 20px",
                margin: "0px 20px",
                borderRadius: "20px",
                fontSize:"16px",
                fontWeight:"400",
                fontFamily:"Poppins"
            }}
            {...innerProps}
        >
            {children}
        </div>
    );
};

const DropdownIndicator = (props: any) => {
    return (
        <components.DropdownIndicator {...props}>
            <img alt="selected" src={require("./drop_down.svg")} width={20} height={20} />
        </components.DropdownIndicator>
    );
};

const groupedOptions = [
    {
        label: "Competitive Game Type",
        options: [
            { value: "Cup", label: "Cup" },
            { value: "League", label: "League" },
            { value: "Season", label: "Season" },
        ],
    },
    {
        label: "Practice Mark Making Type",
        options: [
            { value: "Practice Marking", label: "Practice Marking" },
            { value: "Exhibition", label: "Exhibition" },
            { value: "Practice Match", label: "Practice Match" },
        ],
    },
];

export default class DropDownGroup extends Component<MyProps> {

    constructor(props: any) {
        super(props);
        this.state = {
        };
    }
    Menu = (props: any) => {
        const {
            children,
          
        } = props;
        return (
            <div
                style={{
                    width: this?.props.isStep1 ? "300px":"100%",
                    position: "absolute",
                    zIndex: 5000,
                    top: 60,
                    right: 0,
                    marginTop: 0,
                    scrollbarWidth: "thin",
                    scrollbarColor: "#676767 #252527",
                }}
            >
                <components.MenuList {...props}>{children}</components.MenuList>
            </div>
        );
    };
    render(): React.ReactNode {
        return (
            <div data-testid={this.props.testId}>
                <Select<any>
                    id="selectId"
                    data-testid={this.props.testId}
                    options={this.props.options}
                    components={{
                        Option,
                        DropdownIndicator,
                        GroupHeading,
                        IndicatorSeparator: () => null,
                        Menu:this.Menu,
                    }}
                    placeholder={this.props.placeholder}
                    value={this.props.value ? { value: this.props.value, label: this.props.value } : null}
                    onChange={this.props.handleChangeFunction}
                    name={this.props.name}
                    styles={{
                        singleValue: (base) => ({
                            ...base,
                            color:"#CFD1D4",
                            fontFamily: 'Poppins'
                        }),
                        menu: (base) => ({
                            ...base,
                            position: "absolute",
                            top: 50,
                            right: 0,
                            border: "1px solid #FF881A",
                            marginTop: 0,
                            scrollbarWidth: "thin",
                            scrollbarColor: "#676767 #252527",
                        }),
                        control: (base) => ({
                            ...base,
                            borderRadius: 12,
                            padding: "3px 0",
                            height:this.props.isStep1 ? "100%":"28px",
                            margin: 0,
                            backgroundColor: "#1D1D1F",
                            border: 0,
                            boxShadow: "none",
                            borderBottom: "2px solid #FF881A",
                        }),
                        menuList: (base) => ({
                            ...base,
                            backgroundColor: "#1D1D1F",
                            border: "1px solid #FF881A",
                            borderRadius: 14,
                            padding: 0,
                            margin: 0,
                        }),
                        placeholder: (base) => ({
                            ...base,
                            fontSize: 16,
                            color: "#676767",
                        }),
                        container: (base) => ({
                            ...base,
                            borderRadius: 40,
                        }),
                        option: (base) => ({
                            ...base,
                            backgroundColor: "#1D1D1F",
                           
                        }),
                    }}

                />
            </div>
        );
    }
}


