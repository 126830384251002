import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface ITermsConds {
  id: string;
  description: string;
  is_accepted: boolean;
  created_at: string;
}

// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  showModal: any;
  showAcceptButton: boolean;
  onAcceptPress?: Function;
  onClosePress?: Function;
  onAcknowledgePress?: Function;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  open:boolean;
  isChecked:boolean;

  termAndCondition: {
    account_id?: number;
    created_at: string;
    creator: string;
    description: string;
    id: number;
    title: string;
    updated_at: string;
  } | null;

  privacy: {
    account_id?: number;
    created_at: string;
    creator: string;
    description: string;
    id: number;
    title: string;
    updated_at: string;
  } | null;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class TermsConditionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTermsandConditionsAPICallId :string = "";
  getPrivacyAPICallId:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      open:true,
      isChecked:false,

      termAndCondition: null,
      privacy: null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    this.getTermsandConditionsAPI();
    this.getPrivacyAPI();
  }
 
  CurrentDate = () => {
    const dateString = new Date().toLocaleDateString('en-GB');
    return dateString
  };
  handleTermsCheckBox = () =>{
    this.setState({ isChecked: !this.state.isChecked });
  }
  handlePrivacyAccept = () =>{
  }
  handleClose = () =>{
  }
 
  getTermsandConditionsAPI = ()=>{
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTermsandConditionsAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/terms_and_conditions"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  
  }
  getPrivacyAPI = ()=>{
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPrivacyAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/privacy_policies"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  
  }
  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  termsAndPrivacy = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getTermsandConditionsAPICallId) {
      if (responseJson) {
        this.setState({ termAndCondition: responseJson.terms_and_conditions[0] })
      }
    }
    if (apiRequestCallId === this.getPrivacyAPICallId) {
      if (responseJson) {
        this.setState({ privacy: responseJson.privacy_policies[0] })
      }
    }
  }

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );  
    this.termsAndPrivacy(apiRequestCallId,responseJson);

    // Customizable Area End
  }
}
