import React from "react";
import { makeStyles } from "@material-ui/core/styles";
const logo = require("./assets/logo.svg")

interface myProps {
  loading: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    position: "fixed",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgb(0, 0, 0, .6)",
    backdropFilter: "blur(2px)",
    zIndex: 9999999999999,
  },
  circularContainer: {
    position: "absolute",
    left: "50%",
    top: "calc(50% - 50px)",
    transform: "translate(-50%, -50%)",
    userSelect: 'none',
    pointerEvents: 'none'
  },
}));

export default function Loader(props: myProps) {
  const classes = useStyles();
  return props.loading ? (
    <div className={classes.root}>
      <div className={classes.circularContainer}>
        <img src={logo} width={320} height={150} alt="logo"/>
      </div>
    </div>
  ) : (
    <div />
  );
}
