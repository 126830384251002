import { Box, Typography } from '@material-ui/core'
import { ErrorOutline } from '@material-ui/icons';
import React from 'react'
const checkMarkIcon = require('../assets/checkMarkIcon.svg');

interface IPropsCustomToast {
    isError?: boolean;
}

export default function CustomToastAddEvent(props: IPropsCustomToast) {
    return <Box style={{
        display: "flex",
        gap: "12px",
        alignItems: "center",
        justifyContent: "space-around"
    }}>
        { !props.isError ? <img width={32} height= {32} src={checkMarkIcon} alt="checkMarkIcon"/> : <ErrorOutline style={{ width: "32px", height: "32px", color: "red" }} /> }
        <Typography style={{
            fontFamily: "Poppins",
            fontSize: "14px",
            color: props.isError ? "red" : "#8DE301"
        }}>
            {`Event ${props.isError ? "failure" : "successfully"} Added`}
        </Typography>
    </Box>
}

