import React from "react";

import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/core/styles";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import {
  video1Image,
  playIcon,
  helpcentre2,
  searchIcon,
  accountIcon,
  backgroundImage,
  gradientImage,
  fixIcon,
  logoIcon,
  logoWhiteIcon,
  settingItemIcon,
} from "./assets";
// Customizable Area End

import HelpCentreController, { Props } from "./HelpCentreController";

export default class HelpCentre extends HelpCentreController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <StyledContainer style={{ minHeight: "100vh" }}>
        <NavigationMenu
          navigation={this.props.navigation}
          id="NavigationMenu"
          currentTabProp="profile"
        />

        <Box className="help-centre-page">
          <Box className="banner-wrapper">
            <img className="banner-background" src={backgroundImage} />
            <img className="banner-gradient" src={gradientImage} />
            <Box className="banner-box">
              <Box className="banner-title-wrapper">
                <img className="banner-logo-white" src={logoWhiteIcon} />
                <Typography component="span" className="banner-title">
                  FantasticWe Help Center
                </Typography>
              </Box>
              <Box className="search-wrapper">
                <input
                  type="text"
                  className="inp-search"
                  placeholder="Enter question here..."
                />
                <img className="search-icon" src={searchIcon} />
              </Box>
              <Box className="suggests-wrapper">
                <Typography component="span" className="suggests-span">
                  I Forgot my Password
                </Typography>
                <Typography component="span" className="suggests-span">
                  How do I Sign Up
                </Typography>
                <Typography component="span" className="suggests-span">
                  How do I Create more Keyboards
                </Typography>
                <Typography component="span" className="suggests-span">
                  How do I add friends
                </Typography>
                <Typography component="span" className="suggests-span">
                  Can I mark more than one game?
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box className="support-wrapper">
            <Typography component="h6" className="setting-title">
              FantasticWe Support
            </Typography>

            <Typography component="span" className="setting-span">
              Get Instant answers for most common
              <br />
              questions and learn to become a pro like a pro!
            </Typography>
          </Box>

          <Box className="help-wrapper">
            <Box className="item-wrapper">
              <Box className="setting-item">
                <img src={logoIcon} className="item-icon" alt="" />

                <Typography component="h6" className="setting-item-title" style={{ display: 'flex', flexDirection: 'column' }}>
                  <span style={{ paddingBottom: '10px' }}>Using</span>
                  <span style={{ paddingBottom: '15px' }}>FantasticWe</span>
                </Typography>

                <Typography component="span" className="bottom-text">
                  Learn The Basics!
                </Typography>
                <Button className="setting-item-button">More</Button>
              </Box>

              <Box className="setting-item">
                <img src={accountIcon} className="item-icon" alt="" />
                <Typography component="h6" className="setting-item-title">
                  My Account
                </Typography>

                <Typography component="span" className="bottom-text">
                  Receive instant information regarding your account management
                  and settings
                </Typography>
                <Button className="setting-item-button">More</Button>
              </Box>

              <Box className="setting-item">
                <img src={fixIcon} className="item-icon" alt="" />
                <Typography component="h6" className="setting-item-title">
                  Fix a Problem
                </Typography>

                <Typography component="span" className="bottom-text">
                  Encountering a problem? let us help you!
                </Typography>
                <Button className="setting-item-button">More</Button>
              </Box>

              <Box className="setting-item">
                <img src={settingItemIcon} className="item-icon" alt="" />
                <Typography component="h6" className="setting-item-title">
                  My Account
                </Typography>

                <Typography component="span" className="bottom-text">
                  Receive instant information regarding your account management
                  and settings
                </Typography>
                <Button className="setting-item-button">More</Button>
              </Box>
            </Box>
            <Box className="video-right">
              <Box className="video-wrapper">
                <Box className="video-box">
                  <Box className="video-show">
                    <img src={video1Image} className="video-img" alt="" />
                    <img src={playIcon} className="video-play-icon" alt="" />
                  </Box>
                  <Box className="video-info-wrapper">
                    <Typography component="span" className="video-tag">
                      NEW VIDEO
                    </Typography>

                    <Typography component="h6" className="video-title">
                      How to use FantasticWe
                    </Typography>

                    <Typography component="span" className="video-desc">
                      Learn how to use FantasticWe in order to maximize your own
                      potential!
                    </Typography>
                    <Button className="video-btn">Learn more</Button>
                  </Box>
                </Box>

                <Box className="video-box">
                  <Box className="video-show">
                    <img src={helpcentre2} className="video-img" alt="" />
                    <img src={playIcon} className="video-play-icon" alt="" />
                  </Box>
                  <Box className="video-info-wrapper">
                    <Typography component="span" className="video-tag">
                      NEW VIDEO
                    </Typography>

                    <Typography component="h6" className="video-title">
                      FantasticWe Reports
                    </Typography>

                    <Typography component="span" className="video-desc">
                      Learn how to use FantasticWe reports in order to achieve a
                      faster understanding of your game!{" "}
                    </Typography>
                    <Button className="video-btn">Learn more</Button>
                  </Box>
                </Box>
              </Box>
              <Box className="video-bottom-wrapper">
                <Typography component="span" className="video-bottom-span">
                  Please take a moment to comment on any
                  <br />
                  community thread opened!
                </Typography>
                <Button className="video-bottom-btn">More videos</Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </StyledContainer>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyledContainer = styled(Box)(() => ({
  paddingTop: "20px",
  paddingBottom: "20px",
  boxSizing: "border-box",

  "& .help-centre-page": {
    marginTop: "20px",
    padding: "0 40px",
  },
  "& .item-wrapper": {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "10px",
    justifyContent: "space-between",
    width: "fit-content",
    maxWidth: "100%",
    boxSizing: "border-box",
    flexBasis: "35%",
  },

  "& .setting-item": {
    overflow: "hidden",
    boxSizing: "border-box",
    flexBasis: "49%",
    minHeight: "fit-content",
    borderRadius: "10px",
    backgroundColor: "#252527",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  "& .last-item": {
    flexBasis: "29%",
    paddingLeft: "13px",
    paddingRight: "13px",
  },

  "& .setting-item-title": {
    width: "100%",
    textAlign: "center",
    height: "84px",
    color: "#CFD1D4",
    fontFamily: "Poppins",
    fontWeight: "400",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "36px",
    lineHeight: "30px",
  },

  "& .banner-wrapper": {
    position: "relative",
    paddingBottom: "100px",
  },
  "& .banner-background": {
    position: "absolute",
    left: "0",
    top: "0",
    width: "100%",
    height: "100%",
  },
  "& .banner-gradient": {
    position: "absolute",
    left: "0",
    top: "0",
    width: "100%",
    height: "100%",
  },
  "& .banner-box": {
    position: "relative",
    zIndex: 1,
    color: "white",
    width: "847px",
    margin: "0 auto",
    paddingTop: "90px",
  },
  "& .banner-title-wrapper": {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
  },
  "& .banner-title": {
    fontSize: "36px",
    fontFamily: 'poppins'
  },
  "& .search-wrapper": {
    position: "relative",
    width:'847px',
    height:'84px',
    marginBottom: "8px",
  },
  "& .inp-search": {
    width: "847px",
    color: "white",
    background: "#000",
    height: "65px",
    border: "0",
    borderRadius: "25px",
    padding: "0px 25px",
    fontFamily: 'Poppins',
    fontSize: '23.16px',
    fontWeight:400
  },
  "& .search-icon": {
    position: "absolute",
    right: "15px",
    top: "15px",
    width: "28px",
  },
  "& .banner-logo-white": {
    marginRight: "15px",
    height:'101px',
    width:'200px'
  },
  "& .suggests-span": {
    background: "#00000073",
    padding: "8px 8px",
    marginRight: "20px",
    marginBottom: "8px",
    fontFamily: 'poppins',
    display: "block",
    borderRadius: "25px",
    fontSize: "20px",
  },
  "& .suggests-wrapper": {
    justifyContent: "center",
    display: "flex",
    width:'847px',
    flexWrap: "wrap",
  },
  "& .support-wrapper": {
    textAlign: "center",
    padding: "20px 0 40px",
  },
  "& .setting-title": {
    color: "white",
    fontFamily: 'poppins',
    fontSize: "36px",
    marginBottom: "6px",
  },
  "& .setting-span": {
    color: "#555555",
    fontFamily: 'poppins',
    fontSize: '24px'
  },

  "& .help-wrapper": {
    gap: "10px",
    width: "fit-content",
    display: "flex",
    flexFlow: "row nowrap",
    maxWidth: "100%",
    boxSizing: "border-box",
    justifyContent: "space-between",
  },
  "& .video-right": {
    flexBasis: "65%",
    background: "rgb(37, 37, 39)",
    padding: "10px",
    borderRadius: "10px",
  },
  "& .item-icon": {
    width: "190px",
    height: "190px",
    objectFit: "contain",
  },

  "& .video-box": {
    display: "flex",
    flexFlow: "row nowrap",
    gap: "20px",
    width: "100%",
    justifyContent: "space-between",
    marginBottom: "35px",
  },
  "& .video-show": {
    width: "72%",
    position: "relative",
    height: "340px",
  },
  "& .video-info-wrapper": {
    flexBasis: "45%",
    width: "100%",
  },

  "& .video-play-icon": {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  "& .video-tag": {
    color: "#FF881A",
    fontWeight: "bold",
    fontSize: "16px",
    marginBottom: "6px",
    fontFamily: 'poppins',
    display: "block",
  },
  "& .video-title": {
    color: "#CFD1D4",
    fontSize: "36px",
    marginBottom: "6px",
    fontFamily: 'poppins'
  },
  "& .video-desc": {
    color: "#555555",
    fontSize: "20px",
    marginBottom: "10px",
    fontFamily: 'poppins'
  },

  "& .video-btn": {
    background: "#FF881A",
    fontSize: "20px",
    padding: "4px 10px",
    fontFamily: 'poppins',
    lineHeight: "1.8",
    marginTop: "10px",
    display: "block",
    textTransform: "none",
  },
  "& .video-img": {
    width: "100%",
    height: "100%",
  },

  "& .bottom-text": {
    textAlign: "center",
    color: "#555555",
    display: "block",
    height: "95px",
    fontFamiy: 'poppins',
    fontSize: '20px'
  },
  "& .setting-item-button": {
    background: "#FF881A",
    width: "100%",
    padding: "2px 5px",
    borderRadius: "10px",
    textTransform: "none",
    fontFamily: 'poppins',
    height: '40px',
    fontSize: '15px'
  },
  "& .video-bottom-span": {
    color: "#555555",
    fontSize: "20px",
    marginRight: "30px",
    fontFamiy: 'poppins'
  },

  "& .video-bottom-btn": {
    background: "#FF881A",
    borderRadius: "10px",
    fontSize: "20px",
    fontFamily: 'poppins',
    textTransform: "none",
    padding: "3px 18px",
    height: 45,
  },

  "& .video-bottom-wrapper": {
    display: "flex",
  },
}));

// Customizable Area End
