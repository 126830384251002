import React from 'react'
import { Box, Button, Dialog, Divider, IconButton, Typography, styled } from "@material-ui/core";
import { Close } from "@material-ui/icons";

type ModalProps={
  onClose:()=>void,
  isActive:boolean,
  videoData:any;
}
export default function InformationModal(props: ModalProps) {
  const getFormattedDate = (gameDate: string) => {
    const date = new Date(gameDate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate
  }

  const formatFileSize = (size: string) => {
    let numberValue = parseFloat(size);

    if (numberValue < 0.1) {
      numberValue = 0.1;
    } else {
      numberValue = parseFloat(numberValue.toFixed(1));
    }

    return numberValue;
  }

    return (
        <Dialog
          data-test-id="information-modal"
          maxWidth="lg"
          open={props.isActive}
          PaperProps={{
            style: {
              border: "unset",
              margin: 'auto',
              background: "transparent",
              maxWidth: "auto"
    
            },
          }}
          BackdropProps={{
            style: {
              backdropFilter: "blur(5px)", // Adjust the pixel value to increase or decrease the blur effect
              backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: Adjust the background color and opacity
            }
          }}
        >
          <StyledFolderModal>
            <IconButton
              data-test-id="on-close-info-modal"
              style={{ cursor: "pointer" }}
              onClick={props.onClose}
              aria-label="close">
              <Close style={{ color: "#676767" }} />
            </IconButton>
            <Typography variant="h6">Video Information!</Typography>
            <Box className='informationDetails'>
            <Typography variant="body1">
              {props.videoData.team_name || ''} Vs {props.videoData.oppo_team_name ||''} -  <span data-test-id="video-file-size" className='fileSize'>{formatFileSize(props.videoData.videos_size_gb||0)}GB</span>
            </Typography>
            <Typography variant="body1">
              Video Length: {props.videoData.video_length || ''} Min
            </Typography>
            <Typography variant="body1">
              Game Date: {props.videoData.game_date && getFormattedDate(props.videoData.game_date )|| ''}
            </Typography>
            <Typography variant="body1">
              Game Type: {props.videoData.game_type || ''}
            </Typography>
            <Typography variant="body1">
              Created By: {props.videoData.role_name || ''} | {props.videoData.created_by}
            </Typography>
            <Divider style={{ width: '386px', backgroundColor: '#FF881A', margin: "24px auto",height:2 }} />
          
              <Button
                data-test-id="on-close-info-modal"
                variant="contained"
                style={{
                    backgroundColor: "#FF881A",
                    color: "#1D1D1F",
                    borderRadius: 8,
                    fontSize: 18,
                    padding: "10px",
                    gap: 8,
                    fontFamily: "Montserrat",
                    fontWeight: 600,
                  lineHeight: "22px",
                  width: "200px",
                  height: '55px',
                  marginLeft: '24px',
                  cursor: "pointer"
                }}
                onClick={props.onClose}
              >
                Back
              </Button>
        
                </Box>
          </StyledFolderModal>
        </Dialog>
      )
}

const StyledFolderModal = styled(Box)(({ theme }) => ({
    background: "#1D1D1F",
    borderRadius: "10px",
    boxShadow: "-5px 4px 12px 0px #00000040 inset",
    padding: "40px",
    position: "relative",
    height:422,
    width:626,
  
    "& .MuiPaper-root": {
      maxWidth: "800px",
      // width: "700px",
    },
  
    "& .MuiIconButton-root": {
      height: "24px",
      width: "24px",
      padding: "4px",
      position: "absolute",
      top: "16px",
      right: "16px",
    },
  
    "& .MuiTypography-h6": {
      fontFamily: "Poppins",
      fontSize: "20px",
      fontWeight: 700,
      lineHeight: "28px",
      textAlign: "center",
      color: "#CFD1D4",
      marginBottom: 32,
      
    },
    "& .informationDetails":{
        display:"flex",
        flexDirection:"column",
        gap:24,
        justifyContent: "center", 
        alignItems: "center",
    },
  
    "& .MuiTypography-body1": {
      fontFamily: "Poppins",
      fontSize: "18px",
      fontWeight: 400,
      lineHeight: "26px",
      textAlign: "center",
      color: "#676767",
      "& .fileSize":{
        fontFamily: "Poppins",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "26px",
        textAlign: "center",
        color: "#FF881A",
        letterSpacing: "-0.015em",
      }
    },
  
  }))