import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  IconButton,
  Grid,
  InputBase,
  Typography,
  Tabs,
  Tab,
  Dialog,
  Divider,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import { Close } from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import { calenderIcon, filter, filterOnHover, plus, search, teamLogo } from "./assets";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import CustomTable from "../../../components/src/UserTable";
import DropDownSp from "../../../components/src/DropDownSp.web";
import InputField from "../../../components/src/InputField.web";
import DatePicker, { DateObject } from "react-multi-date-picker";
import Loader from "../../../components/src/Loader.web";
const _ = require('lodash');

export const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiTabs: {
      root: {
        display: "inline-block",
        borderRadius: 10,
        backgroundColor: "#1D1D1F",
      },
      indicator: {
        height: 1,
        display: "none",
        backgroundColor: "#FF881A",
      },
    },
    MuiTab: {
      root: {
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: 500,
        fontFamily: "Poppins",
        letterSpacing: " -0.015em",
        textAlign: "center",
        color: "white",
        '&.Mui-selected': {
          color: "#1D1D1F",
          backgroundColor: "#FF881A",
          borderRadius: 10,
        },
        "@media (min-width:600px)": {
          minWidth: 99,
        },
      },
      textColorInherit: {
        opaciity: 1,
        backgroundColor: '#252527',
        color: "white",
        '&.Mui-selected': {
          color: "#1D1D1F",
        },
      }
    },
  }
});
// Customizable Area End

import AccountGroupsController, {
  configJSON,
  Props,
} from "./AccountGroupsController";

export default class AccountGroups extends AccountGroupsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  selectedEsportTitle = _.find(this.state.esportTitles, (item: any) => item.id + "" === this.state.addUserData.esportTitleId);
  selectedEsportTitleLabel = _.get(this.selectedEsportTitle, 'title', '');

  selectedSportTitle = this.state.sportTitles.find(item => item.id.toString() === this.state.addUserData.sportTitleId);
  selectedSportTitleLabel = this.selectedSportTitle ? this.selectedSportTitle.title : '';

  selectedEsportRole = _.find(this.state.esportRoles, (item: any) => item.id + "" === this.state.addUserData.esportRoleId);
  selectedEsportRoleLabel = _.get(this.selectedEsportRole, 'name', '');

  selectedSportPosition = _.find(this.state.sportPositions, (item: any) => item.id + "" === this.state.addUserData.sportPositionId);
  selectedSportPositionLabel = _.get(this.selectedSportPosition, 'position_title', '');

  renderTeamRoleCountRestElements = () => {
    const teamData = this.state.teamData || {marked_events: {}, role_count: []}
    let content = null;

    if (teamData && teamData.role_count && teamData.role_count.length > 1) {
      const restRoles = teamData.role_count.slice(1);

      const chunkArray = (array: Array<{name:string;count:number}>, chunkSize: number) => {
        const result = [];

        for (let i = 0; i < array.length; i += chunkSize) {
          result.push(array.slice(i, i + chunkSize));
        }
        return result;
      }
      const chunks = chunkArray(restRoles, 3);

      content = (
        <>

          {chunks.map((chunk,index) => (
            <div key={index}>

              <Box style={{ display: "flex", flexDirection: "column", gap: 8, width: "100%" }} key={`${index}-parent`}>
                {
                  chunk.map((item,index) => (
                    <Box style={{ display: "flex", justifyContent: 'space-between' }} key={`${index}-child`}>
                      <Typography component="span" className="key">{item.name}:</Typography>
                      <Typography component="span" className="value">{item.count} Users</Typography>
                    </Box>
                  ))
                }
              </Box>
            </div>
          ))}
        </>
      )
    }

    return content;
  }

  renderTeamRoleCountFirstElement = () => {
    const teamData = this.state.teamData || {marked_events: {}, role_count: []}
    let content = null;

    if (teamData && teamData.role_count && teamData.role_count.length > 0) {
      content = (
        <>
          <Box style={{ display: "flex", justifyContent: 'space-between' }}>
            <Typography component="span" className="key">{teamData.role_count[0].name}:</Typography>
            <Typography component="span" className="value">{teamData.role_count[0].count} Users</Typography>
          </Box>
        </>
      )
    }

    return content;
  }
  renderDeleteModal = () => {
    return (<Dialog
      open={this.state.deleteAccountModal}
      PaperProps={{
        style: {
          border: "unset",
          margin: 'auto',
          background: "transparent",
          maxWidth: "unset"
        },
      }}
      BackdropProps={{
        style: {
          backdropFilter: "blur(5px)", // Adjust the pixel value to increase or decrease the blur effect
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: Adjust the background color and opacity
        }
      }}
    >
      <StyledFolderModal style={{ width: '780px' }}>
        <IconButton
          data-test-id="on-close-delete-modal"
          style={{ cursor: "pointer" }}
          onClick={this.closeDeleteAccount}
          aria-label="close">
          <Close style={{ color: "#676767" }} />
        </IconButton>

        <Typography variant="h6">Delete Account!</Typography>

        <Typography variant="body1" style={{ marginTop: '24px' }}>
          Are you sure, you want to delet this Account ?
        </Typography>

        <Typography variant="body1" style={{ marginTop: '24px' }}>
        {this.state.deleteData?.Name} will be permanently removed from {this.state.teamData?.team_name}, all of their videos will be deleted, but all of their reports & data will remain.        </Typography>

        <Divider style={{ width: '496px', margin: "24px auto", backgroundColor: '#FF881A', height: 2 }} />

        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Button
            data-test-id="close-delete-modal"
            variant="outlined"
            className="delete-modal-close"
            onClick={this.closeDeleteAccount}
          >
            Cancel
          </Button>
          <Button
            data-testid="delete-account"
            variant="contained"
            style={{
              backgroundColor: "#FF1919",
              color: "#1D1D1F",
              borderRadius: 8,
              fontSize: 18,
              padding: "10px",
              gap: 8,
              fontFamily: "Montserrat",
              fontWeight: 600,
              lineHeight: "22px",
              width: "200px",
              height: '55px',
              marginLeft: '24px',
              cursor: "pointer",
              textTransform: 'none'
            }}
            onClick={this.handleClickDelete}
          >
            Delete
          </Button>
        </div>
      </StyledFolderModal>
    </Dialog>)
  }
  renderAddUserForm = () => {
    const {isEdit} = this.state;
    return <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: 912, backgroundColor: '#252527', padding: 24, borderRadius: 10 }}>
      <div className="formHeader">
        <p className="header">{isEdit ? "Edit User" : "Add User"}</p>
        <p className="subHeader">A new user will automatically be added to your team once submitted</p>
      </div>
      <p className="subForm">User Details</p>
      <Box className="form_container">
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} className="inputFieldCol" >
            <Box className="input_field_container">
              <InputField
                className="MuiFilledInput-underline inputField "
                testID="txtUserInputFirstName"
                name="firstName"
                label="First Name"
                type="text"
                value={this.state.addUserData.firstName}
                onChange={this.changeHandler}
                validationMsg={this.state.errorMessageOn.firstName}

              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} className="inputFieldCol" >
            <Box className="input_field_container">
              <InputField
                className="MuiFilledInput-underline inputField "
                testID="txtInputLastName"
                label="Last Name"
                name="lastName"
                type="text"
                value={this.state.addUserData.lastName}
                onChange={this.changeHandler}
                validationMsg={this.state.errorMessageOn.lastName}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} className="inputFieldCol" >
            <Box className="input_field_container">
              <InputField
                className="MuiFilledInput-underline inputField "
                name="username"
                label="Username"
                type="text"
                value={this.state.addUserData.username}
                onChange={this.changeHandler}
                validationMsg={this.state.errorMessageOn.username}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} className="inputFieldCol" >
            <Box className="input_field_container">
              <InputField
                className="MuiFilledInput-underline inputField "
                testID="txtUserInputEmail"
                data-testId="txtUserInputEmail"
                name="email"
                label="Email"
                type="email"
                value={this.state.addUserData.email}
                validationMsg={this.state.errorMessageOn.email}
                onChange={this.changeHandler}
              />
            </Box>
          </Grid>
          {this.state.addUserData.accountType === "Sports" ? (
            <>
              <Grid item xs={12} md={6} style={{ paddingBottom: '24px' }} className="inputFieldCol" >
                <Box className="input_field_container">
                  <DropDownSp
                    value={this.getSportTitle()}
                    label="Sports Title"
                    placeholder="Select Sport Title"
                    option={this.state.sportTitles.map((roleItem) => ({
                      value: roleItem.id + "",
                      label: roleItem.title,
                    }))}
                    name="sportTitleId"
                    handleChangeFunction={this.handleChangeSportTitle}
                    validationMsg={this.state.errorMessageOn.sportTitleId + ''} />
                </Box>
              </Grid>
              <Grid item xs={12} md={6} style={{ paddingBottom: '24px' }} className="inputFieldCol" >
                <Box className="input_field_container">
                  <DropDownSp
                    dataTest-id="sprotPositionId"

                    value={this.getSportPositionTitle()}
                    label="Position"
                    option={this.state.sportPositions.filter(element => element.sport_title_id + "" === this.state.addUserData.sportTitleId + "").map((roleItem) => ({
                      value: roleItem.id + "",
                      label: roleItem.position_title,
                    }))}
                    placeholder="Select Sport Position"
                    name="sportPositionId"
                    handleChangeFunction={this.handleChangeSportPosition}
                    validationMsg={this.state.errorMessageOn.sportPositionId + ''}
                    isDisabled={!this.state.addUserData.sportTitleId}
                  />
                </Box>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} md={6} style={{ paddingBottom: '24px' }} className="inputFieldCol" >
                <Box className="input_field_container">
                  <DropDownSp
                    value={this.getEsportTitle()}
                    label="Esports Title"
                    option={this.state.esportTitles.map((roleItem) => ({
                      value: roleItem.id + "",
                      label: roleItem.title,
                    }))}
                    placeholder="Select Esports Title"
                    name="esportTitleId"
                    handleChangeFunction={this.handleChangeEsportTitle}
                    validationMsg={this.state.errorMessageOn.esportTitleId + ''} />
                </Box>
              </Grid>
              <Grid item xs={12} md={6} style={{ paddingBottom: '24px' }} className="inputFieldCol" >
                <Box className="input_field_container">
                  <DropDownSp
                    value={this.getEsportRole()}
                    dataTest-id="gameRoleId"
                    label="In-game Role"
                    placeholder="Select In-game Role"
                    option={this.state.esportRoles.filter(element => element.esport_id + "" === this.state.addUserData.esportTitleId + "").map((roleItem) => ({
                      value: roleItem.id + "",
                      label: roleItem.name,
                    }))}
                    name="esportRoleId"
                    handleChangeFunction={this.handleChangeEsportRole}
                    isDisabled={!this.state.addUserData.esportTitleId}
                    validationMsg={this.state.errorMessageOn.esportRoleId + ''} />
                </Box>
              </Grid>
            </>
          )}
          <Grid item xs={12} md={6} className="inputFieldCol" >
            <Box className="input_field_container">
              <Typography className="lableStyle">Date of Birth</Typography>
              <DatePicker
                data-test-id="datepickerDOB"
                minDate={this.getDateRange().minDate}
                maxDate={this.getDateRange().maxDate}
                format="MM/DD/YYYY"
                value={new Date(this.state.addUserData.dateOfBirth)}
                onChange={this.handleDateInputField}
                placeholder="MM/DD/YYYY"
                render={(value, openCalendar) => <div
                  className="input-date"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input readOnly value={value || ""} onClick={openCalendar} />
                  <Button className="calendar-icon">
                    <img src={calenderIcon} onClick={openCalendar}/>
                  </Button>
                </div>}
                inputClass="custom-input"
                arrow={false}
                currentDate={new DateObject(new Date(this.state.initialDateOpen))}
              />

              <Box>
                {<span className="validationMsg" style={{ visibility: this.state.errorMessageOn.dateOfBirth ? "visible" : "hidden" }}>{this.state.errorMessageOn.dateOfBirth ? this.state.errorMessageOn.dateOfBirth : "error"}</span>}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} className="inputFieldCol" >
            <Box className="input_field_container">
              <Typography className="lableContainer" children="Gender" />
              <Box className="genderContainer">
                <Button
                  variant="contained"
                  data-test-id='gender-btn-btn'
                  className={`gender-btn ${this.state.isMaleBtnChecked
                    ? "gender-btn-checked"
                    : "gender-btn-unchecked"
                    }`}
                  onClick={() => this.handleGender("Male")}
                >
                  M
                </Button>
                <Button
                  data-test-id='gender-btn-btn'
                  variant="contained"
                  className={`gender-btn ${this.state.isFemaleBtnChecked
                    ? "gender-btn-checked"
                    : "gender-btn-unchecked"
                    }`}
                  onClick={() => this.handleGender("Female")}
                >
                  F
                </Button>
                <InputField
                  data-testId="inputChangeGender"
                  isHideMsgError={true}
                  className="MuiFilledInput-underline gender-input-box"
                  onChange={(event: { target: { value: string } }) => this.handleChangeGender(event)}
                  placeholder="Other please specify"
                  type="text"
                  value={(!this.state.isMaleBtnChecked && !this.state.isFemaleBtnChecked) ? this.state.addUserData.gender : ''}
                />

              </Box>

              <Box>
                {<span className="validationMsg" style={{ visibility: this.state.errorMessageOn.gender ? "visible" : "hidden" }}>{this.state.errorMessageOn.gender ? this.state.errorMessageOn.gender : "error"}</span>}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <p className="subForm">Guardian Details <span className="subHeader">(Only for ages 13-18)</span></p>
      <Box className="form_container">
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} className="inputFieldCol" >
            <Box className="input_field_container">
              <InputField
                className="MuiFilledInput-underline inputField "
                testID="txtInputGuardianFirstName"
                name="guardianFirstName"
                label="First Name"
                type="text"
                value={this.state.addUserData.guardianFirstName}
                onChange={this.changeHandler}
                validationMsg={this.state.errorMessageOn.guardianFirstName}

              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} className="inputFieldCol" >
            <Box className="input_field_container">
              <InputField
                className="MuiFilledInput-underline inputField "
                testID="txtInputGuardianLastName"
                label="Last Name"
                name="guardianLastName"
                type="text"
              value={this.state.addUserData.guardianLastName}
              onChange={this.changeHandler}
              validationMsg={this.state.errorMessageOn.guardianLastName}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} className="inputFieldCol" >
            <Box className="input_field_container">
              <InputField
                className="MuiFilledInput-underline inputField "
                data-testId="txtInputGuardianEmail"
                name="guardianEmail"
                label="Email"
                type="email"
              value={this.state.addUserData.guardianEmail}
              validationMsg={this.state.errorMessageOn.guardianEmail}
              onChange={this.changeHandler}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} className="inputFieldCol" >
            <Box className="input_field_container">
              <Typography className="lableContainer" children="Gender" />
              <Box className="genderContainer">
                <Button
                  variant="contained"
                  data-test-id='gender-btn-btn'
                  className={`gender-btn ${this.state.isGuardianMaleBtnChecked
                    ? "gender-btn-checked"
                    : "gender-btn-unchecked"
                    }`}
                onClick={() => this.handleGuardianGender("Male")}
                >
                  M
                </Button>
                <Button
                  data-test-id='gender-btn-btn'
                  variant="contained"
                  className={`gender-btn ${this.state.isGuardianFemaleBtnChecked
                    ? "gender-btn-checked"
                    : "gender-btn-unchecked"
                    }`}
                onClick={() => this.handleGuardianGender("Female")}
                >
                  F
                </Button>
                <InputField
                  data-testId="inputChangeGuardianGender"
                  isHideMsgError={true}
                  className="MuiFilledInput-underline gender-input-box"
                  onChange={(event:{target:{value:string}}) => this.handleChangeGuardianGender(event)}
                  placeholder="Other please specify"
                  type="text"
                  value={(!this.state.isGuardianMaleBtnChecked && !this.state.isGuardianFemaleBtnChecked) ? this.state.addUserData.guardianGender : ''}

                />

              </Box>

              <Box>
              {<span className="validationMsg" style={{ visibility: this.state.errorMessageOn.guardianGender ? "visible" : "hidden" }}>{this.state.errorMessageOn.guardianGender ? this.state.errorMessageOn.guardianGender : "error"}</span>}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Button
            data-test-id="create-btn"
            variant="contained"
            style={{
              backgroundColor: "#FF881A",
              color: "#1D1D1F",
              borderRadius: 8,
              fontSize: 18,
              padding: "10px",
              gap: 8,
              fontFamily: "Montserrat",
              fontWeight: 600,
              lineHeight: "22px",
              width: "200px",
              height: '55px',
              marginLeft: '24px',
              cursor: "pointer",
              textTransform: 'none',
              marginTop:"10px",
            }}
          onClick={this.state.isEdit ? this.editAccount : this.createAccount}
          disabled={this.state.addUserData.email===""}

          >
            {this.state.isEdit  ? "Edit" : "Create"}
          </Button>
    </div>
  }
  renderInviteUser = () => {
    return <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: 712, backgroundColor: '#252527', padding: 24, borderRadius: 10 }}>
      <p className="header">Add User</p>
      <p className="subHeader">A new user will automatically be added to your team once submitted</p>
      <Box className="form_container">
        <Grid container spacing={3}>
          <Box className="input_field_container" style={{ marginTop: 8,padding:12 }}>
            <InputField
              className="MuiFilledInput-underline inputField "
              testID="txtInputInviteEmail"
              data-testId="txtInputInviteEmail"
              name="invitationEmail"
              label="Email"
              type="email"
              value={this.state.addUserData.invitationEmail}
              validationMsg={this.state.errorMessageOn.invitationEmail}
              onChange={this.changeHandler}
            />
          </Box>


        </Grid>
      </Box>
      <Button
            data-test-id="invite-btn"
            variant="contained"
            style={{
              backgroundColor: "#FF881A",
              color: "#1D1D1F",
              borderRadius: 8,
              fontSize: 18,
              padding: "10px",
              gap: 8,
              fontFamily: "Montserrat",
              fontWeight: 600,
              lineHeight: "22px",
              width: "200px",
              height: '55px',
              marginLeft: '24px',
              cursor: "pointer",
              textTransform: 'none'
            }}
          onClick={this.handleInviteAccount}
          disabled={this.state.addUserData.invitationEmail===""}
          >
           Send Invite
          </Button>
    </div>
  }
  renderDetailsCard = () => {
    const teamData = this.state.teamData || {marked_events: {}, role_count: []}
    const { missed, missed_op, made } = teamData?.marked_events || {}
    return <div className="card">
      <img src={teamData.team_image || teamLogo} height={100} width={100} style={{borderRadius:'10%'}} />
      <div className="details">
  <div style={{ display: "flex", flexDirection: "column", gap: 8, width: "100%" }}>
    <div style={{ display: "flex", justifyContent: 'space-between' }}>
      <span className="key">Team Name:</span>
      <span className="value">{teamData.team_name || ''}</span>
    </div>
    <div style={{ display: "flex", justifyContent: 'space-between' }}>
      <span className="key">No. of Users:</span>
      <span className="value">{teamData.user_size ? `${teamData.user_size} Users`: ''}</span>
    </div>
    {this.renderTeamRoleCountFirstElement()}
    </div>
    <Box className="divider"></Box>
  <div style={{ display: "flex", flexDirection: "column", gap: 8, width: "100%", justifyContent: "center" }}>
    {this.renderTeamRoleCountRestElements()}
    </div>
  <div className="divider"></div>
  <div style={{ display: "flex", flexDirection: "column", gap: 8, width: "100%", justifyContent: "center" }}>
    <div style={{ display: "flex", justifyContent: 'space-between' }}>
      <span className="key">Total Marked Events:</span>
      <span className="value">
      {(missed || 0) + (missed_op || 0) + (made || 0)} 
        {" "}
        (
        <span style={{ color: 'green' }}>{missed || 0}</span> 
        {" | "} 
        <span style={{ color: 'red' }}>{made || 0}</span> 
        {" | "} 
        <span style={{ color: 'orange' }}>{missed_op || 0}</span>
        )
      </span>
    </div>
    <div style={{ display: "flex", justifyContent: 'space-between', alignItems: "center" }}>
      <span className="key">Most Common Difficulty:</span>
      <span className="value">{teamData.most_common}</span>
    </div>
  </div>
</div>
    </div>
  }
  renderDialogForm = () => {
    const {isEdit} = this.state
    return <Dialog
      open={this.state.openCreateModal}
      data-test-id="create-modal"
      PaperProps={{
        style: {
          border: "unset",
          margin: 'auto',
          background: "transparent",
          maxWidth: "unset"
        },
      }}
      BackdropProps={{
        style: {
          backdropFilter: "blur(5px)", // Adjust the pixel value to increase or decrease the blur effect
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: Adjust the background color and opacity
        }
      }}
    >
      <StyledModal style={{ width: '85vw' }}>
        <Button
          data-test-id="on-close-modal"
          style={{ cursor: "pointer", float: "right" }}
          onClick={this.closeCreateModal}
          aria-label="close">
          <Close style={{ color: "#676767" }} />
        </Button>

        <TestFieldStyle>
          {this.renderAddUserForm()}
          { !isEdit && (<><p className="header">Or</p>{this.renderInviteUser()}
          </>)}
        
        </TestFieldStyle>

      </StyledModal>
    </Dialog>
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { accountsList, currentPage, rowsPerPage, totalPages, initialLoading } = this.state;
    const paginatedData = accountsList.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);
    return (
      <ThemeProvider theme={theme}>
        <Loader loading={initialLoading} />

        {this.renderDialogForm()}

        <NavigationMenu navigation={this.props.navigation} id="NavigationMenu" currentTabProp="team" />
        <StyledGridItem>
          {this.renderDeleteModal()}
          <Grid container className="container">
            <Grid item xl={5} lg={5} md={5} sm={5} xs={5} className="grid" style={{opacity: this.getUserType().role==='end user' ? 0.5 : 1}}>
              <Box className="gridItem1">
                <Typography style={{ width: '200px' }}>Invite / Create User:</Typography>
                <Box style={{ display: "flex" }}>
                  <Button data-test-id="create-user" onClick={this.handleOpenCreateModal} disabled={this.getUserType().role==='end user'}>
                    <img src={plus} className="icons" />
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid item xl={7} lg={7} md={7} sm={7} xs={7} className="grid2">
              <Box className="inputbase">
                <InputBase
                  data-test-id="searchTestId"
                  onChange={(e:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => this.handleSearchName(e.target.value)}
                  className="gridItem2"
                  placeholder="Search By Name..."
                  inputProps={{ 'aria-label': 'Search By Name...' }}

                />
                <Box>
                  <IconButton data-test-id="searchIconTest" type="button" style={{ padding: '10px' }} aria-label="search" onClick={this.handleOnSearch}>
                    <img src={search} className="icons" />
                  </IconButton>
                </Box>
              </Box>

              <Box>
                <Button style={{ border: '1px solid #FF881A' }} data-test-id="filter-btn" className="gridItem2 button-hover">
                  Filter
                  <img src={filter} height={32} width={32} className="img icons default" />
                  <img src={filterOnHover} height={32} width={32} className="img icons on-hover" />
                </Button>
              </Box>

            </Grid>
            <Box style={{ paddingTop: 40, display: "flex", width: "100%", justifyContent: "space-between" }}>
              {this.renderDetailsCard()}
              <Box style={{ display: "flex", gap: 10, alignItems: "center" }}>
                <Typography className="label1">Showing:</Typography>
                <Tabs
                  value={this.state.showing} onChange={this.onShowingChange}
                  data-test-id="tabs">
                  <Tab label="Staff" value="Staff" />
                  <Tab label="Players" value="player" />
                </Tabs>
              </Box>
            </Box>
          </Grid>
          <Box style={{ padding: "8px 65px 0px 65px" }}>
            <CustomTable  data-test-id='custom-table-id' columns={this.getColumns(this.state.showing)} data={this.getUserData(this.state.showing)} onEdit={this.handleEditUser} onDelete={this.handleDeleteUser} />
          </Box>
          <StyledPagination
          data-test-id={"user-table-pagination"}
          count={totalPages}
          page={currentPage}
          onChange={this.handleChangePage}
        />
          </StyledGridItem>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const StyledPagination = styled(Pagination)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  backgroundColor: 'transparent',
  fontSize: 20,
  margin: "50px 0px",
  '& .MuiPaginationItem-root': {
    fontSize: 20,
    color: "#CFD1D4",
  },
  '& .Mui-selected': {
    color: "#E87C18",
  },
  '& .MuiPaginationItem-page.Mui-selected:hover': {
    backgroundColor: 'transparent',
  }
}));

export const TestFieldStyle = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: 32,
  justifyContent: "space-evenly",

  "& .rmdp-disabled.rmdp-day": {
    color: "#7F7F7F !important",
    cursor: 'not-allowed',
  },

  "& .signup_form_container": {
    padding: '16px 34px',
    background: "#252527",
    boxShadow: "-11px 0px 13px 0px #00000040 inset",
    flex: "0 0 48%",
    maxWidth: "920px",
    "@media screen and (max-width: 1000px)": {
      maxWidth: "unset",
      flex: 1
    }
  },
  "& .icon_and_form_container": {
    display: 'flex',
    flexDirection: 'column',
    gap: '34px'

  },
  "& .icon_image_container": {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'

  },

  "& .alertContainer": {
    position: "absolute",
    bottom: 0,
    padding: "8px",
    background: "#1D1D1F",
    borderRadius: "0 10px 0 0"
  },

  "& .input_field_container .select-container .css-1okebmr-indicatorSeparator": {
    display: "none"
  },
  "& .signup_image_container": {
    width: '100%',
  },
  "& .genderContainer": {
    display: "flex",
    alignItems: "center",
    gap: '16px'
  },

  "& .gender-btn": {
    borderRadius: "10px",
    boxShadow: " 4px 4px 4px 0px #00000040 inset",
    fontSize: "16px",
    height: "34px",
    width: "34px",
    minWidth: "auto",
    cursor: "pointer",
    borderBottom: "1px solid #FF881A",
  },
  "& .gender-btn-checked": {
    background: "#FF881A",
  },
  "& .gender-btn-unchecked": {
    background: "#1D1D1F",
    color: "#CFD1D4",
  },

  "& .genderContainer .MuiBox-root": {
    width: "100%",
    "& .MuiInputBase-root input::placeholder": {
      opacity: 1,
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: "16px",
      color: "#CFD1D4"
    }
  },
  "& .validationMsg": {
    fontFamily: 'Poppins',
    color: '#FF1919',
    float: 'left',
    fontWeight: 400,
    fontSize: '14px',
    margin: '6px 0'
  },
  "& .inputFieldCol": {
    padding: "0 12px"
  },
  // date picker css start
  "& .rmdp-top-class": {
    width: '300px'
  },
  "& .rmdp-calendar": {
    border: '1px solid #FF881A',
    backgroundColor: '#1D1D1F',
    boxShadow: 'none',
    width: '100%'
  },
  "& .MuiIconButton-root": {
    padding: "10px"
  },
  "& .rmdp-day-picker > div:first-child": {
    width: '100%',
  },
  "& .rmdp-day": {
    color: '#CFD1D4',
    fontSize: '14px',
    fontFamily: `"Poppins", sans-serif`
  },
  "& .rmdp-week-day": {
    color: '#CFD1D4',
    fontSize: '14px',
    fontFamily: `"Poppins", sans-serif`
  },
  "& .rmdp-header-values": {
    color: '#FF881A',
    fontWeight: 700
  },
  "& .rmdp-day.rmdp-today span": {
    backgroundColor: '#FBB26E',
  },
  "& .rmdp-month-picker, .rmdp-year-picker": {
    color: '#FF881A',
    fontWeight: 700,
    backgroundColor: '#1D1D1F'
  },
  "& .rmdp-day.rmdp-selected span:not(.highlight)": {
    backgroundColor: '#FF881A',
    boxShadow: 'none',
    fontWeight: 500
  },
  "& .rmdp-day:not(.rmdp-disabled, .rmdp-day-hidden) span:hover": {
    backgroundColor: '#FF881A'
  },
  "& .rmdp-arrow": {
    border: 'solid #FF881A',
    borderWidth: '0 2px 2px 0'
  },
  "& .rmdp-arrow-container:hover": {
    backgroundColor: "transparent",
    boxShadow: 'none',
    border: 'none',
    borderWidth: 'none'
  },
  "& .MuiTextField-root , .select-container": {
    height: "53px"
  },
  "& .rmdp-container ": {
    width: "100%",
    color: '#CFD1D4',
    height: '53px',
    "& .input-date": {
      background: "#1D1D1F",
      boxShadow: "4px 4px 4px 0px #00000040 inset",
      borderRadius: "10px",
      borderBottom: "1px solid #FF881A"
    },
    "& .input-date input": {
      width: "100%",
      height: "50px",
      background: "transparent",
      border: "unset",
      padding: "7 7px",
      color: "#CFD1D4",
      fontSize: "16px",
      fontFamily: "Poppins",
      fontWeight: 400
    },
    "& .input-date input:focus-visible": {
      outline: "unset"
    }
  },
  "& .icon-tabler-calendar-event ": {
    right: '16px !important'
  },
  "& .rmdp-input": {
    height: '39px',
    width: "100%",
    color: '#CFD1D4',
    background: '#1D1D1F',
    border: 'none',
    borderBottom: "1px solid #FF881A",
    boxShadow: "4px 4px 4px 0px #00000040 inset",
    borderRadius: "10px",
    margin: '1px 0',
    padding: '7px 5px',
    fontSize: '16px',
    fontWeight: 400
  },

  // datepicker css end
  "&  .signup_page_footer_container": {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  "& .login_container": {
    width: '100%',
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: '16px'

  },


  "& .backgroundImg": {
    width: "100%",
    height: "100%",
    objectFit: 'cover'
  },

  "& .lableContainer": {
    color: "#676767",
    fontWeight: 400,
    fontSize: "20px",
  },
  "& .bottom-link-text": {
    color: "#FF881A",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "Poppins",
    cursor: "pointer"
  },

  "& .bottom-text": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "16px",
    color: "#CFD1D4",
    paddingRight: "1%",
  },
  "& .games-company-container": {
    display: "flex",
    justifyContent: "center",
    gap: '32px'
  },

  '& .back-btn': {
    color: "#FF881A",
    backgroundColor: "#252527",
    marginTop: "32",
    border: '1px solid #FF881A',
    fontWeight: 400,
    fontFamily: "Poppins",
    fontSize: "18px",
    maxWidth: "150px",
    width: "100%",
    textTransform: "none"
  },
  "& .signupBtn": {
    backgroundColor: "#FF881A",
    color: "#1D1D1F",
    marginTop: "32",
    fontWeight: 400,
    fontFamily: "Poppins",
    fontSize: "18px",
    maxWidth: "150px",
    width: "100%",
    textTransform: "none"
  },

  "& .btn-below-line": {
    border: "1px solid #676767",
    width: "348px",
    top: "968px",
    margin: "auto",
    marginTop: "2%",
  },

  '& .lableStyle': {
    color: "#676767",
    fontWeight: 400,
    fontSize: '1.3rem'
  }
});
export const StyledModal = styled(Box)(({ theme }) => ({
  background: "#1D1D1F",
  boxShadow: "-5px 4px 12px 0px #00000040 inset",
  padding: "40px",
  borderRadius: "10px",
  position: "relative",


  "& .MuiIconButton-root": {
    height: "24px",
    width: "24px",
    padding: "4px",
    top: "16px",
    right: "16px",
    position: "absolute",
  },
  "& .MuiPaper-root": {
    maxWidth: "1796px",
  },

  "& .MuiTypography-h6": {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "700",
    marginBottom: 32,
    lineHeight: "28px",
    textAlign: "center",
    color: "#CFD1D4",
  },

  "& .MuiTypography-body1": {
    fontFamily: "Poppins",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "26px",
    color: "#676767",
    textAlign: "left"
  },
  "& .formHeader": {
    display: "flex",
    flexDirection: "column",
    gap: 6,
    alignItems: "center"
  },
  "& .header": {
    fontSize: 24,
    fontWeight: 500,
    fontFamily: "Poppins",
    lineHeight: "36px",
    color: "#CFD1D4"
  },
  "& .subHeader": {

    fontSize: 20,
    fontWeight: 400,
    fontFamily: "Poppins",
    lineHeight: "36px",
    color: "#676767"
  },
  "& .subForm": {

    fontSize: 20,
    fontWeight: 400,
    fontFamily: "Poppins",
    lineHeight: "36px",
    color: "#CFD1D4"
  },

}))

export const StyledGridItem = styled('div')(({ theme }) => ({
  "& .button-hover .on-hover": {
    display: 'none',
  },

  "& .card": {
    border: "1px solid #FF881A",
    padding: 16,
    borderRadius: 10,
    display: "flex",
    alignItems: "center",
    gap: 32,
    width: "100%",
    maxWidth:"94.75rem",
    backgroundColor: '#252527',
    boxSizing:'border-box'
  },
  "& .details": {
    display: "flex",
    justifyContent: "space-between",
    width:"100%"
  },
  "& .divider": {
    height: 100,
    width: 3,
    color: "rgb(255, 136, 26)",
    backgroundColor: "rgb(255, 136, 26)",
    margin: "0px 16px 0px 16px",
    opacity: "50%"
  },

  "& .key": {
    //styleName: text-base/font-normal;
    fontFamily: "Poppins",
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "24px",
    textAlign: "left",
    color: "#676767 !important",

  },
  "& .value": {
    ontFamily: "Poppins",
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "24px",
    textAlign: "right",
    color: "#CFD1D4 !important",
  },
  "& .label1": {
    fontSize: 16,
    fontWeight: 500,
    color: "#CFD1D4",
    fontFamily: "Poppins",
    lineHeight: "24px",
  },
  "& .button-hover:hover": {
    backgroundColor: '#FF881A',
    color: "#1D1D1F",

    "& .on-hover": {
      display: 'block',
    },

    "& .default": {
      display: 'none'
    }
  },

  '& .container': {
    width: "93vw",
    margin: "auto",
    marginTop: 20,
    marginBottom: 40,
  },
  '& .grid': {

  },
  '& .grid2': {
    display: "flex",
    alignItems: "center",
    gap: 24,
    justifyContent: "end",
  },
  '& .gridItem1': {
    display: "flex",
    border: '1px solid #FF881A',
    flex: 1,
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 30px",
    paddingLeft: 20,
    borderRadius: 10,
    fontSize: 20,
    width: "34.4rem",
    color: "#CFD1D4",
    backgroundColor: "252527",
    [theme.breakpoints.down('lg')]: {
      fontSize: 18,
      width: "59%",
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      width: "49%",
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      width: "39%",
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
      width: "29%",
    },
  },
  '& .gridItem2': {
    borderRadius: 10,
    fontSize: 18,
    padding: 18,
    width: '100%',
    color: "#CFD1D4",
    backgroundColor: "252527",
    textTransform: "none",
    [theme.breakpoints.down('lg')]: {
      fontSize: 16,
      padding: 14,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      padding: 12,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      padding: 10,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
      padding: 8,
    },
  },
  '& .inputbase': {
    width: "59%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 10,
    fontSize: 20,
    color: "#CFD1D4",
    backgroundColor: "252527",
    textTransform: "none",
    [theme.breakpoints.down('lg')]: {
      fontSize: 18,
      // width:"50%",
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
      // width:"40%",
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      // width:"30%",
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
      // width:"20%",
    },
  },
  '& .img': {
    marginLeft: 10,
  },
  '& .icons': {
    [theme.breakpoints.down('lg')]: {
      width: 40,
    },
    [theme.breakpoints.down('md')]: {
      width: 30,
    },
    [theme.breakpoints.down('sm')]: {
      width: 20,
    },
    [theme.breakpoints.down('xs')]: {
      width: 10,
    },
  }
}));
export const StyledFolderModal = styled(Box)(({ theme }) => ({
  background: "#1D1D1F",
  borderRadius: "10px",
  boxShadow: "-5px 4px 12px 0px #00000040 inset",
  padding: "40px",
  position: "relative",
  "& .delete-modal-close":{
    borderRadius: 8,
    color: "#FF881A",
    borderColor: "#FF881A",
    fontFamily: "Montserrat",
    fontSize: 18,
    width: "200px",
    padding: "10px 16px 10px 16px",
    gap: 8,
    fontWeight: 600,
    textTransform: 'none',
    lineHeight: "22px",
    height: '55px',
    cursor: "pointer",
  
},
  "& .MuiPaper-root": {
    maxWidth: "800px",
    // width: "700px",
  },

  "& .MuiIconButton-root": {
    height: "24px",
    width: "24px",
    padding: "4px",
    position: "absolute",
    top: "16px",
    right: "16px",
  },

  "& .MuiTypography-h6": {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: "28px",
    textAlign: "center",
    color: "#CFD1D4",
    marginBottom: 32,
  },

  "& .MuiTypography-body1": {
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "26px",
    textAlign: "center",
    color: "#676767",
    // margin: "24px auto",
    // width: "700px"
  },

}))
// Customizable Area End
