import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  styled,
  Modal,
} from "@material-ui/core";
import { Item } from "../../../blocks/cfcustomkeyboards/src/CreateKeyboardsController";

interface Props {
  id?: number;
  name?: string;
  created_at?: string;
  created_by?: string;
}

const Wrapper = styled(Box)(({ theme }) => ({
  textTransform: "none",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  marginBottom: 10,

  "& .row": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    margin: 1
  },

  "& .bory-row": {
    backgroundColor: "#1D1D1F",
    borderRadius: '10px'
  },

  "& .center": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  "& .header-label": {
    color: "#676767",
    textAlign: "center"
  },

  "& .body-label": {
    color: "#CFD1D4",
    textAlign: "center"
  },
}));

const columns = [
  { label: "#", required: false, flex: 1},
  { label: "Keyboard Name", required: false, flex: 3},
  { label: "Position Assigned", required: false, flex: 3},
  { label: "Default Keyboard for (position)", required: false, flex: 5},
  { label: "Created In", required: false, flex: 3},
  { label: "Created By", required: false, flex: 3},
  { label: "M-OP Change %", required: false, flex: 3},
  { label: "Made Change %", required: false, flex: 3},
  { label: "Missed Change %", required: false, flex: 3},
]



const SummaryTable = (props: Props) => {

  const {id, name, created_at, created_by} = props;
  const values = [
    id, name, "All", "Forward", created_at, created_by, "", "", ""
  ]

  return (
    <Wrapper>
      <Box className="row">
        {columns.map(columElm => (
          <Box className="center" style={{
            flex: columElm.flex,
            padding: 10
          }}>
            <Typography className="header-label">{columElm.label}</Typography>
          </Box>
        ))}
      </Box>
      <Box className="row bory-row">
        {columns.map((columElm ,index) => (
          <Box className="center" style={{
            flex: columElm.flex,
            padding: 10,
            overflow: "hidden"
          }}>
            <Typography className="body-label">{values[index] || "-"}</Typography>
          </Box>
        ))}
      </Box>
    </Wrapper>
  );
};

export default SummaryTable;
