export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const flagIcon = require("../assets/flag_icon.svg")
export const editIcon = require("../assets/edit_icon.svg")
export const clockIcon = require("../assets/clock_icon.svg")
export const firstLayoutActive = require('../assets/first_layout_active.png')
export const secondLayoutInactive = require('../assets/second_layout_inactive.png')
export const searchIcon = require("../assets/search_icon.svg")
export const previewBg = require("../assets/preview_bg.png")
export const infoIcon = require("../assets/info_icon.svg")
export const previewText = require("../assets/preview_text.png")
export const arrowDown = require("../assets/arrow_down.svg")
export const arrowUp = require("../assets/arrow_up.svg")
export const defaultTeamIcon = require('../assets/defaultTeamIcon.png')
export const customKeyboardEditIcon = require("../assets/custom_kb_edit_icon.svg")
export const deleteIcon = require("../assets/delete_icon.svg")
export const rowEditIcon = require("../assets/row_edit_icon.svg")
export const rowInfoIcon = require("../assets/row_info_icon.svg")
export const statIcon = require("../assets/stat_icon.svg")
export const duplicateIcon = require('../assets/duplicate_icon.svg')
export const createKbGrIcon = require("../assets/create_kb_gr_icon.png")
export const createCustomKbIcon = require("../assets/create_custom_kb_icon.png")
export const closeIcon = require("../assets/close_icon.svg")
export const headerInfoIcon = require('../assets/header_info_icon.svg')
export const defaultTeamImg = require('../assets/default_team_img.png')
export const faTeamImg = require('../assets/fa_team_img.png')
export const faTrainingGroupImg = require('../assets/fa_training_group_img.png')
export const faOverwatchImg = require('../assets/fa_overwatch_img.png')
export const allStaffImg = require('../assets/all_staff_img.png')
export const allPositionImg = require('../assets/all_position_img.png')
export const teamSelected = require('../assets/team_selected.svg')
