import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Typography,
    styled,
    Modal,
} from "@material-ui/core";
import { Item, ItemGroup } from "../../../blocks/cfcustomkeyboards/src/CreateKeyboardsController";
import CheckBoxMirrorActions from "./CheckBoxMirrorActions";

interface Props {
    show: boolean;
    header: string;
    title: string;
    onClose: Function;
}

const Wrapper = styled(Modal)(({ theme }) => ({
    textTransform: "none",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",

    "& .modal-body": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "50%",
        backgroundColor: "#252527",
        borderRadius: 10,
        overflow: "hidden",
        padding: 30,
    },

    "& .header": {
        textAlign: "center",
        color: "white",
        fontSize: 20,
    },

    "& .title": {
        textAlign: "center",
        color: "white",
        fontSize: 20,
        marginTop: 5,
        marginBottom: 20,
    },

    "& .close-btn": {
        textTransform: "none",
        backgroundColor: "#FF881A",
        borderRadius: 8,
        width: 150,
        height: 50,
        fontSize: 20
    },
}));


const InformModal = (props: Props) => {
    const {show, header, title, onClose} = props;
    return (
        <Wrapper open={show}>
            <Box className="modal-body" >
                <Typography className="header">{header}</Typography>
                <Typography className="title">{title}</Typography>
                <Button className="close-btn" onClick={() => onClose()}>Close</Button>
            </Box>
        </Wrapper>
    );
};

export default InformModal;
