import React from "react";

// Customizable Area Start
import { Box,styled,Modal, Button, Checkbox, IconButton, Typography } from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';
import { check,checkedBox ,checkedTick} from "../../../../packages/blocks/videos/src/assets";
import PrivacyPolicy from "./PrivacyPolicy.web";

// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      onAcceptPress = () => {},
      onClosePress = () => {},
      onAcknowledgePress = () => {},
    } = this.props;
    return(
      <>
        <Modal
          open={this.props.showModal}
          onClose={this.handleClose}
          data-test-id='modalClose'
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
          style={{ ...webStyles.termsContainer, scrollbarWidth: 'none' }}
        >
          <Box
            style={{
              backgroundColor: '#1D1D1F',
              padding: '30px',
              border: 'none',
              borderWidth: 0,
            }}
            data-test-id="wrapper"
          >
            <Box style={webStyles.titleContainer}>
              <Box style={{ width: '98%', display: 'flex', justifyContent: 'center' }}>
                <Typography data-test-id='title' style={webStyles.title}>
                  Terms and Conditions & Privacy Policy
                </Typography>
              </Box>
              <Box
                style={{
                  width: '1.5%',
                  alignItems: 'flex-end',
                  padding: 0,
                  display: 'flex',
                }}
              >
                <IconButton style={{ position: 'absolute', top: 8, right: 8 }}>
                  <ClearIcon
                    style={{ color: '#676767' }}
                    data-test-id='crossBtn'
                    onClick={() => onClosePress()}
                  />
                </IconButton>
              </Box>
            </Box>
            <Box style={webStyles.subtitleContainer}>
            
            </Box>
            

            <Box sx={{ ...webStyles.TermsConditionWrapper }}>
              <Box sx={{ ...webStyles.termsContent }}>
              <Typography
                  style={{ ...webStyles.efectiveDateTitle,fontSize:20 }}
                >
                 Terms & Conditions
                  <br /><br />
                </Typography>
                <Typography
                  style={{ ...webStyles.efectiveDateTitle }}
                >
                  Effective Date:
                  {this.CurrentDate()}
                  <br /> <br />
                </Typography>
                <Typography
                  data-test-id='description'
                  style={{ ...webStyles.termText }}
                >
                  {this.state.termAndCondition?.description}
                </Typography>
              </Box>

              <Box style={{ width: 10 }} />

              <Box sx={{ ...webStyles.termsContent }}>
              <Typography
                  style={{ ...webStyles.efectiveDateTitle,fontSize:20 }}
                >
                  Privacy Policy
                  <br /> <br />
                </Typography>
                <Typography
                  style={{ ...webStyles.efectiveDateTitle }}
                >
                  Effective Date:
                  {this.CurrentDate()}
                  <br /> <br />
                </Typography>
                <Typography
                  data-test-id='description'
                  style={{ ...webStyles.termText }}
                >
                  {this.state.privacy?.description}
                </Typography>
              </Box>
            </Box>


            {this.props.showAcceptButton ? (
              <>
                <Box
                  style={{ display: 'flex', flexDirection: "row", justifyContent: 'center', marginTop: '30px' }}
                >
                  <Box style={webStyles.checkboxStyle}>
                    <Checkbox
                      data-test-id='BpCheckbox'
                      disableRipple
                      checked={this.state.isChecked}
                      checkedIcon={<BpIcon />}
                      icon={
                        <BpCheckedIcon
                          style={{
                            border: '3px solid #676767',
                            backgroundImage: `url(${check})`,
                          }}
                        />
                      }
                      inputProps={{ 'aria-label': 'Checkbox demo' }}
                      onClick={this.handleTermsCheckBox}
                    />
                    <Typography
                      style={{
                        fontSize: '16px',
                        fontFamily: 'Poppins',
                        color: '#676767',
                      }}
                    >
                      I acknowledge that I have read and accept the Terms and Conditions & Privacy Policy.
                    </Typography>
                  </Box>
                </Box>
                <Box
                  style={{ display: 'flex', backgroundColor: "#1D1D1F", justifyContent: 'center', marginTop: '30px' }}
                >
                  <Button
                    variant='contained'
                    data-test-id='termsAccept'
                    style={{ ...webStyles.bttnAccept, textTransform: 'none' }}
                    onClick={() => onAcceptPress(this.state.isChecked)}
                  >
                    Accept
                  </Button>
                </Box>
              </>
            ) : (
              <Box
                style={{ display: 'flex', backgroundColor: "#1D1D1F", justifyContent: 'center', marginTop: '30px' }}
              >
                <Button
                  variant='contained'
                  data-test-id='termsAcknowledge'
                  style={{ ...webStyles.bttnAccept, textTransform: 'none' }}
                  onClick={() => onAcknowledgePress()}
                >
                  Acknowledge
                </Button>
              </Box>
            )}
          </Box>
        </Modal>
      </>
);
    // Customizable Area End
  }
}

// Customizable Area Start
const BpIcon = styled("span")({
  borderRadius: 5,
  width: 16,
  height: 16,
  backgroundColor: "transparent", 
  backgroundImage:`url(${checkedBox})`,
  backgroundRepeat:"no-repeat",
  backgroundPosition: "center",
  border:"3px solid #676767",
  padding:1,
  "&::before": {
    display: "block",
    width: 16,
    height: 16,
    color: "black",
    content: '""',
  },
});

export const BpCheckedIcon = styled(BpIcon)({
  borderRadius: 5,
  width: 16,
  height: 16,
  backgroundColor: "transparent", 
  backgroundRepeat:"no-repeat",
  backgroundPosition: "center",
  padding:1,
  "&::before": {
    display: "block",
    width: 16,
    height: 16,
    color: "black",
    content: '""',
  },
});
export const webStyles = {
  checkboxStyle: {
    width: "65%",
    color: "#676767",
    borderBottom: "1px solid #FF881A",
    textalign: 'center',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom:15
  },
  termsContainer: {
    width: '68%',
    height: '715px',
    margin: 'auto',
    borderRadius: 12,
    overflow: "scroll"
  },
  titleContainer: { display: 'flex', justifyContent: 'center' },
  termsContent: { 
    flex: 1,
    height: '300px', 
    padding: "30px", 
    borderRadius: 10, 
    color: '#CFD1D4', 
    backgroundColor: '#252527',
    scrollbarWidth: 'auto',
    overflowY: 'scroll',
    overflowX: 'hidden', scrollbarColor: "#676767 #252527"
  },
  termText: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 400,
    color: '#CFD1D4',
  },
  efectiveDateTitle: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 700,
    color: '#CFD1D4',
  },
  title: {
    width: '40%',
    fontSize: '20px',
    color: 'rgb(207, 209, 212)',
    textAlign: 'center' as const,
    fontWeight: 700,
  },
  subtitleContainer: {
    marginTop: 20,
    marginBottom: 30,
  },
  bttnAccept: {
    backgroundColor: '#FF881A',
    color: '#1D1D1F',
    borderRadius: 8,
    width: "160px",
    fontFamily: 'Montserrat',
    fontSize: '18px',
    fontWeight: 600
    
  },
  TermsConditionWrapper: {
    display: "flex",
              flexDirection: "row"
  }
}
// Customizable Area End
