export const triangle = require("../assets/triangle.png");
export const progressBar = require("../assets/Circle.png");
export const settingIcon = require("../assets/settings.png");
export const progressIcon = require("../assets/Progress.png");
export const mailIcon = require("../assets/mail.png");
export const qrCodeIcon = require("../assets/qrcode.png");
export const helpcentre2 = require("../assets/helpcentre2.png");
export const payPalLogo = require("../assets/paypal.png");
export const rightArrow = require("../assets/Icon.png");
export const accountIcon = require("../assets/account.png");
export const backgroundImage = require("../assets/background.png");
export const gradientImage = require("../assets/gradient.png");
export const fixIcon = require("../assets/fix.png");
export const logoIcon = require("../assets/logo.png");
export const logoWhiteIcon = require("../assets/logo-white.png");
export const settingItemIcon = require("../assets/setting.png");
export const playIcon = require("../assets/play.png");
export const searchIcon = require("../assets/search.png");
export const video1Image = require("../assets/video1.png");
